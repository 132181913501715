import { createSelector } from 'reselect'
import map from 'lodash/map'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'

import { combineEmployeeAttributeFilters } from 'utils/emplify/employee_attributes'
import { selectEmployeeAttributeFilters } from 'selectors/attribute_filters'

import { selectSelectedCampaignCategories } from 'selectors/categories'
import { selectRootGroup } from 'selectors/v3_groups'
import { selectStatementsForCampaign } from 'selectors/statements'
import { selectHasOrganizationResultsReadPermission } from 'selectors/user'
import { selectPermittedGroupsWithSelectedCampaignParticipation } from 'selectors/v3_group_participations'
import { selectSelectedCampaign } from 'selectors/campaigns'
import { selectCurrentOrganizationId } from 'selectors/organizations'

import { createEntityGetter, createPropGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'

/**
 * Selects / determines the correct GroupId based upon the assessments path.
 * If a groupId is not provided in the assessments path:
 * For Admin or Full Results User, the OrganizationGroupId will be return
 * Otherwise, it returns the id of the permitted group with the most members
 */
export const selectPathOrDefaultAssessmentGroupId = createSelector(
  selectRootGroup,
  createPropGetter('pathGroupId'),
  selectHasOrganizationResultsReadPermission,
  selectPermittedGroupsWithSelectedCampaignParticipation,
  (organizationGroup, pathGroupId, hasOrganizationLevelAccess, groups) => {
    if (!pathGroupId || hasOrganizationLevelAccess) {
      // org level or zero results access (stripped down version) go directly to org group
      if (hasOrganizationLevelAccess) {
        return get(organizationGroup, 'id', null)
      }

      const sortedPermissionGroups = orderBy(
        filter(groups, (group) => !group.isOrganizationGroup),
        'campaignMembershipCount',
        'desc',
      )

      return get(sortedPermissionGroups, '[0].id', null)
    }

    return pathGroupId
  },
)

/**
 * Selects the AssessmentStatementResults for the prop provided groupId and campaignId
 * This returns ALL of the assessment statement results for the group and campaign (filter, segmented, unfiltered/unsegmented)
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.groupId GroupId
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {String[]} Array of AssessmentStatementResults for provided group and campaign
 */
export const selectAssessmentStatementResults = createSelector(
  [
    createPropGetter('groupId'),
    createPropGetter('campaignId'),
    createEntityGetter(entities.ASSESSMENT_STATEMENT_RESULTS),
  ],
  (groupId, campaignId, assessmentStatementResults) =>
    filter(
      assessmentStatementResults,
      (statementResults) =>
        `${statementResults.campaignId}` === `${campaignId}` &&
        `${statementResults.v3GroupId}` === `${groupId}`,
    ),
)

/**
 * Selects AssessmentStatementResults broken down by category and statement for a props provided group and campaign
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.organizationId OrganizationId
 * @param {String|Number} ownProps.groupId GroupId
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {String[]} Array of AssessmentResults broken down by category
 */
export const selectAssessmentResultsByCategory = createSelector(
  [
    createPropGetter('organizationId'),
    selectSelectedCampaign,
    selectSelectedCampaignCategories,
    selectAssessmentStatementResults,
    selectStatementsForCampaign,
    selectEmployeeAttributeFilters,
  ],
  (
    organizationId,
    campaign,
    categories,
    assessmentStatementResults,
    campaignStatements,
    employeeAttributeFilters,
  ) => {
    // NOTE: Don't return categories if there aren't statement results
    if (assessmentStatementResults && assessmentStatementResults.length === 0) {
      return []
    }

    const filterAttributes = combineEmployeeAttributeFilters(
      employeeAttributeFilters,
    )

    // NOTE: If there are statements, and they aren't associated with a category, we'll display them in a survey card.
    const themes =
      categories && categories.length > 0
        ? categories
        : [{ id: null, title: campaign.name }] // id: null supports a categoryId of null

    return themes.map((category) => {
      const categoryResults = filter(assessmentStatementResults, (result) => {
        if (filterAttributes && filterAttributes.length > 0) {
          return (
            `${result.categoryId}` === `${category.id}` &&
            `${result.attributeFilters}` === `${filterAttributes.join(',')}`
          )
        }
        return `${result.categoryId}` === `${category.id}`
      })
      const results = categoryResults.map((result) => {
        const statementId = `${result.statementId}:campaignId=${campaign.id},organizationId=${organizationId}`
        const statement = campaignStatements[statementId] || {}

        return {
          ...result,
          statement,
        }
      })

      const displayTitle = category.title

      return {
        ...category,
        displayTitle,
        results,
      }
    })
  },
)

/**
 * Selects AssessmentStatementResults broken down by category and statement for a props provided group and campaign
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.organizationId OrganizationId
 * @param {String|Number} ownProps.groupId GroupId
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {String[]} Array of AssessmentResults broken down by category
 */
export const selectCustomSurveyResults = createSelector(
  selectCurrentOrganizationId,
  selectStatementsForCampaign,
  selectAssessmentStatementResults,
  selectEmployeeAttributeFilters,
  (
    organizationId,
    campaignStatements,
    assessmentStatementResults,
    employeeAttributeFilters,
  ) => {
    if (assessmentStatementResults && assessmentStatementResults.length === 0) {
      return {}
    }

    const filterAttributes = combineEmployeeAttributeFilters(
      employeeAttributeFilters,
    )

    const surveyResults = {}
    forEach(assessmentStatementResults, (result) => {
      const {
        surveyPromptId,
        promptId,
        campaignId,
        surveyId,
        promptDisplayValue,
        surveyName,
      } = result

      if (!surveyResults[surveyId]) {
        surveyResults[surveyId] = {
          surveyId,
          title: surveyName,
          themes: {},
        }
      }

      const theme = {
        promptId,
        campaignId,
        surveyId,
        surveyPromptId,
        displayTitle: promptDisplayValue,
      }

      if (!surveyResults[surveyId].themes[theme.surveyPromptId]) {
        surveyResults[surveyId].themes[theme.surveyPromptId] = theme
      }
    })

    forEach(surveyResults, (section) => {
      const sectionResults = map(section.themes, (theme) => {
        const themeResults = filter(assessmentStatementResults, (result) => {
          if (filterAttributes && filterAttributes.length > 0) {
            return (
              `${result.surveyPromptId}` === `${theme.surveyPromptId}` &&
              `${result.attributeFilters}` === `${filterAttributes.join(',')}`
            )
          }

          return (
            `${result.surveyPromptId}` === `${theme.surveyPromptId}` &&
            !result.attributeFilters
          )
        })

        const results = themeResults.map((result) => {
          const statementId = `${result.statementId}:campaignId=${result.campaignId},organizationId=${organizationId}`
          const statement = campaignStatements[statementId] || {}

          return {
            ...result,
            statement,
          }
        })

        return {
          ...theme,
          results,
        }
      })

      surveyResults[section.surveyId].results = sectionResults
    })

    return surveyResults
  },
)
