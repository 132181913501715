import { createSelector } from 'reselect'
import { encodeFilterAttributes } from 'utils/emplify/filters'

export function getFilters(state) {
  return state.attributeFilters.filters
}

function getAttributeFilters(state) {
  return state.attributeFilters.attributeFilters
}

function getEmployeeAttributeFilters(state) {
  return state.attributeFilters.attributeFilters.employeeAttributes
}

export const selectEmployeeAttributeFilters = createSelector(
  [getEmployeeAttributeFilters],
  filters => {
    if (!filters || filters.length < 1) {
      return null
    }
    return [...encodeFilterAttributes(filters)].sort()
  }
)

export const selectHasActiveFilters = createSelector(
  [selectEmployeeAttributeFilters],
  employeeAttributes => {
    return employeeAttributes && employeeAttributes.length > 0
  }
)

export const selectAttributeFilters = createSelector(
  getAttributeFilters,
  filters => {
    const filterkeys = Object.keys(filters || {})

    if (filterkeys.length === 0) {
      return null
    }

    const query = {}
    filterkeys.forEach(filter => {
      if (filters[filter] && filters[filter].length > 0) {
        query[`filter[${filter}]`] = filters[filter]
      }
    })
    return Object.keys(query).length > 0 ? query : null
  }
)
