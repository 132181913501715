import { createSelector } from 'reselect'
import get from 'lodash/get'
import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import endsWith from 'lodash/endsWith'
import { entities } from 'dux/api/action_types'

import {
  selectLatestCampaignId,
  selectSelectedCampaignId,
  selectOrderedRecentCampaigns,
  selectCampaignConfidentiality
} from 'selectors/campaigns'
import {
  getSelectedV3Group,
  getSelectedV3GroupId,
  selectSelectedV3Group,
  selectSelectedGroupChildren,
  selectRootGroup
} from 'selectors/v3_groups'
import { selectPermittedV3Groups } from 'selectors/group_filter'
import { selectAttributeFilters } from 'selectors/attribute_filters'

import {
  createEntityGetter,
  createPropGetter,
  createPhaseSelector
} from 'utils/selectors'
import { percentage } from 'utils/native/numbers'
import { buildGroupResultId } from 'utils/emplify/campaign_results'
import { getSerializedAttributeFilters } from 'utils/emplify/id_helper'
import { buildFilterableParticipationId } from 'utils/emplify/participation'

/**
 * Selects the phase for the group response rate
 * @param {Object} state Entire redux state tree
 * @returns {String} Group response rate phase
 */
export const selectGroupResponseRatePhase = createPhaseSelector(
  {
    entity: entities.CAMPAIGNS,
    mapProps: props => ({ organizationId: props.organizationId })
  },
  {
    entity: entities.V3_GROUP_RESULTS,
    mapProps: props => ({
      organizationId: props.organizationId,
      groupId: props.groupId,
      ...props.attributeFilters
    })
  }
)

/**
 * Selects the participations for a v3 group for the most recent campaigns
 * @param {Object} state Entire redux state tree
 * @returns {String} Group response rate phase
 */
export const selectResultsParticipationsForGroup = createSelector(
  selectOrderedRecentCampaigns,
  createEntityGetter(entities.V3_GROUP_RESULTS),
  createPropGetter('groupId'),
  selectAttributeFilters,
  (campaigns, groupResults, groupId, attributeFilters) =>
    campaigns
      .map(campaign => {
        const groupResultId = buildGroupResultId(
          campaign.id,
          groupId,
          attributeFilters
        )
        return groupResults[groupResultId] || null
      })
      .map(groupResult => {
        if (!groupResult) {
          return null
        }
        const completedCount = groupResult.completedSurveyCount
        const size = groupResult.campaignAudienceSize
        return {
          completedCount,
          size,
          percentage: size === 0 ? null : percentage(completedCount, size, 2)
        }
      })
)

export const selectRecentCampaignParticipations = createSelector(
  selectOrderedRecentCampaigns,
  createPropGetter('groupId'),
  selectAttributeFilters,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  (campaigns, groupId, attributeFilters, participations) => {
    return map(campaigns, campaign => {
      const participationId = buildFilterableParticipationId(
        campaign.id,
        groupId,
        attributeFilters
      )
      const participation = get(participations, [participationId], {})
      const { completedCount, size } = participation
      if (size && completedCount) {
        return {
          completedCount,
          size,
          percentage: size === 0 ? null : percentage(completedCount, size, 2),
          campaign
        }
      }
      return undefined
    }).filter(item => item !== undefined)
  }
)

const selectVisibleGroupIds = createSelector(
  selectSelectedCampaignId,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('attributeFilters'),
  selectCampaignConfidentiality,
  (campaign, participations, attributeFilters, confidentialityLevel) =>
    Object.values(participations)
      .filter(
        participation =>
          `${participation.campaignId}` === `${campaign.id}` &&
          endsWith(
            participation.id,
            getSerializedAttributeFilters(attributeFilters)
          ) &&
          participation.completedCount >= confidentialityLevel
      )
      .map(participation => `${participation.v3GroupId}`)
)

export const selectVisibleGroups = createSelector(
  selectVisibleGroupIds,
  selectSelectedGroupChildren,
  (visibleGroupIds, groups) =>
    groups.filter(group => includes(visibleGroupIds, group.id))
)

export const selectHiddenGroups = createSelector(
  selectVisibleGroupIds,
  selectSelectedGroupChildren,
  (visibleGroupIds, groups) =>
    groups.filter(group => !includes(visibleGroupIds, group.id))
)

export const selectSortedHiddenGroups = createSelector(
  selectSelectedCampaignId,
  selectHiddenGroups,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('attributeFilters'),
  (campaignId, hiddenGroups, participations, attributeFilters) => {
    const initialHiddenGroups = hiddenGroups.map(group => ({
      ...group,
      hiddenGroup: true
    }))

    const viewableHiddenGroups = initialHiddenGroups.filter(group => {
      const groupParticipationId = buildFilterableParticipationId(
        campaignId,
        group.id,
        attributeFilters
      )
      const groupParticipation = participations[groupParticipationId]

      return groupParticipation && groupParticipation.size > 0
    })

    return sortBy(viewableHiddenGroups, 'name').reverse()
  }
)

/**
 * Selects an UN-filtered participation for a group for the latest campaign.
 * Could be extended to support other campaigns.
 * @param {Object} state
 * @param {Object} props
 * @param {Number|String} [props.groupId]
 * @param {Object} [props.match]
 * @param {Object} [props.match.params]
 * @param {String} [props.match.params.id]
 */
const selectParticipation = createSelector(
  [
    createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
    getSelectedV3GroupId,
    selectLatestCampaignId
  ],
  function getParticipation(participations = {}, groupId, campaignId) {
    return participations[`${groupId}:${campaignId}`]
  }
)

/**
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String|Number} [props.groupId] V3 Group ID manually passed-in
 * @param {Object} [props.campaign] Campaign object manually passed-in
 * @param {Object} [props.match]
 * @param {Object} [props.match.params]
 * @param {Object} [props.match.params.id] V3 Group ID from URL params
 * @returns {String} Null if no problems.
 *  A string describing why there aren't survey results otherwise.
 */
export const selectV3GroupParticipationWarning = createSelector(
  [selectParticipation, getSelectedV3Group, createPropGetter('campaign')],
  function getGroupSizeSufficient(groupParticipation, group, campaign) {
    if (groupParticipation) {
      if (groupParticipation.completedCount >= campaign.confidentialityLevel) {
        return null
      }
      if (groupParticipation.size < campaign.confidentialityLevel) {
        return `Group has fewer than ${
          campaign.confidentialityLevel
        } employees. Current survey results are unavailable.`
      }
      if (groupParticipation.completedCount < campaign.confidentialityLevel) {
        return `Group has fewer than ${
          campaign.confidentialityLevel
        } responses. Current survey results are unavailable.`
      }
    }
    if (group && group.size < campaign.confidentialityLevel) {
      return `Group has fewer than ${
        campaign.confidentialityLevel
      } employees. Survey results will not be available.`
    }
    return null
  }
)

export const selectGroupParticipationForSelectedCampaign = createSelector(
  createPropGetter('campaignId'),
  createPropGetter('groupId'),
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('attributeFilters'),
  (campaignId, groupId, participations, attributeFilters) => {
    const groupParticipationId = buildFilterableParticipationId(
      campaignId,
      groupId,
      attributeFilters
    )
    return participations[groupParticipationId]
  }
)

/**
 * Selects a Group with a props provided campaignId (unfiltered/filtered) campaignMembershipCount participation
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.campaignId CampaignId
 * @param {String|Number} ownProps.groupId GroupId
 * @returns {Object} A group with attached campaignMembershipCount
 */
export const selectSelectedV3GroupWithCampaignParticipation = createSelector(
  createPropGetter('campaignId'),
  selectSelectedV3Group,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('attributeFilters'),
  (campaignId, group = {}, participations, attributeFilters) => {
    const groupParticipationId = buildFilterableParticipationId(
      campaignId,
      group.id,
      attributeFilters
    )
    const campaignMembershipCount = get(
      participations[groupParticipationId],
      'size',
      0
    )

    return {
      ...group,
      campaignMembershipCount
    }
  }
)

/**
 * Selects OrganizationGroup with a props provided campaignId
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {Object} The OrganizationGroup with attached campaignMembershipCount
 */
export const selectOrganizationGroupWithSelectedCampaignParticipation = createSelector(
  createPropGetter('campaignId'),
  selectRootGroup,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  (campaignId, organizationGroup, participations) => {
    const campaignMembershipCount = get(
      participations[`${campaignId}:${organizationGroup.id}`],
      'size',
      0
    )

    return {
      ...organizationGroup,
      campaignMembershipCount
    }
  }
)

/**
 * Selects an array of Permitted Group with a props provided campaignId (unfiltered/filtered)
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {Object[]} Array of groups with attached campaignMembershipCount
 */
export const selectPermittedGroupsWithSelectedCampaignParticipation = createSelector(
  createPropGetter('campaignId'),
  selectPermittedV3Groups,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  (campaignId, groups = [], participations) =>
    map(groups, group => {
      const campaignMembershipCount = get(
        participations[`${campaignId}:${group.id}`],
        'size',
        0
      )

      return {
        ...group,
        campaignMembershipCount
      }
    })
)

/**
 * Determine if a group is in a less than 5 state based on the latest participation.
 * If there is zero campaigns finished, then the less than five rule is unnecessary.
 * @param {Object} state Entire redux state tree
 * @returns {Boolean} isLessThanFiveGroup?
 */
export const selectIsLessThanFiveGroup = createSelector(
  createPropGetter('campaignId'),
  selectGroupParticipationForSelectedCampaign,
  (campaignId, participation) => {
    if (!campaignId) {
      return false
    }

    return participation && participation.completedCount < 5
  }
)

/**
 * Determine if a group is in a less than the selected campaign's confidentiality state based on the group participation.
 * If there is zero campaigns finished, then the rule is unnecessary.
 * @param {Object} state Entire redux state tree
 * @returns {Boolean} isLessThanCampaignConfidentialityGroup?
 *
 */
export const selectIsLessThanCampaignConfidentialityGroup = createSelector(
  createPropGetter('campaignId'),
  selectGroupParticipationForSelectedCampaign,
  selectCampaignConfidentiality,
  (campaignId, participation, confidentiality) => {
    if (!campaignId) {
      return false
    }

    return participation && participation.completedCount < confidentiality
  }
)
