import reducer from 'dux/helpers/reducer'

const initialState = {
  availableToSend: false
}

// Constants (i.e. action types)
const SET_AVAILABLE_TO_SEND = 'assessmentUploadPreview/SET_AVAILABLE_TO_SEND'

function reduceSetAvailableToSend(state = initialState, action) {
  const { availableToSend } = action

  return {
    ...state,
    availableToSend
  }
}

export default reducer(
  {
    [SET_AVAILABLE_TO_SEND]: reduceSetAvailableToSend
  },
  initialState
)

function setAvailableToSend(availableToSend) {
  return {
    type: SET_AVAILABLE_TO_SEND,
    availableToSend
  }
}

export { setAvailableToSend }
