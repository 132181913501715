import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

function RadioButton({
  children,
  className,
  onCheck,
  name,
  label,
  checked,
  disabled,
  description
}) {
  return (
    <div className={cn('radio-button-content', className)}>
      <label className='radio-button-container'>
        <input
          type='radio'
          disabled={disabled}
          checked={checked}
          onChange={e => {
            if (e.target.checked) {
              onCheck(name)
            }
          }}
        />
        <span className={cn('radio-button', { disabled })} />
      </label>
      <div className='radio-button-info'>
        <div className='radio-button-label-container'>
          {!!label && label}
          {!!description && (
            <div className='radio-button-description'>{description}</div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

RadioButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onCheck: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

RadioButton.defaultProps = {
  children: null,
  className: null,
  onCheck: () => {},
  name: null,
  label: null,
  checked: null,
  disabled: false,
  description: null
}

export default RadioButton
