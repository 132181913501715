import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import get from 'lodash/get'

class V2ArrowSelectMenu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      initialElement: null,
      menuItems: [],
      currentIndex: 0
    }

    this.onKeyDown = this.onKeyDown.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    const { initialElementId, menuId } = this.props

    const initialElement = initialElementId
      ? document.getElementById(initialElementId)
      : null

    const menuItems = get(document.getElementById(menuId), 'childNodes', [])

    this.setState({ initialElement, menuItems })
  }

  onKeyDown(e) {
    const { initialElement, menuItems, currentIndex } = this.state

    if (e.key === 'ArrowUp') {
      if (initialElement && currentIndex === 0) {
        initialElement.focus()
      }

      if (currentIndex > 0) {
        this.next(currentIndex - 1)
      }
    }

    if (e.key === 'ArrowDown' && currentIndex < menuItems.length - 1) {
      this.next(currentIndex + 1)
    }
  }

  next(index) {
    const { menuItems } = this.state

    if (menuItems[index]) {
      menuItems[index].focus()
      this.setState({ currentIndex: index })
    }
  }

  render() {
    const { menuId, children, className } = this.props

    const cx = cn('furniture-v2-selectable-menu', {
      [className]: !!className
    })

    return (
      <div className={cx} id={menuId}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            onKeyDown: this.onKeyDown
          })
        })}
      </div>
    )
  }
}

V2ArrowSelectMenu.propTypes = {
  initialElementId: PropTypes.string,
  menuId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

V2ArrowSelectMenu.defaultProps = {
  initialElementId: null,
  className: null
}

export default V2ArrowSelectMenu
