/**
 * Enum for campaign types.
 * @readonly
 * @enum {string}
 */
export const CAMPAIGN_TYPES = {
  ALL: 'all',
  FLEXIBLE: 'flexible',
  QUARTERLY: 'quarterly',
  TOPIC_BASED: 'topic_based',
  SMART_PULSE: 'smart_pulse',
  QUARTERLY_AND_TOPIC_BASED: ['quarterly', 'topic_based'],
  CUSTOM: 'custom',
  LEGACY_CUSTOM: 'legacy_custom',
  LIFECYCLE: 'lifecycle',
}

export const CAMPAIGN_TYPE_NAMES = {
  [CAMPAIGN_TYPES.ALL]: 'All',
  [CAMPAIGN_TYPES.FLEXIBLE]: 'Flexible',
  [CAMPAIGN_TYPES.QUARTERLY]: 'Quarterly',
  [CAMPAIGN_TYPES.TOPIC_BASED]: 'Topic Based',
  [CAMPAIGN_TYPES.SMART_PULSE]: 'Smart Pulse',
  [CAMPAIGN_TYPES.CUSTOM]: 'Custom',
  [CAMPAIGN_TYPES.LIFECYCLE]: 'Lifecycle',
}

export const FEEDBACK_TYPE_NAMES = {
  [CAMPAIGN_TYPES.ALL]: 'All',
  [CAMPAIGN_TYPES.FLEXIBLE]: 'Flexible',
  [CAMPAIGN_TYPES.QUARTERLY]: 'Dynamic',
  [CAMPAIGN_TYPES.TOPIC_BASED]: 'Topic Based',
  [CAMPAIGN_TYPES.SMART_PULSE]: 'Smart Pulse',
  [CAMPAIGN_TYPES.CUSTOM]: 'Custom',
}

export default CAMPAIGN_TYPES
