import CampaignTypes from 'constants/campaign_types'

export const CAMPAIGN_STATUSES = {
  ERRORED: 'errored',
  PENDING: 'pending',
  ACTIVE: 'active',
  PREPARED: 'prepared',
  INPROGRESS: 'inprogress',
  FINISHED: 'finished'
}

export const CAMPAIGN_STATUS_LABELS = {
  [CAMPAIGN_STATUSES.ERRORED]: 'Errored',
  [CAMPAIGN_STATUSES.PENDING]: 'Not Confirmed',
  [CAMPAIGN_STATUSES.ACTIVE]: 'Confirmed',
  [CAMPAIGN_STATUSES.PREPARED]: 'Confirmed',
  [CAMPAIGN_STATUSES.INPROGRESS]: 'In-Progress',
  [CAMPAIGN_STATUSES.FINISHED]: 'Completed'
}

export const SENT_CAMPAIGN_STATUSES = [
  CAMPAIGN_STATUSES.PREPARED,
  CAMPAIGN_STATUSES.INPROGRESS,
  CAMPAIGN_STATUSES.FINISHED,
  CAMPAIGN_STATUSES.ERRORED
]

export const CAMPAIGN_PARTICIPANTS = {
  EVERYONE: 'Everyone',
  SPECIFIC: 'Specific Employees'
}

export const CAMPAIGN_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  IN_PROGRESS: 'inprogress',
  COMPLETED: 'finished'
}

export const CAMPAIGN_STATUS_DISPLAY = {
  [CAMPAIGN_STATUS.PENDING]: 'Not Confirmed',
  [CAMPAIGN_STATUS.ACTIVE]: 'Confirmed',
  [CAMPAIGN_STATUS.IN_PROGRESS]: 'In Progress',
  [CAMPAIGN_STATUS.COMPLETED]: 'Completed'
}

export const CAMPAIGN_FILTER_CHIPS = {
  [CAMPAIGN_PARTICIPANTS.EVERYONE]: 'Everyone',
  [CAMPAIGN_PARTICIPANTS.SPECIFIC]: 'Specific Employees',
  [CAMPAIGN_STATUS_DISPLAY[CAMPAIGN_STATUS.PENDING]]: 'Not Confirmed',
  [CAMPAIGN_STATUS_DISPLAY[CAMPAIGN_STATUS.IN_PROGRESS]]: 'In Progress',
  [CAMPAIGN_STATUS_DISPLAY[CAMPAIGN_STATUS.ACTIVE]]: 'Confirmed',
  [CAMPAIGN_STATUS_DISPLAY[CAMPAIGN_STATUS.COMPLETED]]: 'Completed'
}

export const campaignStatusFieldInitial = Object.values(CAMPAIGN_STATUS_DISPLAY)
export const campaignParticipantsFieldInitial = Object.values(
  CAMPAIGN_PARTICIPANTS
)

export const CREATION_STEPS = {
  [CampaignTypes.QUARTERLY]: ['Survey Setup', 'Review'],
  [CampaignTypes.CUSTOM]: ['Ask', 'Who', 'When'],
  [CampaignTypes.TOPIC_BASED]: ['Assessment Setup', 'Review']
}

export const MIN_NOTIFICATION_COUNT = {
  [CampaignTypes.QUARTERLY]: 4,
  [CampaignTypes.TOPIC_BASED]: 4,
  [CampaignTypes.CUSTOM]: 2
}

export const CUSTOM_CAMPAIGNS_VIEWS = {
  ACTIVE: 'active',
  COMPLETED: 'completed'
}

export const INVITATION_TYPES = {
  INVITE: 'invite',
  REMINDER: 'reminder',
  FINAL: 'final'
}

export const CAMPAIGN_RELATED_ENTITIES = {
  CAMPAIGNS: 'campaigns',
  CAMPAIGN_NOTIFICATIONS: 'campaign-notifications',
  CAMPAIGN_TARGETS: 'campaign-targets',
  CAMPAIGN_SURVEYS: 'campaign-surveys'
}

// TODO: If we want to support multiple locales,
// we will need to pull templates for those languages.
export const MESSAGE_TEMPLATE_DEFAULTS = {
  [CampaignTypes.QUARTERLY]: [],
  [CampaignTypes.CUSTOM]: [
    'en-us-custom-email-body',
    'en-us-custom-email-subject-invite',
    'en-us-custom-email-subject-final',
    'en-us-custom-email-subject-reminder'
  ]
}

export const MESSAGE_TEMPLATE_TREE = {
  [CampaignTypes.QUARTERLY]: {},
  [CampaignTypes.CUSTOM]: {
    [INVITATION_TYPES.INVITE]: {
      subject: 'en-us-custom-email-subject-invite',
      body: 'en-us-custom-email-body'
    },
    [INVITATION_TYPES.REMINDER]: {
      subject: 'en-us-custom-email-subject-reminder',
      body: 'en-us-custom-email-body'
    },
    [INVITATION_TYPES.FINAL]: {
      subject: 'en-us-custom-email-subject-final',
      body: 'en-us-custom-email-body'
    }
  }
}
