import reducer from 'dux/helpers/reducer'

const initialState = {
  templateFilter: null
}

// Constants (i.e. action types)
const SET_TEMPLATE_FILTER = 'campaign_participation/SET_TEMPLATE_FILTER'

// Reducers
function reduceSetTemplateFilter(state = initialState, action) {
  const { templateFilter } = action

  if (typeof templateFilter !== 'string') {
    return state
  }

  return {
    ...state,
    templateFilter,
    page: 0
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_TEMPLATE_FILTER]: reduceSetTemplateFilter
  },
  initialState
)

function setTemplateFilter(templateFilter) {
  return {
    type: SET_TEMPLATE_FILTER,
    templateFilter
  }
}

// Export necessary action types and action creators
export { setTemplateFilter }
