import reducer from 'dux/helpers/reducer'

const initialState = {
  isUserIdentified: false,
  flags: {}
}

// Constants
const SET_FLAGS = 'launchDarkly/SET_FLAGS'
const SET_USER_IDENTIFIED = 'launchDarkly/USER_IDENTIFIED'

function reduceSetLaunchDarklyFlags(state, action) {
  return {
    ...state,
    flags: action.flags
  }
}

function reduceSetLaunchDarklyUserIdentified(state) {
  return {
    ...state,
    isUserIdentified: true
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_FLAGS]: reduceSetLaunchDarklyFlags,
    [SET_USER_IDENTIFIED]: reduceSetLaunchDarklyUserIdentified
  },
  initialState
)

// Action creators
function setLaunchDarklyFlags(flags) {
  return {
    type: SET_FLAGS,
    flags
  }
}

function setLaunchDarklyUserIdentified() {
  return {
    type: SET_USER_IDENTIFIED
  }
}

// Export necessary action types and action creators
export { setLaunchDarklyFlags, setLaunchDarklyUserIdentified }
