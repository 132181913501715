import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import { createSelector } from 'reselect'
import { createPropGetter } from 'utils/selectors'

const EMPTY_OBJECT = {}

function getEmplifyForms(state) {
  return state.emplifyForm || EMPTY_OBJECT
}

export const selectEmplifyForm = createSelector(
  createPropGetter('formName'),
  getEmplifyForms,
  (formName, forms) => forms[formName] || EMPTY_OBJECT
)

export const selectEmplifyFormData = createSelector(
  selectEmplifyForm,
  form => form.data || EMPTY_OBJECT
)

export const selectEmplifyFormErrorData = createSelector(
  selectEmplifyForm,
  form => form.errorData || EMPTY_OBJECT
)

export const selectEmplifyFormInitialData = createSelector(
  selectEmplifyForm,
  form => form.initialData || EMPTY_OBJECT
)

export const selectIsEmplifyFormValid = createSelector(
  selectEmplifyForm,
  form => form.isValid || false
)

export const selectIsEmplifyFormDirty = createSelector(
  selectEmplifyForm,
  form => form.dirty || false
)

export const selectIsEmplifyFormFieldDirty = createSelector(
  selectEmplifyFormInitialData,
  selectEmplifyFormData,
  createPropGetter('field'),
  (initialData, data, field) =>
    !isEqual(get(initialData, field), get(data, field))
)
