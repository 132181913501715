import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Popover, { POPOVER_POSITIONS } from 'furniture/popover'

import VocabDefinition from './vocab_definition'

function Vocab({
  onClick,
  children,
  dict,
  dictProps,
  canMouseOverDefinition,
  spanClassName,
  position,
  prompt,
  className
}) {
  const [showPopover, setShowPopover] = useState(false)
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    show()
  }
  const show = () => {
    setShowPopover(true)
  }

  const hide = () => {
    setShowPopover(false)
  }

  const { word } = dict

  if (!word) {
    return (
      <span
        className={cn(spanClassName, {
          'education-vocab': !!word
        })}
      >
        {children}
      </span>
    )
  }

  return (
    <Popover
      open={showPopover}
      onClickOutside={hide}
      anchorEl={
        <span
          onMouseLeave={hide}
          onMouseEnter={show}
          onClick={handleClick}
          className={cn(spanClassName, className, {
            'education-vocab': !!word
          })}
          role='tooltip'
        >
          {children}
        </span>
      }
      className='dropdown-menu-popover-root'
      position={position}
      shouldMatchAnchorWidth={false}
    >
      <div
        onMouseLeave={hide}
        onMouseEnter={() => canMouseOverDefinition && show()}
        className={cn('education-vocab-tooltip')}
      >
        <VocabDefinition dict={dict} dictProps={dictProps} prompt={prompt} />
      </div>
    </Popover>
  )
}

Vocab.propTypes = {
  onClick: PropTypes.func,
  // the node to be defined, usually a string
  children: PropTypes.node,
  // our dictionary definition, with a function for context definitions
  dict: PropTypes.shape({
    word: PropTypes.string,
    definition: PropTypes.func
  }),
  dictProps: PropTypes.object,
  canMouseOverDefinition: PropTypes.bool,
  spanClassName: PropTypes.string,
  position: PropTypes.oneOf(Object.values(POPOVER_POSITIONS)),
  prompt: PropTypes.string,
  className: PropTypes.string
}
Vocab.defaultProps = {
  onClick: () => {},
  children: '',
  dict: {
    word: '',
    definition: () => ''
  },
  dictProps: {},
  canMouseOverDefinition: false,
  spanClassName: '',
  position: POPOVER_POSITIONS.BOTTOM,
  prompt: null,
  className: ''
}

export default Vocab
