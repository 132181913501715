export const RESULTS_EXPERIENCE_TYPES = {
  ORG_MODE: 'org-mode',
  LIMITED_ACCESS_VIEWS: 'limited-access-views',
  FULL_ACCESS: 'full-access'
}

RESULTS_EXPERIENCE_TYPES.all = () => [
  RESULTS_EXPERIENCE_TYPES.ORG_MODE,
  RESULTS_EXPERIENCE_TYPES.LIMITED_ACCESS_VIEWS,
  RESULTS_EXPERIENCE_TYPES.FULL_ACCESS
]
