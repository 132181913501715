import get from 'lodash/get'

export function getSelectedNavItem(state) {
  return get(state, ['v2Nav', 'selectedNavItem'])
}

export function getSelectedNavSubMenuItem(state) {
  return get(state, ['v2Nav', 'selectedNavSubMenuItem'])
}

export function getIsNavCollapsed(state) {
  return get(state, ['v2Nav', 'isCollapsed'])
}
