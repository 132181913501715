/**
 * @readonly
 * @enum {string}
 */
const LOGGER_TYPES = {
  REDIRECT: 'REDIRECT',
  ANALYTICS: 'ANALYTICS',
  API: 'API'
}

const LOGGER_TYPE_DESCRIPTIONS = {
  REDIRECT:
    'Enables the logging of the when store and force a redirect path during a session',
  ANALYTICS:
    'Enables logging analytic events to the console. Blue if the a client tried submitting the event, and red if no client is enabled',
  API: 'Enable trace logging for our api'
}

/**
 * @readonly
 * @enum {string}
 */
const LOGGER_TYPE_ENABLE_KEYS = {
  [LOGGER_TYPES.REDIRECT]: 'enableRedirectLogger',
  [LOGGER_TYPES.ANALYTICS]: 'enableAnalyticsLogger',
  [LOGGER_TYPES.API]: 'enableApiLogger'
}

/**
 * @readonly
 * @enum {string}
 */
const LOGGER_ENABLE_VALUES = {
  TRUE: 'true',
  VERBOSE: 'verbose'
}

export {
  LOGGER_TYPES,
  LOGGER_TYPE_ENABLE_KEYS,
  LOGGER_ENABLE_VALUES,
  LOGGER_TYPE_DESCRIPTIONS
}
