import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'
import { CLEAR_CAMPAIGN_NOTIFICATIONS, entities } from 'dux/api/action_types'

/**
 * Get all campaign notifications for a single campaign.
 * @param {String} organizationId - organizationId for the campaign
 * @param {String} campaignId - campaignId you want to fetch notifications for
 * @param {Object} passedOptions - additional options
 */
export function getCampaignNotificationsAsync(
  organizationId,
  campaignId,
  passedOptions = {}
) {
  const params = { organizationId, campaignId }
  const options = { ...passedOptions, entity: entities.CAMPAIGN_NOTIFICATIONS }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/notifications',
    params,
    options
  )
}

/**
 * Resend a campaign notification to non-respondants.
 * @param {String} organizationId
 * @param {String} campaignId
 * @param {String} notificationId
 * @param {Object} data
 */
export function resendCampaignNotificationAsync(
  organizationId,
  campaignId,
  notificationId,
  data
) {
  const params = { organizationId, campaignId, notificationId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/notifications/:notificationId/resend',
    params,
    data,
    {
      toastMessage: 'Successfully re-sent messages'
    }
  )
}

export function deleteCampaignNotificationAsync(
  organizationId,
  campaignId,
  notificationId,
  toastId,
  toastMessage
) {
  const params = { organizationId, campaignId, notificationId }

  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/campaigns/:campaignId/notifications/:notificationId',
    params,
    { entity: entities.CAMPAIGN_NOTIFICATIONS, toastId, toastMessage }
  )
}

export function campaignNotificationsAndEndDateDefaultAsync(
  organizationId,
  campaignId
) {
  const params = { organizationId, campaignId }

  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/notifications/reset-to-default',
    params,
    { entity: entities.CAMPAIGN_NOTIFICATIONS }
  )
}

export function updateCampaignNotificationAsync(
  organizationId,
  campaignId,
  notificationId,
  data
) {
  const params = { organizationId, campaignId, notificationId }

  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/campaigns/:campaignId/notifications/:notificationId',
    params,
    data,
    { entity: entities.CAMPAIGN_NOTIFICATIONS }
  )
}

export function bulkUpdateCampaignNotificationsAsync(
  organizationId,
  campaignId,
  notifications
) {
  const params = { organizationId, campaignId }

  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/notifications/bulk-update',
    params,
    notifications,
    { entity: entities.CAMPAIGN_NOTIFICATIONS }
  )
}

/**
 * Remove a campaign's notifications from entity state.
 * NOTE: We should not use this sparingly. Instead, we should use DELETE, POST, and UPDATEs
 * to allow our frontend to reduce the updates through the normal workflow.
 * @param {Array} notificationIds - array of notifications to remove
 */
export function clearCampaignNotifications(notificationIds) {
  return {
    type: CLEAR_CAMPAIGN_NOTIFICATIONS,
    notificationIds
  }
}
