import React, { useState, Children, isValidElement, cloneElement } from 'react'
import PropTypes from 'prop-types'

import Popover, { POPOVER_POSITIONS } from 'furniture/popover'

import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { Button, BUTTON_RANKS } from 'furniture/buttons'

function MoreDropdown({ children, dataStaticId }) {
  const [showPopover, setShowPopover] = useState(false)

  return (
    <Popover
      open={showPopover}
      anchorEl={
        <Button
          onClick={() => {
            setShowPopover(true)
          }}
          icon={<FontAwesomeIcon icon={faEllipsisH} />}
          rank={BUTTON_RANKS.TERTIARY}
          className='furniture-more-dropdown'
          dataStaticId={dataStaticId}
        />
      }
      className='dropdown-menu-popover-root'
      onClickOutside={() => setShowPopover(false)}
      position={POPOVER_POSITIONS.BOTTOM}
    >
      {Children.map(children, child => {
        if (isValidElement(child)) {
          const { className, onClick } = child.props
          return cloneElement(child, {
            className,
            onClick: () => {
              setShowPopover(false)
              return onClick()
            }
          })
        }
        return child
      })}
    </Popover>
  )
}

MoreDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  dataStaticId: PropTypes.string
}

MoreDropdown.defaultProps = {
  dataStaticId: null
}

export default MoreDropdown
