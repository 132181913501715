import find from 'lodash/find'
import get from 'lodash/get'

/**
 * Pulls back the engage survey major verison to determine what benchmark set we should use
 * for driver scores.
 * @param {Object} campaign - current campaign
 * @param {Collection} surveys - all surveys
 * @param {Collection} campaignSurveys - all campaign surveys
 * @returns {Number} EngageSurvey's major version for current campaign.
 */
export function getEngageSurveyMajorVersion(
  campaign,
  surveys,
  campaignSurveys
) {
  const engageSurvey = find(
    surveys,
    survey =>
      survey.name === 'EngageSurvey' &&
      find(
        campaignSurveys,
        cs =>
          `${cs.surveyId}` === `${survey.id}` &&
          `${cs.campaignId}` === `${campaign.id}`
      )
  )

  return get(engageSurvey, 'version', '').split('.')[0]
}
