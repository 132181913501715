export function getShowObscuredGroups(state) {
  return state.results.showObscuredGroups
}

/**
 * @param {KickState} state state - The redux state tree.
 * @returns {String} The experience type we are providing for the results
 */
export function getExperienceType(state) {
  return state.results.experienceType
}

export function getGroupResultsGroupIdToFilterBy(state) {
  return state?.results?.pathGroupId
}
