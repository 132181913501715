import { createSelector } from 'reselect'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import filter from 'lodash/filter'
import values from 'lodash/values'
import find from 'lodash/find'
import { getDisplayName } from 'utils/emplify/employee'
import {
  createEntityGetter,
  createPhaseSelector,
  createPropGetter,
  createEntitySelector
} from 'utils/selectors'
import { selectOrganizations } from 'selectors/organizations'
import { entities, methods } from 'dux/api/action_types'

export const selectPeople = createSelector(
  createEntityGetter(entities.PEOPLE),
  people => people
)

export const selectPerson = createEntitySelector(entities.PEOPLE, 'personId')

export const selectPersonPhase = createPhaseSelector(
  entities.PEOPLE,
  methods.GET,
  props => ({ organizationId: props.organizationId, personId: props.personId })
)

export const selectCustomerSuccessPhase = createPhaseSelector(
  entities.CUSTOMER_SUCCESS,
  methods.GET
)

export const selectExecutiveAdvisorsPhase = createPhaseSelector(
  entities.EXECUTIVE_ADVISORS
)

export const selectPersonIdFromEmployee = createSelector(
  createEntityGetter(entities.EMPLOYEES),
  createPropGetter('employeeId'),
  (employees, employeeId) => {
    return get(employees[employeeId], 'personId', '')
  }
)

/**
 * @param state - The state that should have people and employees populated
 * @param props.employeeId - The employee ID to get the name
 */
export const selectPersonNameFromEmployeeId = createSelector(
  createEntityGetter(entities.PEOPLE),
  selectPersonIdFromEmployee,
  (people, personId) => getDisplayName(people[personId])
)

/**
 * @param state - The state that should have people and employees populated
 * @param props.personId - The employee ID to get the name
 */
export const selectPersonNameFromPersonId = createSelector(
  createEntityGetter(entities.PEOPLE),
  createPropGetter('personId'),
  (people, personId) => getDisplayName(people[personId])
)

/**
 * @param state - The state that should have people and employees populated
 * @param props.employeeId - The employee ID to get the photo
 */
export const selectPhotoUrlForEmployeeId = createSelector(
  createEntityGetter(entities.PEOPLE),
  selectPersonIdFromEmployee,
  (people, personId) => get(people[personId], 'photo')
)

/**
 * @param state - The state that should have people, person_roles, roles, and permissions to hydrate
 * @param props.roleNames - The roleNames to filter by (will only return people with these roles)
 * @param props.organizationId - The organization to filter the roles by
 */
export const selectPeopleWithHydratedRolesAndPermissions = createSelector(
  createEntityGetter(entities.PEOPLE),
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  createEntityGetter(entities.PERMISSIONS),
  selectOrganizations,
  createPropGetter('roleNames'),
  createPropGetter('organizationId'),
  (
    people,
    personRoles,
    roles,
    permissions,
    organizations,
    roleNames,
    organizationId
  ) => {
    const pickedRoles = roleNames
      ? pickBy(roles, r => includes(roleNames, r.name))
      : roles
    const filteredPersonRoles = organizationId
      ? filter(
          personRoles,
          pr => `${pr.organizationId}` === `${organizationId}`
        )
      : personRoles
    const pickedPersonRoles = filter(
      filteredPersonRoles,
      // This is a filter function first. So were filtering each person role by GETing the pickedRoles and accessing that at the Person Roles roleID
      pr => !!get(pickedRoles, [pr.roleId])
    ).map(pr => {
      const role = get(roles, [pr.roleId], {})
      const organization = get(organizations, [pr.organizationId], {})
      return {
        ...pr,
        role,
        name: role.name,
        organizationName: organization.name
      }
    })

    const peopleWithRolesAndPermissions = {}

    // Hydrate personRoles
    forEach(pickedPersonRoles, pr => {
      if (!peopleWithRolesAndPermissions[pr.personId]) {
        const person = get(people, [pr.personId], {})
        peopleWithRolesAndPermissions[pr.personId] = {
          ...person,
          personId: pr.personId,
          fullName: getDisplayName(person),
          personRoles: [],
          permissions: []
        }
      }

      peopleWithRolesAndPermissions[pr.personId].personRoles.push(pr)
    })

    // Hydrate permissions
    forEach(permissions, perm => {
      if (peopleWithRolesAndPermissions[perm.personId]) {
        const organization = get(organizations, [perm.organizationId], {})
        peopleWithRolesAndPermissions[perm.personId].permissions.push({
          ...perm,
          organizationName: organization.name
        })
      }
    })

    // Return array for ReduxTable compatability
    return values(peopleWithRolesAndPermissions)
  }
)

/**
 * @param state - The state that should have people, person_roles, roles, and permissions to hydrate
 * @param props.personId - The personId to filter by (will only return person object with this id)
 */
export const selectPersonWithHydratedRolesAndPermissions = createSelector(
  selectPeopleWithHydratedRolesAndPermissions,
  createPropGetter('personId'),
  (people, personId) => {
    return find(people, p => `${p.id}` === `${personId}`)
  }
)
