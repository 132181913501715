import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'
import { entities } from 'dux/api/action_types'
import { selectHasOrganizationResultsReadPermission } from 'selectors/user'
import { createEntityGetter } from 'utils/selectors'

import { OrganizationFileTypes } from 'constants/organization_files'

const getOrganizationId = state =>
  state.organizations.currentOrganizationId || null

export const selectLegacyCustomCampaignOrganizationFiles = createSelector(
  createEntityGetter(entities.ORGANIZATION_FILES),
  getOrganizationId,
  selectHasOrganizationResultsReadPermission,
  (files, organizationId, hasOrgReadPermission) => {
    if (!hasOrgReadPermission) {
      return []
    }

    return orderBy(
      filter(
        files,
        file =>
          `${file.organizationId}` === `${organizationId}` &&
          file.fileType === OrganizationFileTypes.LEGACY_CUSTOM_CAMPAIGN_REPORT
      ),
      ['id'],
      ['desc']
    )
  }
)
