import { getSerializedAttributeFilters } from 'utils/emplify/id_helper'
import { buildSortedFilters } from './filters'

export function buildSegmentFilter(oeaId, segment) {
  return `${oeaId}:${encodeURIComponent(segment)}`
}

export function buildCampaignResultId(campaignId, attributeFilters) {
  return `${campaignId}${getSerializedAttributeFilters(attributeFilters)}`
}

export function buildGroupResultId(campaignId, groupId, attributeFilters) {
  return `${campaignId}:${groupId}${getSerializedAttributeFilters(
    attributeFilters
  )}`
}

/**
 * Produces an ID by campaign, group, and statement that can optionally have attribute and segment filters defined.
 * @param {String} campaignId - desired campaign
 * @param {String} groupId - desired group
 * @param {String} statementId - desired statement
 * @param {Object} attributeFilters - object with a key of `filter[employeeAttributes]`
 * @param {String} segmentFilter - string `{oeaId}:{attrValue}`
 */
export function buildGroupStatementResultId(
  campaignId,
  groupId,
  statementId,
  attributeFilters,
  segmentFilter
) {
  const id = `${campaignId}:${groupId}:${statementId}`
  const sortedFilters = buildSortedFilters(attributeFilters, segmentFilter)

  if (sortedFilters.length > 0) {
    return `${id}:${sortedFilters}`
  }

  return id
}

export function buildBracketId(
  campaignId,
  groupId,
  bracketId,
  attributeFilters
) {
  if (!groupId || groupId === 'organization') {
    return `${campaignId}:${bracketId}${getSerializedAttributeFilters(
      attributeFilters
    )}`
  }
  return `${campaignId}:${groupId}:${bracketId}${getSerializedAttributeFilters(
    attributeFilters
  )}`
}

export function buildSegmentFilteredBracketId(
  campaignId,
  groupId,
  bracketId,
  segmentFilter,
  attributeFilters
) {
  const sortedFilters = buildSortedFilters(attributeFilters, segmentFilter)

  if (!groupId || groupId === 'organization') {
    return `${campaignId}:${bracketId}:${sortedFilters}`
  }
  return `${campaignId}:${groupId}:${bracketId}:${sortedFilters}`
}

export function buildSegmentFilteredCategoryScoreId(
  campaignId,
  groupId,
  categoryId,
  attributeFilters,
  segmentFilter
) {
  const id = `${campaignId}:${groupId}:${categoryId}`
  const sortedFilters = buildSortedFilters(attributeFilters, segmentFilter)

  if (sortedFilters.length > 0) {
    return `${id}:${sortedFilters}`
  }

  return id
}
