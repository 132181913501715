/**
 * A object of key constants that represent base route detection.
 * Most are represented on the left side nav, but if you need to control
 * sub menu and select a particular side nav item (ex. Assessment Results),
 * then add to this constant and build out a NAV_ITEMS object for route
 * detection and labels.
 */
const NAV_ITEM_KEYS = {
  RESULTS: 'results',
  ASSESSMENTS_RESULTS_ROOT: 'assessments_results_root',
  ASSESSMENTS_RESULTS: 'assessment_results',
  ASSESSMENTS_RESPONSES: 'assessment_responses',
  FEEDBACK: 'feedback',
  ACTIONS: 'actions',
  SESSIONS: 'sessions',
  ORG_SWITCH: 'org_switch',
  CAMPAIGNS: 'campaigns'
}

const COLLAPSE_KEY = 'collapse'

const NAV_ITEMS = {
  // this needs to be ordered above the journey since its a more specific route,
  // and the logic we use to match the routes will go down this whole tree in order
  // to find a match. Since the /timeline/events route only is shown on event details
  // and were hiding submenu on event details, this is why that route is defined as so
  // TODO: Make route matching not rely on the order of routes defined in this constants file.

  // Campaign
  [NAV_ITEM_KEYS.CAMPAIGNS]: {
    id: NAV_ITEM_KEYS.CAMPAIGNS,
    label: 'Campaigns',
    route: '/campaigns',
    alternateRoutes: ['/']
  },

  // Results
  [NAV_ITEM_KEYS.RESULTS]: {
    id: NAV_ITEM_KEYS.RESULTS,
    label: 'Results',
    route: '/group_results'
  },

  // Assessments
  // NOTE: The Assessment results root route handles forwarding the individuals to the correct group context.
  // This allows us to have a singular navigation path that leads into the results.
  [NAV_ITEM_KEYS.ASSESSMENTS_RESULTS_ROOT]: {
    id: NAV_ITEM_KEYS.ASSESSMENTS_RESULTS_ROOT,
    label: 'Journey', // Force the Journey Nav Item Selection.
    // Ability to override the subMenu label
    subMenuLabel: 'Results',
    route: String.raw`\/assessments\/\d*\/results`,
    subMenu: {
      // NOTE: Added to capture `/assessments/*/results` route.
      // This is not actually displayed in the UI
      ASSESSMENT_RESULTS: {
        label: 'Assessment Results',
        route: String.raw`\/assessments\/\d*\/results`
      }
    }
  },
  [NAV_ITEM_KEYS.ASSESSMENTS_RESULTS]: {
    id: NAV_ITEM_KEYS.ASSESSMENTS_RESULTS,
    label: 'Journey', // Force the Journey Nav Item Selection.
    // Ability to override the subMenu label
    subMenuLabel: 'Results',
    route: String.raw`\/assessments\/\d*\/groups\/\d*\/results`,
    subMenu: {
      // NOTE: Added to capture `/assessments/*/groups/*/results` route.
      // This is not actually displayed in the UI
      ASSESSMENT_RESULTS: {
        label: 'Assessment Results',
        route: String.raw`\/assessments\/\d*\/groups\/\d*\/results`
      }
    }
  },
  [NAV_ITEM_KEYS.ASSESSMENTS_RESPONSES]: {
    id: NAV_ITEM_KEYS.ASSESSMENTS_RESPONSES,
    label: 'Journey', // Force the Journey Nav Item Selection.
    // NOTE: Added to capture `/assessments/*/responses` route.
    route: String.raw`\/assessments\/\d*\/responses`
  },

  // Feedback
  [NAV_ITEM_KEYS.FEEDBACK]: {
    id: NAV_ITEM_KEYS.FEEDBACK,
    label: 'Feedback',
    route: '/feedback/engagement'
  },

  // Sessions
  [NAV_ITEM_KEYS.SESSIONS]: {
    id: NAV_ITEM_KEYS.SESSIONS,
    label: 'Sessions',
    route: '/sessions/my-sessions'
  },

  // Actions
  [NAV_ITEM_KEYS.ACTIONS]: {
    id: NAV_ITEM_KEYS.ACTIONS,
    label: 'Actions',
    route: '/action_dashboard'
  },

  // Org Switch
  [NAV_ITEM_KEYS.ORG_SWITCH]: {
    id: NAV_ITEM_KEYS.ORG_SWITCH,
    label: 'Org Switch'
  }
}

export { NAV_ITEM_KEYS, NAV_ITEMS, COLLAPSE_KEY }
