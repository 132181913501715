import reducer from 'dux/helpers/reducer'

// The redux state for employee edit is currently split across a couple different dux files
// employee_edit_actions, employee_edit_memberships
// This file is for state related to the entire form

const initialState = {
  panels: {
    // For now, only one panel is being manually controlled
    employmentStatus: {
      isExpanded: false
    }
  }
}

const TOGGLE_PANEL = 'employeeEdit/TOGGLE_PANEL'

export function toggleEmploymentStatusPanel() {
  return { type: TOGGLE_PANEL, panelName: 'employmentStatus' }
}

function reduceTogglePanel(state = initialState, action) {
  if (!action.panelName) {
    return state
  }
  if (action.panelName !== 'employmentStatus') {
    // For now, validation is very naive as there is only one acceptable value
    return state
  }
  return {
    ...state,
    panels: {
      ...state.panels,
      employmentStatus: {
        ...state.panels.employmentStatus,
        isExpanded: !state.panels.employmentStatus.isExpanded
      }
    }
  }
}

export default reducer(
  {
    [TOGGLE_PANEL]: reduceTogglePanel
  },
  initialState
)
