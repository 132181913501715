import reducer from 'dux/helpers/reducer'

const initialState = {
  redirectUrl: null
}

// Constants
const SET_REDIRECT = 'organization_new_form/SET_REDIRECT'

// Reducers
function reduceSetRedirect(state = initialState, action) {
  return {
    ...state,
    redirectUrl: action.redirectUrl
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_REDIRECT]: reduceSetRedirect
  },
  initialState
)

// Actions
function setRedirect(redirectUrl) {
  return {
    type: SET_REDIRECT,
    redirectUrl
  }
}

export { setRedirect }
