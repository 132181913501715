import reducer from 'dux/helpers/reducer'

const initialState = {
  surveyToAdd: {},
  showCampaignValidationMessage: false
}

// Constants
const SET_SURVEY_TO_ADD = 'campaigns/SET_SURVEY_TO_ADD'
const SHOW_CAMPAIGN_VALIDATION_MESSAGE =
  'campaigns/SHOW_CAMPAIGN_VALIDATION_MESSAGE'

// Reducers
function reduceSurveyToAdd(state = initialState, action) {
  return {
    ...state,
    surveyToAdd: action.surveyToAdd
  }
}

function reduceShowCampaignValidationMessage(state = initialState, action) {
  return {
    ...state,
    showCampaignValidationMessage: action.showCampaignValidationMessage
  }
}

// reducer functions
export default reducer(
  {
    [SET_SURVEY_TO_ADD]: reduceSurveyToAdd,
    [SHOW_CAMPAIGN_VALIDATION_MESSAGE]: reduceShowCampaignValidationMessage
  },
  initialState
)

// Action creators
function setSurveyToAdd(surveyToAdd) {
  return {
    type: SET_SURVEY_TO_ADD,
    surveyToAdd
  }
}

function showCampaignValidationMessage(showValidation = false) {
  return {
    type: SHOW_CAMPAIGN_VALIDATION_MESSAGE,
    showCampaignValidationMessage: showValidation
  }
}

// Export necessary action types and action creators
export { setSurveyToAdd, showCampaignValidationMessage }
