export function skipWeekends(dayjsObject) {
  const day = dayjsObject.day()
  if (day === 0 /* Sunday */) {
    return dayjsObject.add(1, 'd')
  }

  if (day === 6 /* Saturday */) {
    return dayjsObject.add(2, 'd')
  }

  return dayjsObject
}
