import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { useLocation, useHistory } from 'react-router-dom'

import {
  setSelectedNavItem,
  setSelectedNavSubMenuItem,
  setSubMenuOpen,
} from 'dux/v2_nav'
import { getSelectedNavItem } from 'selectors/v2_nav'
import { getPersonEmployeesAsync } from 'dux/api/actions/people'
import { getV3GroupsAsync } from 'dux/api/actions/v3_groups'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import {
  selectHasOrganizationResultsReadPermission,
  getUserPersonId,
} from 'selectors/user'
import {
  selectRootGroup,
  selectCurrentGroupResultGroupId,
  selectResultsOnlyAccess,
} from 'selectors/v3_groups'

import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck'
import { faUserFriends } from '@fortawesome/pro-light-svg-icons/faUserFriends'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { NAV_ITEMS, NAV_ITEM_KEYS } from 'constants/v2_nav'
import { matchMenuItemToRoute } from '../helpers/route'

import MainNavItem from './main_nav_item'
import ResultsNavItem from './results_nav_item'
import ResultsMainNav from './results_main_nav'

const ASSESSMENT_NAV_ITEMS = [
  NAV_ITEM_KEYS.ASSESSMENTS_RESULTS_ROOT,
  NAV_ITEM_KEYS.ASSESSMENTS_RESULTS,
  NAV_ITEM_KEYS.ASSESSMENTS_RESPONSES,
]

function MainNavigationListItems({
  route,
  noResultsOrgAdminOrLeader,
  showTooltip,
}) {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const organizationId = useSelector((state) =>
    selectCurrentOrganizationId(state),
  )
  const personId = useSelector((state) => getUserPersonId(state))
  const orgGroup = useSelector((state) => selectRootGroup(state))

  const orgGroupId = get(orgGroup, 'id', null)
  const groupIdToFilterBy = useSelector((state) =>
    selectCurrentGroupResultGroupId(state, {
      organizationId,
    }),
  )
  const resultsOnlyAccess = useSelector((state) =>
    selectResultsOnlyAccess(state, {
      organizationId,
    }),
  )
  const hasOrganizationLevelAccess = useSelector((state) =>
    selectHasOrganizationResultsReadPermission(state),
  )
  const selectedNavItem = useSelector((state) => getSelectedNavItem(state))

  useEffect(() => {
    if (organizationId && !hasOrganizationLevelAccess) {
      dispatch(getV3GroupsAsync(organizationId))
    }
  }, [dispatch, hasOrganizationLevelAccess, organizationId])

  useEffect(() => {
    if (personId) {
      dispatch(getPersonEmployeesAsync(personId))
    }
  }, [dispatch, personId])

  /* this hook is setting the current nav item by route */
  useEffect(() => {
    if (route) {
      let matchFound = false
      forEach(NAV_ITEMS, (item) => {
        if (item.subMenu) {
          forEach(item.subMenu, (subMenuItem) => {
            if (matchMenuItemToRoute(subMenuItem, pathname)) {
              dispatch(setSelectedNavItem(item.id))

              // Display the main nav item, but not the sub menu under the new nav feature flag
              // The sub menu should be removed from the main nav item when the feature is live.
              if (!ASSESSMENT_NAV_ITEMS.includes(item.id)) {
                dispatch(setSelectedNavSubMenuItem(null))
                dispatch(setSubMenuOpen(false))
              } else {
                dispatch(setSelectedNavSubMenuItem(subMenuItem.label))
                dispatch(setSubMenuOpen(true))
              }

              matchFound = true
            }

            // break or continue inner forEach
            return !matchFound
          })
        } else if (matchMenuItemToRoute(item, pathname)) {
          dispatch(setSelectedNavItem(item.id))
          dispatch(setSelectedNavSubMenuItem(null))
          dispatch(setSubMenuOpen(false))

          matchFound = true
        }

        // break or continue outer forEach
        return !matchFound
      })
      if (!matchFound) {
        // error case - render an incomplete state.
        dispatch(setSelectedNavItem(null))
        dispatch(setSelectedNavSubMenuItem(null))
        dispatch(setSubMenuOpen(false))
      }
    }
  }, [dispatch, route, orgGroupId, pathname])

  return (
    <>
      <MainNavItem
        selected={selectedNavItem === NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].id}
        navItemId={NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].id}
        isCollapsed={showTooltip}
        icon={<FontAwesomeIcon icon={faCalendarCheck} />}
        label={NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].label}
        tooltip={NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].label}
        onClick={() => {
          history.push(NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].route)
          dispatch(setSelectedNavItem(NAV_ITEMS[NAV_ITEM_KEYS.CAMPAIGNS].id))
        }}
      />
      {resultsOnlyAccess && groupIdToFilterBy === orgGroupId && (
        <ResultsNavItem
          selectedNavItem={selectedNavItem}
          groupIdToFilterBy={groupIdToFilterBy}
          showTooltip={showTooltip}
        />
      )}
      {!resultsOnlyAccess && !noResultsOrgAdminOrLeader && (
        <ResultsMainNav
          selectedNavItem={selectedNavItem}
          groupIdToFilterBy={groupIdToFilterBy}
          showTooltip={showTooltip}
        />
      )}
      <MainNavItem
        selected={selectedNavItem === NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].id}
        navItemId={NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].id}
        isCollapsed={showTooltip}
        icon={<FontAwesomeIcon icon={faUserFriends} />}
        label={NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].label}
        tooltip={NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].label}
        onClick={() => {
          history.push(NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].route)
          dispatch(setSelectedNavItem(NAV_ITEMS[NAV_ITEM_KEYS.SESSIONS].id))
        }}
      />
    </>
  )
}

MainNavigationListItems.propTypes = {
  route: PropTypes.string.isRequired,
  noResultsOrgAdminOrLeader: PropTypes.bool,
  showTooltip: PropTypes.bool,
}

MainNavigationListItems.defaultProps = {
  noResultsOrgAdminOrLeader: false,
  showTooltip: false,
}

export default MainNavigationListItems
