/**
 * Normalize a JSON-API-formatted repsonse
 * @module shared/json_api/normalizer/normalize
 */

/* eslint-disable no-param-reassign */

function addResult(result, entity) {
  const { type, id } = entity

  if (!result[type]) {
    result[type] = []
  }

  result[type].push(id)
}

function duplicateRelationships(relationships) {
  if (Array.isArray(relationships)) {
    return [...relationships]
  }

  return { ...relationships }
}

function extractRelationships(entity) {
  const { relationships: responseRelationships } = entity

  if (!responseRelationships) {
    return undefined
  }

  const relationships = {}

  Object.keys(responseRelationships).forEach(type => {
    relationships[type] = duplicateRelationships(
      responseRelationships[type].data
    )
  })

  return relationships
}

function addEntity(entities, entity) {
  const { type, id, attributes } = entity

  if (!entities[type]) {
    entities[type] = {}
  }

  entities[type][id] = {
    id,
    ...attributes,
    ...extractRelationships(entity)
  }

  return entities
}

/**
 * Module for normalizing JSON API entities
 * This code is directly ported from https://github.com/stevenpetryk/jsonapi-normalizer
 * We decided to copy the project into our own source code because:
 * 1. The project is only lightly supported
 * 2. We want to be able to easily extend the functionality
 * 3. We want to tweak noramlization to work for our (arguably bastardized) flavor of JSON API
 *
 * @param {Object} response A JSON-API formatted resopnse from a web server
 * @returns {Object} Normalized response with data ready to be inserted into a state tree
 */
export function normalize(response) {
  let data

  if (Array.isArray(response.data)) {
    data = response.data
  } else {
    data = [response.data]
  }

  const included = response.included || []

  const allResources = [...data, ...included]
  const result = {}
  const entities = {}

  allResources.forEach(entity => {
    addResult(result, entity)
    addEntity(entities, entity)
  })

  return {
    result,
    entities
  }
}

/* eslint-enable no-param-reassign */
