import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import V2TextField from './text_field'

class V2NumberField extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue
    }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(value) {
    const { min, max } = this.props

    if (Number(value) < Number(min)) {
      return undefined
    }

    if (Number(value) > Number(max)) {
      return undefined
    }

    this.props.onChange(value)

    return this.setState({ value })
  }

  onBlur(e) {
    const { min } = this.props

    if (e.target.value === '') {
      this.onChange(min)
    }
  }

  render() {
    const { className, value, selectContentOnFocus } = this.props

    const cx = cn('furniture-v2-number-field', {
      [className]: !!className
    })

    return (
      <V2TextField
        type='number'
        className={cx}
        value={value || this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        selectContentOnFocus={selectContentOnFocus}
      />
    )
  }
}

V2NumberField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  selectContentOnFocus: PropTypes.bool,
  min: PropTypes.string,
  max: PropTypes.string
}

V2NumberField.defaultProps = {
  className: null,
  value: null,
  defaultValue: '',
  onChange: () => {},
  selectContentOnFocus: true,
  min: null,
  max: null
}

export default V2NumberField
