import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import { useLocation, useHistory } from 'react-router-dom'

import { useSelectedValueDispatch } from 'furniture/selectable_list'
import { getSelectedV3Group } from 'selectors/v3_groups'
import { selectPathOrDefaultAssessmentGroupId } from 'selectors/assessments'
import { selectHasOrganizationResultsReadPermission } from 'selectors/user'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { getExperienceType } from 'selectors/results'
import {
  selectOrganizationGroupWithSelectedCampaignParticipation,
  selectPermittedGroupsWithSelectedCampaignParticipation
} from 'selectors/v3_group_participations'
import { RESULTS_EXPERIENCE_TYPES } from 'constants/results_experience_types'
import { updateExperienceType } from 'dux/results'
import { getV3GroupParticipationsAsync } from 'dux/api/actions/v3_group_participations'
import {
  getAssessmentCampaignIdFromPath,
  getAssessmentGroupIdFromPath
} from 'utils/browser/query'

import { isEmpty } from 'lodash'
import GroupNavItem from './group_nav_item'

function AssessmentsSecondaryNav() {
  const { replace: historyReplace, push: historyPush } = useHistory()
  const location = useLocation()
  const selectedValueDispatch = useSelectedValueDispatch()
  const dispatch = useDispatch()

  const organizationId = useSelector(state =>
    selectCurrentOrganizationId(state)
  )

  const campaignId = getAssessmentCampaignIdFromPath(location?.pathname)
  const groupIdToFilterBy = useSelector(state =>
    selectPathOrDefaultAssessmentGroupId(state, {
      pathGroupId: getAssessmentGroupIdFromPath(location?.pathname),
      organizationId,
      campaignId
    })
  )

  const organizationGroup = useSelector(state =>
    selectOrganizationGroupWithSelectedCampaignParticipation(state, {
      campaignId
    })
  )
  const groups = useSelector(state =>
    selectPermittedGroupsWithSelectedCampaignParticipation(state, {
      campaignId
    })
  )
  const hasOrganizationLevelAccess = useSelector(state =>
    selectHasOrganizationResultsReadPermission(state)
  )
  const activeGroup = useSelector(state =>
    getSelectedV3Group(state, { groupId: groupIdToFilterBy })
  )
  const resultsExperienceType = useSelector(state => getExperienceType(state))

  useEffect(() => {
    if (campaignId && groupIdToFilterBy) {
      selectedValueDispatch({ value: groupIdToFilterBy })
      historyReplace(
        `/assessments/${campaignId}/groups/${groupIdToFilterBy}/results`
      )
    }
  }, [campaignId, groupIdToFilterBy, historyReplace, selectedValueDispatch])

  const groupItemOnClick = group => {
    const selectedGroupId = group.id
    selectedValueDispatch({ value: selectedGroupId })
    historyPush(`/assessments/${campaignId}/groups/${selectedGroupId}/results`)
    return false
  }

  useEffect(() => {
    if (organizationId && campaignId) {
      // NOTE: We are not expecting to filter down the participations here
      // Which is why we're not applying the filters to the fetch
      dispatch(getV3GroupParticipationsAsync(organizationId, campaignId))
    }
  }, [dispatch, organizationId, campaignId])

  useEffect(() => {
    function decideExperience() {
      if (hasOrganizationLevelAccess) {
        return RESULTS_EXPERIENCE_TYPES.FULL_ACCESS
      }
      if (activeGroup.isRoot) {
        return RESULTS_EXPERIENCE_TYPES.ORG_MODE
      }
      return RESULTS_EXPERIENCE_TYPES.LIMITED_ACCESS_VIEWS
    }

    const experience = decideExperience()
    if (activeGroup && experience !== resultsExperienceType) {
      dispatch(updateExperienceType(experience))
    }
  }, [dispatch, activeGroup, resultsExperienceType, hasOrganizationLevelAccess])

  if (isEmpty(groups) && isEmpty(organizationGroup)) {
    return null
  }

  const orgGroupId = organizationGroup.id
  if (hasOrganizationLevelAccess) {
    if (!orgGroupId) {
      return null
    }
    return (
      orgGroupId && (
        <GroupNavItem
          value={orgGroupId}
          key={`${orgGroupId}-${organizationGroup.name}`}
          group={organizationGroup}
          campaignMembershipCount={organizationGroup.campaignMembershipCount}
          onClick={groupItemOnClick}
        />
      )
    )
  }

  const sortedGroups = orderBy(
    Object.values(groups),
    ['isRoot', 'campaignMembershipCount'],
    ['desc', 'desc']
  )

  return (
    <>
      {orgGroupId && (
        <GroupNavItem
          value={orgGroupId}
          key={`${orgGroupId}-${organizationGroup.name}`}
          group={organizationGroup}
          campaignMembershipCount={organizationGroup.campaignMembershipCount}
          onClick={groupItemOnClick}
        />
      )}
      {sortedGroups.map(g => {
        const key = `${g.id}-${g.name}`

        return (
          get(g, 'id') && (
            <GroupNavItem
              value={g.id}
              key={key}
              group={g}
              campaignMembershipCount={g.campaignMembershipCount}
              onClick={groupItemOnClick}
            />
          )
        )
      })}
    </>
  )
}

AssessmentsSecondaryNav.propTypes = {}

AssessmentsSecondaryNav.defaultProps = {}

export default AssessmentsSecondaryNav
