import reducer from 'dux/helpers/reducer'
import {
  createApiCallActionType,
  statuses,
  entities,
  methods,
} from 'dux/api/action_types'
import { clearEntities } from 'dux/api/reducers/entities'
import { getJobs } from 'dux/api/actions/jobs'
import { loadQueryParams } from 'utils/browser/query'
import { FETCH_LIMIT } from 'constants/jobs'

const initialState = {
  totalJobCount: 0,
}

// Helper
function filterToQuery(filter) {
  return Object.keys(filter).reduce((filterQuery, key) => {
    if (filter[key] && filter[key] !== 'all') {
      // eslint-disable-next-line no-param-reassign
      filterQuery[`filter[${key}]`] = filter[key]
    }

    return filterQuery
  }, {})
}

// Constants (i.e. action types)
const JOBS_GET_SUCCESS = createApiCallActionType(
  entities.JOBS,
  methods.GET,
  statuses.SUCCESS,
)

function reduceSuccessfulJobGet(state = initialState, action) {
  if (action.meta) {
    const { count } = action.meta
    return {
      ...state,
      totalJobCount: count,
    }
  }
  return state
}

// Combined reducer functions
export default reducer(
  {
    [JOBS_GET_SUCCESS]: reduceSuccessfulJobGet,
  },
  initialState,
)

function fetchJobs(offset, { filter = {}, history }) {
  return (dispatch) => {
    const query = {
      'page[offset]': offset,
      'page[limit]': FETCH_LIMIT,
      ...filterToQuery(filter),
      ...loadQueryParams(history),
    }
    dispatch(clearEntities(['jobs']))
    return dispatch(getJobs({ query, force: true }))
  }
}

// Export necessary action types and action creators
export { fetchJobs }
