import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'
import { getPageQueryOptions } from 'utils/pagination'
import { ROLES } from 'constants/roles'

/**
 * Get all employees for an org, optionally filtering by ID.
 * @param {String|Number} organizationId
 * @param {Object} [options]
 * @param {Object} [options.query]
 * @param {String} [options.query.filter] e.g., "filter[id]=1,2,3"
 * @param {String} [options.query.include] e.g., "people,employments"
 * @returns {Promise.<Object>} Resolves to API response
 */
export function getEmployeesAsync(organizationId, employeeOptions) {
  const url = '/organizations/:organizationId/employees'
  const params = { organizationId }

  const options = getPageQueryOptions(employeeOptions)
  return ApiCallThunkCreator.get(url, params, options)
}

export function getInsightsUsersAsync(organizationId, force = false) {
  return getEmployeesAsync(organizationId, {
    force,
    query: {
      'filter[role][ne]': ROLES.EMPLOYEE,
      include: 'people.person-roles.roles',
    },
  })
}

export function getEmployeeAsync(organizationId, employeeId, options = {}) {
  const params = {
    organizationId,
    employeeId,
  }
  const newOptions = {
    ...options,
    query: {
      include: 'people,employments,memberships',
      ...options.query, // Let the caller override the include
    },
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/employees/:employeeId',
    params,
    newOptions,
  )
}

/**
 * Create a new employee record
 * @param {number|string} organizationId ID of the organization to create an employee in
 * @param {Object} employee Attributes of new employee we want to create
 * @param {Object} [person] Attributes of the new person to associate with the new employee
 * @param {Object[]} [memberships] Attributes of new memberships to associate with the new employee
 * @param {Object} [options] Pass through ApiCallThunkCreator options
 */
export function sidepostEmployeeAsync(
  organizationId,
  employee,
  memberships,
  v3Memberships,
  employeeAttributes,
  options = {},
) {
  const params = { organizationId }
  // Employee hydrated with their person and memberships
  // The employee serializer understands how to handle these related resources
  const data = {
    ...employee,
    memberships,
    v3Memberships,
    employeeAttributes,
  }

  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/employees',
    params,
    data,
    options,
  )
}

export function updateEmployeeAsync(
  organizationId,
  employeeId,
  data,
  options = {},
) {
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/employees/:employeeId',
    { organizationId, employeeId },
    { ...data, id: employeeId },
    options,
  )
}

/**
 * Delete an employee. Cascades to remove associated records.
 *
 * @param {String|Number} organizationId Org that employee belongs to
 * @param {String|Number} employeeId ID of the employee to delete
 * @param {Boolean} hardDelete True to completely remove employee and all associated records
 *  from the database. False to soft-delete the employee.
 * @returns {Promise.<Object>} Resolves to last action dispatched by thunk
 */
export function deleteEmployeeAsync(
  organizationId,
  employeeId,
  hardDelete = false,
) {
  const url = '/organizations/:organizationId/employees/:employeeId'
  const params = { organizationId, employeeId }
  const options = { query: { hard: hardDelete } }
  return ApiCallThunkCreator.destroy(url, params, options)
}

/**
 * Reset an employee's survey responses. Cascades to reset associated records.
 *
 * @param {String|Number} organizationId Org that employee belongs to
 * @param {String|Number} campaignId ID of the campaign
 * @param {String|Number} employeeResultId ID of the employee result to reset
 * @returns {Promise.<Object>} Resolves to last action dispatched by thunk
 */
export function resetEmployeeResultResponsesAsync(
  organizationId,
  campaignId,
  employeeId,
) {
  const url =
    '/organizations/:organizationId/campaigns/:campaignId/employee/:employeeId/reset-employee-result'
  const params = { organizationId, campaignId, employeeId }
  return ApiCallThunkCreator.create(url, params, null, {
    toastMessage: "Successfully reset employee's assessment responses",
  })
}

/**
 * Add an employee to a campaign
 *
 * @param {String|Number} organizationId Org that employee belongs to
 * @param {String|Number} campaignId ID of the campaign
 * @param {String|Number} employeeIds IDs of the employees to add to the campaign
 * @returns {Promise.<Object>} Resolves to last action dispatched by thunk
 */
export function addEmployeesToCampaignAsync(
  organizationId,
  campaignId,
  employeeIds,
) {
  const url =
    '/organizations/:organizationId/campaigns/:campaignId/add-employees-to-campaign'
  const params = {
    organizationId,
    campaignId,
  }
  return ApiCallThunkCreator.create(
    url,
    params,
    { employeeIds },
    {
      toastMessage: 'Successfully added employee(s) to campaign',
    },
  )
}

/**
 * Remove employee from a campaign
 *
 * @param {String|Number} organizationId Org that employee belongs to
 * @param {String|Number} campaignId ID of the campaign
 * @param {String|Number} employeeIds IDs of the employee to from the campaign
 * @returns {Promise.<Object>} Resolves to last action dispatched by thunk
 */
export function removeEmployeesFromCampaignAsync(
  organizationId,
  campaignId,
  employeeIds,
) {
  const url =
    '/organizations/:organizationId/campaigns/:campaignId/remove-employees-from-campaign'
  const params = {
    organizationId,
    campaignId,
  }
  return ApiCallThunkCreator.update(
    url,
    params,
    { employeeIds },
    {
      toastMessage: 'Successfully removed employee from campaign',
    },
  )
}
