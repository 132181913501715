import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class CardSection extends PureComponent {
  render() {
    const cx = cn(
      this.props.className,
      { divider: this.props.divider },
      { padding: this.props.padding },
      { [this.props.extraClassNames]: !!this.props.extraClassNames }
    )
    return <div className={cx}>{this.props.children}</div>
  }
}

CardSection.propTypes = {
  className: PropTypes.string,
  extraClassNames: PropTypes.string,
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  padding: PropTypes.bool
}

CardSection.defaultProps = {
  className: 'furniture-card-section',
  extraClassNames: null,
  divider: false,
  padding: true
}

export default CardSection
