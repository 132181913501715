import get from 'lodash/get'

/**
 * URI Encodes the values of the attribute filters
 * @param {Array[]} filters
 * @returns {Array[]} Array of encoded filters
 */
export const encodeFilterAttributes = filters => {
  return filters.map(filter => {
    const filterArray = filter.split(':')
    // To avoid breaking filtering when group names have colons, only pull the key from the first occurrence of the colon and then
    // reconstruct the rest of the colon-split array for the value (group name)
    const key = filterArray[0]
    const val = filterArray.slice(1).join(':')
    return `${key}:${encodeURIComponent(val)}`
  })
}

/**
 * Get comma-separated segment + attribute filters to build correct entity IDs
 * We are implementing the same sort here that we use on the backend to generate entity virtual ID fields
 *
 * @param {string} attributeFilters object that we track in state containing current attribute filters
 * @param {string} segmentFilter - String containing the current segment (e.g. '75:Male')
 *
 * @return {string} Comma separated string made up of segmentFilter + all attributeFilters, alpha sorted
 */
export const buildSortedFilters = (attributeFilters, segmentFilter) => {
  const filters = [
    ...encodeFilterAttributes(
      get(attributeFilters, 'filter[employeeAttributes]', [])
    )
  ]

  if (segmentFilter) {
    filters.push(segmentFilter)
  }

  return filters.sort().join(',')
}
