import React from 'react'
import PropTypes from 'prop-types'

import { Heading } from '../typography'

const Title = ({ icon, title }) => {
  if (!icon && !title) {
    return null
  }
  return (
    <Heading card>
      {icon}
      {title}
    </Heading>
  )
}
Title.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}
Title.defaultProps = { icon: null, title: '' }

export default Title
