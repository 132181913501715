import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectPerson,
  selectUsername,
  selectOrganization,
} from 'selectors/user'

const pendo = window.pendo

function PendoController() {
  // get logged in user, name, and organization
  const person = useSelector((state) => selectPerson(state))
  const fullName = useSelector((state) => selectUsername(state))
  const organization = useSelector((state) => selectOrganization(state))

  // only identify user for pendo if it is an integrated user
  useEffect(() => {
    if (
      pendo &&
      person?.ffUserId &&
      person?.ffGlobalUserId &&
      organization?.isFFManaged &&
      organization?.ffCompanyId
    ) {
      pendo.initialize({
        visitor: {
          id: person.ffUserId,
          email: person.emailAddress,
          globalId: person.ffGlobalUserId,
          name: fullName,
        },
        account: {
          id: organization.ffCompanyId, // Future TODO: Should we use ffGlobalId instead for uniqueness across environments?
          name: organization.name,
        },
      })
    }
  }, [person, fullName, organization])

  return null
}

export default PendoController
