import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import find from 'lodash/find'
import cn from 'classnames'

import { AuthComponentByRole } from 'containers/authorization'
import { getSelectedNavItem } from 'selectors/v2_nav'
import { NAV_ITEMS } from 'constants/v2_nav'

import SubMenuContent, { SUB_MENU_ALLOWED_ROLES } from './sub_menu_content'

function DrawerSubMenu({ selectedNavItem, isOpen }) {
  const navItem =
    find(NAV_ITEMS, item => {
      return item.id === selectedNavItem
    }) || {}
  const label = navItem.subMenuLabel || navItem.label

  return (
    <nav className={cn('drawer-v2-sub-nav', { 'manual-open': isOpen })}>
      <div className='drawer-v2-top-nav'>
        <h2 className='drawer-sub-nav-header'>{label}</h2>
      </div>
      <div className='drawer-v2-sub-menu'>
        <AuthComponentByRole allowedRoles={SUB_MENU_ALLOWED_ROLES}>
          <SubMenuContent navItemId={selectedNavItem} className='flex-1' />
        </AuthComponentByRole>
      </div>
    </nav>
  )
}

function mapStateToProps(state) {
  return {
    selectedNavItem: getSelectedNavItem(state)
  }
}

DrawerSubMenu.propTypes = {
  selectedNavItem: PropTypes.string,
  isOpen: PropTypes.bool
}

DrawerSubMenu.defaultProps = {
  selectedNavItem: null,
  isOpen: false
}

export default connect(mapStateToProps)(DrawerSubMenu)
