import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import get from 'lodash/get'
import { Tooltip } from 'furniture/tooltip'

import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'

import FontAwesomeIcon from 'furniture/font_awesome_icon'

/**
 * V2DropdownMenuItem can take either an `item` prop (with a `valueField` specified) OR simply a `value` string
 * @property {string} value
 * If a `value` string prop is provided, this is the string that will be displayed as the menu item value
 * The passed onClick function will act on this value string as it's parameter
 *
 * @property {object} item
 * @property {string} valueField
 * If an `item` object is provided as a prop, the `valueField` will be used to access the items value string to display
 * The passed onClick function will act on the item object as it's parameter
 */
class V2DropdownMenuItem extends PureComponent {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
    this.onKeyPressEnter = this.onKeyPressEnter.bind(this)
  }

  onClick(e) {
    const { item, value, onClick } = this.props

    onClick(e, value || item)
  }

  onKeyPressEnter(e) {
    if (e.key === 'Enter') {
      this.onClick(e)
    }
  }

  render() {
    const {
      className,
      icon,
      item,
      valueField,
      value,
      onKeyDown,
      selected,
      disabled,
      secondaryTextField,
      tooltip
    } = this.props

    const cx = cn('furniture-v2-dropdown-menu-item', {
      [className]: !!className,
      selected,
      'disabled-with-tooltip': disabled && tooltip,
      disabled: disabled && !tooltip
    })

    return (
      <div
        role='button'
        tabIndex={-1} // Required to make this element focusable
        className={cx}
        onClick={!disabled ? this.onClick : null}
        onKeyDown={!disabled ? onKeyDown : null}
        onKeyPress={!disabled ? this.onKeyPressEnter : null}
        data-static-id={this.props.dataStaticId}
      >
        <div className={cn('flex flex-col', { 'w-full': !!icon })}>
          {tooltip ? (
            <Tooltip anchorEl={<span>{value || get(item, valueField)}</span>}>
              {tooltip}
            </Tooltip>
          ) : (
            <div
              className={cn({
                'flex items-center justify-between': !!icon
              })}
            >
              <span>{value || get(item, valueField)}</span>
            </div>
          )}
          {secondaryTextField && (
            <span className='secondary-text-field'>
              {get(item, secondaryTextField)}
            </span>
          )}
        </div>
        {selected && <FontAwesomeIcon icon={faCheck} size='sm' />}
      </div>
    )
  }
}

V2DropdownMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  valueField: PropTypes.string,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  secondaryTextField: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  dataStaticId: PropTypes.string
}

V2DropdownMenuItem.defaultProps = {
  className: null,
  icon: null,
  item: null,
  valueField: 'name',
  value: null,
  onClick: () => {},
  onKeyDown: () => {},
  disabled: false,
  selected: false,
  secondaryTextField: null,
  tooltip: '',
  dataStaticId: null
}

export default V2DropdownMenuItem
