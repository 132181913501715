import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cn from 'classnames'
import { BREAKPOINTS } from 'constants/devices'
import { COLLAPSE_KEY } from 'constants/v2_nav'
import { bindActionCreators } from 'redux'

import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

import { faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons/faChevronCircleLeft'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import FurnitureDrawer from 'furniture/drawer'
import { SelectableList } from 'furniture/selectable_list'
import { selectUserRole } from 'selectors/user'
import { selectResultPermissionsByPersonId } from 'selectors/permissions'
import { selectOptimizedOrganizations } from 'selectors/optimized_organizations'
import { AuthComponentByRole } from 'containers/authorization'
import DrawerSubMenu from 'containers/drawer/sub_menu'
import { ROLES } from 'constants/roles'
import useLocalStorage from 'hooks/use_local_storage'

import { setIsCollapsed as setIsCollapsedDux } from 'dux/v2_nav'
import Brand from './components/brand'
import MainNavigationListItems from './components/main_navigation_list_items'
import OrgSwitcherNavigationItem from './components/org_switcher_navigation_item'
import MainNavItem from './components/main_nav_item'

const DRAWER_COLLAPSE_KEY = 'drawer-collapse'
function Drawer({
  location,
  optimizedOrganizations,
  subMenuOpen,
  role,
  userResultPermissions,
  isOpen,
  setShowDrawer,
  actions
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const {
    storedValue: isCollapsed,
    setStoredValue: setIsCollapsed
  } = useLocalStorage(DRAWER_COLLAPSE_KEY)

  const noResultsOrgAdminOrLeader =
    (role === ROLES.ORGANIZATION_ADMIN && !userResultPermissions.length) ||
    (role === ROLES.LEADER && !userResultPermissions.length)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const manualOpen = isOpen && windowWidth < BREAKPOINTS.md
  return (
    <FurnitureDrawer
      className={cn('drawer-v2-container rebrand-drawer', {
        'manual-open': manualOpen,
        collapsed: isCollapsed && !manualOpen
      })}
      setShowDrawer={setShowDrawer}
      isOpen={manualOpen}
    >
      <nav className='drawer-v2-nav'>
        <AuthComponentByRole
          allowedRoles={[
            ROLES.ADMIN,
            ROLES.CUSTOMER_SUCCESS_MANAGER,
            ROLES.EXECUTIVE_ADVISOR,
            ROLES.LEADER,
            ROLES.ENGAGEMENT_ADMIN,
            ROLES.ORGANIZATION_ADMIN,
            ROLES.COACH,
            ROLES.FACILITATOR
          ]}
        >
          <div className='drawer-v2-main-nav'>
            <div className='top-nav-menu-container'>
              {windowWidth < BREAKPOINTS.md && (
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => {
                    setShowDrawer(false)
                  }}
                  className='drawer-close'
                />
              )}
              <Brand engageRebrand />
              <SelectableList className='flex-1'>
                <MainNavigationListItems
                  showTooltip={isCollapsed}
                  route={location.pathname}
                  noResultsOrgAdminOrLeader={noResultsOrgAdminOrLeader}
                />
              </SelectableList>
            </div>
            <div className='bottom-nav-menu-container'>
              {Object.keys(optimizedOrganizations).length > 1 && (
                <SelectableList className='flex-1'>
                  <OrgSwitcherNavigationItem showTooltip={isCollapsed} />
                </SelectableList>
              )}
              {windowWidth > BREAKPOINTS.md && (
                <MainNavItem
                  isCollapsed={isCollapsed}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed)
                    actions.setIsCollapsedDux(!isCollapsed)
                  }}
                  navItemId={COLLAPSE_KEY}
                  icon={
                    <FontAwesomeIcon
                      icon={faChevronCircleLeft}
                      className='collapse-icon'
                    />
                  }
                  tooltip='Expand'
                  label='Collapse'
                />
              )}
            </div>
          </div>
        </AuthComponentByRole>
      </nav>
      {subMenuOpen && <DrawerSubMenu isOpen={manualOpen} />}
    </FurnitureDrawer>
  )
}

function mapStateToProps(state) {
  const role = selectUserRole(state)

  return {
    optimizedOrganizations: selectOptimizedOrganizations(state),
    drawer: state.drawer,
    subMenuOpen: state.v2Nav.subMenuOpen,
    role,
    userResultPermissions: selectResultPermissionsByPersonId(state)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setIsCollapsedDux
      },
      dispatch
    )
  }
}

Drawer.propTypes = {
  location: PropTypes.object.isRequired,
  optimizedOrganizations: PropTypes.object.isRequired,
  subMenuOpen: PropTypes.bool.isRequired,
  role: PropTypes.string,
  userResultPermissions: PropTypes.array,
  isOpen: PropTypes.bool,
  setShowDrawer: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired
}

Drawer.defaultProps = {
  role: ROLES.EMPLOYEE,
  userResultPermissions: [],
  isOpen: false
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Drawer)
