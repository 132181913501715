import React, { useState, useEffect } from 'react'
import Popover, { POPOVER_POSITIONS } from 'furniture/popover'
import { Button, BUTTON_RANKS } from 'furniture/buttons'
import FontAwesomeIcon from 'furniture/font_awesome_icon'
import { faCog as SettingsIcon } from '@fortawesome/pro-light-svg-icons/faCog'
import { SelectableList } from 'furniture/selectable_list'
import DropdownNavSection from 'containers/dropdown_nav/dropdown-nav-section'
import {
  AuthComponentByPermission,
  AuthComponentByRole
} from 'containers/authorization'
import { useHistory } from 'react-router-dom'

import { ROLES } from 'constants/roles'
import { ORGANIZATIONS_ADMINISTRATE_READ_GRANT } from 'constants/permissions'

import AdminSubMenuContainer from './settings_sub_menus/admin'
import ProfileSubMenuContainer from './settings_sub_menus/profile'
import SignoutMenuItem from './settings_sub_menus/signout_menu_item'
import HelpCenterContainer from './settings_sub_menus/help_center'
import SettingsSubMenuContainer from './settings_sub_menus/settings'

const SettingsButton = () => {
  const [showPopover, setShowPopover] = useState(false)
  const location = useHistory()?.location

  useEffect(() => {
    setShowPopover(false)
  }, [location])

  return (
    <Popover
      anchorEl={
        <Button
          onClick={() => {
            setShowPopover(!showPopover)
          }}
          icon={
            <FontAwesomeIcon
              className='app-bar-settings-icon'
              icon={SettingsIcon}
            />
          }
          rank={BUTTON_RANKS.TERTIARY}
          className='app-bar-settings'
          dataStaticId='settings-button'
        />
      }
      onClickOutside={() => setShowPopover(false)}
      open={showPopover}
      position={POPOVER_POSITIONS.BOTTOM}
      offset={[-110, 10]}
    >
      <SelectableList className='app-bar-settings-dropdown'>
        <DropdownNavSection header='Account Settings'>
          <ProfileSubMenuContainer />
        </DropdownNavSection>
        <AuthComponentByPermission
          requiredGrant={ORGANIZATIONS_ADMINISTRATE_READ_GRANT}
          bypassRoles={[
            ROLES.CUSTOMER_SUCCESS_MANAGER,
            ROLES.COACH,
            ROLES.FACILITATOR
          ]}
        >
          <DropdownNavSection header='Settings'>
            <SettingsSubMenuContainer />
          </DropdownNavSection>
        </AuthComponentByPermission>
        <AuthComponentByRole
          allowedRoles={[
            ROLES.ADMIN,
            ROLES.CUSTOMER_SUCCESS_MANAGER,
            ROLES.EXECUTIVE_ADVISOR,
            ROLES.COACH,
            ROLES.FACILITATOR
          ]}
        >
          <DropdownNavSection header='Internal Admin Settings'>
            <AdminSubMenuContainer />
          </DropdownNavSection>
        </AuthComponentByRole>
        <DropdownNavSection header='Resources'>
          <HelpCenterContainer />
        </DropdownNavSection>
        <SignoutMenuItem />
      </SelectableList>
    </Popover>
  )
}

export default SettingsButton
