import variables from 'styles/_variables_export.module.scss'

export const BROWSERS = {
  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  IE: 'IE',
  IE_MOBILE: 'IEMobile',
  MOZILLA: 'Mozilla',
  OPERA: 'Opera'
}

export const BREAKPOINTS = {
  xs: Number(variables.breakpointXs),
  sm: Number(variables.breakpointSm),
  md: Number(variables.breakpointMd),
  lg: Number(variables.breakpointLg),
  xl: Number(variables.breakpointXl),
  '2xl': Number(variables.breakpoint2xl)
}
