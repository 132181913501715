import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import noop from 'lodash/noop'
import get from 'lodash/get'

import {
  useSelectedValueState,
  useSelectedValueDispatch
} from './selected_context'

const ContainerElementChild = ({
  icon,
  rightIcon,
  label,
  onClick,
  style,
  iconStyle,
  rightIconStyle,
  labelStyle,
  selected,
  className,
  iconClassName,
  rightIconClassName,
  labelClassName,
  id,
  applyDefaultStyles
}) => {
  const cx = cn({
    'custom-list-item': applyDefaultStyles,
    [className]: !!className,
    selected
  })

  return (
    <div id={id} role='button' onClick={onClick} className={cx} style={style}>
      {icon && (
        <div
          className={iconClassName || 'custom-list-item-icon'}
          style={iconStyle}
        >
          {icon}
        </div>
      )}
      <div
        className={labelClassName || 'custom-list-item-label'}
        style={labelStyle}
      >
        {label}
      </div>
      {rightIcon && (
        <div
          className={rightIconClassName || 'custom-list-item-icon'}
          style={rightIconStyle}
        >
          {rightIcon}
        </div>
      )}
    </div>
  )
}

ContainerElementChild.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element,
  rightIcon: PropTypes.element,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  rightIconClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  rightIconStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  id: PropTypes.string,
  applyDefaultStyles: PropTypes.bool
}
ContainerElementChild.defaultProps = {
  label: null,
  icon: null,
  rightIcon: null,
  className: undefined,
  iconClassName: undefined,
  rightIconClassName: undefined,
  labelClassName: undefined,
  style: null,
  iconStyle: null,
  rightIconStyle: null,
  labelStyle: null,
  onClick: noop,
  selected: false,
  id: '',
  applyDefaultStyles: true
}

const ContainerElement = props => {
  const { containerElement } = props

  if (!containerElement) {
    return <ContainerElementChild {...props} />
  }

  const containerType = get(containerElement, 'type', 'span')
  const containerProps = get(containerElement, 'props', {})

  return React.createElement(
    containerType,
    { ...containerProps, className: 'custom-list-item-shared' },
    <ContainerElementChild {...props} />
  )
}

ContainerElement.propTypes = {
  className: PropTypes.string,
  containerElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}
ContainerElement.defaultProps = {
  className: '',
  containerElement: null
}

function ListItem(props) {
  const { value, onClick, selectable } = props
  const contextValue = useSelectedValueState()
  const contextDispatch = useSelectedValueDispatch()

  const handleOnClick = event => {
    onClick(event, value)
    return contextDispatch({ value })
  }

  const selected = selectable ? `${value}` === `${contextValue.value}` : false
  return (
    <ContainerElement {...props} selected={selected} onClick={handleOnClick} />
  )
}

ListItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element,
  rightIcon: PropTypes.element,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  rightIconClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  rightIconStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  onClick: PropTypes.func,
  containerElement: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  selected: PropTypes.bool,
  selectable: PropTypes.bool
}
ListItem.defaultProps = {
  value: '',
  label: null,
  icon: null,
  rightIcon: null,
  className: '',
  iconClassName: '',
  rightIconClassName: '',
  labelClassName: '',
  style: null,
  iconStyle: null,
  rightIconStyle: null,
  labelStyle: null,
  onClick: noop,
  containerElement: null,
  selected: false,
  selectable: true
}

export default ListItem
