const CATEGORY_LABELS = {
  WORK_FORCE: 'Work Force',
  WORK_FEELING: 'Work Feeling',
  WORK_FOCUS: 'Work Focus',
  PURPOSE: 'Purpose',
  ROLE_CLARITY: 'Role Clarity',
  UTILIZATION: 'Utilization',
  AUTONOMY: 'Autonomy',
  SHARED_VALUES: 'Shared Values',
  FRIENDSHIP: 'Friendship',
  TRUST: 'Trust',
  AUTHENTICITY: 'Authenticity',
  FAIRNESS: 'Fairness',
  FEEDBACK: 'Feedback',
  MANAGER: 'Manager',
  REST: 'Rest',
  COMPETENCY: 'Competency',
  PROF_DEV: 'Prof. Dev.',
  MEANING: 'Meaning',
  SAFETY: 'Safety',
  CAPACITY: 'Capacity',
  ADAPTABILITY: 'Adaptability',
  PERSISTENCE: 'Persistence',
  PROACTIVE_BEHAVIOR: 'Proactive Behavior',
  ENPS: 'Employee Net Promoter Score',
  EXPAND_SKILL: 'Expand Skill',
  EXPAND_ROLE: 'Expand Role',
  CHECK: 'Check',
  GOAL_SUPPORT: 'Goal Support',
  LEADER_AVAILABILITY: 'Leader Availability',
  LEADER_INTEGRITY: 'Leader Integrity',
  COWORKER_RELATIONSHIPS: 'Coworker Relationships',
  ORGANIZATIONAL_COMMITMENT: 'Organizational Commitment',
  EMPLOYEE_WORKLOAD: 'Employee Workload',
  MANAGER_SUPPORT: 'Manager Support',
  ORGANIZATIONAL_FAIRNESS: 'Organizational Fairness',
  BURNOUT_AND_REST: 'Burnout and Rest',
  WFH_ENVIRONMENT: 'Work from Home Environment',
  DIRECTION_AND_AUTONOMY: 'Direction and Autonomy',
  ENVIRONMENT: 'Environment',
  PERSONAL_EXPERIENCE: 'Personal Experience',
  ORGANIZATION_EFFORTS: 'Organization Efforts',
  BALANCE: 'Balance',
  ORGANIZATION: 'Organization',
  ROLE: 'Role'
}

const AUTHORITY_LEVELS = {
  WORK_EXPERIENCE: 'Work Experience',
  LEADERSHIP: 'Leadership',
  MANAGER: 'Manager',
  COWORKERS: 'Coworkers'
}

const CATEGORIES_DEFINITIONS = {
  [CATEGORY_LABELS.MEANING]:
    'A measure of the work experience and organizational culture. The organization helps employees have a sense of value (purpose, money, status, and influence) when they immerse themselves in their roles.',
  [CATEGORY_LABELS.SAFETY]:
    "A measure of an employee's direct manager. The sense within an individual that they can show and employ their true selves at work without fear of negative consequences to self-image, status, or career.",
  [CATEGORY_LABELS.CAPACITY]:
    'A measure of the work experience and organizational culture. The organization enables employees to feel they possess the emotional and psychological resources necessary for investing themselves in their roles.',
  [CATEGORY_LABELS.AUTHENTICITY]:
    'Employees have a sense that leadership is honest about the business and themselves.',
  [CATEGORY_LABELS.AUTONOMY]:
    'A measure of the work experience and organizational culture. The organization trusts employees to use their expertise to make decisions about how to do their jobs.',
  [CATEGORY_LABELS.COMPETENCY]:
    'There is a match between the employee’s ability and the challenge of their work.',
  [CATEGORY_LABELS.FAIRNESS]:
    "A measure of the organization's leadership. Leaders help employees feel that the rewards and treatment of individuals are fair within the organization.",
  [CATEGORY_LABELS.FEEDBACK]:
    "A measure of an employee's direct manager. Employees feel that they receive adequate and helpful feedback from their manager.",
  [CATEGORY_LABELS.FRIENDSHIP]:
    'Employees have close relationships and feel cared for by another person (or persons) at work.',
  [CATEGORY_LABELS.MANAGER]:
    "A measure of an employee's direct manager. The relationship between the employee and their manager that looks at respect, fairness, and development.",
  [CATEGORY_LABELS.PROF_DEV]:
    "A measure of the work experience and organizational culture. The organization promotes and encourages employees' professional development.",
  [CATEGORY_LABELS.REST]:
    'A measure of the work experience and organizational culture. The organization gives employees a sense that they can take time off when needed.',
  [CATEGORY_LABELS.PURPOSE]:
    'A measure of the work experience and organizational culture. The organization communicates to employees why it exists beyond making a profit.',
  [CATEGORY_LABELS.ROLE_CLARITY]:
    "A measure of the work experience and organizational culture. The organization connects employees' daily work tasks to the purpose of the business and provides clarity about what that work is.",
  [CATEGORY_LABELS.SHARED_VALUES]:
    "A measure of an employee's relationship with their coworkers. Coworkers share common work attitudes.",
  [CATEGORY_LABELS.TRUST]:
    'Employees feel there is trust and respect in the working environment, specifically between people they work most closely with.',
  [CATEGORY_LABELS.UTILIZATION]:
    "A measure of the work experience and organizational culture. The organization effectively uses employees' abilities and skills in their roles.",
  [CATEGORY_LABELS.GOAL_SUPPORT]:
    'A measure of the organizational culture. The organization makes efforts to remove structural barriers that prevent an employee from achieving their goals.',
  [CATEGORY_LABELS.LEADER_AVAILABILITY]:
    "A measure of the organization's leadership. Leaders are approachable, visible, accessible and readily available to all employees in the organization.",
  [CATEGORY_LABELS.LEADER_INTEGRITY]:
    "A measure of the organization's leadership. The perception of leaders based on their commitment to do what is best for employees and the company and their ability to follow through on that commitment.",
  [CATEGORY_LABELS.ORGANIZATIONAL_COMMITMENT]:
    'A measure of the organizational culture. The organization encourages commitment, loyalty, and a positive outlook in employees.',
  [CATEGORY_LABELS.COWORKER_RELATIONSHIPS]:
    "A measure of an employee's relationship with their coworkers. There is coworker cohesion and amicable interactions leading to positive relationships at the organization."
}

const AUTHORITY_LEVEL_DEFINITIONS = {
  [AUTHORITY_LEVELS.WORK_EXPERIENCE]:
    'The work environment and organizational culture impact the engagement drivers that are part of this sphere.',
  [AUTHORITY_LEVELS.LEADERSHIP]:
    'Top-level leadership impacts the engagement drivers that are part of this sphere.',
  [AUTHORITY_LEVELS.MANAGER]:
    "An employee's direct manager impacts the engagement drivers that are part of this sphere.",
  [AUTHORITY_LEVELS.COWORKERS]:
    "The working relationship with an employee's immediate coworkers impacts the engagement drivers that are part of this sphere."
}

const DEPRECATED_DRIVER_DEFINITIONS = {
  [CATEGORY_LABELS.COWORKER_RELATIONSHIPS]:
    'Previously known as "Friendship" and "Trust".',
  [CATEGORY_LABELS.GOAL_SUPPORT]: 'Previously known as "Competency".',
  [CATEGORY_LABELS.LEADER_AVAILABILITY]: 'Previously known as "Authenticity".',
  [CATEGORY_LABELS.LEADER_INTEGRITY]: 'Previously known as "Authenticity".'
}

const DEPRECATED_DRIVER_MAP = {
  'Coworker Relationships': ['Friendship', 'Trust'],
  'Goal Support': ['Competency'],
  'Leader Availability': ['Authenticity'],
  'Leader Integrity': ['Authenticity']
}

export {
  CATEGORY_LABELS,
  CATEGORIES_DEFINITIONS,
  DEPRECATED_DRIVER_MAP,
  AUTHORITY_LEVELS,
  AUTHORITY_LEVEL_DEFINITIONS,
  DEPRECATED_DRIVER_DEFINITIONS
}
