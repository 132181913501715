import { PureComponent, createElement, createRef } from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'
import debounce from 'lodash/debounce'

export default class Resize extends PureComponent {
  constructor(props) {
    super(props)
    const { skipOnMount, refreshRate } = props

    this.skipOnMount = skipOnMount
    this.animationFrameID = null
    this.wrapper = createRef()
    this.createResizeHandler = this.createResizeHandler.bind(this)
    this.resizeHandler = debounce(this.createResizeHandler, refreshRate)
    this.resizeObserver = new ResizeObserver(this.resizeHandler)
  }

  componentDidMount() {
    this.resizeObserver.observe(this.wrapper.current)
  }

  componentWillUnmount() {
    // Unobserve all elements
    this.resizeObserver.disconnect()
    if (typeof window !== 'undefined' && this.animationFrameID) {
      window.cancelAnimationFrame(this.animationFrameID)
    }
    if (this.resizeHandler && this.resizeHandler.cancel) {
      this.resizeHandler.cancel()
    }
  }

  createResizeHandler(entries) {
    entries.forEach(entry => {
      const { height, width } = entry.contentRect
      if (!this.skipOnMount && typeof window !== 'undefined') {
        this.animationFrameID = window.requestAnimationFrame(() => {
          this.props.onResize({ height, width })
        })
      }
      this.skipOnMount = false
    })
  }

  render() {
    return createElement(
      this.props.componentType,
      {
        style: this.props.style,
        ref: this.wrapper
      },
      this.props.children
    )
  }
}

Resize.propTypes = {
  skipOnMount: PropTypes.bool,
  refreshRate: PropTypes.number,
  onResize: PropTypes.func,
  children: PropTypes.node,
  componentType: PropTypes.string,
  style: PropTypes.object
}
Resize.defaultProps = {
  skipOnMount: false,
  refreshRate: 300,
  onResize: e => e,
  children: null,
  componentType: 'div',
  style: {}
}
