import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getAllCategoryScoresCorrelationsForCampaignAsync(
  organizationId,
  campaignId
) {
  const params = { organizationId, campaignId }
  const options = {
    entity: 'category-scores-correlations',
    organizationId,
    campaignId
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/category-scores-correlations',
    params,
    options
  )
}
