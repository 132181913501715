import CategoryTypes from 'constants/category_types'
import { CATEGORY_LABELS } from 'constants/categories'
import get from 'lodash/get'

const FRIENDLY_CATEGORY_LABELS = {
  [CATEGORY_LABELS.SAFETY]: {
    short: 'Psych. Safety',
    long: 'Psychological Safety'
  },
  [CATEGORY_LABELS.ORGANIZATIONAL_COMMITMENT]: {
    short: 'Org. Commitment',
    long: 'Organizational Commitment'
  },
  [CATEGORY_LABELS.LEADER_AVAILABILITY]: {
    short: 'Leader Avail.',
    long: 'Leader Availability'
  },
  [CATEGORY_LABELS.COWORKER_RELATIONSHIPS]: {
    short: 'Coworker Rel.',
    long: 'Coworker Relationships'
  },
  [CATEGORY_LABELS.PROF_DEV]: {
    short: 'Prof. Dev.',
    long: 'Professional Development'
  },
  [CATEGORY_LABELS.ENPS]: {
    short: 'eNPS',
    long: 'Employee Net Promoter Score'
  }
}

/**
 * Determine if a category is a condition or driver.
 * @param {Object} [category = {}]
 * @param {String} [category.categoryType]
 * @returns {Boolean} True if the category is a condition or a driver
 */
export function isConditionOrDriver(category = {}) {
  const type = category.categoryType
  return type === CategoryTypes.CONDITIONS || type === CategoryTypes.DRIVERS
}

/**
 * Get the friendly name of a category for display
 * @param {String} categoryTitle - the title of the category
 * @param {String} length - the length variant of the friendly name (short/long)
 * @returns {String} friendly display name for the category
 */
export function getFriendlyCategoryName(categoryTitle, length = 'long') {
  return get(FRIENDLY_CATEGORY_LABELS, [categoryTitle, length], categoryTitle)
}
