const ACCESS_LEVELS = {
  EMPLOYEE: 'Employee',
  LEADER: 'Leader',
  ORGANIZATION_ADMIN: 'Organization Admin',
  ENGAGEMENT_ADMIN: 'Engagement Admin',
  ENGAGE_SUPER_ADMIN: 'Engage Super Admin'
}

const ACCESS_RESULTS_LEVELS = {
  FULL: 'Full',
  LIMITED: 'Limited',
  NONE: 'None'
}

const ACCESS_LEVEL_DEFINITIONS = {
  [ACCESS_LEVELS.EMPLOYEE]: 'Employees do not have access to Engage.',
  [ACCESS_LEVELS.LEADER]:
    'Leaders have full or limited access to results in Engage.',
  [ACCESS_LEVELS.ORGANIZATION_ADMIN]:
    'Organization Admins have admin capabilities and can be granted full or limited access to results.',
  [ACCESS_LEVELS.ENGAGEMENT_ADMIN]:
    'The Engagement Admin has admin capabilities and full access to Engage.',
  [ACCESS_LEVELS.ENGAGE_SUPER_ADMIN]:
    'Engage Super Admins have access to all organizations. Please proceed with caution when updating their role as this action will remove their admin access. NOTE: To update someone to Engage Super Admin, we are still requiring Engineering effort to keep it intentional.'
}

const ACCESS_RESULTS_LEVEL_DEFINITIONS = {
  [ACCESS_RESULTS_LEVELS.FULL]:
    'Users with full access have visibility to assessment results in all groups.',
  [ACCESS_RESULTS_LEVELS.LIMITED]:
    'Users with limited access will see the organization wide results and the detailed results for all employees within all groups they have access to.',
  [ACCESS_RESULTS_LEVELS.NONE]:
    'Users will have NO access or visibility to assessment results.'
}

export {
  ACCESS_LEVELS,
  ACCESS_RESULTS_LEVELS,
  ACCESS_LEVEL_DEFINITIONS,
  ACCESS_RESULTS_LEVEL_DEFINITIONS
}
