import React from 'react'
import { Link } from 'react-router-dom'

import NavListItem from 'containers/dropdown_nav/dropdown-nav-list-item'

function MeSubMenuContainer() {
  return (
    <NavListItem
      value='Profile'
      containerElement={<Link to='/profile' />}
      isSubMenuItem
    />
  )
}

MeSubMenuContainer.propTypes = {}

export default MeSubMenuContainer
