import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getJobById(jobId, options) {
  return ApiCallThunkCreator.get('/jobs/:jobId', { jobId }, { ...options })
}

export function getJobs(options = {}) {
  return ApiCallThunkCreator.get('/jobs', null, { ...options })
}

export function getOrganizationJobs(organizationId, options = {}) {
  return ApiCallThunkCreator.get(
    '/organizations/:id/jobs',
    { id: organizationId },
    { ...options },
  )
}

export function updateJobByIdAsync(id, data, options = {}) {
  const jobData = { ...data, id }
  return ApiCallThunkCreator.update('/jobs/:id', { id }, jobData, options)
}

export function getMostRecentEmployeeManagementStagingJobsAsync(
  organizationId,
  options = {},
) {
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/employee-management/jobs',
    { organizationId },
    { ...options },
  )
}

export function getJobTypesAsync() {
  return ApiCallThunkCreator.get('/job-types')
}
