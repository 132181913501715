import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import cn from 'classnames'

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { Button, BUTTON_RANKS } from 'furniture/buttons'
import { generateUniqueId } from 'utils/native/numbers'

export const FloatingDismissButton = ({ onRequestClose }) => (
  <div className='dialog-dismiss-button-container'>
    <Button
      rank={BUTTON_RANKS.TERTIARY}
      icon={<FontAwesomeIcon icon={faTimes} />}
      onClick={onRequestClose}
    />
  </div>
)

FloatingDismissButton.propTypes = {
  onRequestClose: PropTypes.func.isRequired
}

function Dialog({
  actions,
  title,
  open,
  children,
  className,
  onRequestClose,
  displayDismissButton
}) {
  return (
    <Modal
      className={cn('furniture-dialog', { [className]: !!className })}
      isOpen={open}
      onRequestClose={onRequestClose}
      overlayClassName='furniture-dialog-overlay'
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
    >
      {title && <div className='furniture-dialog-title'> {title} </div>}
      {children}
      {actions && (
        <div className='furniture-dialog-actions'>
          {actions.map(action => {
            return React.cloneElement(action, { key: generateUniqueId() })
          })}
        </div>
      )}
      {displayDismissButton && (
        <FloatingDismissButton onRequestClose={onRequestClose} />
      )}
    </Modal>
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  className: PropTypes.string,
  displayDismissButton: PropTypes.bool,
  title: PropTypes.node,
  actions: PropTypes.arrayOf(PropTypes.node)
}

Dialog.defaultProps = {
  open: false,
  className: '',
  displayDismissButton: true,
  title: null,
  actions: []
}

export default Dialog
