import { createSelector } from 'reselect'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import get from 'lodash/get'
import values from 'lodash/values'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import some from 'lodash/some'
import {
  createEntityGetter,
  createPropGetter,
  createPhaseSelector,
} from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import {
  selectCustomSurveyTypeId,
  selectQuarterlySurveyTypeId,
  selectTopicBasedSurveyTypeId,
  selectSmartPulseSurveyTypeId,
  getSurveyTypes,
} from 'selectors/survey_types'
import { selectSelectedCampaignLegacySurvey } from 'selectors/campaigns'
import { ENGAGE_SURVEY } from 'constants/surveys'
import {
  SURVEY_TYPES,
  SURVEY_TYPE_NAMES,
  AVAILABLE_CAMPAIGN_SURVEY_TYPES,
  EXPRESS_SURVEY_OPTIONS,
  SURVEY_CATEGORY_TYPES,
  RECOMMENDED_SURVEY_NAMES,
  DRIVERS_SURVEY_NAMES,
} from 'constants/survey_types'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { selectHydratedCampaignSurveysForCampaign } from 'selectors/campaign_surveys'

import { STATEMENT_TYPES } from 'constants/statements'

const EMPTY_OBJECT = {}

// 15Five Custom Surveys Used for Project Falcon!
const FULL_PERCEPTION_SURVEY_NAME = 'Perception Survey'
const ONE_ON_ONE_PERCEPTION_SURVEY_NAME = '1-on-1 Perception Question Survey'

export function getCurrentSurvey(state, props) {
  const currentSurveyId = get(props, 'match.params.id')
  const { surveys } = state.entities

  return surveys[currentSurveyId] || null
}

export function getCategories(state) {
  return state.entities.categories || EMPTY_OBJECT
}

function getSurveyStatements(state) {
  return state.entities['survey-statements'] || EMPTY_OBJECT
}

function getStatements(state) {
  return state.entities.statements || EMPTY_OBJECT
}

function getCategoryId(state, props) {
  return get(props, 'statement.category.id', null)
}

function getSurveys(state) {
  return state.entities.surveys || EMPTY_OBJECT
}

const selectStatementsWithCategoryArray = createSelector(
  getStatements,
  getCategories,
  (statements, categories) =>
    values(statements).map((statement) => {
      const category = categories[statement.categoryId]
      if (category) {
        return {
          ...statement,
          id: statement.id,
          body: statement.body,
          categoryTitle: category.title,
          categoryType: category.categoryType,
          category,
        }
      }
      return statement
    }),
)

export const selectSurveyById = createSelector(
  getSurveys,
  createPropGetter('surveyId'),
  (surveys, surveyId) => {
    const survey = get(surveys, `[${surveyId}]`)
    return survey
  },
)

export const selectSurveys = createSelector(
  getSurveys,
  getSurveyTypes,
  (surveys, surveyTypes) => {
    const hydratedSurveys = map(surveys || {}, (s) => {
      const nameWithVersion = s.version ? `${s.name} (${s.version})` : s.name

      return {
        ...s,
        nameWithVersion,
        surveyType: find(
          surveyTypes,
          (type) => `${s.surveyTypeId}` === `${type.id}`,
        ),
      }
    })

    return orderBy(hydratedSurveys, ['name', 'version'], ['asc', 'desc'])
  },
)

export const selectAvailableSurveysForAddingToCampaign = createSelector(
  selectCurrentOrganizationId,
  selectSurveys,
  (organizationId, surveys) => {
    return filter(surveys, (s) => {
      const surveyType = get(s, 'surveyType.name')

      const isAvailableForOrganization =
        !s.organizationId || `${s.organizationId}` === `${organizationId}`

      return (
        s.isAvailableToSend &&
        includes(AVAILABLE_CAMPAIGN_SURVEY_TYPES, surveyType) &&
        isAvailableForOrganization
      )
    })
  },
)

export const selectSystemSurveys = createSelector(selectSurveys, (surveys) =>
  filter(surveys || {}, (s) => {
    const surveyTypeName = get(s, 'surveyType.name')
    return !s.organizationId && surveyTypeName !== SURVEY_TYPES.CUSTOM
  }),
)

const selectStatementsForSelectedSurveyId = createSelector(
  selectSelectedCampaignLegacySurvey,
  getSurveyStatements,
  selectStatementsWithCategoryArray,
  getStatementsForSurveyId,
)

export const selectStatementsForSurvey = createSelector(
  selectSurveyById,
  getSurveyStatements,
  selectStatementsWithCategoryArray,
  getStatementsForSurveyId,
)

export const selectCategoriesForSurvey = createSelector(
  selectStatementsForSurvey,
  (statements) => {
    const categories = new Set()
    statements.forEach((s) => {
      if (s.category) {
        categories.add(s.category)
      }
    })
    return [...categories]
  },
)

function getStatementsForSurveyId(
  selectedSurvey,
  surveyStatements,
  statements,
) {
  if (!selectedSurvey) {
    return []
  }

  const surveyLinkedSurveyStatements = selectedSurvey.surveyStatements || []

  const finalSurveyStatements = map(
    surveyLinkedSurveyStatements,
    (linkedSurveyStatement) => {
      const fullSurveyStatement = surveyStatements[linkedSurveyStatement.id]
      const fullStatement = find(statements, [
        'id',
        fullSurveyStatement.statementId.toString(),
      ])
      const fullMergedSurveyStatement = {
        ...fullStatement,
        ...fullSurveyStatement,
      }
      return fullMergedSurveyStatement
    },
  )

  return orderBy(finalSurveyStatements, 'order')
}

export const selectStatementsBySurveyId = createSelector(
  createPropGetter('surveyId'),
  getSurveyStatements,
  selectStatementsWithCategoryArray,
  (surveyId, surveyStatements, statements) => {
    const surveyStatementsBySurveyId = filter(
      surveyStatements,
      (ss) => `${ss.surveyId}` === `${surveyId}`,
    )

    const finalSurveyStatements = map(
      surveyStatementsBySurveyId,
      (surveyStatement) => {
        const statement = find(
          statements,
          (s) => `${s.id}` === `${surveyStatement.statementId}`,
        )

        return {
          ...statement,
          ...surveyStatement,
        }
      },
    )

    return orderBy(finalSurveyStatements, 'order')
  },
)

export const selectSurveyPromptsBySurveyId = createSelector(
  createPropGetter('surveyId'),
  createEntityGetter(entities.SURVEY_PROMPTS),
  (surveyId, surveyPrompts) => {
    const surveyPromptsBySurveyId = filter(
      surveyPrompts,
      (sp) => `${sp.surveyId}` === `${surveyId}`,
    )

    return orderBy(surveyPromptsBySurveyId, 'order')
  },
)

/**
 * Returns object containing statements that are not bound by prompts
 * as well as prompts (with statements) based on what the survey requires.
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String} props.surveyId
 * @returns {Object} Object containing prompts and statements
 */
export const selectStatementsWithPromptsBySurveyId = createSelector(
  selectSurveyById,
  selectStatementsBySurveyId,
  selectSurveyPromptsBySurveyId,
  createEntityGetter(entities.PROMPTS),
  (survey, statements, surveyPrompts, prompts) => {
    const statementsAndPrompts = { statements: [], prompts: {} }

    forEach(surveyPrompts, (surveyPrompt) => {
      const prompt = get(prompts, [surveyPrompt.promptId])
      if (!statementsAndPrompts.prompts[surveyPrompt.id]) {
        statementsAndPrompts.prompts[surveyPrompt.id] = {
          ...prompt,
          ...surveyPrompt,
          organizationId: survey.organizationId,
          surveyId: survey.id,
          surveyPromptId: surveyPrompt.id,
          statements: [],
        }
      }
    })

    forEach(statements, (statement) => {
      if (statementsAndPrompts.prompts[statement.surveyPromptId]) {
        statementsAndPrompts.prompts[statement.surveyPromptId].statements.push(
          statement,
        )
      } else {
        statementsAndPrompts.statements.push(statement)
      }
    })

    // Convert statementsAndPrompts.prompts into an array sorted by 'order'
    statementsAndPrompts.prompts = orderBy(
      statementsAndPrompts.prompts,
      'order',
    )

    return statementsAndPrompts
  },
)

export const selectCategory = createSelector(
  getCategories,
  getCategoryId,
  function getCategory(categories, categoryId) {
    const category = categories[categoryId]
    if (!category) {
      return undefined
    }
    return category
  },
)

export const selectCategoriesArray = createSelector(
  getCategories,
  (categories) => values(categories),
)

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object} the survey object for the latest Engage survey
 */
export const selectLatestEngageSurvey = createSelector(
  getSurveys,
  (surveys) => {
    const ordered = orderBy(
      filter(surveys, (s) => s.name === ENGAGE_SURVEY),
      'version',
      'desc',
    )
    return ordered[0] || EMPTY_OBJECT
  },
)

/**
 * @param {Object} state - entire Redux state tree
 * @returns {Object}
 */
export const selectSurveysForSurveyType = createSelector(
  getSurveys,
  getSurveyTypes,
  createPropGetter('surveyTypeName'),
  (surveys, surveyTypes, surveyTypeName) => {
    const surveyType = find(surveyTypes, (st) => st.name === surveyTypeName)
    return !surveyType
      ? {}
      : keyBy(
          filter(surveys, (s) => `${s.surveyTypeId}` === `${surveyType.id}`),
          'id',
        )
  },
)

/**
 * @param {Object} state - Entire redux state tree
 * @returns {Object} The request phase for the surveys entity.
 */
export const selectSurveysPhase = createSelector(
  createPhaseSelector(entities.SURVEYS),
  (phase) => phase,
)

export const selectQuarterlySurveys = createSelector(
  selectSurveys,
  selectQuarterlySurveyTypeId,
  getSurveysOfType,
)

export const selectEngageSurveys = createSelector(
  selectQuarterlySurveys,
  (qSurveys) => filter(qSurveys, (s) => s.name === ENGAGE_SURVEY),
)

export const selectTopicBasedSurveys = createSelector(
  selectSurveys,
  selectTopicBasedSurveyTypeId,
  getSurveysOfType,
)

export const selectSelectedOrganizationTopicBasedSurveys = createSelector(
  selectTopicBasedSurveys,
  selectCurrentOrganizationId,
  (surveys, organizationId) =>
    surveys.filter(
      (s) => !s.organizationId || `${s.organizationId}` === `${organizationId}`,
    ),
)

export const selectSmartPulseSurveys = createSelector(
  createEntityGetter(entities.SURVEYS),
  selectSmartPulseSurveyTypeId,
  getSurveysOfType,
)

export const selectQuarterlySurveysMappedById = createSelector(
  selectQuarterlySurveys,
  (qSurveys) => keyBy(qSurveys, 'id'),
)

export const selectSmartPulseSurveysMappedById = createSelector(
  selectSmartPulseSurveys,
  (spSurveys) => keyBy(spSurveys, 'id'),
)

function getSurveysOfType(surveys, surveyTypeId) {
  return filter(
    values(surveys),
    (survey) => `${survey.surveyTypeId}` === `${surveyTypeId}`,
  )
}

export const getSurveyTypeForSurveyId = createSelector(
  getSurveyTypes,
  getSurveys,
  createPropGetter('surveyId'),
  (surveyTypes, surveys, surveyId) => {
    const survey = get(surveys, `[${surveyId}]`)
    const surveyTypeId = get(survey, 'surveyTypeId')
    return get(surveyTypes, `[${surveyTypeId}]`)
  },
)

export const selectAssessmentHasFeedback = createSelector(
  selectStatementsForSelectedSurveyId,
  (statements) => {
    return some(statements, (statement) => {
      return statement.statementType === 'open_response'
    })
  },
)

export const selectEnpsSurvey = createSelector(
  getSurveyTypes,
  getSurveys,
  (surveyTypes, surveys) => {
    const enpsSurveyType =
      find(surveyTypes, (st) => st.name === SURVEY_TYPES.ENPS) || {}

    const enpsSurveys = orderBy(
      filter(
        surveys,
        (s) =>
          s.name === SURVEY_TYPE_NAMES[SURVEY_TYPES.ENPS] &&
          `${s.surveyTypeId}` === `${enpsSurveyType.id}`,
      ),
      ['version'],
      ['desc'],
    )
    return get(enpsSurveys, '0')
  },
)

export const selectCustomSurveys = createSelector(
  selectSurveys,
  selectCustomSurveyTypeId,
  getSurveysOfType,
)

export const selectSelectedOrganizationSurveys = createSelector(
  selectCustomSurveys,
  selectCurrentOrganizationId,
  (surveys, organizationId) => {
    const orgSurveys = filter(
      surveys,
      (s) => `${s.organizationId}` === `${organizationId}`,
    )

    return orderBy(orgSurveys, ['createdAt'], ['desc'])
  },
)

export const selectGlobalCustomSurveys = createSelector(
  selectCustomSurveys,
  (surveys) => {
    const globalSurveys = filter(surveys, (s) => !s.organizationId)
    return orderBy(globalSurveys, ['createdAt'], ['desc'])
  },
)

export const selectHasOpenResponse = createSelector(
  selectHydratedCampaignSurveysForCampaign,
  getStatements,
  (campaignSurveys, statements) => {
    return !!campaignSurveys.find((survey) => {
      // we do not have an actual survey statement of open response set for the full EngageSurvey, but we do ask Dynamic Feedback.
      if (survey?.surveyName === 'EngageSurvey') {
        return true
      }
      if (!survey?.surveyStatements) {
        return false
      }
      const surveyStatements = survey.surveyStatements.map((surveyStatement) =>
        values(statements).find((statement) => {
          return `${surveyStatement.statementId}` === statement.id
        }),
      )
      return surveyStatements.find((statement) => {
        return statement?.statementType === STATEMENT_TYPES.OPEN_RESPONSE
      })
    })
  },
)

export const getExpressSurveysAvailableForAddingToCampaign = createSelector(
  selectAvailableSurveysForAddingToCampaign,
  (surveys) => {
    return filter(surveys, (survey) => {
      return Object.keys(EXPRESS_SURVEY_OPTIONS).find((s) => {
        return s === survey.name
      })
    })
  },
)

export const selectAvailableSurveysForDropdown = createSelector(
  selectAvailableSurveysForAddingToCampaign,
  getSurveyTypes,
  selectHydratedCampaignSurveysForCampaign,
  createPropGetter('hideQuarterlySurveys'),
  createPropGetter('showPerceptionSurvey'),
  (
    surveys,
    surveyTypes,
    selectedSurveys,
    hideQuarterlySurveys,
    showPerceptionSurvey,
  ) => {
    const dropdownSections = {
      [SURVEY_CATEGORY_TYPES.RECOMMENDED]: [],
      [SURVEY_CATEGORY_TYPES.DRIVERS]: [],
      [SURVEY_CATEGORY_TYPES.CUSTOM_GLOBAL]: [],
      [SURVEY_CATEGORY_TYPES.CUSTOM]: [],
    }
    const unselectedSurveys = surveys.filter((survey) => {
      return !selectedSurveys.find((s) => `${s.surveyId}` === survey.id)
    })

    unselectedSurveys.forEach((survey) => {
      const surveyType = find(
        surveyTypes,
        (type) => `${survey.surveyTypeId}` === `${type.id}`,
      )
      if (surveyType.name === SURVEY_TYPES.QUARTERLY && hideQuarterlySurveys) {
        // skip adding the quarterly survey to the dropdown
        return null
      }
      if (!survey.organizationId) {
        if (
          (surveyType.name === SURVEY_TYPES.QUARTERLY &&
            survey.name === RECOMMENDED_SURVEY_NAMES.ENGAGEMENT_WITH_DRIVERS) ||
          (surveyType.name === SURVEY_TYPES.QUARTERLY &&
            survey.name === RECOMMENDED_SURVEY_NAMES.ENGAGEMENT_SCORE_ONLY) ||
          (surveyType.name === SURVEY_TYPES.CUSTOM &&
            survey.name === RECOMMENDED_SURVEY_NAMES.MANAGER_EFFECTIVENESS) ||
          (surveyType.name === SURVEY_TYPES.ENPS &&
            survey.name === RECOMMENDED_SURVEY_NAMES.ENPS)
        ) {
          return dropdownSections[SURVEY_CATEGORY_TYPES.RECOMMENDED].push(
            survey,
          )
        }
        if (
          surveyType.name === SURVEY_TYPES.QUARTERLY &&
          Object.values(DRIVERS_SURVEY_NAMES).includes(survey.name)
        ) {
          return dropdownSections[SURVEY_CATEGORY_TYPES.DRIVERS].push(survey)
        }
        if (surveyType.name === SURVEY_TYPES.CUSTOM) {
          if (
            (survey.name === FULL_PERCEPTION_SURVEY_NAME ||
              survey.name === ONE_ON_ONE_PERCEPTION_SURVEY_NAME) &&
            !showPerceptionSurvey
          ) {
            return null
          }
          return dropdownSections[SURVEY_CATEGORY_TYPES.CUSTOM_GLOBAL].push(
            survey,
          )
        }
      }
      if (surveyType.name === SURVEY_TYPES.CUSTOM && survey.organizationId) {
        return dropdownSections[SURVEY_CATEGORY_TYPES.CUSTOM].push(survey)
      }

      // survey doesn't match any of the categories, so don't add it to the dropdown
      return null
    })

    Object.keys(dropdownSections).forEach((section) => {
      if (dropdownSections[section].length === 0) {
        delete dropdownSections[section]
      }
    })
    return Object.keys(dropdownSections).map((section) => {
      let label = SURVEY_TYPE_NAMES[section]
      if (section === SURVEY_CATEGORY_TYPES.RECOMMENDED) {
        label = 'Recommended Surveys'
      }
      if (section === SURVEY_CATEGORY_TYPES.DRIVERS) {
        label = 'Driver Surveys'
      }
      if (section === SURVEY_CATEGORY_TYPES.CUSTOM_GLOBAL) {
        label = '15Five Custom Surveys'
      }
      if (section === SURVEY_CATEGORY_TYPES.CUSTOM) {
        label = 'My Custom Surveys'
      }
      return {
        label,
        options: dropdownSections[section].map((s) => {
          return { label: s.name, value: s.id }
        }),
      }
    })
  },
)
