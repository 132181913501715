import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { LoadingIndicator } from '@15five/design-system'

function LoadingButtonIcon({ className }) {
  return (
    <div className={cn('furniture-circular-button-icon', className)}>
      <LoadingIndicator isSmall />
    </div>
  )
}

LoadingButtonIcon.propTypes = {
  className: PropTypes.string,
}

LoadingButtonIcon.defaultProps = {
  className: '',
}
export default LoadingButtonIcon
