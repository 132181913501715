import get from 'lodash/get'
import reducer from 'dux/helpers/reducer'
import { CUSTOM_ACTION_NAME } from 'constants/action_occurrences'
import { getActionPlansAsync } from 'dux/api/actions/action_plans'
import { getV3GroupAsync } from 'dux/api/actions/v3_groups'
import { getV3GroupParticipationForCampaignAsync } from 'dux/api/actions/v3_group_participations'
import { getAllCategoryScoresCorrelationsForCampaignAsync } from 'dux/api/actions/category_scores_correlations'
import { pushQueryParams } from 'utils/browser/query'
import { setData } from './emplify_form'

function fetchV3GroupData(dispatch, organizationId, groupId, campaignId) {
  let last = Promise.resolve()

  if (organizationId && groupId) {
    last = dispatch(getV3GroupAsync(organizationId, groupId))
  }

  if (organizationId && groupId && campaignId) {
    dispatch(
      getActionPlansAsync(organizationId, {
        query: {
          'filter[v3GroupId][ne]': 'null',
          'filter[campaignId]': campaignId,
        },
      }),
    )
    last = dispatch(
      getV3GroupParticipationForCampaignAsync(
        organizationId,
        campaignId,
        groupId,
      ),
    )
  }

  return last
}

export function fetchActionPlanGroupData(organizationId, groupId, campaignId) {
  return (dispatch) => {
    if (organizationId && campaignId) {
      dispatch(
        getAllCategoryScoresCorrelationsForCampaignAsync(
          organizationId,
          campaignId,
        ),
      )
    }
    return fetchV3GroupData(dispatch, organizationId, groupId, campaignId)
  }
}

export function chooseCategoryScore(formName, categoryScore, history) {
  return (dispatch) => {
    const id = get(categoryScore, 'id', null)
    const categoryId = get(categoryScore, 'categoryId', null)
    pushQueryParams({ driver: categoryId }, history)
    return dispatch(
      setData(formName, {
        actionId: null,
        categoryScore: { id, categoryId },
      }),
    )
  }
}

export function chooseAction(formName, currentActionId, action) {
  return (dispatch) => {
    const newActionId = get(action, 'id', null)
    if (currentActionId === newActionId) {
      // Normally, we wouldn't have to care about this case, but we especially don't want to
      // clear out the note field if they are clicking on the already-selected Custom action row
      return Promise.resolve()
    }

    const isCustom = get(action, 'name') === CUSTOM_ACTION_NAME
    return dispatch(
      setData(formName, {
        isCustom,
        note: '',
        actionId: get(action, 'id', null),
      }),
    )
  }
}

const initialState = {
  isReviewingActionPlan: false,
}

// Constants
const SET_IS_REVIEWING = 'action_plan_worksheet/SET_IS_REVIEWING'

// Reducers
function reduceSetIsReviewingActionPlan(state = initialState, action) {
  return {
    ...state,
    isReviewingActionPlan: action.isReviewingActionPlan,
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_IS_REVIEWING]: reduceSetIsReviewingActionPlan,
  },
  initialState,
)

// Actions
function setIsReviewingActionPlan(isReviewingActionPlan) {
  return {
    type: SET_IS_REVIEWING,
    isReviewingActionPlan,
  }
}

export { setIsReviewingActionPlan }
