import React, { PureComponent } from 'react'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import { Button, BUTTON_RANKS } from 'furniture/buttons'
import { RowCollapseIcon, RowExpandIcon } from 'components/icons'

class CollapseButton extends PureComponent {
  constructor(props) {
    super(props)

    this.onClickWrapper = this.onClickWrapper.bind(this)
  }

  onClickWrapper(e) {
    return this.props.onClick(e)
  }

  render() {
    const { expanded, hideText, label } = this.props
    let buttonLabel = label
    if (!buttonLabel) {
      buttonLabel = expanded ? 'Collapse' : 'Expand'
    }

    if (hideText) {
      return (
        <Button
          rank={BUTTON_RANKS.TERTIARY}
          icon={expanded ? <RowCollapseIcon /> : <RowExpandIcon />}
          onClick={this.onClickWrapper}
        />
      )
    }
    return (
      <Button
        rank={BUTTON_RANKS.TERTIARY}
        label={buttonLabel}
        onClick={this.onClickWrapper}
        icon={expanded ? <RowCollapseIcon /> : <RowExpandIcon />}
      />
    )
  }
}

CollapseButton.propTypes = {
  expanded: PropTypes.bool,
  hideText: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string
}
CollapseButton.defaultProps = {
  expanded: false,
  hideText: false,
  onClick: noop,
  label: null
}

export default CollapseButton
