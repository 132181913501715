import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { POPOVER_POSITIONS } from 'furniture/popover'
import cn from 'classnames'

import { usePopper } from 'react-popper'

function Tooltip({
  anchorEl,
  children,
  position,
  className,
  style,
  offset,
  anchorRef,
  allowTooltipHover
}) {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  const [arrowElement, setArrowElement] = useState(null)
  const [visible, setVisibility] = useState(false)
  const { styles, attributes } = usePopper(
    anchorRef || referenceElement,
    popperElement,
    {
      placement: position,
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset
          }
        },
        { name: 'arrow', options: { element: arrowElement } }
      ]
    }
  )

  const onMouseEnter = () => setVisibility(true)
  const onMouseLeave = () => setVisibility(false)

  const anchorElement = anchorEl ? (
    <div
      className='furniture-tooltip-anchor'
      ref={setReferenceElement}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {anchorEl}
    </div>
  ) : null

  return (
    <div>
      {anchorElement}
      <div
        className={cn('furniture-tooltip-transition', { isVisible: visible })}
      >
        {visible && (
          <div
            className='furniture-tooltip-wrapper'
            ref={setPopperElement}
            role='tooltip'
            style={{
              ...styles.popper,
              ...style
            }}
            {...attributes.popper}
            onMouseEnter={() => allowTooltipHover && onMouseEnter()}
            onMouseLeave={() => allowTooltipHover && onMouseLeave()}
          >
            <div
              className={cn('furniture-tooltip', className)}
              style={
                allowTooltipHover
                  ? { ...styles.offset }
                  : { ...styles.offset, pointerEvents: 'none' }
              }
            >
              <div
                className='furniture-tooltip-arrow'
                ref={setArrowElement}
                style={{ ...styles.arrow }}
              />
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.node,
  position: PropTypes.oneOf(Object.values(POPOVER_POSITIONS)),
  className: PropTypes.string,
  style: PropTypes.object,
  offset: PropTypes.arrayOf(PropTypes.number),
  anchorRef: PropTypes.object,
  allowTooltipHover: PropTypes.bool
}

Tooltip.defaultProps = {
  position: POPOVER_POSITIONS.RIGHT,
  children: null,
  anchorEl: null,
  className: '',
  style: {},
  /**
   * Offset the tooltip to prevent overlap of the anchor element
   * and the tooltip element.
   */
  offset: [0, 4],
  anchorRef: null,
  allowTooltipHover: false
}

export default Tooltip
