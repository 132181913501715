import { createElement } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { mutuallyExclusiveBooleanProps } from 'utils/validation/proptypes'

const textPropTypes = [
  'big',
  'body',
  'info',
  'warning',
  'subheading',
  'ellipsis'
]

function Text({
  style,
  component,
  className,
  children,
  big,
  body,
  info,
  warning,
  subheading,
  ellipsis,
  weight
}) {
  const cx = cn(
    'furniture-text',
    { big },
    { body },
    { info },
    { warning },
    { subheading },
    { ellipsis },
    { [className]: !!className },
    { 'furniture-text-weight-light': weight === 'light' },
    { 'furniture-text-weight-heavy': weight === 'heavy' }
  )

  return createElement(component, { className: cx, style, children })
}

Text.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  ...mutuallyExclusiveBooleanProps(textPropTypes),
  weight: PropTypes.oneOf(['light', 'heavy'])
}
Text.defaultProps = {
  component: 'div',
  className: null,
  style: {},
  children: '',
  weight: null
}

export default Text
