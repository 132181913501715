import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import cn from 'classnames'
import get from 'lodash/get'

import { selectGroupHasChildren } from 'selectors/v3_groups'

import { ListItem } from 'furniture/selectable_list'

function GroupNavItem({
  value,
  group,
  campaignMembershipCount,
  isSelectedParent,
  groupHasChildren,
  onClick,
  selected
}) {
  const cx = cn(['v2-sub-menu-list-item'], {
    'group-nav-list-item-selected-parent': isSelectedParent,
    'has-sub-title': !!group.parentGroupName
  })

  const rightIcon = <span className='thick'>{campaignMembershipCount}</span>

  const label = (
    <>
      <div>{group.name}</div>
      {group.parentGroupName && (
        <div className='thin'>{group.parentGroupName}</div>
      )}
    </>
  )

  return (
    <ListItem
      key={`${group.parentGroupId}-${group.displayValue}`}
      value={value}
      rightIcon={rightIcon}
      label={label}
      className={cx}
      iconClassName='v2-sub-menu-list-item-icon'
      rightIconClassName='v2-sub-menu-list-item-right-icon'
      labelClassName='v2-sub-menu-list-item-label'
      onClick={() => onClick(group, isSelectedParent, groupHasChildren)}
      selected={selected}
    />
  )
}

function mapStateToProps(state, ownProps) {
  const groupId = get(ownProps, ['group', 'id'])

  return {
    groupHasChildren: selectGroupHasChildren(state, { groupId })
  }
}

GroupNavItem.propTypes = {
  value: PropTypes.string.isRequired,
  group: PropTypes.object.isRequired,
  campaignMembershipCount: PropTypes.number,
  isSelectedParent: PropTypes.bool,
  groupHasChildren: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool
}

GroupNavItem.defaultProps = {
  isSelectedParent: false,
  selected: false,
  campaignMembershipCount: null
}

export default connect(mapStateToProps)(GroupNavItem)
