import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import NavigateAway from './navigate_away'

function Breadcrumb({
  title,
  subtitle,
  backUrl,
  dataStaticId,
  allowBack,
  allowUp,
  onClickBack,
  titleClassName
}) {
  return (
    <div className='rebrand-furniture-breadcrumb'>
      {(backUrl || allowBack || allowUp) && (
        <NavigateAway
          allowUp={allowUp}
          allowBack={allowBack}
          backUrl={backUrl}
          onClick={onClickBack}
        />
      )}
      <h1
        data-static-id={dataStaticId}
        className={cn('furniture-breadcrumb-title', titleClassName)}
      >
        {title}
        {`${subtitle ? ':' : ''}`}
      </h1>
      {subtitle && (
        <h2 className='furniture-breadcrumb-subtitle'>{subtitle}</h2>
      )}
    </div>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  backUrl: PropTypes.string,
  allowBack: PropTypes.bool,
  allowUp: PropTypes.bool,
  dataStaticId: PropTypes.string,
  onClickBack: PropTypes.func,
  titleClassName: PropTypes.string.isRequired
}

Breadcrumb.defaultProps = {
  subtitle: '',
  backUrl: null,
  dataStaticId: undefined,
  allowBack: false,
  allowUp: false,
  onClickBack: () => {}
}

export default Breadcrumb
