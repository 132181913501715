import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export const rootConfig = {
  storage,
  whitelist: ['organizations'],
  key: 'emplify'
}

export { persistReducer, persistStore }
