import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export function getDisplayName(employeeOrPerson) {
  if (isEmpty(employeeOrPerson)) {
    return ''
  }

  const firstName =
    get(employeeOrPerson, 'firstName') ||
    get(employeeOrPerson, 'person.firstName')
  const lastName =
    get(employeeOrPerson, 'lastName') ||
    get(employeeOrPerson, 'person.lastName')
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }

  const emailAddress =
    get(employeeOrPerson, 'emailAddress') ||
    get(employeeOrPerson, 'person.emailAddress')
  if (emailAddress) {
    return emailAddress
  }

  const mobilePhoneNumber =
    get(employeeOrPerson, 'mobilePhoneNumber') ||
    get(employeeOrPerson, 'person.mobilePhoneNumber')
  if (mobilePhoneNumber) {
    return mobilePhoneNumber
  }

  return ''
}

export function getNameAndContactInfo(employee = {}) {
  const { person = {} } = employee

  const contact = employee.emailAddress || employee.mobilePhoneNumber || ''

  if (employee.firstName && employee.lastName) {
    return `${employee.firstName} ${employee.lastName} (${contact})`
  }

  if (person.firstName && person.lastName) {
    return `${person.firstName} ${person.lastName} (${contact})`
  }

  return contact
}

export function splitFirstAndLastName(fullName) {
  const names = fullName.split(' ')
  return { firstName: names[0], lastName: names[1] }
}
