import { validatePhone, phoneValidationRegex } from 'utils/validation/phone'

/**
 * Sanitizes phone numbers for saving to the backend
 *
 * @export
 * @param {string} phoneNumber - input
 * @returns {string} `+13174206969` or null if invalid
 */
export function sanitizePhoneNumber(phoneNumber) {
  // We do not have a valid phone number
  if (!validatePhone(phoneNumber)) {
    return null
  }

  // We have a valid phone number
  // Returning a formatted string: +13174206969
  return phoneNumber.replace(phoneValidationRegex, '+1$2$3$4')
}

/**
 * Formats phone numbers for viewing on the frontend
 *
 * @export
 * @param {string} phoneNumber - input
 * @returns {string} - `(317) 420-6969` or '' if invalid
 */
export function formatPhoneNumber(phoneNumber) {
  // We do not have a valid phone number
  if (!validatePhone(phoneNumber)) {
    return ''
  }

  // We have a valid phone number
  // Returning a formatted string: (317) 420-6969
  return phoneNumber.replace(phoneValidationRegex, '($2) $3-$4')
}

/**
 * Formats phone number as user is typing
 *
 * @export
 * @param {string} value - input
 * @returns {string} - `(___`, `(___) `, `(___) ___`, `(___) ___-`, `(___) ___-____`
 */
export function formatPhoneField(value) {
  // return cleanly early
  if (!value) {
    return ''
  }

  const nums = value.replace(/[^\d]/g, '')
  // If it's valid, then it was sanitized with `+1`, so we must offset by 1
  if (validatePhone(value)) {
    return `(${nums.slice(1, 4)}) ${nums.slice(4, 7)}-${nums.slice(7, 11)}`
  }

  // Add `(` when starting to type
  if (
    nums.length < 3 ||
    (nums.length === 3 && value.length !== 4) // allow for easy deleting
  ) {
    return `(${nums}`
  }
  // Add `(___) ___`
  if (
    nums.length < 6 ||
    (nums.length === 6 && value.length !== 9) // allow for easy deleting
  ) {
    return `(${nums.slice(0, 3)}) ${nums.slice(3)}`
  }
  // Return full `(___) ___-____`
  return `(${nums.slice(0, 3)}) ${nums.slice(3, 6)}-${nums.slice(6, 10)}`
}

/**
 * Parse phone field
 *
 * @export
 * @param {string} value
 * @returns {string} sanitized phone number if valid
 */
export function parsePhoneField(value) {
  return validatePhone(value) ? sanitizePhoneNumber(value) : value
}
