import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function createCampaignAsync(organizationId, data) {
  const params = { organizationId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns',
    params,
    data,
  )
}

export function getCampaignAsync(organizationId, campaignId, options = {}) {
  const params = { organizationId, campaignId }

  const opts = {
    ...options,
    query: {
      include: [
        'surveys.survey-types',
        'campaign-surveys.surveys.survey-types',
        'campaign-participations',
        'campaign-notifications',
        'campaign-targets',
      ].join(','),
    },
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId',
    params,
    opts,
  )
}

export function getAllCampaignsByOrgIdAsync(organizationId, options = {}) {
  const params = { organizationId }

  const opts = {
    ...options,
    query: {
      ...options.query,
      include: [
        'surveys.survey-types',
        'campaign-surveys.surveys.survey-types',
        'campaign-participations',
        'campaign-targets',
      ].join(','),
    },
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns',
    params,
    opts,
  )
}

export function getInProgressCampaignsForEmployeeAsync(
  organizationId,
  employeeId,
  options,
) {
  const params = { organizationId, employeeId }
  const queryOptions = {
    ...options,
    query: {
      include: [
        'surveys.survey-types',
        'campaign-surveys.surveys.survey-types',
      ].join(','),
    },
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/employees/:employeeId/campaigns',
    params,
    queryOptions,
  )
}

export function getAllQuarterlyCampaignsByOrgIdAsync(organizationId) {
  const params = { organizationId }
  const options = {
    query: {
      'filter[parentCampaignId]': 'null',
      include: [
        'surveys.survey-types',
        'campaign-surveys.surveys.survey-types',
        'campaign-participations',
      ].join(','),
    },
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns',
    params,
    options,
  )
}

export function updateCampaignAsync(
  campaignId,
  organizationId,
  data,
  options = {},
) {
  const params = { organizationId, campaignId }

  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/campaigns/:campaignId',
    params,
    data,
    options,
  )
}

export function updateCampaignMembershipsAsync(campaignId, groupId = null) {
  const params = { campaignId }
  return ApiCallThunkCreator.create(
    '/campaigns/:campaignId/update-memberships',
    params,
    { groupId },
    {
      entity: 'membership',
      toastMessage: 'Successfully updated employee memberships',
    },
  )
}

export function deleteCampaignAsync(campaignId, organizationId, options = {}) {
  const params = { organizationId, campaignId }

  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/campaigns/:campaignId',
    params,
    options,
  )
}

export function createInviteForEmployeeAsync(
  organizationId,
  campaignId,
  employeeId,
  body,
) {
  const path =
    '/organizations/:organizationId/campaigns/:campaignId/employees/:employeeId/invite'
  const params = { organizationId, campaignId, employeeId }
  const callOptions = { entity: 'messages' }
  return ApiCallThunkCreator.create(path, params, body, callOptions)
}

export function updateCampaignIsPublishedAsync(
  campaignId,
  organizationId,
  data,
) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/campaigns/:campaignId/publish',
    params,
    data,
    { entity: 'campaigns', toastMessage: 'Successfully updated assessment' },
  )
}

export function refreshMembershipsAsync(campaignId, organizationId) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/refresh-memberships',
    params,
    null,
    {
      entity: 'memberships',
      toastMessage: 'Successfully refreshed memberships',
    },
  )
}

export function reopenCampaignAsync(organizationId, campaignId) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/reopen',
    params,
    null,
    {
      entity: 'campaign',
      toastMessage:
        'Campaign has been reopened and will automatically close again in 24 hours',
    },
  )
}

export function deleteCampaignInsightsAsync(organizationId, campaignId) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/campaigns/:campaignId/campaign-insights',
    params,
    {
      entity: 'campaign-insights',
      toastMessage: 'Successfully deleted campaign insights',
    },
  )
}
