import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

function Card({ children, className, hasTabs, style, id, dataStaticId }) {
  const cx = cn('furniture-card', {
    [className]: !!className,
    tabs: hasTabs
  })
  return (
    <div id={id} className={cx} style={style} data-static-id={dataStaticId}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasTabs: PropTypes.bool,
  style: PropTypes.object,
  dataStaticId: PropTypes.string,
  id: PropTypes.string
}
Card.defaultProps = {
  children: undefined,
  className: null,
  hasTabs: false,
  style: {},
  dataStaticId: undefined,
  id: undefined
}

export default Card
