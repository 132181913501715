import React from 'react'
import PropTypes from 'prop-types'

const SelectedValueContext = React.createContext()
const SelectedValueDispatchContext = React.createContext()

function selectedValueReducer(state, action) {
  return {
    value: action.value
  }
}

function SelectedValueProvider({ children, value }) {
  const [state, dispatch] = React.useReducer(selectedValueReducer, {
    value
  })
  return (
    <SelectedValueContext.Provider value={state}>
      <SelectedValueDispatchContext.Provider value={dispatch}>
        {children}
      </SelectedValueDispatchContext.Provider>
    </SelectedValueContext.Provider>
  )
}

SelectedValueProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SelectedValueProvider.defaultProps = {
  value: null
}

function useSelectedValueState() {
  const context = React.useContext(SelectedValueContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedValueState must be used within a SelectedValueProvider'
    )
  }
  return context
}

function useSelectedValueDispatch() {
  const context = React.useContext(SelectedValueDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedValueDispatch must be used within a SelectedValueProvider'
    )
  }
  return context
}

export {
  SelectedValueProvider,
  useSelectedValueState,
  useSelectedValueDispatch
}
