import get from 'lodash/get'
import { fuzzyFilter, initialismMatch } from '../match'

export const PERFECT = 1
export const STARTS_WITH = 2
export const INITIALISM = 3
export const INCLUDES = 4
export const FUZZY = 5
export const NOT_A_MATCH = 6

/**
 * Computes a number that can be used to sort matches with best matches first.
 * Searches are case-insensitive
 *
 * @param {Object} item The item to search
 * @param {String} propertyToSearch The property on the item to search
 * @param {String} pattern The search query
 * @returns {Number} A number from 1-6 indicating how strong the match is.
 *  Lower numbers are stronger than higher numbers.
 *  1 - An exact match
 *  2 - A "starts with" match
 *  3 - An initialism match
 *  4 - An "includes" match
 *  5 - A fuzzy match
 *  6 - Not a match
 *
 * @example
 * const org = { name: 'Cobalt Corp' }
 * computeSortScore(org, 'name', 'cobalt corp') // 1
 * computeSortScore(org, 'name', 'cob') // 2
 * computeSortScore(org, 'name', 'cc') // 3
 * computeSortScore(org, 'name', 'balt') // 4
 * computeSortScore(org, 'name', 'cbt') // 5
 * computeSortScore(org, 'name', 'xyz') // 6
 */
export default function computeSortScore(item, propertyToSearch, pattern = '') {
  if (!item || !propertyToSearch || !pattern) {
    return NOT_A_MATCH
  }

  const text = get(item, propertyToSearch)
  if (!text) {
    return NOT_A_MATCH
  }

  const lowercaseText = text.toLowerCase()
  const lowercasePattern = pattern.toLowerCase()

  if (lowercasePattern === lowercaseText) {
    return PERFECT
  }

  if (lowercaseText.startsWith(lowercasePattern)) {
    return STARTS_WITH
  }

  if (initialismMatch(lowercasePattern, lowercaseText)) {
    return INITIALISM
  }

  if (lowercaseText.includes(lowercasePattern)) {
    return INCLUDES
  }

  if (fuzzyFilter(pattern, text)) {
    return FUZZY
  }

  return NOT_A_MATCH
}
