import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import FontAwesomeIcon, {
  FONT_AWESOME_SIZES,
} from 'furniture/font_awesome_icon'

import AppBar from 'furniture/app_bar'
import BillingChangesProtip from 'components/billing_changes_protip'

import SettingsButton from './settings'

let routes = []
import('router').then((module) => {
  routes = module.default
})

function Header({ toggleShowDrawer }) {
  return (
    <div className='app-header'>
      <AppBar
        menuButton={
          <FontAwesomeIcon
            icon={faBars}
            size={FONT_AWESOME_SIZES.small}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              toggleShowDrawer?.()
            }}
          />
        }
      >
        <SettingsButton />
      </AppBar>
      <header id='insights-header'>
        <BillingChangesProtip />
        <Suspense fallback={<div />}>
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={
                  typeof route.title === 'function' ? route.title : undefined
                }
                render={
                  typeof route.title !== 'function'
                    ? (props) => <route.title {...props} />
                    : undefined
                }
              />
            ))}
          </Switch>
        </Suspense>
      </header>
    </div>
  )
}

Header.propTypes = {
  toggleShowDrawer: PropTypes.func.isRequired,
}

export default Header
