import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getRolesAsync() {
  return ApiCallThunkCreator.get('/roles')
}

export function addEmployeePersonWithRoleAsync(
  organizationId,
  employeeId,
  roleId,
  options = {}
) {
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/employees/:employeeId/roles/:roleId/person-roles',
    { organizationId, employeeId, roleId },
    null,
    options
  )
}

export function addRoleAsync(
  organizationId,
  personId,
  roleId,
  data = {},
  options = {}
) {
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/people/:personId/roles/:roleId/person-roles',
    { organizationId, personId, roleId },
    data,
    options
  )
}

export function addInternalPersonRoleAsync(
  personId,
  roleId,
  data = {},
  options = {}
) {
  return ApiCallThunkCreator.create(
    '/people/:personId/roles/:roleId/internal',
    { personId, roleId },
    data,
    options
  )
}

export function removeRoleAsync(
  organizationId,
  personId,
  personRoleId,
  options
) {
  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/people/:personId/person-roles/:personRoleId',
    { organizationId, personId, personRoleId },
    options
  )
}

export function removePersonRoleAsync(personId, personRoleId, options = {}) {
  return ApiCallThunkCreator.destroy(
    '/people/:personId/person-roles/:personRoleId',
    { personId, personRoleId },
    options
  )
}

export function changeRoleAsync(
  organizationId,
  personId,
  personRoleId,
  roleId,
  options = {}
) {
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/people/:personId/person-roles/:personRoleId',
    { organizationId, personId, personRoleId },
    { roleId },
    options
  )
}

export function getPersonRolesAsync(personId, options = {}) {
  return ApiCallThunkCreator.get(
    '/people/:personId/person-roles',
    { personId },
    options
  )
}

export function getAllPersonRolesAsync(options = {}) {
  return ApiCallThunkCreator.get('/person-roles', null, options)
}

export function resetRolesToEmployeeRoles(organizationId, role, options = {}) {
  return ApiCallThunkCreator.update(
    `/organizations/:organizationId/reset-person-roles-to-employee`,
    { organizationId },
    null,
    { ...options, entity: 'person-roles', query: { 'filter[role]': role } }
  )
}
