import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getAllOrganizationsAsync(options = {}) {
  const opts = {
    ...options
  }
  return ApiCallThunkCreator.get('/organizations', undefined, opts)
}

export function getOrganizationAsync(organizationId, options = {}) {
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId',
    { organizationId },
    options
  )
}

export function createOrganizationAsync(data, options = {}) {
  return ApiCallThunkCreator.create('/organizations', null, data, options)
}

export function deleteOrganizationAsync(organizationId) {
  const params = { organizationId }
  return ApiCallThunkCreator.destroy('/organizations/:organizationId', params)
}

export function updateOrganizationAsync(organizationId, data) {
  const params = { organizationId }
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId',
    params,
    data
  )
}

export function restoreOrganizationAsync(organizationId) {
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/restore',
    { organizationId },
    undefined,
    { entity: 'organizations' }
  )
}
