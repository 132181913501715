import jws from 'jws'
import queryString from 'query-string'
import get from 'lodash/get'
import requester from 'utils/http/requester'

import { TOKEN_KEY } from 'constants/login'

const defaultOptions = {
  url: process.env.AUTH_URL,
  clientID: process.env.AUTH_CLIENT_ID,
  redirectUri: process.env.AUTH_REDIRECT_URI,
  audience: process.env.AUTH_AUDIENCE,
  responseType: 'token'
}

/**
 * A util for handling the Emplify Authentication
 */
export default class EmplifyAuth {
  constructor(opts) {
    this.baseOptions = {
      ...defaultOptions,
      opts
    }
  }

  /**
   * Redirects the browser to the remote login screen for the individual to authenticate
   * @param  {Object} opts Options for overriding defaults
   */
  authorize(opts) {
    const settings = {
      ...this.baseOptions,
      ...opts
    }

    const { url, ...otherSettings } = settings
    window.location = `${url}?${queryString.stringify(otherSettings)}`
  }

  /**
   * Refreshes an individual's access token to expend his/her session
   * @param  {Object} opts Options for overriding defaults
   * @param  {Function} cb Callback that returns an access_token
   */
  checkSession(opts, cb) {
    const settings = {
      ...this.baseOptions,
      ...opts
    }

    const { url, clientID, audience } = settings
    const accessToken = localStorage.getItem(TOKEN_KEY)
    const query = {
      client_id: clientID,
      audience,
      accessToken
    }

    requester
      .get(`${url}/auth/authorize?${queryString.stringify(query)}`)
      .then(res => {
        cb(null, {
          accessToken: get(res, 'data.access_token', null)
        })
      })
  }

  /**
   * Logs an individual out, and redirects them to the logout screen
   */
  // eslint-disable-next-line class-methods-use-this
  logout() {
    // NOTE: We do not have anything to clear out at the moment.
    // In the future we may want to redirect to EmplifyAuth and back to invalidate tokens
    window.location = `${process.env.APP_URL}/logout`
  }

  /**
   * Parses authentication hash delivered with the redirectUri
   */
  static parseHash() {
    const windowHash = window.location.hash.replace(/^#?\/?/, '')
    const parsedHash = queryString.parse(windowHash)

    return {
      accessToken: get(parsedHash, 'access_token', null),
      redirectTo: get(parsedHash, 'redirect_to', null)
    }
  }

  /**
   * Helper function for properly parsing the payload from the JWT
   * @param  {Object} token JWT
   * @return {Object} Parsed JWT payload
   */
  static getTokenPayload(token) {
    const decodedJwt = jws.decode(token)
    const payload = decodedJwt.payload

    if (typeof payload === 'string') {
      return JSON.parse(payload)
    }

    return payload
  }

  /**
   * Helper function for getting a scoped attribute from the JWT payload
   * @param  {Object} payload JWT Payload
   * @param  {string} attr Attribute
   * @return {Object} Attribute value
   */
  static getPayloadScopedAttribute(payload, attr) {
    const scope = process.env.AUTH_AUDIENCE
    return payload[`${scope}/${attr}`]
  }

  /**
   * Helper function for checking if a JWT payload matches the expect issuer
   * NOTE: This can be removed once we've completed rolled off of Auth0 for awhile
   * @param  {Object} payload JWT payload
   * @return {boolean} Is correct issuer
   */
  static doesTokenHaveCorrectIssuer(payload) {
    const { iss } = payload

    return !process.env.AUTH_ISSUER || iss === process.env.AUTH_ISSUER
  }
}
