import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import includes from 'lodash/includes'
import values from 'lodash/values'
import { selectUserRole } from 'selectors/user'
import { isBypassRole } from 'utils/emplify/authorization'
import { ROLES } from 'constants/roles'

class AuthComponentByRole extends PureComponent {
  render() {
    const {
      allowedRoles,
      disallowedRoles,
      children,
      fallback,
      role
    } = this.props
    const permitted =
      isBypassRole(role, allowedRoles) ||
      (disallowedRoles.length && !includes(disallowedRoles, role))

    if (permitted) {
      return children
    }

    if (fallback) {
      return fallback
    }

    return null
  }
}

function mapStateToProps(state) {
  return {
    role: selectUserRole(state)
  }
}

AuthComponentByRole.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
  disallowedRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  fallback: PropTypes.node,
  role: PropTypes.oneOf(values(ROLES))
}

AuthComponentByRole.defaultProps = {
  role: ROLES.EMPLOYEE,
  allowedRoles: [],
  disallowedRoles: [],
  fallback: null
}

export default connect(mapStateToProps)(AuthComponentByRole)
