import {
  LOGGER_TYPES,
  LOGGER_TYPE_ENABLE_KEYS,
  LOGGER_ENABLE_VALUES
} from 'constants/loggers'

/**
 * LocalStorageLogger - A localStorage enabled Logger class
 * Logging is enabled based on a specific key provided to the class
 *
 * @param {object} [options={}]   Options object that configures the log function
 * @param {string} [options.type] Type of logger that is being created. Is used in the `.log` function
 * @param {string} [options.enableKey] The localStorage key that enables the logger to log to the console
 */
export default class LocalStorageLogger {
  constructor({ type, enableKey }) {
    this.type = type
    this.enableKey = enableKey
  }

  /**
   * log calls to console.log with a specific string format
   * Only calls if logger class this.key is enabled
   * For example, `localStorage.getItem('enabledAnalyticsLogger') = 'true'`
   * to allow the logger to log for that key
   *
   * @param {*} messageParam        Array or any data type to spread to the console.log function
   * @param {string} context        Description string to add context to the log message
   * @param {object} [options={}]   Options object that configures the log function
   * @param {string} [options.css='color:blue']  Css string used to apply style to the context string
   *
   */
  log(messageParam, context, { css = 'color:blue' } = {}) {
    if (this.isLoggerEnabled()) {
      const contextString = context ? ` {${context}}` : ''
      // Support any data type in message by spreading from an array
      const message = Array.isArray(messageParam)
        ? messageParam
        : [messageParam]

      // eslint-disable-next-line no-console
      console.log(`[${this.type}]%c${contextString}`, css, ...message)
    }
  }

  isLoggerEnabled() {
    const value = localStorage.getItem(this.enableKey)

    switch (value) {
      case LOGGER_ENABLE_VALUES.TRUE:
      case LOGGER_ENABLE_VALUES.VERBOSE:
        return true
      default:
        return false
    }
  }

  getLocalStorageValue() {
    return localStorage.getItem(this.enableKey)
  }
}

export const redirectLoggerParams = {
  type: LOGGER_TYPES.REDIRECT,
  enableKey: LOGGER_TYPE_ENABLE_KEYS[LOGGER_TYPES.REDIRECT]
}

export const analyticsLoggerParams = {
  type: LOGGER_TYPES.ANALYTICS,
  enableKey: LOGGER_TYPE_ENABLE_KEYS[LOGGER_TYPES.ANALYTICS]
}
