/**
 * Enum for campaign open response types.
 * @readonly
 * @enum {string}
 */
const OPEN_RESPONSE_TYPES = {
  CUSTOM: 'custom',
  STANDARD: 'standard'
}

export default OPEN_RESPONSE_TYPES
