import { createSelector } from 'reselect'
import { entities } from 'dux/api/action_types'
import filter from 'lodash/filter'
import { createEntityGetter } from '../utils/selectors'

export const selectOptimizedOrganizations = createEntityGetter(
  entities.OPTIMIZED_ORGANIZATIONS
)

export const selectActiveOptimizedOrganizations = createSelector(
  createEntityGetter(entities.OPTIMIZED_ORGANIZATIONS),
  optimizedOrgs => filter(optimizedOrgs, org => !org.deletedAt)
)
