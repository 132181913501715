import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

/**
 * 🔼 Spin Button 🔽
 *
 * The spin button should be able to handle any case where you need a single button component with UP and DOWN functions
 *
 * For example, we use this to change the order of Survey Prompts within a editable Custom Survey
 *
 * @param {String} props.className - any additional classnames you want to apply to button (only use if need additional styling)
 * @param {Function} props.onClickUp - the onClickUp event handler
 * @param {Function} props.onClickDown - the onClickDown event handler
 */
function SpinButton({ className, onClickUp, onClickDown }) {
  const cx = cn('furniture-spin-button', { [className]: !!className })

  return (
    <div className={cx}>
      <div role='button' className='furniture-spin-arrow' onClick={onClickUp}>
        <FontAwesomeIcon icon={faChevronUp} size='sm' />
      </div>
      <div role='button' className='furniture-spin-arrow' onClick={onClickDown}>
        <FontAwesomeIcon icon={faChevronDown} size='sm' />
      </div>
    </div>
  )
}

SpinButton.propTypes = {
  className: PropTypes.string,
  onClickUp: PropTypes.func,
  onClickDown: PropTypes.func
}

SpinButton.defaultProps = {
  className: null,
  onClickUp: () => {
    console.warn("You didn't pass onClickUp to SpinButton")
  },
  onClickDown: () => {
    console.warn("You didn't pass onClickDown to SpinButton")
  }
}

export default SpinButton
