import dayjs from 'dayjs'

// Date must match:
// 1999-11-11
// 11-11-1999
// 1999/11/11
// 11/11/1999
const dateValidationRegex = /^(\d{4}[-/]\d{2}[-/]\d{2})|(\d{2}[-/]\d{2}[-/]\d{4})$/

export function validateDate(value) {
  return dateValidationRegex.test(value)
}

export const isValid = date => {
  return validateDate(date)
}

export const isPast = date => {
  return dayjs(date).isBefore(dayjs())
}

export const isDistantPast = date => {
  return dayjs(date).isBefore(dayjs('1900'))
}

export const isFuture = date => {
  return dayjs(date).isAfter(dayjs())
}

export const isDistantFuture = date => {
  return dayjs(date).isAfter(dayjs().add(1, 'year'))
}
