import { serializeRequestParams } from 'dux/api/helper'
import { statuses } from 'dux/api/action_types'

export function isFetchingCampaigns(state) {
  return state.requests.campaigns.isFetching
}

export function isFetchingCategories(state) {
  return state.requests.categories.isFetching
}

export function isFetchingCampaignResults(state) {
  return state.requests['campaign-results'].isFetching
}

export function isFetchingGroups(state) {
  return state.requests.groups.isFetching
}

export function isFetchingGroupTypes(state) {
  return state.requests['group-types'].isFetching
}

export function isFetchingSurveys(state) {
  return state.requests.surveys.isFetching
}

export function isFetchingGroupResults(state) {
  return state.requests['group-results'].isFetching
}

export function isFetchingPermissions(state) {
  return state.requests.permissions.isFetching
}

/**
 * A helper function for the time being while we are transitioning.
 * NOTE: You should be transitioning over to use the new ApiCallThunkCreator, which
 * will use getRequestStateByParams to get request state instead of this.
 * @param {Object} requestEntity entity for the request
 * @param {String} campaignId campaign id used for request
 */
export function isEntityFetchingByCampaign(requestEntity, campaignId) {
  return requestEntity.isFetchingByCampaign[campaignId]
}

export function getRequestStateByParams(requests, entity, method, params) {
  const paramKey = serializeRequestParams(params)
  // This may not be needed but I think it makes sense
  return requests[entity][method][paramKey] || statuses.PREREQUEST
}
