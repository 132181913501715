import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function createV3GroupAsync(organizationId, data) {
  const params = { organizationId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/v3-groups',
    params,
    data,
    {
      toastMessage: 'Successfully created group'
    }
  )
}

export function getV3GroupAsync(organizationId, groupId, options = {}) {
  const params = { organizationId, groupId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/v3-groups/:groupId',
    params,
    options
  )
}

export function getV3GroupsAsync(organizationId, options = {}) {
  const params = { organizationId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/v3-groups',
    params,
    options
  )
}

export function updateV3GroupAsync(organizationId, groupId, data) {
  const params = { organizationId, groupId }
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/v3-groups/:groupId',
    params,
    data,
    {
      toastMessage: 'Successfully updated group'
    }
  )
}

export function updateV3GroupsAsync(organizationId, data) {
  const params = { organizationId }
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/v3-groups',
    params,
    data,
    {
      toastMessage: 'Successfully updated groups'
    }
  )
}

export function deleteV3GroupAsync(organizationId, groupId, options = {}) {
  const params = { organizationId, groupId }
  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/v3-groups/:groupId',
    params,
    { ...options, toastMessage: 'Successfully deleted group' }
  )
}
