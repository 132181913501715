import { createSelector } from 'reselect'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import has from 'lodash/has'

import { DEFAULT_GROUP_NAMES } from 'constants/groups'
import { entities, methods } from 'dux/api/action_types'
import { selectSelectedCampaign } from 'selectors/campaigns'
import { selectGroupPermissions } from 'selectors/permissions'
import { createEntityGetter, createPhaseSelector } from 'utils/selectors'
import EmplifyPropTypes from 'prop_types/emplify'

export const ApiGroupShape = {
  id: EmplifyPropTypes.stringOrNumber,
  groupTypeId: EmplifyPropTypes.stringOrNumber,
  name: EmplifyPropTypes.string,
  createdAt: EmplifyPropTypes.string,
  updatedAt: EmplifyPropTypes.string,
  deletedAt: EmplifyPropTypes.string,
  displayName: EmplifyPropTypes.number,
  order: EmplifyPropTypes.number
}

const EMPTY_OBJECT = {}

export function getGroups(state) {
  return state.entities.groups || EMPTY_OBJECT
}

export function getSelectedGroup(state, ownProps) {
  const groupId = get(ownProps, 'groupId') || get(ownProps, 'match.params.id')
  return getGroups(state)[Number(groupId)] || EMPTY_OBJECT
}

/**
 * Get the group type for the currently selected group
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - the selected group type
 */
export const selectSelectedGroupType = createSelector(
  getSelectedGroup,
  createEntityGetter(entities.GROUP_TYPES),
  (selectedGroup, groupTypes) => groupTypes[selectedGroup.groupTypeId]
)

// TODO: This should be shifted into the v3_groups selector
// as the selectGroupPermissions actually returns v3groups permissions
/**
 * Select all group permissions keyed by accessibleId
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - map of permissions mapped by accessibleId
 */
export const selectPermissionsByGroup = createSelector(
  selectGroupPermissions,
  permissions => groupBy(permissions, 'accessibleId')
)

export const selectGroupsGetPhase = createPhaseSelector([
  {
    entity: entities.GROUPS,
    method: methods.GET,
    mapProps: ({ organizationId, groupId }) => ({
      organizationId,
      groupId
    })
  }
])

/**
 * Select group create phase
 * @param {Object} state The Redux store
 */
export const selectTenureGroupCreatePhase = createPhaseSelector({
  entity: entities.TENURE_GROUPS,
  method: methods.POST
})

/**
 * Determine the group that took the selected campaign
 * @param {Object} state - entire Redux state tree
 * @returns {String} - the group that took the campaign
 */
export const selectSelectedCampaignGroup = createSelector(
  selectSelectedCampaign,
  createEntityGetter(entities.GROUPS),
  createEntityGetter(entities.V3_GROUPS),
  (campaign, groups, v3Groups) => {
    if (has(campaign, 'v3GroupId') && campaign.v3GroupId !== null) {
      return v3Groups[campaign.v3GroupId]
    }

    if (has(campaign, 'groupId') && campaign.groupId === null) {
      return { name: DEFAULT_GROUP_NAMES.ORGANIZATION }
    }

    return (
      groups[campaign.groupId] || { name: DEFAULT_GROUP_NAMES.CROSS_SEGMENTED }
    )
  }
)
