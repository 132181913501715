import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import iconUrl from 'static/404_icon.svg'
import PageStatusCard from 'components/page_status_card'
import MailToHelp from 'components/mail_to_help'
import upperFirst from 'lodash/upperFirst'

class NotFound extends PureComponent {
  render() {
    return (
      <PageStatusCard imageUrl={iconUrl}>
        <h3>{upperFirst(this.props.resource)} Not Found</h3>
        <p>
          {`The ${
            this.props.resource
          } you are looking for could not be found because:`}
        </p>
        <ul>
          <li>You may not have access to view this page</li>
          <li>{`This ${this.props.resource} may not exist`}</li>
        </ul>
        <p>
          Please contact <MailToHelp /> for assistance.
        </p>
      </PageStatusCard>
    )
  }
}

NotFound.propTypes = {
  resource: PropTypes.string
}
NotFound.defaultProps = {
  resource: 'page'
}

export default NotFound
