import chunk from 'lodash/chunk'
import get from 'lodash/get'
import head from 'lodash/head'
import dayjs from 'dayjs'

import reducer from 'dux/helpers/reducer'
import {
  bulkUpdateCampaignNotificationsAsync,
  deleteCampaignNotificationAsync
} from 'dux/api/actions/campaign_notifications'
import { createOrganizationSurveyAsync } from 'dux/api/actions/surveys'
import {
  createCampaignAsync,
  updateCampaignAsync
} from 'dux/api/actions/campaigns'
import { createCampaignSurveyAsync } from 'dux/api/actions/campaign_surveys'
import { getGroupParticipationAsync } from 'dux/api/actions/group_participations'
import { getCampaignMembershipsAsync } from 'dux/api/actions/campaign_memberships'
import { getEmployeesAsync } from 'dux/api/actions/employees'
import { deleteOldCampaignFiltersEntities } from 'dux/campaign_filters'
import { statuses } from 'dux/api/action_types'
import { AUDIENCE_TYPES } from 'constants/audience_types'
import promiseEach from 'utils/promise/each'
import { targetedEmployeeAttributesToCampaignFilters } from 'utils/campaign_wizard'
import { SURVEY_FORM_NAME } from 'selectors/campaign_wizard'
import { selectIsEmplifyFormFieldDirty } from 'selectors/emplify_form'

const initialState = {
  activeStep: 0
}

// Constants
const SET_ACTIVE_STEP = 'campaign_wizard/SET_ACTIVE_STEP'
const SET_REDIRECT = 'campaign_wizard/SET_REDIRECT'

// Reducers
function reduceSetActiveStep(state = initialState, action) {
  return {
    ...state,
    activeStep: action.activeStep
  }
}

function reduceSetRedirect(state = initialState, action) {
  return {
    ...state,
    redirectUrl: action.redirectUrl
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_ACTIVE_STEP]: reduceSetActiveStep,
    [SET_REDIRECT]: reduceSetRedirect
  },
  initialState
)

/**
 * Helper function for getting the survey data for a campaign. Returns a new
 * survey if a custom survey was created, otherwise returns the selected survey.
 * @param {Object} campaignData
 * @param {number} campaignData.organizationId - the org id of the campaign
 * @param {Object} campaignData.survey - survey to add to the campaign
 * @param {String} campaignData.survey.name - name for survey
 * @param {number} campaignData.survey.surveyTypeId - surveyTypeId for custom survey
 * @param {Array} campaignData.survey.statements - statements if the survey is custom
 */
function getSurveyForCampaign(campaignData, dispatch, getState) {
  const { survey, organizationId } = campaignData
  const dirtyStatements = selectIsEmplifyFormFieldDirty(getState(), {
    formName: SURVEY_FORM_NAME,
    field: 'statements'
  })
  const dirtyName = selectIsEmplifyFormFieldDirty(getState(), {
    formName: SURVEY_FORM_NAME,
    field: 'name'
  })
  if (dirtyStatements || dirtyName) {
    const { name, surveyTypeId, statements } = survey
    const expandedStatements = statements.map(body => ({
      statementType: 'open_response',
      body
    }))
    const surveyPayload = {
      organizationId,
      surveyTypeId,
      name,
      included: { statements: expandedStatements }
    }

    if (dirtyStatements && !dirtyName && survey.version) {
      const major = Number(survey.version.split('.')[0])
      surveyPayload.version = `${major + 1}.0.0`
    }

    return createOrganizationSurveyAsync(organizationId, surveyPayload)(
      dispatch,
      getState
    )
  }

  return Promise.resolve(survey)
}

// Actions

function setActiveStep(activeStep) {
  return {
    type: SET_ACTIVE_STEP,
    activeStep
  }
}

function setRedirect(redirectUrl) {
  return {
    type: SET_REDIRECT,
    redirectUrl
  }
}

function getCampaignNotifications(campaignData) {
  const { id, campaignNotifications = [] } = campaignData

  return campaignNotifications.map(notification => {
    const mappedNotification = {
      scheduledAt: notification.scheduledAt,
      templateId: notification.templateId,
      templateData: get(notification, 'templateData'),
      audienceType: AUDIENCE_TYPES.CAMPAIGN_PARTICIPANTS
    }

    // If a campaign id is present, we're dealing with an update.
    // In this circumstance use the existing id for the campaign notification.
    if (id) {
      mappedNotification.id = notification.id
    }

    return mappedNotification
  })
}

/**
 * Create / Update a campaign with campaign form data. This action allows the creation
 * of a dynamic group, that will be built and applied to the campaign. It also allows
 * a custom survey to be added.
 * @param {Object} campaignData
 * @param {Object} campaignData.survey - survey for the campaign
 * @param {String} campaignData.startedAt - time to kick off the campaign
 * @param {String} campaignData.messageType - type of messages to send with the campaign
 * @param {number} campaignData.organizationId - the org id of the campaign
 * @param {number} campaignData.parentCampaignId - parent campaign if smartpulse
 */
function upsertCampaignAsync(campaignData) {
  return function processCampaignThunk(dispatch, getState) {
    const {
      id,
      sendsRemindersOnWeekends,
      messageType,
      campaignType,
      organizationId,
      parentCampaignId,
      openResponseType,
      templateData,
      targetedEmployeeAttributes,
      oldTargetedEmployeeAttributes,
      v3Group = {},
      scheduledEndedAt,
      campaignNotificationsToRemove = []
    } = campaignData

    const campaignNotifications = getCampaignNotifications(campaignData)
    const startedAt = dayjs(head(campaignNotifications).scheduledAt)
    const campaignFilters = targetedEmployeeAttributes
      ? targetedEmployeeAttributesToCampaignFilters(targetedEmployeeAttributes)
      : null

    const campaignPayload = {
      id,
      startedAt,
      openResponseType,
      sendsRemindersOnWeekends,
      messageType,
      organizationId,
      parentCampaignId,
      templateData,
      campaignFilters,
      v3GroupId: v3Group.id,
      scheduledEndedAt,
      campaignType
    }

    const isCreatingCampaign = !id
    if (isCreatingCampaign) {
      // if this is create, then include the notifications!
      campaignPayload.campaignNotifications = campaignNotifications
      return getSurveyForCampaign(campaignData, dispatch, getState).then(
        survey => {
          if (survey && survey.status && survey.status !== statuses.SUCCESS) {
            return Promise.reject()
          }
          campaignPayload.legacySurveyId = survey ? survey.id : null

          // TODO: Once we have a UI that allows us to set a name for a campaign, we will not longer rely on the legacySurvey
          campaignPayload.name = get(survey, 'name')
          return createCampaignAsync(organizationId, campaignPayload)(
            dispatch,
            getState
          ).then(campaign =>
            createCampaignSurveyAsync(organizationId, campaign.id, survey.id)(
              dispatch,
              getState
            )
          )
        }
      )
    }

    // editing campaign promise chain
    campaignPayload.legacySurveyId = get(campaignData, 'survey.id')
    return Promise.all(
      campaignNotificationsToRemove.map(notification =>
        deleteCampaignNotificationAsync(organizationId, id, notification.id)(
          dispatch,
          getState
        )
      )
    )
      .then(responses => {
        if (
          !responses ||
          responses.find(res => res.status !== statuses.SUCCESS)
        ) {
          return Promise.reject(
            new Error('Could not update campaign notifications')
          )
        }
        return bulkUpdateCampaignNotificationsAsync(
          organizationId,
          id,
          campaignNotifications
        )(dispatch, getState)
      })
      .then(() => {
        // Only delete campaign filters entities on update campaign
        if (campaignFilters) {
          return deleteOldCampaignFiltersEntities(
            targetedEmployeeAttributes,
            oldTargetedEmployeeAttributes
          )(dispatch, getState)
        }
        return Promise.resolve()
      })
      .then(() =>
        updateCampaignAsync(id, organizationId, campaignPayload)(
          dispatch,
          getState
        )
      )
  }
}

/**
 * Fetch all normal group, tenure, hire cohort, and generation participation
 * information for the campaign.
 * Additionally fetch the campaign memberships.
 * @param {Number} organizationId
 * @param {Number} campaignId
 */
function fetchCampaignParticipationAndMembershipsAsync(
  organizationId,
  campaignId
) {
  return function fetchCampaignParticipationAndMembershipsThunk(
    dispatch,
    getState
  ) {
    getGroupParticipationAsync(organizationId, campaignId)(dispatch, getState)
    return getCampaignMembershipsAsync(organizationId, campaignId)(
      dispatch,
      getState
    )
  }
}

// TODO: Move to dux/api/actions/employees.js?
/**
 * Fetch specific employees within an org.
 * @param {String} organizationId
 * @param {String[]} employeeIds
 * @returns {Promise}
 */
function fetchEmployees(organizationId, employeeIds) {
  return dispatch => {
    if (!organizationId) {
      return Promise.resolve(
        'Not fetching recipients due to missing organization ID.'
      )
    }

    if (!employeeIds || employeeIds.length < 1) {
      return Promise.resolve(
        'Not fetching recipients because there are no employee IDs to fetch.'
      )
    }

    // Have to batch these to avoid hitting browser limits on how long a URL can be
    // If we filter by 2000 IDs at once for instance, Chrome does not make the request
    const batches = chunk(employeeIds, 500)

    // I am chaining these instead of firing them off all at once to avoid spamming the database
    return promiseEach(batches, batch => {
      const options = {
        query: {
          'filter[id]': batch.filter(employeeId => !!employeeId).join(','),
          include: 'people,employments'
        }
      }
      return dispatch(getEmployeesAsync(organizationId, options))
    })
  }
}

export {
  setActiveStep,
  setRedirect,
  upsertCampaignAsync,
  fetchCampaignParticipationAndMembershipsAsync,
  fetchEmployees
}
