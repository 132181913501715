import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setSelectedNavItem } from 'dux/v2_nav'
import { AuthComponentByRole } from 'containers/authorization'

import { faComments } from '@fortawesome/pro-light-svg-icons/faComments'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { NAV_ITEMS, NAV_ITEM_KEYS } from 'constants/v2_nav'
import { ROLES } from 'constants/roles'
import MainNavItem from './main_nav_item'
import ResultsNavItem from './results_nav_item'

function ResultsMainNav({ selectedNavItem, showTooltip, groupIdToFilterBy }) {
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <>
      <ResultsNavItem
        selectedNavItem={selectedNavItem}
        groupIdToFilterBy={groupIdToFilterBy}
        showTooltip={showTooltip}
      />
      <AuthComponentByRole disallowedRoles={[ROLES.CUSTOMER_SUCCESS_MANAGER]}>
        <MainNavItem
          selected={selectedNavItem === NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].id}
          navItemId={NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].id}
          isCollapsed={showTooltip}
          icon={<FontAwesomeIcon icon={faComments} />}
          label={NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].label}
          tooltip={NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].label}
          onClick={() => {
            history.push(NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].route)
            dispatch(setSelectedNavItem(NAV_ITEMS[NAV_ITEM_KEYS.FEEDBACK].id))
          }}
        />
      </AuthComponentByRole>
      <MainNavItem
        selected={selectedNavItem === NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].id}
        navItemId={NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].id}
        isCollapsed={showTooltip}
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
        label={NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].label}
        tooltip={NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].label}
        onClick={() => {
          history.push(NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].route)
          dispatch(setSelectedNavItem(NAV_ITEMS[NAV_ITEM_KEYS.ACTIONS].id))
        }}
      />
    </>
  )
}

ResultsMainNav.propTypes = {
  selectedNavItem: PropTypes.string,
  showTooltip: PropTypes.bool,
  groupIdToFilterBy: PropTypes.string,
}

ResultsMainNav.defaultProps = {
  selectedNavItem: null,
  groupIdToFilterBy: null,
  showTooltip: false,
}

export default ResultsMainNav
