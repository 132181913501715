import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getCampaignSurveysAsync(
  organizationId,
  campaignId,
  options = {}
) {
  const opts = {
    query: {
      include: 'surveys.survey-types,surveys.survey-statements.statements'
    },
    ...options
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/surveys',
    { organizationId, campaignId },
    { ...opts, entity: 'campaign-surveys' }
  )
}

export function createCampaignSurveyAsync(
  organizationId,
  campaignId,
  surveyId,
  options = {}
) {
  const params = { organizationId, campaignId, surveyId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/campaigns/:campaignId/surveys/:surveyId',
    params,
    null,
    { ...options, entity: 'campaign-surveys' }
  )
}

export function updateCampaignSurveysAsync(
  organizationId,
  campaignId,
  data,
  options = {}
) {
  const params = { organizationId, campaignId }

  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/campaigns/:campaignId/campaign-surveys',
    params,
    data,
    options
  )
}

export function deleteCampaignSurveyAsync(
  organizationId,
  campaignId,
  campaignSurveyId,
  options = {}
) {
  const params = { organizationId, campaignId, campaignSurveyId }

  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/campaigns/:campaignId/campaign-surveys/:campaignSurveyId',
    params,
    options
  )
}
