import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PageTitle } from 'furniture'
import NotFound from 'components/not_found'
import includes from 'lodash/includes'
import values from 'lodash/values'
import { selectUserRole } from 'selectors/user'
import { ROLES } from 'constants/roles'

/**
 * Method that wraps a component and determines if it should be shown,
 * if the current user has an allowed role.
 *
 * @param {Array} allowedRoles - the roles necessary to see the page
 * @param {Object} options
 * @param {Boolean} option.isTitle - bool indicating if the component we're wrapping is a page title
 */
function withAuthPageByRole(allowedRoles, options = {}) {
  return function wrap(WrappedComponent) {
    class WithAuthorization extends Component {
      render() {
        const { role } = this.props
        if (includes(allowedRoles, role)) {
          return <WrappedComponent {...this.props} />
        }

        const { isTitle } = options

        if (isTitle) {
          return <PageTitle>Something went wrong</PageTitle>
        }

        return (
          <div className='content'>
            <NotFound />
          </div>
        )
      }
    }

    function mapStateToProps(state) {
      return {
        role: selectUserRole(state)
      }
    }

    WithAuthorization.propTypes = {
      role: PropTypes.oneOf(values(ROLES))
    }

    WithAuthorization.defaultProps = {
      role: ROLES.EMPLOYEE
    }

    return connect(mapStateToProps)(WithAuthorization)
  }
}

export default withAuthPageByRole
