import { createSelector } from 'reselect'
import find from 'lodash/find'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'

import { createEntityGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { RETIRED_TYPES } from 'constants/substitution_types'
import { includes } from 'lodash'

const getOrganizationSubstitutions = createEntityGetter(
  entities.ORGANIZATION_SUBSTITUTIONS
)
const getSubstitutionTypes = createEntityGetter(entities.SUBSTITUTION_TYPES)
const getSubstitutions = createEntityGetter(entities.SUBSTITUTIONS)

/**
 * Returns array of ALL substitution types
 * @param {Object} state - The complete redux state tree
 * @returns {Array[Object]} array of substitution type objects
 */
export const selectSubstitutionTypes = createSelector(
  createEntityGetter('substitution-types'),
  substitutionTypes => substitutionTypes
)

/**
 * Returns all of the substitutions owned by an organization
 * @param {Object} state - The complete redux state tree
 * @returns {Array[Object]} array of substitution objects
 */
export const selectCurrentOrgSubstitutions = createSelector(
  selectCurrentOrganizationId,
  getOrganizationSubstitutions,
  getSubstitutions,
  (currentOrganizationId, organizationSubstitutions, substitutions) => {
    const currentOrganizationSubstitutions = filter(
      organizationSubstitutions,
      os => `${os.organizationId}` === `${currentOrganizationId}`
    )
    return currentOrganizationSubstitutions.map(os =>
      find(substitutions, s => `${os.substitutionId}` === `${s.id}`)
    )
  }
)

/**
 * Returns all of the substitutions owned by an organization mapped by their
 * type name.
 * @param {Object} state - The complete redux state tree
 * @returns {Object} collection of substitution objects
 */
export const selectCurrentOrgSubstitutionsMappedByType = createSelector(
  selectCurrentOrgSubstitutions,
  getSubstitutionTypes,
  (substitutions, substitutionTypes) => {
    const substitutionsWithType = substitutions.map(substitution => {
      const type =
        find(
          substitutionTypes,
          st => `${st.id}` === `${substitution.substitutionTypeId}`
        ) || {}
      return {
        ...substitution,
        type: type.name
      }
    })
    return keyBy(substitutionsWithType, 'type')
  }
)

/**
 * Select all of the substitutions
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - an array of the substitutions
 */
export const selectSubstitutionsArray = createSelector(
  createEntityGetter(entities.SUBSTITUTIONS),
  substitutions => Object.values(substitutions)
)

/**
 * Select all of the substitution-types with it's included substitutions
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - an array of the substitution-types
 */
export const selectSubstitutionTypesArray = createSelector(
  createEntityGetter(entities.SUBSTITUTION_TYPES),
  selectSubstitutionsArray,
  (substitutionTypes, substitutions) => {
    return Object.values(substitutionTypes)
      .filter(type => !includes(Object.values(RETIRED_TYPES), type.name))
      .map(subType => {
        return {
          ...subType,
          substitutions: substitutions.filter(
            sub => `${sub.substitutionTypeId}` === subType.id
          )
        }
      })
  }
)

/**
 * Select the organization substitutions for currently selected organization
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - an array of organization substitutions
 */
export const selectOrganizationSubstitutionsForSelectedOrganization = createSelector(
  selectCurrentOrganizationId,
  createEntityGetter(entities.ORGANIZATION_SUBSTITUTIONS),
  (selectedOrganizationId, organizationSubstitutions) => {
    return filter(
      organizationSubstitutions,
      orgSubstitution =>
        `${orgSubstitution.organizationId}` === `${selectedOrganizationId}`
    )
  }
)
