import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '../typography'

const Subtitle = ({ subtitle }) => {
  if (!subtitle) {
    return null
  }
  return (
    <Text body className='furniture-card-header-subtitle'>
      {subtitle}
    </Text>
  )
}

Subtitle.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}
Subtitle.defaultProps = { subtitle: '' }

export default Subtitle
