import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip } from 'furniture/tooltip'
import { POPOVER_POSITIONS } from 'constants/popover_positions'
import ButtonAnchor from './button_anchor'

export const BUTTON_RANKS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
}

export const BUTTON_CONTEXTS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  DESTRUCTIVE: 'destructive',
  METHODOLOGY: 'methodology',
  LIGHT: 'light',
  WARNING: 'warning',
  RED: 'red'
}

/**
 * 💎 Topaz Button 💎
 * Our button's style guide can be found here: https://github.com/bluebridgedigital/citadel/wiki/Topaz:-Button-Styles
 *
 * The button should be able to handle any case described in the style guide using the combination of rank, context, and icon.
 *
 * Rank describes the level of action for the button or what's the importance of this action for this page/product? For example,
 * our filter buttons are secondary actions as they are not necessary for the results page.
 *
 * Context describes the type of action we want our button to represent. For example, Methodology context is an action that is key to our
 * engagement methodology (create a campaign, create an action, etc.).
 *
 * Icons can be used without button labels to create Icon Buttons according to the button guide.
 * NOTE: Icons are styled by our base CSS, so there's no need to pass in color styling for your icon.
 *
 * @param {String} props.id - the id of the container element
 * @param {String} props.label - the button text
 * @param {Node} props.icon - the optional icon SVG
 * @param {String} props.linkTo - the optional link if you want the button to act as link
 * @param {Function} props.onClick - the onClick event handler
 * @param {String} props.className - any additional classnames you want to apply to button (only use if need additional styling)
 * @param {Object} props.style - the style object for the container element (only use if need to override style)
 * @param {Object} props.labelStyle - the style object for the span element for label (only use if need to override style)
 * @param {Boolean} props.disabled - boolean to disable button
 * @param {String} props.rank - rank of our button (Primary, Secondary, or Tertiary)
 * @param {String} props.context - context of our button (Default, Success, Destructive, or Methodology)
 * @param {String} props.tooltipPosition - string indicating direction to display tooltip; Options: POPOVER_POSITIONS Defaults to: POPOVER_POSITIONS.BOTTOM_CENTER
 */
function Button({
  label,
  icon,
  onClick,
  style,
  className,
  labelStyle,
  disabled,
  linkTo,
  rank,
  context,
  disabledContext,
  tooltipContext,
  children,
  tooltipPosition,
  dataStaticId
}) {
  // NOTE: We need to clone the provide icon to attach a key to the element
  const iconElement = icon
    ? React.cloneElement(icon, { key: 'iconElement' })
    : undefined

  const labelElement = label ? (
    <span key='labelElement' className='tpz-btn-label' style={labelStyle}>
      {label}
    </span>
  ) : null
  const buttonContent = [iconElement, labelElement, children]

  if ((disabledContext && disabled) || tooltipContext) {
    return (
      <Tooltip
        anchorEl={
          <ButtonAnchor
            label={label}
            icon={icon}
            onClick={onClick}
            style={style}
            className={className}
            disabled={disabled}
            linkTo={linkTo}
            rank={rank}
            context={context}
            dataStaticId={dataStaticId}
          >
            {buttonContent}
          </ButtonAnchor>
        }
        position={tooltipPosition}
        className='furniture-button-tooltip'
      >
        {disabled ? disabledContext : tooltipContext}
      </Tooltip>
    )
  }

  return (
    <ButtonAnchor
      label={label}
      icon={icon}
      onClick={onClick}
      style={style}
      className={className}
      disabled={disabled}
      linkTo={linkTo}
      rank={rank}
      context={context}
      dataStaticId={dataStaticId}
    >
      {buttonContent}
    </ButtonAnchor>
  )
}

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.node,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  labelStyle: PropTypes.object,
  disabled: PropTypes.bool,
  rank: PropTypes.oneOf(Object.values(BUTTON_RANKS)),
  context: PropTypes.oneOf(Object.values(BUTTON_CONTEXTS)),
  disabledContext: PropTypes.node,
  tooltipContext: PropTypes.node,
  // eslint-disable-next-line react/no-unused-prop-types
  children: PropTypes.node,
  tooltipPosition: PropTypes.oneOf(Object.values(POPOVER_POSITIONS)),
  dataStaticId: PropTypes.string
}
Button.defaultProps = {
  label: null,
  icon: null,
  linkTo: null,
  onClick: null,
  style: {},
  className: null,
  labelStyle: {},
  disabled: false,
  rank: '',
  context: BUTTON_CONTEXTS.DEFAULT,
  disabledContext: null,
  tooltipContext: null,
  children: undefined,
  tooltipPosition: POPOVER_POSITIONS.BOTTOM,
  dataStaticId: null
}

export default Button
