const CHART_TYPES = {
  DRIVER: 'drivers', // Drivers and Conditions
  SCORE_BY_GROUP: 'engagement',
}

const CONFIG_TYPES = {
  SCORE: 'score',
  SPACER: 'spacer',
  CORRELATIONS: 'correlations',
  ORGANIZATION: 'organization',
}

const TABS = {
  OVERVIEW: 'overview',
  HEATMAP: 'drivers', // Drivers and Conditions
  LIST: 'engagement',
}

const V3_TABS = {
  ENGAGEMENT: 'engagement',
  DRIVERS: 'drivers',
  HEATMAP: 'heatmap',
  ENPS: 'enps',
  PEOPLE: 'people',
  // TODO: Remove Custom Tab value after we release Statement Breakdown
  CUSTOM: 'custom',
  STATEMENTS: 'statements',
}

const SURVEY_RESULTS_CSV = 'survey-results-csv'

export { CHART_TYPES, CONFIG_TYPES, TABS, V3_TABS, SURVEY_RESULTS_CSV }
