import find from 'lodash/find'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import SurveyTypes, { SURVEY_TYPE_NAMES } from 'constants/survey_types'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'

const EMPTY_OBJECT = {}

export function getSurveyTypes(state) {
  const surveyTypes = state.entities['survey-types']
  if (!surveyTypes) {
    return EMPTY_OBJECT
  }
  const surveyTypesWithDisplayName = map(surveyTypes, type => ({
    ...type,
    displayName: SURVEY_TYPE_NAMES[type.name]
  }))
  return keyBy(surveyTypesWithDisplayName, 'id')
}

export const selectQuarterlySurveyTypeId = createSelector(
  getSurveyTypes,
  () => SurveyTypes.QUARTERLY,
  getSurveyTypeId
)

export const selectTopicBasedSurveyTypeId = createSelector(
  getSurveyTypes,
  () => SurveyTypes.TOPIC_BASED,
  getSurveyTypeId
)

export const selectSmartPulseSurveyTypeId = createSelector(
  getSurveyTypes,
  () => SurveyTypes.SMART_PULSE,
  getSurveyTypeId
)

export const selectCustomSurveyTypeId = createSelector(
  getSurveyTypes,
  () => SurveyTypes.CUSTOM,
  getSurveyTypeId
)

function getSurveyTypeId(surveyTypes, type) {
  const surveyType = find(values(surveyTypes), t => t.name === type)
  return surveyType ? parseInt(surveyType.id, 10) : undefined
}
