const FETCH_LIMIT = 20

const JobStatuses = {
  FINISHED: 'finished',
  FINISHED_WITH_ERRORS: 'finished_with_errors',
  ERROR: 'error',
  PROCESSING: 'processing',
  PENDING: 'pending'
}

const JobStatusOptions = [
  { id: 'all', name: 'All Statuses' },
  { id: 'error', name: 'Errored' },
  { id: 'finished', name: 'Finished' },
  { id: 'processing', name: 'Processing' },
  { id: 'pending', name: 'Pending' }
]

export { FETCH_LIMIT, JobStatuses, JobStatusOptions }
