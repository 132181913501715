import { buildSegmentFilter } from 'utils/emplify/campaign_results'

/**
 * Takes a list of filters as well as attribute-segmentation information,
 * and creates a sorted list of _all_ filters.
 *
 * i.e., This is essentially just adding treating the segment as an additional filter, adding it
 * to the filters list, and then sorting alphabetically for the canonical filter list.
 *
 * @param {Array<String>} filters e.g., ['72:Male', '45:New York']
 * @param {Number|String} segmentByOeaId e.g., 59
 * @param {String} segmentByValue e.g., 'Nights'
 * @returns {Array<String>} Combined list of filters sorted alphabetically. e.g., ['45:New York', '59:Nights', '72:Male']
 *
 * @example
 * const filters = combineEmployeeAttributeFilters(
 *   ['72:Male', '45:New York'],
 *   '59',
 *   'Nights'
 * )
 * console.log(filters) // ['45:New York', '59:Nights', '72:Male']
 */
export function combineEmployeeAttributeFilters(
  filters = [],
  segmentByOeaId,
  segmentByValue
) {
  if ((!filters || filters.length < 1) && !segmentByOeaId) {
    return ''
  }

  const combinedFilters = []

  if (filters && filters.length > 0) {
    combinedFilters.push(...filters)
  }

  if (segmentByOeaId) {
    combinedFilters.push(buildSegmentFilter(segmentByOeaId, segmentByValue))
  }

  return combinedFilters.sort()
}
