import UAParser from 'ua-parser-js'
import get from 'lodash/get'
import { BROWSERS } from 'constants/devices'

/**
 * A LANDSCAPE viewport is wider than it is tall
 */
const LANDSCAPE = Symbol('landscape')

/**
 * A portrait viewport is taller than it is wide
 */
const PORTRAIT = Symbol('portrait')

export function getLanguage() {
  return navigator.userLanguage || navigator.language
}

export function getDeviceWidth() {
  return (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  )
}

export function getDeviceHeight() {
  return (
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  )
}

export function getOrientation() {
  return getDeviceWidth() >= getDeviceHeight() ? LANDSCAPE : PORTRAIT
}

export function getIsMobile() {
  const userAgent = UAParser(navigator.userAgent)
  return get(userAgent, 'device.type', 'desktop') === 'mobile'
}

export function getIsTablet() {
  const userAgent = UAParser(navigator.userAgent)
  return get(userAgent, 'device.type', 'desktop') === 'tablet'
}

export function isBrowserEvil() {
  const userAgent = UAParser(navigator.userAgent)
  const browserName = get(userAgent, 'browser.name')

  return !!(
    browserName === BROWSERS.EDGE ||
    browserName === BROWSERS.IE ||
    browserName === BROWSERS.IE_MOBILE
  )
}

export const orientations = { LANDSCAPE, PORTRAIT }
