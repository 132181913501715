export const mutuallyExclusiveBooleanProps = (exclusiveProps, options = {}) => {
  const { required = true } = options

  if (exclusiveProps.length < 1) {
    throw new TypeError(
      'at least one prop that is mutually exclusive is required'
    )
  }
  if (!exclusiveProps.every(x => typeof x === 'string')) {
    throw new TypeError('all exclusive true props must be strings')
  }

  // PropTypes does not allow returning when valid
  // eslint-disable-next-line consistent-return
  const validator = (props, propName, componentName) => {
    const countProps = (count, prop) => count + (props[prop] ? 1 : 0)
    const exclusivePropCount = exclusiveProps.reduce(countProps, 0)

    if (required && exclusivePropCount === 0) {
      return new Error(
        `${componentName} must only have a single type prop. Valid props are: ${exclusiveProps.join(
          ', '
        )}`
      )
    }
    if (exclusivePropCount > 1) {
      return new Error(
        `${componentName} may have more than a single type prop. Valid props are: ${exclusiveProps.join(
          ', '
        )}`
      )
    }
  }

  return Object.assign(
    {},
    ...exclusiveProps.map(prop => ({
      [prop]: validator
    }))
  )
}
