import requester from './requester'

const API_URL = process.env.API_URL

function uri(path) {
  return `${API_URL}${path}`
}

function get(path, token, options = {}) {
  return requester.get(uri(path), token, options)
}

function post(path, data, token) {
  return requester.post(uri(path), data, token)
}

function postFile(path, data, token) {
  return requester.postFile(uri(path), data, token)
}

function patch(path, data, token) {
  return requester.patch(uri(path), data, token)
}

function del(path, token) {
  return requester.del(uri(path), token)
}

export default {
  post,
  postFile,
  get,
  del,
  patch
}
