import isFinite from 'lodash/isFinite'
import lodashRound from 'lodash/round'

/**
 * This module contains utility functions to help with common numerical operations
 */

// This function is helpful for things like trendline charts where zero
// is the baseline, even though negative numbers may exist in a dataset
export function zeroIfNegative(number) {
  return number < 0 ? 0 : number
}

/**
 * Compute what percentage of `denominator` the `numerator` is.
 * @param {Number} numerator
 * @param {Number} denominator
 * @param {Number} [numberOfDecimals=2]
 * @returns {Number} The rounded percentage
 */
export function percentage(numerator, denominator, numberOfDecimals = 2) {
  if (
    !isFinite(numerator) ||
    !isFinite(denominator) ||
    !isFinite(numberOfDecimals)
  ) {
    throw new Error(
      'numerator, denominator, and numberOfDecimals must be numbers'
    )
  }
  if (denominator === 0) {
    throw new Error('Cannot compute percentage of 0')
  }
  return lodashRound((numerator / denominator) * 100, numberOfDecimals)
}

/**
 * Calculate the change between two numbers as a percentage (of the first number).
 * @param {Number} first Original value
 * @param {Number} second New value
 * @param {Number} numberOfDecimals Number of decimals to round the answer to
 */
export function percentChange(first, second, numberOfDecimals = 2) {
  // Invalid arguments
  if (
    first === null ||
    first === undefined ||
    second === null ||
    second === undefined
  ) {
    return null
  }
  // Impossible to show growth rate as a percentage when the first value is 0
  if (first === 0) {
    return null
  }
  const change = second - first
  const percent = percentage(change, first, numberOfDecimals)
  return percent
}

/**
 * Generate a pseudo-random ID
 * @param {Number} [length = 9] Number of characters in the generated ID.
 *  9 default is arbitrary.
 * @param {Number} [base = 36] Numeric base for the generated ID. e.g., 16 for hex.
 *    Defaults to 36 so that IDs use all letters and numbers
 * @returns {String} A new, pseudo-random ID
 *
 * Implementation taken from https://gist.github.com/gordonbrander/2230317
 */
export function generateUniqueId(length = 9, base = 36) {
  // Math.random should be unique because of its seeding algorithm.
  // Grab the requested number of digits after the decimal.
  return Math.random()
    .toString(base)
    .substr(2, length)
}

export function round(value, decimalPlaces = 2) {
  if (value === undefined) {
    throw new Error('Cannot round undefined')
  }
  if (value === null) {
    return value
  }
  let roundedValue = lodashRound(value, decimalPlaces)
  if (Object.is(roundedValue, -0)) {
    roundedValue = 0
  }
  return roundedValue
}

/**
 * Division that will return null if the values are not numbers or if the denominator is 0.
 * This is as opposed to throwing an error. So this should be used when we want to swallow errors.
 * @param {Number} numerator
 * @param {Number} denominator
 * @param {Number} decimalPlaces
 * @returns {Number}
 */
export function safeDivide(numerator, denominator, decimalPlaces = 2) {
  if (!isFinite(numerator) || !isFinite(denominator) || denominator === 0) {
    return null
  }
  return lodashRound(numerator / denominator, decimalPlaces)
}

export function addNumberSuffix(num) {
  if (num % 100 >= 11 && num % 100 <= 13) {
    return `${num}th`
  }
  if (num % 10 === 1) {
    return `${num}st`
  }
  if (num % 10 === 2) {
    return `${num}nd`
  }
  if (num % 10 === 3) {
    return `${num}rd`
  }

  return `${num}th`
}
