import get from 'lodash/get'
import { createSelector } from 'reselect'
import { createEntityGetter, createPropGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import { getCurrentOrganizationId } from 'selectors/organizations'

/**
 * Get the package progress for the specified organization.
 * If no organization is specified, then will use the "current" organization.
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {Object} [props.organizationId]
 */
export const selectOrganizationPackageProgress = createSelector(
  [
    createEntityGetter(entities.ORGANIZATION_PACKAGE_PROGRESS),
    createPropGetter('organizationId'),
    getCurrentOrganizationId
  ],
  (orgPackageProgress, organizationId, currentOrganizationId) =>
    get(orgPackageProgress, organizationId || currentOrganizationId, {})
)
