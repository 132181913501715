import get from 'lodash/get'
import isInteger from 'lodash/isInteger'
import { serializeRequestParams } from 'dux/api/helper'

/**
 * @deprecated
 * !Please use `shouldFetch` from utils/fetch
 */
export const verifyId = (id, props) => props && props[id]

/**
 * @deprecated
 * !Please use `shouldFetch` from utils/fetch
 */
export const verifyNextId = (id, props, nextProps) =>
  props && // props[id] can be null
  (nextProps && nextProps[id]) && // nextProps[id] must be valid
  props[id] !== nextProps[id]

/**
 * Determines if the ID is a valid for Emplify resources.
 * Emplify IDs are strings that parse into positive integers.
 * For these purposes, both a string or number are acceptable.
 * @param {String | Number} id
 * @returns {Boolean}
 */
export function isValidId(id) {
  const typeOfId = typeof id
  if (typeOfId !== 'string' && typeOfId !== 'number') {
    return false
  }

  const idAsNumber = Number(id)

  if (isInteger(idAsNumber) && idAsNumber > 0) {
    return true
  }

  return false
}

export const getSerializedAttributeFilters = attributeFilters => {
  const employeeAttributeFilters = get(
    attributeFilters,
    'filter[employeeAttributes]',
    []
  )

  return attributeFilters &&
    Object.keys(attributeFilters).length > 0 &&
    employeeAttributeFilters.length > 0
    ? `:${serializeRequestParams(attributeFilters)}`
    : ''
}
