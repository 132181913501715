export const ACCESSIBLE_TYPES = {
  CAMPAIGNS: 'organizations-campaigns',
  GROUPS: 'organizations-groups',
  V3_GROUPS: 'organizations-results-v3groups',
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_ADMINISTRATE: 'organizations-administrate',
  ORGANIZATIONS_SESSIONS: 'organizations-sessions',
  ORGANIZATIONS_RESULTS: 'organizations-results',
  EMPLOYEE_ATTRIBUTES: 'employee_attributes',
  COACHING_SESSIONS: 'coaching-sessions'
}

export const FRIENDLY_ACCESSIBLE_TYPES = {
  [ACCESSIBLE_TYPES.CAMPAIGNS]: 'Campaign',
  [ACCESSIBLE_TYPES.GROUPS]: 'Group',
  [ACCESSIBLE_TYPES.V3_GROUPS]: 'V3 Group',
  [ACCESSIBLE_TYPES.ORGANIZATIONS]: 'Organization',
  [ACCESSIBLE_TYPES.ORGANIZATIONS_ADMINISTRATE]: 'Organization Administrate',
  [ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS]: 'Organization Results'
}

export const ACCESS = {
  READ: 'r',
  WRITE: 'w'
}

/**
 * Why are we passing organizationId multiple times here?
 * The findPermission check steps through each hyphenated word in the accessible type
 * and checks each permission with the proper param accessible id until it works through
 * the higher level permission checks. Therefore, with the addition of more granular
 * permissions (results, administrate), we need to pass in organizationId a second time
 * to satisfy the existing flow.
 */
export const ORGANIZATIONS_RESULTS_READ_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS,
  access: ACCESS.READ,
  params: ['organizationId', 'organizationId']
}

export const ORGANIZATIONS_RESULTS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS,
  access: ACCESS.WRITE,
  params: ['organizationId', 'organizationId']
}

export const ORGANIZATIONS_ADMINISTRATE_READ_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_ADMINISTRATE,
  access: ACCESS.READ,
  params: ['organizationId', 'organizationId']
}

export const ORGANIZATIONS_ADMINISTRATE_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_ADMINISTRATE,
  access: ACCESS.WRITE,
  params: ['organizationId', 'organizationId']
}

export const ORGANIZATIONS_SESSIONS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_SESSIONS,
  access: ACCESS.WRITE,
  params: ['organizationId', 'organizationId']
}

export const ORGANIZATIONS_SESSIONS_READ_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS_SESSIONS,
  access: ACCESS.READ,
  params: ['organizationId', 'organizationId']
}

export const GROUPS_READ_GRANT = {
  type: ACCESSIBLE_TYPES.GROUPS,
  access: ACCESS.READ,
  params: ['groupId', 'organizationId']
}

export const V3_GROUPS_READ_GRANT = {
  type: ACCESSIBLE_TYPES.V3_GROUPS,
  access: ACCESS.READ,
  params: ['groupId', 'organizationId', 'organizationId']
}

export const GROUPS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.GROUPS,
  access: ACCESS.WRITE,
  params: ['groupId', 'organizationId']
}

export const V3_GROUPS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.V3_GROUPS,
  access: ACCESS.WRITE,
  params: ['groupId', 'organizationId', 'organizationId']
}

export const ORGANIZATIONS_READ_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS,
  access: ACCESS.READ,
  params: ['organizationId']
}

export const ORGANIZATIONS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.ORGANIZATIONS,
  access: ACCESS.WRITE,
  params: ['organizationId']
}

export const CAMPAIGNS_WRITE_GRANT = {
  type: ACCESSIBLE_TYPES.CAMPAIGNS,
  access: ACCESS.WRITE,
  params: ['organizationId', 'campaignId']
}
