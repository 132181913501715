import isEmpty from 'lodash/isEmpty'

import RESOURCES from './resources'
import createPresenter from './create_presenter'

/**
 * Get a yayson Presenter to serialize the specified data of the specified type
 * into a JSON API compliant payload.
 * @param {string} type Resource type of the presenter
 * @param {*} data The data we are going to serialize.
 *  We need this to check if any associated resources are attached to it
 * @returns {Object} A yayson presenter for serializing the passed-in data
 */
export default function getPresenter(type, data) {
  const PrimaryPresenter = createPresenter(type)

  const resource = RESOURCES[type]
  const relationshipInfo = resource && resource.relationships
  if (!relationshipInfo) {
    return PrimaryPresenter
  }

  const relationships = {}
  Object.keys(relationshipInfo).forEach(relation => {
    if (data[relation]) {
      // The related entity is hydrated into the data.
      // Add that relationship to the serializer
      relationships[relation] = createPresenter(relationshipInfo[relation])
    }
  })

  if (!isEmpty(relationships)) {
    PrimaryPresenter.prototype.relationships = () => relationships
  }

  return PrimaryPresenter
}
