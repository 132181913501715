const EMPLOYMENT_STATUSES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

const EMPLOYMENT_STATUS_LABELS = {
  [EMPLOYMENT_STATUSES.ACTIVE]: 'Active',
  [EMPLOYMENT_STATUSES.INACTIVE]: 'Inactive'
}

export { EMPLOYMENT_STATUSES, EMPLOYMENT_STATUS_LABELS }
