import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PageTitle } from 'furniture'
import NotFound from 'components/not_found'
import values from 'lodash/values'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { selectUserRole, selectUserPermissions } from 'selectors/user'
import { findPermission, isBypassRole } from 'utils/emplify/authorization'
import { ROLES } from 'constants/roles'

/**
 * Method that wraps a component and determines if it should be shown,
 * based on roles and permissions for the current user
 *
 * @param {Object} requiredGrant - the grant required to see the page
 * @param {Object} options
 * @param {Component} options.fallbackComponent - component to show if role and permission do not allow
 * @param {Boolean} option.isTitle - bool indicating if the component we're wrapping is a page title
 */
function withAuthPageByPermission(requiredGrant, options = {}) {
  return function wrap(WrappedComponent) {
    function WithAuthorization(props) {
      const { ids, permissions, role } = props

      const { fallbackComponent, isTitle, bypassRoles = [] } = options

      if (
        isBypassRole(role, bypassRoles) ||
        findPermission(values(permissions), requiredGrant, ids)
      ) {
        return <WrappedComponent {...props} />
      }

      if (fallbackComponent) {
        return fallbackComponent
      }

      if (isTitle) {
        return <PageTitle>Something went wrong</PageTitle>
      }

      return (
        <div className='content'>
          <NotFound />
        </div>
      )
    }

    function mapStateToProps(state, ownProps) {
      const organizationId = selectCurrentOrganizationId(state)
      return {
        role: selectUserRole(state),
        permissions: selectUserPermissions(state),
        // In order to support resource-based access, we need to additionally factor in route params
        ids: { organizationId, ...ownProps.match.params }
      }
    }

    WithAuthorization.propTypes = {
      role: PropTypes.oneOf(values(ROLES)),
      permissions: PropTypes.object,
      ids: PropTypes.object.isRequired,
      match: PropTypes.object.isRequired
    }

    WithAuthorization.defaultProps = {
      role: ROLES.EMPLOYEE,
      permissions: {}
    }
    return connect(mapStateToProps)(WithAuthorization)
  }
}

export default withAuthPageByPermission
