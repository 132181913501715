import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import { entities } from 'dux/api/action_types'
import {
  createEntityGetter,
  createPropGetter,
  createPhaseSelector
} from 'utils/selectors'
import { ACCESSIBLE_TYPES } from 'constants/permissions'

import {
  getEmployeeById,
  selectHydratedActiveEmployeesForCurrentOrg
} from 'selectors/employees'
import { getUserPersonId } from 'selectors/user'
import { selectCurrentOrganizationId } from 'selectors/organizations'

export const selectPersonPermissionsPhase = createPhaseSelector([
  {
    entity: entities.PERMISSIONS,
    mapProps: ({ personId }) => ({ personId })
  }
])

const selectAllCurrentOrganizationPermissions = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  selectCurrentOrganizationId,
  (permissions, organizationId) => {
    return filter(
      permissions,
      p => `${p.organizationId}` === `${organizationId}`
    )
  }
)

/**
 * Select all 'organizations' type permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of permissions
 */
export const selectOrganizationPermissions = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  permissions =>
    filter(
      permissions,
      permission =>
        permission.accessibleType === ACCESSIBLE_TYPES.ORGANIZATIONS ||
        permission.accessibleType === ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS
    )
)

/**
 * Select all 'employee_attributes' type permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of permissions
 */
export const selectEmployeeAttributesPermissionsByOeaId = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  createPropGetter('organizationEmployeeAttributeId'),
  (permissions, organizationEmployeeAttributeId) =>
    filter(
      permissions,
      permission =>
        permission.accessibleType === ACCESSIBLE_TYPES.EMPLOYEE_ATTRIBUTES &&
        `${permission.accessibleId}` === `${organizationEmployeeAttributeId}`
    )
)

/**
 * Select all 'organizations-campaigns' type permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of permissions
 */
export const selectCampaignReportPermissions = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  permissions =>
    filter(
      permissions,
      permission => permission.accessibleType === ACCESSIBLE_TYPES.CAMPAIGNS
    )
)

/**
 * Select all 'organizations-results-v3groups' type permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of permissions
 */
export const selectGroupPermissions = createSelector(
  createEntityGetter(entities.PERMISSIONS),
  permissions =>
    filter(
      permissions,
      permission => permission.accessibleType === ACCESSIBLE_TYPES.V3_GROUPS
    )
)

/**
 * Select the groups permissions for a specific employee
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps - props from react component
 * @param {String} ownProps.employee - employee
 * @returns {Array} - collection of groups permissions
 */
export const selectGroupPermissionsByEmployee = createSelector(
  getEmployeeById,
  selectGroupPermissions,
  (employee, permissions) => {
    const personId = get(employee, 'personId')
    if (personId === undefined) {
      return null
    }
    return filter(
      permissions,
      permission => `${permission.personId}` === `${personId}`
    )
  }
)

export const selectOrganizationResultsPermissionsForEmployee = createSelector(
  createPropGetter('personId'),
  createEntityGetter(entities.PERMISSIONS),
  (personId, permissions) => {
    return filter(
      permissions,
      permission =>
        permission.accessibleType === ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS &&
        `${permission.personId}` === `${personId}`
    )
  }
)

/**
 * Select the 'organizations', 'organizations-results', and 'organization-results-v3groups' for a person
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps - props from react component
 * @param {String} ownProps.employee - employee
 * @returns {Array} - collection of 'organizations' and 'organization-groups' permissions
 * for an employee
 */
export const selectResultPermissionsByPersonId = createSelector(
  getUserPersonId,
  selectOrganizationPermissions,
  selectGroupPermissions,
  (personId, orgPermissions, groupPermissions) => {
    if (personId === undefined) {
      return []
    }
    return filter(
      [...orgPermissions, ...groupPermissions],
      permission => `${permission.personId}` === `${personId}`
    )
  }
)

/**
 * Select all campaign_report type permissions for a specific campaign
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of permissions
 */
export const selectCampaignReportPermissionsForCampaign = createSelector(
  selectCampaignReportPermissions,
  createPropGetter('campaignId'),
  (permissions, campaignId) =>
    filter(permissions, p => `${p.accessibleId}` === `${campaignId}`)
)

export const selectV3GroupPermissionsForGroup = createSelector(
  selectGroupPermissions,
  createPropGetter('v3GroupId'),
  (permissions, v3GroupId) => {
    return filter(
      permissions,
      p =>
        p.accessibleType === ACCESSIBLE_TYPES.V3_GROUPS &&
        `${p.accessibleId}` === `${v3GroupId}`
    )
  }
)

/**
 * Select an array of Engage users for an organization with their *optional* employeeId.
 * NOTE: This array will include multi-org users!
 * @param {Object} state - entire Redux state tree
 * @returns {Oject[]} - an array of Engage user objects with employee id
 */
export const selectAllCurrentOrganizationPeopleWithPermissions = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  createEntityGetter(entities.PEOPLE),
  selectAllCurrentOrganizationPermissions,
  (employees, people, permissions) => {
    const uniquePersonIds = uniq(map(permissions, p => p.personId))
    return map(uniquePersonIds, personId => {
      const currentPerson = people[personId]
      const employee = find(employees, e => `${e.personId}` === `${personId}`)
      const personPermissions =
        filter(
          permissions,
          permission => `${personId}` === `${permission.personId}`
        ) || []
      return {
        ...currentPerson,
        employeeId: get(employee, 'id'),
        permissions: personPermissions,
        role: get(employee, 'role')
      }
    })
  }
)

/**
 * Select the hydrated employee records for employees with
 * campaign_report permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Array[Object]} - list of hydrated employees
 */
export const selectHydratedCampaignReportOwners = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  selectCampaignReportPermissionsForCampaign,
  (employees, permissions) =>
    uniqBy(
      map(permissions, perm =>
        find(employees, employee => {
          const { person } = employee
          return `${person.id}` === `${perm.personId}`
        })
      ),
      'id'
    )
)

export const selectSingleV3GroupHydratedUsers = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  selectV3GroupPermissionsForGroup,
  (employees, permissions) =>
    uniqBy(
      map(permissions, perm =>
        find(employees, employee => {
          const { person } = employee
          return `${person.id}` === `${perm.personId}`
        })
      ),
      'id'
    )
)

export const selectAllV3GroupHydratedUsers = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  selectGroupPermissions,
  (employees, permissions) =>
    uniqBy(
      map(permissions, perm =>
        find(employees, employee => {
          const { person } = employee
          return `${person.id}` === `${perm.personId}`
        })
      ),
      'id'
    )
)
