import reducer from 'dux/helpers/reducer'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import find from 'lodash/find'
import isObject from 'lodash/isObject'

const initialState = {}

// Constants (i.e. action types)
const SET_INITIAL_DATA = 'emplifyForm/SET_INITIAL_DATA'
const SET_DATA = 'emplifyForm/SET_DATA'
const SET_ERROR_DATA = 'emplifyForm/SET_ERROR_DATA'
const RESET_FORM = 'emplifyForm/RESET_FORM'
const UPDATE_ARRAY_FIELD_ITEM = 'emplifyForm/UPDATE_ARRAY_FIELD_ITEM'
const ADD_ARRAY_FIELD_ITEM = 'emplifyForm/ADD_ARRAY_FIELD_ITEM'
const REMOVE_ARRAY_FIELD_ITEM = 'emplifyForm/REMOVE_ARRAY_FIELD_ITEM'

// Reducers
function reduceSetInitialData(state = initialState, action) {
  const { formName, initialData } = action
  return {
    ...state,
    [formName]: {
      initialData,
      data: initialData,
      dirty: false,
      errorData: {},
      isValid: true
    }
  }
}

function reduceSetData(state = initialState, action) {
  const { formName } = action

  const formData = state[formName] || {}
  const data = { ...formData.data, ...action.data }
  const dirty = !isEqual(formData.initialData, data)

  return {
    ...state,
    [formName]: {
      ...formData,
      data,
      dirty
    }
  }
}

function reduceUpdateArrayFieldItem(state = initialState, action) {
  const { formName, fieldName, index: updateIndex, data } = action

  const formData = state[formName] || {}
  const arrayField = formData.data[fieldName] || []
  const newArrayField = map(arrayField.slice(), (item, index) => {
    if (index !== updateIndex) {
      return item
    }
    if (isObject(item)) {
      return {
        ...item,
        ...data
      }
    }
    return data
  })

  const dirty = !isEqual(formData.initialData[fieldName], newArrayField)

  return {
    ...state,
    [formName]: {
      ...formData,
      data: {
        ...formData.data,
        [fieldName]: newArrayField
      },
      dirty
    }
  }
}

function reduceAddArrayFieldItem(state = initialState, action) {
  const { formName, fieldName, index, data } = action

  const formData = state[formName] || {}
  const arrayField = formData.data[fieldName] || []
  const newArrayField = arrayField.slice()
  newArrayField.splice(index, 0, data)

  const dirty = !isEqual(formData.initialData[fieldName], newArrayField)

  return {
    ...state,
    [formName]: {
      ...formData,
      data: {
        ...formData.data,
        [fieldName]: newArrayField
      },
      dirty
    }
  }
}

function reduceRemoveArrayFieldItem(state = initialState, action) {
  const { formName, fieldName, index } = action

  const formData = state[formName] || {}
  const arrayField = formData.data[fieldName] || []
  const newArrayField = arrayField.slice()
  newArrayField.splice(index, 1)

  const dirty = !isEqual(formData.initialData[fieldName], newArrayField)

  return {
    ...state,
    [formName]: {
      ...formData,
      data: {
        ...formData.data,
        [fieldName]: newArrayField
      },
      dirty
    }
  }
}

function reduceSetErrorData(state = initialState, action) {
  const { formName } = action

  const formData = state[formName] || {}
  const isValid = !find(action.errorData, err => err)
  return {
    ...state,
    [formName]: {
      ...formData,
      errorData: { ...action.errorData },
      isValid
    }
  }
}

function reduceResetForm(state = initialState, action) {
  const { formName } = action

  const newState = { ...state }
  delete newState[formName] // This also mutates the object. Please make sure form name is reset correctly.

  return newState
}

// Combined reducer functions
export default reducer(
  {
    [SET_INITIAL_DATA]: reduceSetInitialData,
    [SET_DATA]: reduceSetData,
    [UPDATE_ARRAY_FIELD_ITEM]: reduceUpdateArrayFieldItem,
    [ADD_ARRAY_FIELD_ITEM]: reduceAddArrayFieldItem,
    [REMOVE_ARRAY_FIELD_ITEM]: reduceRemoveArrayFieldItem,
    [SET_ERROR_DATA]: reduceSetErrorData,
    [RESET_FORM]: reduceResetForm
  },
  initialState
)

// Action creators

function setInitialData(formName, initialData) {
  return {
    type: SET_INITIAL_DATA,
    formName,
    initialData
  }
}

function setData(formName, data) {
  return {
    type: SET_DATA,
    formName,
    data
  }
}

function updateArrayFieldItem(formName, fieldName, index, data) {
  return {
    type: UPDATE_ARRAY_FIELD_ITEM,
    formName,
    fieldName,
    index,
    data
  }
}

function addArrayFieldItem(formName, fieldName, index, data) {
  return {
    type: ADD_ARRAY_FIELD_ITEM,
    formName,
    fieldName,
    index,
    data
  }
}

function removeArrayFieldItem(formName, fieldName, index) {
  return {
    type: REMOVE_ARRAY_FIELD_ITEM,
    formName,
    fieldName,
    index
  }
}

function setErrorData(formName, errorData) {
  return {
    type: SET_ERROR_DATA,
    formName,
    errorData
  }
}

function resetForm(formName) {
  return {
    type: RESET_FORM,
    formName
  }
}

// Export necessary action types and action creators

export {
  setInitialData,
  setData,
  updateArrayFieldItem,
  addArrayFieldItem,
  removeArrayFieldItem,
  setErrorData,
  resetForm
}
