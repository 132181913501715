import { statuses, methods, entities, isApiCall } from 'dux/api/action_types'
import { ERROR } from 'dux/employee_import'
import { SET_SAVING_STATUS as NEW_EMPLOYEE_SET_SAVING_STATUS } from 'dux/employee_new'
import SavingStatuses from 'constants/saving_statuses'

import isString from 'lodash/isString'
import get from 'lodash/get'
import lowerCase from 'lodash/lowerCase'
import includes from 'lodash/includes'
import { showToast, ToastTypes } from 'utils/toast'

import pluralize from 'pluralize'

const initialState = {
  message: '',
  messageType: ToastTypes.INFO,
  messageDetail: '',
  isOpen: false
}

// Status codes

const HTTP_OK = 200

// Constants (i.e. action types)
const ADD_MESSAGE = 'toast/ADD_MESSAGE'
const CLEAR_MESSAGE = 'toast/CLEAR_MESSAGE'
const CLEAR_MESSAGE_DETAIL = 'toast/CLEAR_MESSAGE_DETAIL'
const FAILED_FETCH_MESSAGE =
  'We have experienced a network issue and your changes could not be saved. Please try again later.'

// Helper "reducers" per method type

function reducePostCall(state, action) {
  const {
    entity,
    toastMessage,
    suppressToast,
    showErrorModal,
    toastId
  } = action
  let callStatus = action.status

  const singularEntity = pluralize.singular(lowerCase(entity))
  let successMessage = `Successfully created ${singularEntity}`
  let errorMessage = `Failed to create ${singularEntity}`

  switch (entity) {
    case entities.EMPLOYEE_IMPORT:
      successMessage = 'Employee file uploaded successfully'
      errorMessage = 'Employee file failed to upload'
      break
    case entities.PEOPLE:
      if (action.status_code === HTTP_OK) {
        // Override call status, this is a failure to a user.
        callStatus = statuses.FAILURE
        errorMessage =
          'Failed to create person. The email address or phone number is already in use.'
      }
      successMessage = 'Successfully created new person'
      break
    case entities.ITEMS:
      successMessage = 'Reply sent'
      errorMessage = 'Failed to send reply'
      break
    case entities.PERMISSIONS:
      successMessage = 'Successfully updated permissions'
      errorMessage = 'Failed to add permission'
      break
    // We do not want create toast for the following entities
    case entities.UPLOAD_URL:
      return state
    default:
  }

  if (suppressToast) {
    return {
      ...state,
      isOpen: false
    }
  }
  if (callStatus === statuses.SUCCESS) {
    showToast(toastMessage || successMessage, ToastTypes.SUCCESS, toastId)
    return {
      ...state,
      message: toastMessage || successMessage,
      messageType: ToastTypes.SUCCESS,
      isOpen: true,
      showModal: false
    }
  }
  if (callStatus === statuses.FAILURE) {
    let errorDetail = get(action, 'response.data.errors[0].detail') || ''
    if (!isString(errorDetail)) {
      errorDetail = JSON.stringify(errorDetail)
    }
    const actionErrorMessage = get(action, 'error.fullError.message')
    if (actionErrorMessage === 'Failed to fetch') {
      errorMessage = FAILED_FETCH_MESSAGE
    }

    showToast(toastMessage || errorMessage, ToastTypes.ERROR, toastId)
    return {
      ...state,
      message: toastMessage || errorMessage,
      messageDetail: errorDetail,
      messageType: ToastTypes.ERROR,
      isOpen: true,
      showModal: showErrorModal || false
    }
  }
  return state
}

function reducePatchCall(state, action) {
  const {
    entity,
    toastMessage,
    suppressToast,
    showErrorModal,
    toastId
  } = action
  const callStatus = action.status

  const singularEntity = pluralize.singular(lowerCase(entity))
  let successMessage = `Successfully updated ${singularEntity}`
  let errorMessage = `Failed to update ${singularEntity}`
  switch (entity) {
    case entities.PEOPLE:
      successMessage = 'Successfully updated employee'
      errorMessage = 'Failed to update employee'
      break
    case entities.ROLES:
      successMessage = 'Successfully updated employee role'
      errorMessage = 'Failed to update employee role'
      break
    // We do not want update toast for the following entities
    case entities.TENURE_GROUPS:
    case entities.ITEMS:
      return state
    default:
  }

  if (suppressToast) {
    return {
      ...state,
      isOpen: false
    }
  }
  if (callStatus === statuses.SUCCESS) {
    showToast(toastMessage || successMessage, ToastTypes.SUCCESS, toastId)
    return {
      ...state,
      message: toastMessage || successMessage,
      messageType: ToastTypes.SUCCESS,
      isOpen: true,
      showModal: false
    }
  }
  if (callStatus === statuses.FAILURE) {
    let errorDetail = get(action, 'response.data.errors[0].detail') || ''
    if (!isString(errorDetail)) {
      errorDetail = JSON.stringify(errorDetail)
    }
    const actionErrorMessage = get(action, 'error.fullError.message')
    if (actionErrorMessage === 'Failed to fetch') {
      errorMessage = FAILED_FETCH_MESSAGE
    }

    showToast(toastMessage || errorMessage, ToastTypes.ERROR, toastId)
    return {
      ...state,
      message: toastMessage || errorMessage,
      messageDetail: errorDetail,
      messageType: ToastTypes.ERROR,
      isOpen: true,
      showModal: showErrorModal || false
    }
  }
  return state
}

function reduceDeleteCall(state, action) {
  const {
    entity,
    toastMessage,
    suppressToast,
    showErrorModal,
    toastId
  } = action
  const callStatus = action.status

  const singularEntity = pluralize.singular(lowerCase(entity))
  const successMessage = `Successfully deleted ${singularEntity}`
  let errorMessage = `Failed to delete ${singularEntity}`
  if (suppressToast) {
    return {
      ...state,
      isOpen: false
    }
  }
  if (callStatus === statuses.SUCCESS) {
    showToast(toastMessage || successMessage, ToastTypes.SUCCESS, toastId)
    return {
      ...state,
      message: toastMessage || successMessage,
      messageType: ToastTypes.SUCCESS,
      isOpen: true,
      showModal: false
    }
  }
  if (callStatus === statuses.FAILURE) {
    let errorDetail = get(action, 'response.data.errors[0].detail') || ''
    if (!isString(errorDetail)) {
      errorDetail = JSON.stringify(errorDetail)
    }
    const actionErrorMessage = get(action, 'error.fullError.message')
    if (actionErrorMessage === 'Failed to fetch') {
      errorMessage = FAILED_FETCH_MESSAGE
    }

    showToast(toastMessage || errorMessage, ToastTypes.ERROR, toastId)
    return {
      ...state,
      message: toastMessage || errorMessage,
      messageDetail: errorDetail,
      messageType: ToastTypes.ERROR,
      isOpen: true,
      showModal: showErrorModal || false
    }
  }
  return state
}

function reduceGetCall(state, action) {
  const { entity } = action
  const callStatus = action.status
  /**
   * Right now, this is only used for the Smart Pulse Responses error handling.
   * This could get extended for more toast errors on GETs.
   */
  const fileDownloadEntities = [
    entities.SMART_PULSE,
    entities.CUSTOM_CAMPAIGNS,
    entities.ORGANIZATION_OVERVIEW_PDF
  ]
  if (
    callStatus === statuses.FAILURE &&
    includes(fileDownloadEntities, entity)
  ) {
    const errorMessage = `Failed to download ${entity.replace(/-/g, ' ')}`
    let errorDetail = get(action, 'error[0].code') || ''
    if (!isString(errorDetail)) {
      errorDetail = JSON.stringify(errorDetail)
    }
    return {
      ...state,
      message: errorMessage,
      messageDetail: errorDetail,
      messageType: ToastTypes.ERROR,
      isOpen: true
    }
  }
  // By default, return the state/no toast action.
  return state
}

// Reducers

function reduceApiCall(state = initialState, action) {
  const { method } = action

  switch (method) {
    case methods.POST:
      return reducePostCall(state, action)
    case methods.PATCH:
      return reducePatchCall(state, action)
    case methods.DELETE:
      return reduceDeleteCall(state, action)
    case methods.GET:
      return reduceGetCall(state, action)
    default:
      return state
  }
}

function reduceAddMessage(state = initialState, action) {
  if (!action.messageType || !action.message) {
    return state
  }

  const { messageType, message, messageDetail } = action

  if (
    state.messageType === messageType &&
    state.message === message &&
    state.messageDetail === messageDetail
  ) {
    return state
  }

  return {
    ...state,
    messageType,
    message,
    messageDetail,
    isOpen: true
  }
}

function reduceClearMessage(state = initialState) {
  return {
    ...state,
    isOpen: false
  }
}

function reduceClearMessageDetail(state = initialState) {
  return {
    ...state,
    messageDetail: ''
  }
}

function reduceEmployeeImportError(state = initialState, action) {
  return {
    ...state,
    message: action.message,
    messageType: ToastTypes.ERROR,
    isOpen: true
  }
}

function reduceSavingStatusCall(state = initialState, action) {
  if (action.savingStatus === SavingStatuses.ERROR) {
    return {
      ...state,
      message: action.message,
      messageDetail: action.messageDetail,
      messageType: ToastTypes.ERROR,
      isOpen: true
    }
  }
  if (action.savingStatus === SavingStatuses.SUCCESS) {
    return {
      ...state,
      message: action.message,
      messageType: ToastTypes.SUCCESS,
      isOpen: true
    }
  }
  return {
    ...state
  }
}

// Combined reducer functions
export default function reduce(state = initialState, action) {
  const { type } = action

  if (type === NEW_EMPLOYEE_SET_SAVING_STATUS) {
    return reduceSavingStatusCall(state, action)
  }

  if (isApiCall(type)) {
    return reduceApiCall(state, action)
  }

  if (type === ADD_MESSAGE) {
    return reduceAddMessage(state, action)
  }

  if (type === CLEAR_MESSAGE) {
    return reduceClearMessage(state, action)
  }

  if (type === CLEAR_MESSAGE_DETAIL) {
    return reduceClearMessageDetail(state, action)
  }

  if (type === ERROR) {
    return reduceEmployeeImportError(state, action)
  }

  return state
}

// Action creators

function clearToastMessage() {
  return {
    type: CLEAR_MESSAGE
  }
}

function clearToastDialogMessageDetail() {
  return {
    type: CLEAR_MESSAGE_DETAIL
  }
}

function addToastMessage(messageType, message, messageDetail, toastId) {
  showToast(message, messageType, toastId)
  return { type: ADD_MESSAGE, messageType, message, messageDetail }
}

// Export necessary action types and action creators

export {
  // exported for unit tests
  CLEAR_MESSAGE,
  CLEAR_MESSAGE_DETAIL,
  // exported for actual use
  ToastTypes,
  addToastMessage,
  clearToastMessage,
  clearToastDialogMessageDetail
}
