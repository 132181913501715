import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

/**
 * @param {string} organizationId The organization id
 * @param {object} actionPlan The parent record
 * @param {string} actionPlan.campaignId The campaign id
 * @param {string} actionPlan.groupId The group id
 * @param {string} actionPlan.categoryId The category id
 * @param {string} actionPlan.coachingSessionId The coaching session id
 * @param {object} actionOccurrences 1-many child records
 */
export function createActionPlanAsync(
  organizationId,
  actionPlan,
  actionOccurrences
) {
  const params = { organizationId }
  const data = {
    ...actionPlan,
    actionOccurrences
  }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/action-plans',
    params,
    data,
    { toastMessage: 'New Action Plan Created' }
  )
}

export function getActionPlansAsync(organizationId, options = {}) {
  const url = '/organizations/:organizationId/action-plans'
  const params = { organizationId }
  return ApiCallThunkCreator.get(url, params, options)
}

export function getActionPlanAsync(organizationId, id, options = {}) {
  const url = '/organizations/:organizationId/action-plans/:id'
  const params = { organizationId, id }
  return ApiCallThunkCreator.get(url, params, options)
}

export function updateActionPlanAsync(organizationId, actionPlanId, updates) {
  const url = '/organizations/:organizationId/action-plans/:actionPlanId'
  const params = { organizationId, actionPlanId }
  const body = {
    ...updates,
    organizationId
  }
  return ApiCallThunkCreator.update(url, params, body)
}

export function deleteActionPlanAsync(organizationId, actionPlanId) {
  const url = '/organizations/:organizationId/action-plans/:actionPlanId'
  const params = { organizationId, actionPlanId }
  return ApiCallThunkCreator.destroy(url, params)
}
