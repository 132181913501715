import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Breadcrumb from './breadcrumb'

function PageTitle({
  dataStaticId,
  className,
  contentClassName,
  backUrl,
  title,
  subtitle,
  pageActions,
  allowBack,
  children,
  info,
  allowUp,
  titleClassName,
  onClickBack
}) {
  return (
    <div
      className={cn('rebrand-furniture-page-title-wrapper', {
        [className]: !!className
      })}
    >
      <div className='ml-4 mt-4'>
        <div className={cn('furniture-page-title-content', contentClassName)}>
          <div className='furniture-page-title'>
            <Breadcrumb
              dataStaticId={dataStaticId}
              title={title}
              subtitle={subtitle}
              backUrl={backUrl}
              allowBack={allowBack}
              allowUp={allowUp}
              onClickBack={onClickBack}
              titleClassName={titleClassName}
            />
            {info && <div className='furniture-page-title-info'>{info}</div>}
          </div>
          {pageActions && (
            <div className='furniture-page-actions'>{pageActions}</div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

PageTitle.propTypes = {
  dataStaticId: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backUrl: PropTypes.string,
  pageActions: PropTypes.node,
  allowBack: PropTypes.bool,
  allowUp: PropTypes.bool,
  children: PropTypes.node,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClickBack: PropTypes.func,
  titleClassName: PropTypes.string
}
PageTitle.defaultProps = {
  dataStaticId: undefined,
  className: '',
  contentClassName: '',
  title: '',
  subtitle: '',
  backUrl: '',
  pageActions: null,
  allowBack: false,
  allowUp: false,
  children: null,
  info: '',
  onClickBack: () => {},
  titleClassName: ''
}

export default PageTitle
