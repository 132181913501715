export const ROLES = {
  ADMIN: 'Engage Super Admin',
  LEADER: 'Leader',
  EMPLOYEE: 'Employee',
  ENGAGEMENT_ADMIN: 'Engagement Admin',
  EXECUTIVE_ADVISOR: 'Executive Advisor',
  CUSTOMER_SUCCESS_MANAGER: 'Customer Success Manager',
  ORGANIZATION_ADMIN: 'Organization Admin',
  COACH: 'Coach',
  FACILITATOR: 'Facilitator'
}

export const FACILITATOR_ROLES = {
  coach: ROLES.COACH,
  executive_advisor: ROLES.EXECUTIVE_ADVISOR,
  facilitator: ROLES.FACILITATOR
}

export const INTERNAL_ROLE_NAMES = [
  ROLES.ADMIN,
  ROLES.EXECUTIVE_ADVISOR,
  ROLES.CUSTOMER_SUCCESS_MANAGER,
  ROLES.COACH,
  ROLES.FACILITATOR
]
