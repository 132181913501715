import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { Button, BUTTON_RANKS } from 'furniture/buttons'

function NavigateAway({ allowBack, allowUp, levels, backUrl, onClick }) {
  const { push: historyPush, goBack: historyNavigateBack } = useHistory()
  const { pathname } = useLocation()
  if (!(allowBack || allowUp || backUrl)) {
    return null
  }

  function historyNavigateUp(numLevels = 1) {
    const paths = pathname.split('/')
    const pathnameLeveled = paths.slice(0, -numLevels).join('/')
    historyPush(pathnameLeveled)
  }

  const navigateAway = () => {
    if (onClick) {
      onClick()
    }

    if (allowBack) {
      if (backUrl) {
        return historyPush(backUrl)
      }
      return historyNavigateBack()
    }
    return historyNavigateUp(levels)
  }

  return (
    <div className='icon'>
      <Button
        rank={BUTTON_RANKS.TERTIARY}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={navigateAway}
      />
    </div>
  )
}

NavigateAway.propTypes = {
  allowUp: PropTypes.bool,
  allowBack: PropTypes.bool,
  levels: PropTypes.number,
  backUrl: PropTypes.string,
  onClick: PropTypes.func
}
NavigateAway.defaultProps = {
  allowUp: true,
  allowBack: false,
  levels: 1,
  backUrl: null,
  onClick: () => {}
}

export default NavigateAway
