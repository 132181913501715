export default function timeoutPromise(
  promise,
  ms,
  rejectMessage = 'Promise timed out'
) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(rejectMessage)
    }, ms)

    function resolvePromise(res) {
      clearTimeout(timeoutId)
      resolve(res)
    }

    function rejectPromise(err) {
      clearTimeout(timeoutId)
      reject(err)
    }

    return promise.then(resolvePromise, rejectPromise)
  })
}
