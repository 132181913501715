import forEach from 'lodash/forEach'

/**
 * Checks to see if current path matches a specific route
 *
 * Example: Match Found
 * route = '/groups'
 * currentPath = '/groups/:id?someParam=true'
 *
 * Example: Match Not Found
 * route = '/group'
 * currentPath = '/group_types
 *
 * @param {String} route The name of the base route to check against (e.g. '/groups')
 * @returns {Array|null} - The regex match array OR null if no match is made
 */
function matchRoute(route, currentPath) {
  // If route === '/groups', the below regex matches in the following scenarios:
  // `/groups`
  // '/groups/...'
  // '/groups?...'

  // I'm using a String.raw here in order to pass in a template literal to our regex
  const regex = new RegExp(String.raw`^${route}($|\/|\?)`)
  const match = currentPath.match(regex)

  return match
}

function matchMenuItemToRoute(item, pathname) {
  let matchFound = false

  if (matchRoute(item.route, pathname)) {
    return true
  }

  if (Array.isArray(item.alternateRoutes)) {
    forEach(item.alternateRoutes, route => {
      if (matchRoute(route, pathname)) {
        matchFound = true
      }
    })
  }

  return matchFound
}

export { matchMenuItemToRoute }
