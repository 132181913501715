import React from 'react'
import PropTypes from 'prop-types'

function Drawer({ children, className, setShowDrawer, isOpen }) {
  return (
    <>
      {isOpen && (
        <div
          role='button'
          className='glass-pane'
          onClick={() => setShowDrawer?.(false)}
        />
      )}
      <div className={className}>{children}</div>
    </>
  )
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  setShowDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
}

Drawer.defaultProps = {
  className: '',
  isOpen: false
}

export default Drawer
