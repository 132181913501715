import yayson from 'yayson'

const jsonApi = yayson({ adapter: 'default' })

/**
 * Create a presenter for serializing a specific type of resource
 * @param {String} type The entity type of the resource this presenter is for.
 *  e.g., "employees"
 * @returns {Object} A yayson presenter for serializing data
 * @example
 * const EmployeePresenter = createPresenter('employees')
 * EmployeePresenter.render(employee)
 */
export default function createPresenter(type) {
  class Presenter extends jsonApi.Presenter {}
  Presenter.prototype.type = type
  return Presenter
}
