import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { UNLIMITED_PACKAGE_LIMIT } from 'constants/packages'

function ProgressBar({ title, percentage, numerator, denominator, className }) {
  const adjustedDenominator =
    denominator === -1 ? UNLIMITED_PACKAGE_LIMIT : denominator

  const denominatorLabel =
    adjustedDenominator === UNLIMITED_PACKAGE_LIMIT
      ? 'Unlimited'
      : adjustedDenominator

  const context = percentage
    ? `${percentage}% (${numerator} / ${denominatorLabel})`
    : `${numerator} / ${denominatorLabel}`

  return (
    <div className={cn('furniture-progress-bar-container', className)}>
      <div className='furniture-progress-bar-title'>
        <h4>{title}</h4>
        <span>{context}</span>
      </div>
      <div className='furniture-progress-bar'>
        <div
          className='furniture-progress'
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  numerator: PropTypes.number,
  denominator: PropTypes.number,
  percentage: PropTypes.string,
  className: PropTypes.string
}
ProgressBar.defaultProps = {
  numerator: 0,
  denominator: 0,
  percentage: null,
  className: ''
}

export default ProgressBar
