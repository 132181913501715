import reducer from 'dux/helpers/reducer'

const initialState = {
  searchText: ''
}

const CLOSE_SEARCH = 'group_campaign_members/CLOSE_SEARCH'
const SET_SEARCH_TEXT = 'group_campaign_members/SET_SEARCH_TEXT'

function reduceCloseSearch(state = initialState) {
  if (state.isSearchExpanded === false) {
    return state
  }
  return {
    ...state,
    searchText: ''
  }
}

function reduceSetSearchText(state = initialState, action) {
  if (state.searchText === action.text) {
    return state
  }
  return {
    ...state,
    searchText: action.text
  }
}

export default reducer(
  {
    [CLOSE_SEARCH]: reduceCloseSearch,
    [SET_SEARCH_TEXT]: reduceSetSearchText
  },
  initialState
)

export function closeSearch() {
  return { type: CLOSE_SEARCH }
}

export function setSearchText(text) {
  return { type: SET_SEARCH_TEXT, text }
}
