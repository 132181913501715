import { createSelector } from 'reselect'
import get from 'lodash/get'
import filter from 'lodash/filter'
import Mustache from 'mustache'
import { entities } from 'dux/api/action_types'
import {
  createEntityGetter,
  createPropGetter,
  createEntitySelector,
  createPhaseSelector
} from 'utils/selectors'

function getSelectedOrganizationIdFromProps(state, props = {}) {
  const { organization, organizationId } = props
  return organization ? organization.id : organizationId
}

function getSelectedOrganizationIdFromParams(state, props = {}) {
  return get(props, 'match.params.id') || null
}

export const selectSelectedOrganizationId = createSelector(
  getSelectedOrganizationIdFromProps,
  getSelectedOrganizationIdFromParams,
  getCurrentOrganizationId,
  (orgFromProps, orgFromParams, currentOrganizationId) =>
    orgFromProps || orgFromParams || currentOrganizationId
)

export function getCurrentOrganizationId(state) {
  return get(state, 'organizations.currentOrganizationId')
}

const EMPTY_OBJECT = {}

export function getOrganizations(state) {
  return state.entities.organizations || EMPTY_OBJECT
}

export const selectOrganizations = createEntityGetter(entities.ORGANIZATIONS)

export const selectActiveOrganizations = createSelector(
  getOrganizations,
  organizations => {
    return filter(organizations, org => !org.deletedAt)
  }
)

/**
 * @param {Object} state - Entire redux state tree
 * @returns {Object} The request phase for the organizations entity
 */
export const selectOrganizationsPhase = createSelector(
  createPhaseSelector(entities.ORGANIZATIONS),
  phase => phase
)

/**
 * @param {Object} state - The complete redux state tree
 * @returns {Object} - The current organization object
 */
export const selectCurrentOrganization = createSelector(
  createEntityGetter(entities.ORGANIZATIONS),
  getCurrentOrganizationId,
  (organizations, currentOrgId) => get(organizations, currentOrgId, {})
)

/**
 * Select the organization object for currently selected organization
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - the organization object
 */
export const selectSelectedOrganization = createSelector(
  createEntityGetter('organizations'),
  selectSelectedOrganizationId,
  (organizations, selectedOrganizationId) =>
    organizations[selectedOrganizationId] || {}
)

export const selectOrganizationById = createEntitySelector(
  entities.ORGANIZATIONS,
  'organizationId'
)

export const selectCurrentOrganizationId = createSelector(
  getCurrentOrganizationId,
  id => id || null
)

export const selectParamOrgId = createSelector(
  createPropGetter('organizationId'),
  selectCurrentOrganizationId,
  (organizationId, currentOrganizationId) =>
    organizationId || currentOrganizationId || null
)

/**
 * @param {Object} state - The complete redux state tree
 * @returns {String} - The name of the current organization, or the empty string
 */
export const selectCurrentOrganizationName = createSelector(
  selectCurrentOrganization,
  organization => organization.name || ''
)

/**
 * @param {Object} state - The complete redux state tree
 * @returns {String} - The displays name of the current organization, or its name
 */
export const selectCurrentOrganizationDisplayName = createSelector(
  selectCurrentOrganization,
  organization => organization.displayName || organization.name || ''
)

/**
 * Generates a url to return back to the 15Five Dashboard for the current organization
 */
export const selectReturnToFFUrlForCurrentOrganization = createSelector(
  selectCurrentOrganization,
  createPropGetter('path'),
  (organization, path) => {
    // NOTE: There are a few assumptions here
    // 1. For Engage Super Admins, this function assumes the subdomain from the current organization. This may not be true for their "primary" organization.
    // 2. Subdomains in the 15Five platform can be more complicated then this. ATM, I'm assuming this won't function as expected in customer specific VPCs.
    const ffSubdomain = get(organization, 'ffSubdomain')
    const isFFManaged = get(organization, 'isFFManaged')

    // we need to be data integrated and have a subdomain to return to a 15Five URL
    if (!isFFManaged || !ffSubdomain) {
      return null
    }

    return Mustache.render(process.env.FF_APP_URL, { ffSubdomain, path })
  }
)

export const selectCurrentOrganizationIsFFManaged = createSelector(
  selectCurrentOrganization,
  organization => {
    return get(organization, 'isFFManaged')
  }
)
