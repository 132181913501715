/**
 * Enum for coaching session types.
 * @readonly
 * @enum {string}
 */
export const COACHING_SESSION_TYPES = {
  ONE_ON_ONE_ACTION_PLANNING: 'one_on_one_action_planning',
  GROUP_ACTION_PLANNING: 'group_action_planning',
  ONE_ON_ONE_COACHING: 'one_on_one_coaching',
  GROUP_COACHING: 'group_coaching',
  TRANSFORM_SKILLS_INTENSIVE: 'transform_skills_intensive',
  OKR_SKILLS_INTENSIVE: 'okr_skills_intensive',
  OKR_COACHING: 'okr_coaching',
  BRIEFING: 'briefing',
  PERFORMANCE_ACCELERATOR_LIVE: 'performance_accelerator_live',
  EXECUTIVE_GROUP_COACHING: 'executive_group_coaching',
  STRATEGY_AND_GOAL_SETTING: 'strategy_and_goal_setting',
  ONSITE_FULL_DAY: 'onsite_full_day',
  ONLINE: 'online',
  TRANSFORM_ACCELERATOR_GROUP_COACHING: 'transform_accelerator_group_coaching',
  ONE_TIME_ON_ON_ONE_COACHING: 'one_time_one_on_one_coaching',
}

export const COACHING_SESSION_TYPE_NAMES = {
  [COACHING_SESSION_TYPES.ONE_ON_ONE_ACTION_PLANNING]:
    '1:1 Engagement Action Planning',
  [COACHING_SESSION_TYPES.GROUP_ACTION_PLANNING]:
    'Group Engagement Action Planning',
  [COACHING_SESSION_TYPES.ONE_ON_ONE_COACHING]: '1:1 Coaching',
  [COACHING_SESSION_TYPES.GROUP_COACHING]: 'Group Coaching',
  [COACHING_SESSION_TYPES.TRANSFORM_SKILLS_INTENSIVE]:
    'Transform Skills Intensive',
  [COACHING_SESSION_TYPES.OKR_SKILLS_INTENSIVE]: 'OKR Skills Intensive',
  [COACHING_SESSION_TYPES.OKR_COACHING]: 'OKR Coaching',
  [COACHING_SESSION_TYPES.BRIEFING]: 'Briefing',
  [COACHING_SESSION_TYPES.PERFORMANCE_ACCELERATOR_LIVE]:
    'Performance Accelerator Live',
  [COACHING_SESSION_TYPES.EXECUTIVE_GROUP_COACHING]: 'Executive Group Coaching',
  [COACHING_SESSION_TYPES.STRATEGY_AND_GOAL_SETTING]:
    'Strategy and Goal Setting',
  [COACHING_SESSION_TYPES.ONSITE_FULL_DAY]: 'Onsite Full Day',
  [COACHING_SESSION_TYPES.ONLINE]: 'Manager Accelerator Online',
  [COACHING_SESSION_TYPES.TRANSFORM_ACCELERATOR_GROUP_COACHING]:
    'Transform Accelerator Group Coaching',
  [COACHING_SESSION_TYPES.ONE_TIME_ON_ON_ONE_COACHING]:
    'One-time 1-on-1 Coaching',
}

export const COACHING_SESSION_ONE_TIME_TYPES = {
  [COACHING_SESSION_TYPES.ONE_ON_ONE_ACTION_PLANNING]:
    '1:1 Engagement Action Planning',
  [COACHING_SESSION_TYPES.GROUP_ACTION_PLANNING]:
    'Group Engagement Action Planning',
  [COACHING_SESSION_TYPES.OKR_SKILLS_INTENSIVE]: 'OKR Skills Intensive',
  [COACHING_SESSION_TYPES.OKR_COACHING]: 'OKR Coaching',
  [COACHING_SESSION_TYPES.BRIEFING]: 'Briefing',
  [COACHING_SESSION_TYPES.STRATEGY_AND_GOAL_SETTING]:
    'Strategy and Goal Setting',
  [COACHING_SESSION_TYPES.ONSITE_FULL_DAY]: 'Onsite Full Day',
  [COACHING_SESSION_TYPES.ONLINE]: 'Manager Accelerator Online',
  [COACHING_SESSION_TYPES.ONE_TIME_ON_ON_ONE_COACHING]:
    'One-time 1-on-1 Coaching',
}

// Coaching session types that are one-on-one
// Used to determine if calendly session title
// should be prefilled with the user's name
export const COACHING_SESSION_ONE_ON_ONE_TYPES = [
  COACHING_SESSION_TYPES.ONE_ON_ONE_ACTION_PLANNING,
  COACHING_SESSION_TYPES.ONE_ON_ONE_COACHING,
  COACHING_SESSION_TYPES.ONE_TIME_ON_ON_ONE_COACHING,
]

export const FACILITATION_TYPES = {
  TEAM: 'team',
  INDIVIDUAL: 'individual',
}

export const COACHING_SESSION_TYPE_FACILITATION_TYPES = {
  [FACILITATION_TYPES.TEAM]: 'Team',
  [FACILITATION_TYPES.INDIVIDUAL]: 'Individual',
}

export const COACHING_SESSION_TYPE_FILTER_MAP = {
  ONE_ON_ONE_COACHING: '1:1 Coaching',
  ONE_ON_ONE_ENGAGEMENT_ACTION_PLANNING: '1:1 Engagement Action Planning',
  BRIEFING: 'Briefing',
  GROUP_COACHING: 'Group Coaching',
  GROUP_ENGAGEMENT_ACTION_PLANNING: 'Group Engagement Action Planning',
  MANAGER_ACCELERATOR_GROUP_COACHING: 'Manager Accelerator Group Coaching',
  MANAGER_ACCELERATOR_HYBRID_GROUP_COACHING:
    'Manager Accelerator Hybrid Group Coaching',
  MANAGER_ACCELERATOR_SKILLS_INTENSIVE: 'Manager Accelerator Skills Intensive',
  OKR_COACHING: 'OKR Coaching',
  OKR_EXECUTIVE_ALIGNMENT_SET: 'OKR Executive Alignment Set',
  OKR_SKILLS_INTENSIVE: 'OKR Skills Intensive',
  TRANSFORM_ACCELERATOR_GROUP_COACHING: 'Transform Accelerator Group Coaching',
  ONE_TIME_ON_ON_ONE_COACHING: 'One-time 1-on-1 Coaching',
}

export const COACHING_SESSION_TYPE_FILTER_VALUES = Object.values(
  COACHING_SESSION_TYPE_FILTER_MAP,
)

// Remaps the filter names to the abbreviated names used in the filter chips
export const COACHING_SESSION_TYPE_FILTER_CHIPS = {
  [COACHING_SESSION_TYPE_FILTER_MAP.ONE_ON_ONE_COACHING]: '1:1 Coaching',
  [COACHING_SESSION_TYPE_FILTER_MAP.ONE_ON_ONE_ENGAGEMENT_ACTION_PLANNING]:
    '1:1 Engagement Action Planning',
  [COACHING_SESSION_TYPE_FILTER_MAP.BRIEFING]: 'Briefing',
  [COACHING_SESSION_TYPE_FILTER_MAP.GROUP_COACHING]: 'Group Coaching',
  [COACHING_SESSION_TYPE_FILTER_MAP.GROUP_ENGAGEMENT_ACTION_PLANNING]:
    'Group Engagement Action Planning',
  [COACHING_SESSION_TYPE_FILTER_MAP.MANAGER_ACCELERATOR_GROUP_COACHING]:
    'MA-Group Coaching',
  [COACHING_SESSION_TYPE_FILTER_MAP.MANAGER_ACCELERATOR_HYBRID_GROUP_COACHING]:
    'MA-Hybrid Group Coaching',
  [COACHING_SESSION_TYPE_FILTER_MAP.MANAGER_ACCELERATOR_SKILLS_INTENSIVE]:
    'MA-Skills Intensive',
  [COACHING_SESSION_TYPE_FILTER_MAP.OKR_COACHING]: 'OKR Coaching',
  [COACHING_SESSION_TYPE_FILTER_MAP.OKR_EXECUTIVE_ALIGNMENT_SET]:
    'OKR Executive Alignment Set',
  [COACHING_SESSION_TYPE_FILTER_MAP.OKR_SKILLS_INTENSIVE]:
    'OKR Skills Intensive',
  [COACHING_SESSION_TYPE_FILTER_MAP.TRANSFORM_ACCELERATOR_GROUP_COACHING]:
    'Transform Accelerator Group Coaching',
  [COACHING_SESSION_TYPES.ONE_TIME_ON_ON_ONE_COACHING]:
    'One-time 1-on-1 Coaching',
}
