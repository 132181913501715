import reducer from 'dux/helpers/reducer'
import isEqual from 'lodash/isEqual'

const initialState = {
  /*
  Keys are tableNames
  Values are objects containing pagination, sortinging, and filtering
  e.g.,
  employees: {
    pagination: { page: 0, rowsPerPage: 10 },
    sorting: { header: '', isAsc: true }
  },
  */
}

// Constants (i.e. action types)

const SET_PAGINATION = 'reduxTable/SET_PAGINATION'
const SET_SORTING = 'reduxTable/SET_SORTING'

// Reducers

function reduceSetPagination(state = initialState, action) {
  const { tableName, pagination } = action

  if (state[tableName] && isEqual(state[tableName].pagination, pagination)) {
    return state
  }

  return {
    ...state,
    [tableName]: {
      ...state[tableName],
      pagination
    }
  }
}

function reduceSetSorting(state = initialState, action) {
  const { tableName, sorting } = action

  if (state[tableName] && isEqual(state[tableName].sorting, sorting)) {
    return state
  }

  return {
    ...state,
    [tableName]: {
      ...state[tableName],
      sorting
    }
  }
}

// Combined reducer functions

export default reducer(
  {
    [SET_PAGINATION]: reduceSetPagination,
    [SET_SORTING]: reduceSetSorting
  },
  initialState
)

// Action creators
export function setPagination(tableName, pagination) {
  return {
    type: SET_PAGINATION,
    tableName,
    pagination
  }
}

export function setSorting(tableName, sorting) {
  return {
    type: SET_SORTING,
    tableName,
    sorting
  }
}
