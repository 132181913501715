import { createSelector } from 'reselect'
import get from 'lodash/get'
import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import pickBy from 'lodash/pickBy'

import { createPropGetter, createEntityGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import { selectParamOrgId } from 'selectors/organizations'

export const selectCurrentOrganizationGroupTypes = createSelector(
  selectParamOrgId,
  createEntityGetter(entities.ORGANIZATION_GROUP_TYPES),
  (currentOrgId, orgGroupTypes) =>
    filter(orgGroupTypes, ogt => `${ogt.organizationId}` === currentOrgId)
)

/**
 * Select an array of group types that are owned by the current organization
 * and have an associated organization group type record.
 *
 * @param {Object} state The Redux store
 * @returns {Array} An array of group types
 */
export const selectActiveGroupTypes = createSelector(
  selectCurrentOrganizationGroupTypes,
  createEntityGetter(entities.GROUP_TYPES),
  (currentOrgGroupTypes, groupTypes) =>
    reduce(
      groupTypes,
      (result, gt) => {
        const orgGroupType = find(
          currentOrgGroupTypes,
          ogt => `${ogt.groupTypeId}` === gt.id
        )
        if (orgGroupType !== undefined) {
          result.push({
            ...gt,
            ogtId: orgGroupType.id,
            order: orgGroupType.order
          })
        }
        return result
      },
      []
    )
)

/**
 * Select groups that belong to an active group type
 * @param {Object} state The entire Redux store
 * @returns {Object} Groups keyed by id
 */
export const selectActiveGroups = createSelector(
  createEntityGetter(entities.GROUPS),
  selectActiveGroupTypes,
  (groups, activeGroupTypes) => {
    const activeGroupTypeIds = map(activeGroupTypes, 'id')
    return pickBy(groups, value =>
      activeGroupTypeIds.includes(`${value.groupTypeId}`)
    )
  }
)

export const selectGroupFromId = createSelector(
  createEntityGetter(entities.GROUPS),
  createPropGetter('groupId'),
  (groups, groupId) => get(groups, groupId, null)
)

export const selectGroupTypeFromGroupId = createSelector(
  createEntityGetter(entities.GROUP_TYPES),
  selectGroupFromId,
  (groupTypes, group) =>
    find(
      groupTypes,
      groupType => `${groupType.id}` === `${get(group, 'groupTypeId')}`
    ) || null
)
