import reducer from 'dux/helpers/reducer'
import {
  createApiCallActionType,
  statuses,
  entities,
  methods
} from 'dux/api/action_types'
import { EMPLOYEE_VIEWS } from 'constants/employees_list_paginated'
import values from 'lodash/values'
import { ROLES } from 'constants/roles'
import { EMPLOYMENT_STATUSES } from 'constants/employment'

const PAGE_LIMIT = 10

const initialState = {
  employeeView: null,
  [EMPLOYEE_VIEWS.ACCESS]: {
    [ROLES.LEADER]: true,
    [ROLES.ORGANIZATION_ADMIN]: true,
    [ROLES.ENGAGEMENT_ADMIN]: true
  },
  [EMPLOYEE_VIEWS.ALL]: {
    [EMPLOYMENT_STATUSES.ACTIVE]: true,
    [EMPLOYMENT_STATUSES.INACTIVE]: true
  },
  pageOptions: {
    employeeView: null,
    rowsPerPage: PAGE_LIMIT,
    page: 0,
    sortBy: 'status',
    sortDirection: 'ASC',
    searchOptions: {
      searchText: ''
    }
  },
  totalEmployeeCount: 0
}

// Constants (i.e. action types)
const SET_EMPLOYEE_VIEW = 'employees/SET_EMPLOYEE_VIEW'
const SET_EMPLOYEE_FILTER = 'employees/SET_EMPLOYEE_FILTER'
const SET_PAGE_OPTIONS = 'employees/SET_PAGE_OPTIONS'
const EMPLOYEES_GET_SUCCESS = createApiCallActionType(
  entities.EMPLOYEES,
  methods.GET,
  statuses.SUCCESS
)

function reduceSetEmployeeView(state = initialState, action) {
  const { employeeView } = action

  // Do not allow the employee view to be set to an unacceptable value
  if (!values(EMPLOYEE_VIEWS).includes(employeeView)) {
    return state
  }

  return {
    ...state,
    employeeView
  }
}

function reduceSetEmployeeFilter(state = initialState, action) {
  const { filter } = action
  const { employeeView } = state

  if (
    employeeView === EMPLOYEE_VIEWS.ACCESS ||
    employeeView === EMPLOYEE_VIEWS.ALL
  ) {
    return {
      ...state,
      [employeeView]: {
        ...state[employeeView],
        [filter.name]: filter.value
      }
    }
  }

  return state
}

function reduceSetPageOptions(state = initialState, action) {
  const { pageOptions } = action

  if (!pageOptions) {
    return state
  }

  return {
    ...state,
    pageOptions
  }
}

function reduceSuccessfulEmployeeGet(state = initialState, action) {
  if (action.meta) {
    const { count } = action.meta
    return {
      ...state,
      totalEmployeeCount: count
    }
  }
  return state
}

// Combined reducer functions
export default reducer(
  {
    [SET_EMPLOYEE_VIEW]: reduceSetEmployeeView,
    [SET_EMPLOYEE_FILTER]: reduceSetEmployeeFilter,
    [SET_PAGE_OPTIONS]: reduceSetPageOptions,
    [EMPLOYEES_GET_SUCCESS]: reduceSuccessfulEmployeeGet
  },
  initialState
)

// Action creators

function setEmployeeView(employeeView) {
  return {
    type: SET_EMPLOYEE_VIEW,
    employeeView
  }
}

function setEmployeeFilter(filter) {
  return {
    type: SET_EMPLOYEE_FILTER,
    filter
  }
}

function setPageOptions(pageOptions) {
  return {
    type: SET_PAGE_OPTIONS,
    pageOptions
  }
}

export { setEmployeeView, setEmployeeFilter, setPageOptions }
