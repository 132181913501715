import React from 'react'
import NavListLink from 'containers/dropdown_nav/dropdown-nav-list-link'

function HelpCenterSubMenuContainer() {
  return <NavListLink label='Help Center' route='https://success.15five.com' />
}

HelpCenterSubMenuContainer.propTypes = {}

export default HelpCenterSubMenuContainer
