export const CATEGORY_TYPES = {
  CONDITIONS: 'Psych. Conditions',
  DRIVERS: 'Driver',
  EMPLIFY_SCORE: 'Emplify Score',
  ENGAGEMENT: 'Engagement',
  ENPS: 'eNPS',
  LIFECYCLE: 'lifecycle',
}

export default CATEGORY_TYPES
