import React from 'react'
import PropTypes from 'prop-types'

import { Card } from 'furniture/card'

/**
 * This component provides common layout code for our "placeholder" pages.
 * These pages can be used to explain why the content the user is seeking is not being displayed.
 * Expected use for this page layout component would be error or unusual states
 * e.g., Not Found, Not Ready, No Results
 * We may, however, find this layout useful for other things and expand the concept of it.
 */
function PageStatusCard({ children, imageUrl }) {
  return (
    <Card>
      <div className='page-status-card-wrapper'>
        <div className='icon' style={{ backgroundImage: `url(${imageUrl})` }} />
        <div className='explanation'>{children}</div>
      </div>
    </Card>
  )
}

PageStatusCard.propTypes = {
  children: PropTypes.node.isRequired,
  imageUrl: PropTypes.string.isRequired
}

export default PageStatusCard
