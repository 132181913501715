import reducer from 'dux/helpers/reducer'

const initialState = {
  selectedMembershipGroupIndex: 0,
  selectedMembershipGroup: {},
  selectedAttributeGroup: { entity: null, id: null, name: null },
  shouldAutoCommitImport: true
}

// Constants (i.e. action types)

const SET_SELECTED_MEMBERSHIP_GROUP =
  'employeeManagement/SET_SELECTED_MEMBERSHIP_GROUP'
const SET_SELECTED_ATTRIBUTE_GROUP =
  'employeeManagement/SET_SELECTED_ATTRIBUTE_GROUP'
const SET_SHOULD_AUTO_COMMIT_IMPORT =
  'employeeManagement/SET_SHOULD_AUTO_COMMIT_IMPORT'

// Reducers

function reduceSetSelectedMembershipGroup(state = initialState, action) {
  return {
    ...state,
    selectedMembershipGroupIndex: action.selectedMembershipGroupIndex,
    selectedMembershipGroup: action.selectedMembershipGroup
  }
}

function reduceSetSelectedAttributeGroup(state = initialState, action) {
  return {
    ...state,
    selectedAttributeGroup: action.selectedAttributeGroup
  }
}
function reduceSetShouldAutoCommitImport(state = initialState, action) {
  return {
    ...state,
    shouldAutoCommitImport: action.shouldAutoCommitImport
  }
}

// Combined reducer functions

export default reducer(
  {
    [SET_SELECTED_MEMBERSHIP_GROUP]: reduceSetSelectedMembershipGroup,
    [SET_SELECTED_ATTRIBUTE_GROUP]: reduceSetSelectedAttributeGroup,
    [SET_SHOULD_AUTO_COMMIT_IMPORT]: reduceSetShouldAutoCommitImport
  },
  initialState
)

// Action creators

function setSelectedMembershipGroup(
  selectedMembershipGroupIndex,
  selectedMembershipGroup
) {
  return {
    type: SET_SELECTED_MEMBERSHIP_GROUP,
    selectedMembershipGroupIndex,
    selectedMembershipGroup
  }
}

function setSelectedAttributeGroup(selectedAttributeGroup) {
  return {
    type: SET_SELECTED_ATTRIBUTE_GROUP,
    selectedAttributeGroup
  }
}

function setShouldAutoCommitImport(shouldAutoCommitImport) {
  return {
    type: SET_SHOULD_AUTO_COMMIT_IMPORT,
    shouldAutoCommitImport
  }
}

// Export necessary action types and action creators

export {
  setSelectedMembershipGroup,
  setSelectedAttributeGroup,
  setShouldAutoCommitImport
}
