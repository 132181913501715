import React, { useEffect, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { POPOVER_POSITIONS } from 'constants/popover_positions'
import cn from 'classnames'

import { usePopper } from 'react-popper'

function Popover({
  anchorEl,
  children,
  position,
  className,
  style,
  offset,
  open,
  onClickOutside,
  shouldMatchAnchorWidth,
  anchorRef,
  dataStaticId
}) {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)

  const popoverRef = useRef()

  const popperModifiers = useMemo(
    () => [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset
        }
      },
      {
        name: 'matchAnchorWidth',
        enabled: shouldMatchAnchorWidth,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px` // eslint-disable-line no-param-reassign
        },
        phase: 'beforeWrite'
      }
    ],
    [offset, shouldMatchAnchorWidth]
  )

  const { styles, attributes, update } = usePopper(
    anchorRef || referenceElement,
    popperElement,
    {
      placement: position,
      modifiers: popperModifiers
    }
  )

  useEffect(() => {
    if (open && update) {
      update()
    }
  }, [open, update])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        open
      ) {
        onClickOutside?.() // eslint-disable-line no-unused-expressions
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef, open, onClickOutside])

  let anchorDiv = null
  if (anchorEl) {
    /* For more accurate results for sizing it's better to set the popper 
      ref to the anchor element. However, popover won't work correctly when setting 
      the ref on a components that return a function (AnalyticWrapper). So for these
      we need to bump the ref up the anchor element container div. */
    anchorDiv =
      typeof anchorEl.type === 'function' ? (
        <div className='furniture-popover-anchor' ref={setReferenceElement}>
          {anchorEl}
        </div>
      ) : (
        <div className='furniture-popover-anchor'>
          {React.cloneElement(anchorEl, { ref: setReferenceElement })}
        </div>
      )
  }
  return (
    <>
      {anchorDiv}
      <div
        className='furniture-popover-wrapper'
        ref={setPopperElement}
        style={{
          ...styles.popper,
          ...style
        }}
        {...attributes.popper}
      >
        <div
          className={cn('furniture-popover-transition', { isVisible: open })}
        >
          {open && (
            <div
              className={cn('furniture-popover', className)}
              style={{ ...styles.offset }}
              ref={popoverRef}
              data-static-id={dataStaticId}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

Popover.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.node,
  position: PropTypes.oneOf(Object.values(POPOVER_POSITIONS)),
  className: PropTypes.string,
  style: PropTypes.object,
  offset: PropTypes.arrayOf(PropTypes.number),
  open: PropTypes.bool,
  onClickOutside: PropTypes.func,
  shouldMatchAnchorWidth: PropTypes.bool,
  anchorRef: PropTypes.object,
  dataStaticId: PropTypes.string
}

Popover.defaultProps = {
  position: POPOVER_POSITIONS.RIGHT,
  children: null,
  anchorEl: null,
  className: '',
  style: {},
  offset: [0, 0],
  open: false,
  onClickOutside: null,
  shouldMatchAnchorWidth: false,
  anchorRef: null,
  dataStaticId: undefined
}

export default Popover
export { POPOVER_POSITIONS }
