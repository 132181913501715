import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class CardBodyAnimated extends PureComponent {
  render() {
    const cx = cn(
      'furniture-card-body',
      { [this.props.className]: !!this.props.className },
      'animated',
      { closed: !this.props.open }
    )

    const style = { maxHeight: this.props.open ? this.props.height : 0 }

    return (
      <div style={style} className={cx}>
        {this.props.children}
      </div>
    )
  }
}

CardBodyAnimated.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
CardBodyAnimated.defaultProps = {
  children: undefined,
  open: true,
  className: null,
  height: 1000
}

export default CardBodyAnimated
