import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class CardRow extends PureComponent {
  render() {
    const cx = cn(
      'furniture-card-row',
      { [this.props.className]: !!this.props.className },
      { divider: !!this.props.divider }
    )

    return (
      <div style={this.props.style} className={cx}>
        {this.props.children}
      </div>
    )
  }
}

CardRow.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  divider: PropTypes.bool
}
CardRow.defaultProps = {
  children: undefined,
  style: {},
  className: null,
  divider: true
}

export default CardRow
