import React, { PureComponent } from 'react'

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

class CloseIcon extends PureComponent {
  render() {
    return <FontAwesomeIcon icon={faTimes} />
  }
}

export default CloseIcon
