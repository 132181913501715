import variables from 'styles/_variables_export.module.scss'

// Primary Blue Tints
const BLUE = variables.colorBlue
const SELECTED_BLUE = '#0D47A1' // Used for select field selected item
const TREND_BLUE = '#093579' // Used for trendlines besides Emplify score trend
const DARK_BLUE = '#18669e'
const DARKEST_BLUE = '#12232e' // Does not match sass variable
const EMPLIFY_BLUE = '#38abdd'
const SEMI_TRANSPARENT_BLUE = `${variables.colorBlue}50` // Semi transparent (31% alpha) BLUE
const DEEP_CERULEAN = '#2d536c' // Used in text and links
const LIGHT_CERULEAN = '#d6eaf6'
const TIDE_200 = '#1A0D3F'

// Secondary Colors
const RED = '#ed431d'
const ORANGE = '#f19111'
const YELLOW = '#febf0c'
const GREEN = '#31c065'
const BRIGHT_GREEN = '#82c51b'

// Engagement Bracket Colors
const HIGHLY_DISENGAGED = '#d13941'
const DISENGAGED = '#ff6f41'
const MODERATELY_ENGAGED = '#f6be55'
const HIGHLY_ENGAGED = '#16dbdb'
const EXTREMELY_ENGAGED = '#12afaf'

// Engagement Change Colors
const CHANGE_POSITIVE = '#558b2f'
const CHANGE_NEGATIVE = '#eb4720'

// Grow Colors
const GROW_BLUE = '#d6eaf6'
const GROW_DARK_BLUE = '#007BA2'
const GROW_PURPLE = '#654F8F'
const GROW_BLACK_BORDER = '#979797'

// Greys

const BG_GREY = 'hsl(0, 0%, 94%)'
const SOFT_GREY = 'hsla(0, 0%, 0%, .12)'
const FAINT_GREY = '#e0e0e1'
const INFLUENCE_GREY = '#929b9c'
const GRAY_80 = 'hsl(0, 0%, 80%)'
const GRAY_700 = '#4d4d4d'
const TPZ_INPUT_BACKGROUND_GREY = '#d4d4d4'
const TPZ_INPUT_ICON_GREY = '#adadad'
const TPZ_SEARCH_GREY = 'rgba(0, 0, 0, 0.3)'
const TPZ_SEARCH_BG_GREY = '#f5f5f5'
const TPZ_ICON_GREY = '#999999'

const PRIMARY_BLACK = 'hsla(0, 0%, 0%, .87)'
const SECONDARY_BLACK = 'hsla(0, 0%, 0%, .54)'
const DISABLED_BLACK = 'hsla(0, 0%, 0%, .38)'

const PRIMARY_WHITE = 'hsla(0, 0%, 100%, 1)'
const SECONDARY_WHITE = 'hsla(0, 0%, 100%, .7)'
const DISABLED_WHITE = 'hsla(0, 0%, 100%, .5)'

// Other neutrals
const BONE = '#e6ddcb'

const DRIVER_SCORES = [
  '#ed4609',
  '#f66905',
  '#ff8c00',
  '#f3b565',
  BONE,
  '#91bbbf',
  '#3b99b3',
  '#2a80a9',
  DARK_BLUE
]

// Chip Colors
const CHIP_POSITIVE_BG = '#F9FCF6'
const CHIP_POSITIVE_FG = '#436022'

const CHIP_NEGATIVE_BG = '#FCF5F5'
const CHIP_NEGATIVE_FG = '#5F1C19'

const CHIP_WARNING_BG = '#FEF5EA'
const CHIP_WARNING_FG = '#7A4C16'

// Topaz Colors
const SUCCESS_EMERALD = '#89C445'

/* New Design System */
/* Once we hash out some more of the design system, we can rename the colors */
/* For now just naming using http://chir.ag/projects/name-that-color/#931A29 */
/* ------------------------------------------------------------------------- */

const OLD_BRICK = '#931a29'

const COLOR_MOTION_100 = '#f0e9fb'

export default {
  BLUE,
  SELECTED_BLUE,
  DARK_BLUE,
  DARKEST_BLUE,
  EMPLIFY_BLUE,
  SEMI_TRANSPARENT_BLUE,
  TREND_BLUE,
  OLD_BRICK,
  DEEP_CERULEAN,
  LIGHT_CERULEAN,
  RED,
  ORANGE,
  YELLOW,
  GREEN,
  BRIGHT_GREEN,
  BG_GREY,
  SOFT_GREY,
  FAINT_GREY,
  INFLUENCE_GREY,
  GRAY_80,
  GRAY_700,
  TPZ_INPUT_BACKGROUND_GREY,
  TPZ_INPUT_ICON_GREY,
  TPZ_SEARCH_GREY,
  TPZ_SEARCH_BG_GREY,
  TPZ_ICON_GREY,
  PRIMARY_BLACK,
  SECONDARY_BLACK,
  DISABLED_BLACK,
  PRIMARY_WHITE,
  SECONDARY_WHITE,
  DISABLED_WHITE,
  BONE,
  DRIVER_SCORES,
  HIGHLY_DISENGAGED,
  DISENGAGED,
  MODERATELY_ENGAGED,
  HIGHLY_ENGAGED,
  EXTREMELY_ENGAGED,
  GROW_BLUE,
  GROW_DARK_BLUE,
  GROW_BLACK_BORDER,
  GROW_PURPLE,
  CHIP_POSITIVE_BG,
  CHIP_POSITIVE_FG,
  CHIP_NEGATIVE_BG,
  CHIP_NEGATIVE_FG,
  CHIP_WARNING_BG,
  CHIP_WARNING_FG,
  SUCCESS_EMERALD,
  CHANGE_POSITIVE,
  CHANGE_NEGATIVE,
  COLOR_MOTION_100,
  TIDE_200
}
