import reducer from 'dux/helpers/reducer'

const initialState = {
  byCampaignId: {
    // 4: { topic: datetime }
  }
}

// Constants
const SET_VISITED_BY_CAMPAIGN_ID = 'visit/SET_VISITED_BY_CAMPAIGN_ID'
const CLEAR_ALL_VISITED = 'visit/CLEAR_ALL_VISITED'

function reduceSetVisitedByCampaignId(state = initialState, action) {
  const { topic, campaignId } = action
  if (!state.byCampaignId[campaignId]) {
    return {
      ...state,
      byCampaignId: {
        [campaignId]: {
          [topic]: new Date().toISOString()
        }
      }
    }
  }

  return {
    ...state,
    byCampaignId: {
      [campaignId]: {
        ...state.byCampaignId[campaignId],
        [topic]: new Date().toISOString()
      }
    }
  }
}

function reduceClearAllVisited() {
  return { ...initialState }
}

// Combined reducer functions
export default reducer(
  {
    [SET_VISITED_BY_CAMPAIGN_ID]: reduceSetVisitedByCampaignId,
    [CLEAR_ALL_VISITED]: reduceClearAllVisited
  },
  initialState
)

// Action creators
function setVisitedByCampaignId(campaignId, topic) {
  return {
    type: SET_VISITED_BY_CAMPAIGN_ID,
    campaignId,
    topic
  }
}

function clearAllVisited() {
  return {
    type: CLEAR_ALL_VISITED
  }
}

// Export necessary action types and action creators
export { setVisitedByCampaignId, clearAllVisited }
