import React from 'react'
import PropTypes from 'prop-types'

import Tooltip from './tooltip'

function CardTooltip({ anchorEl, position, children }) {
  return (
    <Tooltip anchorEl={anchorEl} position={position}>
      {children}
    </Tooltip>
  )
}
CardTooltip.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left'])
}
CardTooltip.defaultProps = {
  anchorEl: null,
  position: 'top'
}

export default CardTooltip
