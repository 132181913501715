import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link } from 'react-router-dom'

export const BUTTON_RANKS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
}

export const BUTTON_CONTEXTS = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  DESTRUCTIVE: 'destructive',
  METHODOLOGY: 'methodology',
  LIGHT: 'light',
  WARNING: 'warning'
}

/**
 * 💎 Topaz Button 💎
 * Our button's style guide can be found here: https://github.com/bluebridgedigital/citadel/wiki/Topaz:-Button-Styles
 *
 * The button should be able to handle any case described in the style guide using the combination of rank, context, and icon.
 *
 * Rank describes the level of action for the button or what's the importance of this action for this page/product? For example,
 * our filter buttons are secondary actions as they are not necessary for the results page.
 *
 * Context describes the type of action we want our button to represent. For example, Methodology context is an action that is key to our
 * engagement methodology (create a campaign, create an action, etc.).
 *
 * Icons can be used without button labels to create Icon Buttons according to the button guide.
 * NOTE: Icons are styled by our base CSS, so there's no need to pass in color styling for your icon.
 *
 * @param {String} props.id - the id of the container element
 * @param {String} props.label - the button text
 * @param {Node} props.icon - the optional icon SVG
 * @param {String} props.linkTo - the optional link if you want the button to act as link
 * @param {Function} props.onClick - the onClick event handler
 * @param {String} props.className - any additional classnames you want to apply to button (only use if need additional styling)
 * @param {Object} props.style - the style object for the container element (only use if need to override style)
 * @param {Object} props.labelStyle - the style object for the span element for label (only use if need to override style)
 * @param {Boolean} props.disabled - boolean to disable button
 * @param {String} props.rank - rank of our button (Primary, Secondary, or Tertiary)
 * @param {String} props.context - context of our button (Default, Success, Destructive, or Methodology)
 * @param {String} props.tooltipPosition - string indicating direction to display tooltip; Options: POPOVER_POSITIONS Defaults to: POPOVER_POSITIONS.BOTTOM_CENTER
 */
function ButtonAnchor({
  label,
  icon,
  onClick,
  style,
  className,
  disabled,
  linkTo,
  rank,
  context,
  children,
  dataStaticId
}) {
  const cx = cn('tpz-btn', {
    'tpz-btn-icon-no-label': !!icon && !label,
    'tpz-btn-icon-with-label': !!icon && label,
    [className]: !!className,
    'tpz-btn-primary': rank === BUTTON_RANKS.PRIMARY,
    'tpz-btn-secondary': rank === BUTTON_RANKS.SECONDARY,
    'tpz-btn-tertiary': rank === BUTTON_RANKS.TERTIARY,
    methodology: !disabled && context === BUTTON_CONTEXTS.METHODOLOGY,
    success: !disabled && context === BUTTON_CONTEXTS.SUCCESS,
    destructive: !disabled && context === BUTTON_CONTEXTS.DESTRUCTIVE,
    warning: !disabled && context === BUTTON_CONTEXTS.WARNING,
    light: !disabled && context === BUTTON_CONTEXTS.LIGHT,
    disabled
  })

  if (linkTo && !disabled) {
    return (
      <Link
        role='link'
        className={cx}
        style={{ ...style, textDecoration: 'none' }}
        to={linkTo || undefined}
        onClick={disabled ? undefined : onClick}
        data-static-id={dataStaticId}
      >
        {children}
      </Link>
    )
  }
  return (
    <div
      role='button'
      className={cx}
      style={{ ...style, textDecoration: 'none' }}
      onClick={disabled ? undefined : onClick}
      data-static-id={dataStaticId}
    >
      {children}
    </div>
  )
}

ButtonAnchor.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.node,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  rank: PropTypes.oneOf(Object.values(BUTTON_RANKS)),
  context: PropTypes.oneOf(Object.values(BUTTON_CONTEXTS)),
  // eslint-disable-next-line react/no-unused-prop-types
  extraAnalyticsProperties: PropTypes.object, // only used for withAnalytics()
  children: PropTypes.node,
  dataStaticId: PropTypes.string
}
ButtonAnchor.defaultProps = {
  label: null,
  icon: null,
  linkTo: null,
  onClick: null,
  style: {},
  className: null,
  disabled: false,
  rank: '',
  context: BUTTON_CONTEXTS.DEFAULT,
  extraAnalyticsProperties: {},
  children: undefined,
  dataStaticId: null
}

export default ButtonAnchor
