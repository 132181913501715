import PropTypes from 'prop-types'

import Phases from 'constants/phases'
import { TABS, V3_TABS } from 'constants/results'
import { Order } from 'constants/sort'
import { USER_TYPES } from 'constants/user'

const order = PropTypes.oneOf(Object.values(Order))

const analyticEvent = PropTypes.shape({
  name: PropTypes.string.isRequired,
  properties: PropTypes.object,
  mapEventProperties: PropTypes.func,
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func
})

const EmplifyPropTypes = {
  order,
  analyticEvent,
  participation: PropTypes.shape({
    completedCount: PropTypes.number,
    size: PropTypes.number
  }),
  phase: PropTypes.oneOf(Object.values(Phases)),
  resultTab: PropTypes.oneOf(Object.values(TABS)),
  v3GroupTab: PropTypes.oneOf(Object.values(V3_TABS)),
  sort: PropTypes.shape({ field: PropTypes.string, order }),
  stringOrNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userType: PropTypes.oneOf(Object.values(USER_TYPES))
}

export default EmplifyPropTypes
