import { PureComponent } from 'react'
import PropTypes from 'prop-types'

class ExpansionPanelDetails extends PureComponent {
  render() {
    return this.props.children
  }
}

ExpansionPanelDetails.propTypes = {
  children: PropTypes.node
}
ExpansionPanelDetails.defaultProps = {
  children: undefined
}

export default ExpansionPanelDetails
