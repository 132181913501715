import reducer from 'dux/helpers/reducer'

const initialState = {
  showNumericalValues: true,
  showObscuredGroups: false,
  showEducationSlide: true,
  heatmapYAxisWidth: null,
  experienceType: null,
  campaignId: null,
  pathGroupId: null
}

/**
 * Current chart, group, and filter selections for the Analyze page
 * @typedef {Object} Settings
 * @property {string} groupType
 *  The group type selected for the current chart. (last dropdown)
 * @property {string} compareTo
 *  It will be null for engagement charts and for current heatmap. "previous" for trending heatmap.
 * @property {string} filterGroup
 *  The group, if any, we are filtering the current chart by (middle drop down)
 */

// Constants (i.e. action types)
const TOGGLE_SHOW_NUMERICAL_VALUES = 'results/TOGGLE_SHOW_NUMERICAL_VALUES'
const TOGGLE_SHOW_HIDDEN_GROUPS = 'results/TOGGLE_SHOW_HIDDEN_GROUPS'
const UPDATE_HEATMAP_Y_AXIS_WIDTH = 'results/UPDATE_HEATMAP_Y_AXIS_WIDTH'
const UPDATE_EXPERIENCE_TYPE = 'results/UPDATE_EXPERIENCE_TYPE'
const UPDATE_CAMPAIGN_ID = 'results/UPDATE_CAMPAIGN_ID'
const SET_PATH_GROUP_ID = 'results/SET_PATH_GROUP_ID'

// Reducers
function reduceToggleShowNumericalValues(state = initialState) {
  return {
    ...state,
    showNumericalValues: !state.showNumericalValues
  }
}

function reduceToggleShowHiddenGroups(state = initialState) {
  return {
    ...state,
    showObscuredGroups: !state.showObscuredGroups
  }
}

function reduceUpdateHeatmapYAxisWidth(state = initialState, action) {
  const { width } = action

  if (width !== state.heatmapYAxisWidth) {
    return {
      ...state,
      heatmapYAxisWidth: width
    }
  }

  return state
}

function reduceUpdateExperienceType(state = initialState, action) {
  const { experienceType } = action
  return {
    ...state,
    experienceType
  }
}

function reduceUpdateCampaignId(state = initialState, action) {
  const { campaignId } = action
  return {
    ...state,
    campaignId
  }
}

function reducePathGroupId(state, action) {
  if (state.pathGroupId === action.pathGroupId) {
    return state
  }

  return {
    ...state,
    pathGroupId: action.pathGroupId
  }
}

// Combined reducer functions
export default reducer(
  {
    [TOGGLE_SHOW_NUMERICAL_VALUES]: reduceToggleShowNumericalValues,
    [TOGGLE_SHOW_HIDDEN_GROUPS]: reduceToggleShowHiddenGroups,
    [UPDATE_HEATMAP_Y_AXIS_WIDTH]: reduceUpdateHeatmapYAxisWidth,
    [UPDATE_EXPERIENCE_TYPE]: reduceUpdateExperienceType,
    [UPDATE_CAMPAIGN_ID]: reduceUpdateCampaignId,
    [SET_PATH_GROUP_ID]: reducePathGroupId
  },
  initialState
)

// Action creators
function toggleShowNumericalValues() {
  return {
    type: TOGGLE_SHOW_NUMERICAL_VALUES
  }
}

function toggleShowObscuredGroups() {
  return {
    type: TOGGLE_SHOW_HIDDEN_GROUPS
  }
}

function updateHeatmapYAxisWidth(width) {
  return {
    type: UPDATE_HEATMAP_Y_AXIS_WIDTH,
    width
  }
}

function updateExperienceType(experienceType) {
  return {
    type: UPDATE_EXPERIENCE_TYPE,
    experienceType
  }
}

function updateCampaignId(campaignId) {
  return {
    type: UPDATE_CAMPAIGN_ID,
    campaignId
  }
}

function setPathGroupId(pathGroupId) {
  return {
    type: SET_PATH_GROUP_ID,
    pathGroupId
  }
}

export {
  toggleShowNumericalValues,
  toggleShowObscuredGroups,
  updateHeatmapYAxisWidth,
  updateExperienceType,
  updateCampaignId,
  setPathGroupId
}
