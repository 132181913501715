import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import { selectReturnToFFUrlForCurrentOrganization } from 'selectors/organizations'
import Navigate15Five from './navigate_15Five'

function Brand({ className }) {
  const logoClasses = cn('drawer-v2-logo-container', {
    [className]: !!className
  })

  const returnToFFUrl = useSelector(state =>
    selectReturnToFFUrlForCurrentOrganization(state, {
      path: '/engage/dashboard'
    })
  )

  return (
    <div className='drawer-v2-top-nav'>
      <div className={logoClasses}>
        <Navigate15Five returnToFFUrl={returnToFFUrl} />
      </div>
    </div>
  )
}

Brand.propTypes = {
  className: PropTypes.string
}

Brand.defaultProps = {
  className: null
}

export default Brand
