import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import map from 'lodash/map'
import get from 'lodash/get'

import Dialog from 'furniture/dialog'
import { V2SearchField } from 'furniture'
import Switch from 'furniture/buttons/switch'
import {
  V2ArrowSelectMenu,
  V2DropdownMenuItem as MenuItem,
} from 'furniture/menu'
import { Text } from 'furniture/typography'
import Colors from 'constants/colors'

import {
  selectSearchText,
  selectIncludeTrialOrgs,
  selectRecentOrganizations,
  selectFilteredOrganizations,
} from 'selectors/org_switcher_v2'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { updateSearchText, setIncludeTrialOrgs } from 'dux/org_switcher_v2'
import { switchOrganizationAsync } from 'dux/organizations'
import { getOrganizationAsync } from 'dux/api/actions/organizations'

const SEARCH_FIELD_ID = 'org-switcher-search-field'
const MENU_ID = 'org-switcher-items'

function onSearchArrowDown(e) {
  if (e.key === 'ArrowDown') {
    const menuItems = get(document.getElementById(MENU_ID), 'childNodes', [])
    if (menuItems[0]) {
      menuItems[0].focus()
    }
  }
}

/* eslint-disable react/prop-types */
function OrgSwitcherSearch({
  searchText,
  includeTrialOrgs,
  onChange,
  onKeyDown,
  toggleIncludeTrialOrgs,
}) {
  return (
    <>
      <div className='org-switcher-search-container'>
        <V2SearchField
          id={SEARCH_FIELD_ID}
          className='org-switcher-search'
          hintText='Search Organizations'
          value={searchText}
          onChange={onChange}
          onKeyDown={onKeyDown}
          autoFocus
        />
        <Switch
          className='org-switcher-include-trial-orgs'
          switched={includeTrialOrgs}
          description={
            <p className='org-switcher-switch-description'>
              Include Trial Organizations
            </p>
          }
          labelStyle={{ color: Colors.SECONDARY_BLACK }}
          onSwitch={() => toggleIncludeTrialOrgs(!includeTrialOrgs)}
        />
      </div>
      <Text subheading className='org-switcher-items-label'>
        {searchText ? 'Search Results' : 'Recently Visited Organizations'}
      </Text>
    </>
  )
}
/* eslint-enable react/prop-types */

function OrgSwitcherDialog({
  actions,
  open,
  handleClose,
  currentOrganizationId,
  searchText,
  includeTrialOrgs,
  filteredOrganizations,
  recentOrganizations,
}) {
  const history = useHistory()
  const onSearchChange = (text) => {
    actions.updateSearchText(text)
  }

  const handleOrgChange = (e, organization) => {
    if (organization.id && organization.id !== currentOrganizationId) {
      actions.switchOrganizationAsync(organization.id, history, {})
      actions.getOrganizationAsync(organization.id, {
        query: { include: 'v3-groups' },
      })
      history.replace('/')
    }
    handleClose()
  }

  const orgsForSwitcher =
    !recentOrganizations || searchText
      ? filteredOrganizations
      : recentOrganizations

  return (
    <Dialog
      title={
        <OrgSwitcherSearch
          searchText={searchText}
          includeTrialOrgs={includeTrialOrgs}
          onChange={onSearchChange}
          onKeyDown={onSearchArrowDown}
          toggleIncludeTrialOrgs={actions.setIncludeTrialOrgs}
        />
      }
      open={open}
      onRequestClose={handleClose}
      className='org-switcher-dialog'
      displayDismissButton={false}
    >
      <V2ArrowSelectMenu
        initialElementId={SEARCH_FIELD_ID}
        menuId={MENU_ID}
        className='org-switcher-items-container'
      >
        {map(orgsForSwitcher, (org) => (
          <MenuItem
            key={org.id}
            className='org-switcher-menu-item'
            item={org}
            valueField='nameAndId'
            onClick={handleOrgChange}
          />
        ))}
      </V2ArrowSelectMenu>
    </Dialog>
  )
}

function mapStateToProps(state) {
  return {
    searchText: selectSearchText(state),
    includeTrialOrgs: selectIncludeTrialOrgs(state),
    filteredOrganizations: selectFilteredOrganizations(state),
    currentOrganizationId: selectCurrentOrganizationId(state),
    recentOrganizations: selectRecentOrganizations(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateSearchText,
        setIncludeTrialOrgs,
        switchOrganizationAsync,
        getOrganizationAsync,
      },
      dispatch,
    ),
  }
}

OrgSwitcherDialog.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  includeTrialOrgs: PropTypes.bool.isRequired,
  filteredOrganizations: PropTypes.array.isRequired,
  currentOrganizationId: PropTypes.string.isRequired,
  recentOrganizations: PropTypes.array.isRequired,
}

OrgSwitcherDialog.defaultProps = {
  open: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgSwitcherDialog)
