import reducer from 'dux/helpers/reducer'

const initialState = {
  personSearch: '',
  selectedPerson: {}
}

// Constants
const SET_PERSON_SEARCH = 'permissionsEditor/SET_PERSON_SEARCH'
const SET_SELECTED_PERSON = 'permissionsEditor/SET_SELECTED_PERSON'

// Reducers
function reduceSetPersonSearch(state = initialState, action) {
  return {
    ...state,
    personSearch: action.personSearch
  }
}

function reduceSetSelectedPerson(state = initialState, action) {
  return {
    ...state,
    selectedPerson: action.selectedPerson
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_PERSON_SEARCH]: reduceSetPersonSearch,
    [SET_SELECTED_PERSON]: reduceSetSelectedPerson
  },
  initialState
)

// Actions
function setPersonSearch(personSearch) {
  return {
    type: SET_PERSON_SEARCH,
    personSearch
  }
}

function setSelectedPerson(selectedPerson) {
  return {
    type: SET_SELECTED_PERSON,
    selectedPerson
  }
}

export { setPersonSearch, setSelectedPerson }
