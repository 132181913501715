import yayson from 'yayson'
import getPresenter from './presenters'

/**
 * This module is inspired by JsonApiHelper from the citadel repo.
 * Emplify resources adhere to the JSON API spec. That means we can programatically
 * prep request bodies and interpret responses to extract out the pertinent data.
 */

const jsonApi = yayson({ adapter: 'default' })

/**
 * Create a fresh copy of the resource with an "id" property added. The value is a temporary ID.
 * @param {Object} resource Resource to add a temporary ID to
 * @param {Number} number The number to tack on to the temporary ID to make it unique
 * @return {Object} Fresh copy of the resource with temp ID added
 */
function addTempIdToResource(resource, number) {
  return {
    ...resource,
    id: `temp-id-${number}`
  }
}

/**
 * If any associated resources are present on the primary resource, add temporary IDs.
 * This is to enable sideposting of the associated resources.
 * Without the temporary IDs, the server will not deserialize correctly.
 *
 * @param {Object} data The resource to serialize
 * @param {Object} Presenter The yayson presenter that will be used for serialization
 *  This is needed because the Presenter understands the relationships of the resource
 * @returns {Object} The resource to serialize, but with temporary IDs added to
 *  any associated resources
 */
function addTempIdsToRelatedEntities(data, Presenter) {
  const relationships = Presenter.prototype.relationships()
  if (!relationships) {
    return data
  }
  const relationshipNames = Object.keys(relationships)
  if (!relationshipNames || relationshipNames.length < 1) {
    return data
  }

  const newData = { ...data }
  relationshipNames.forEach(relationshipName => {
    const relatedResource = data[relationshipName]
    if (Array.isArray(relatedResource)) {
      const newResources = relatedResource.map((r, i) =>
        addTempIdToResource(r, i + 1)
      )
      newData[relationshipName] = newResources
    } else {
      newData[relationshipName] = addTempIdToResource(relatedResource, 1)
    }
  })
  return newData
}

function serialize(type, data) {
  let dataToSerialize = data
  // If we are posting more than one primary resource, each item needs to have a unique ID
  // Otherwise, yayson will only serialize the first one and ignore the others
  // Technically, we should not send these temporary IDs across the pipe
  // JSON API specifies that the server respond 400 to requests that specify an ID,
  // when client-generated IDs are not allowed
  // However, in our endpoints, we deserialize with a { dropId: true } option in our POST endpoints
  if (Array.isArray(data) && data.length > 0) {
    dataToSerialize = data.map((d, i) => {
      if (d.id !== undefined) {
        return d
      }
      return addTempIdToResource(d, i + 1)
    })
  }

  const Presenter = getPresenter(type, data)
  dataToSerialize = addTempIdsToRelatedEntities(dataToSerialize, Presenter)
  return Presenter.render(dataToSerialize)
}

function deserialize(data = {}) {
  const store = new jsonApi.Store()
  const deserialized = store.sync(data)
  delete deserialized.type
  // We may want to convert the `id` property to a number from a string
  return deserialized
}

export default { serialize, deserialize }
