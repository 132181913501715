import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

class InfoIcon extends PureComponent {
  render() {
    const { overrideIcon } = this.props
    if (overrideIcon) {
      return overrideIcon
    }

    if (this.props.warning || this.props.error) {
      return <FontAwesomeIcon icon={faExclamationCircle} />
    }

    if (this.props.info || this.props.ff) {
      return <FontAwesomeIcon icon={faInfoCircle} />
    }

    if (this.props.success) {
      return <FontAwesomeIcon icon={faThumbsUp} />
    }

    return null
  }
}

InfoIcon.propTypes = {
  overrideIcon: PropTypes.node,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  info: PropTypes.bool,
  success: PropTypes.bool,
  ff: PropTypes.bool
}

InfoIcon.defaultProps = {
  overrideIcon: null,
  warning: false,
  error: false,
  info: false,
  success: false,
  ff: false
}

export default InfoIcon
