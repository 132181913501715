import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Route, matchPath, useLocation, useHistory } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Mustache from 'mustache'
import { updateByWhitelist } from 'utils/intercom'

import {
  authorizationStatuses,
  logout as duxLogout,
  concludeAuthentication,
} from 'dux/login'
import { TOKEN_KEY } from 'constants/login'
import EmplifyAuth from 'utils/emplify/authentication'
import LOCAL_STORAGE from 'constants/local_storage'
import { getRedirectTo } from 'utils/browser/query'
import { selectPerson } from 'selectors/user'
import { selectReturnToFFUrlForCurrentOrganization } from 'selectors/organizations'
import LocalStorageLogger, { redirectLoggerParams } from 'utils/logger'

const redirectLogger = new LocalStorageLogger(redirectLoggerParams)
const emplifyAuth = new EmplifyAuth()

const LOGIN_PATH = '/login/callback'
const FF_REDIRECT_ROUTE = '/ff/:ffSubdomain/:redirect*'

/**
 * Handles authentication when the request is through the login path.
 * Extracting the access_token and extra params from the hash and applying it
 * @param {Object} actions Login Actions
 */
function handleLoginPathAuthentication(actions, history) {
  const { accessToken, redirectTo } = EmplifyAuth.parseHash()

  if (accessToken) {
    actions.concludeAuthentication(accessToken, redirectTo, history)
  } else {
    // No token in the hash
    // Just reset them to a logged out state.
    actions.logout()
  }
}

/**
 * Checks the current path for the 15Five SSO redirect path
 * If it matches, it'll return a path to the 15Five Engagement SSO redirect
 * otherwise, it'll return null
 * @param {Object} path
 * @returns 15Five Redirect URL
 */
function getFFRedirectAuthenticationUrl(path) {
  // "matchPath" verifies if the path matches and automatically extracts the params
  const ffSSOPath = matchPath(path, FF_REDIRECT_ROUTE)
  if (!ffSSOPath) {
    return null
  }

  const ffSubdomain = get(ffSSOPath, 'params.ffSubdomain')
  const redirectTo = `/${get(ffSSOPath, 'params.redirect')}`
  const ffAppUrl = Mustache.render(process.env.FF_APP_URL, {
    ffSubdomain,
  })
  return `${ffAppUrl}/engagement-sso/redirect?redirect_to=${redirectTo}`
}

/**
 * Handles authentication when making a request outside the login path.
 * Attempts to extract the token from localStorage
 * @param {Object} actions Login Actions
 */
function handleReturnAuthentication(actions, path, history) {
  const token = localStorage.getItem(TOKEN_KEY)
  if (token) {
    actions.concludeAuthentication(token, null, history)
  } else {
    const ffRedirectUrl = getFFRedirectAuthenticationUrl(path)
    if (ffRedirectUrl) {
      window.location = ffRedirectUrl
    } else {
      // No token in local storage
      // No way to try and authenticate the user
      // Just reset them to a logged out state.
      actions.logout()
    }
  }
}

function handleAuthentication(actions, location, history) {
  const path = get(location, 'pathname')

  if (path && path === LOGIN_PATH) {
    return handleLoginPathAuthentication(actions, history)
  }
  return handleReturnAuthentication(actions, path, history)
}

/**
 * Clears any sso token that is currently valid
 * Only run this if you want to clear their sso session
 */
function logout(person, returnToFFUrl) {
  if (person && person.ffGlobalUserId && returnToFFUrl) {
    window.location = returnToFFUrl
    return
  }
  emplifyAuth.logout()
}

/**
 * Directs the user to the hosted Auth0 page
 */
function login(errorMessage) {
  emplifyAuth.authorize({
    errorMessage,
  })
}

function isUnauthorized(authorizationStatus) {
  return authorizationStatus === authorizationStatuses.NOT_AUTHORIZED
}

function hasAuthorizationErrored(authorizationStatus) {
  return authorizationStatus === authorizationStatuses.ERRORED
}

function AuthenticatedRoute({
  manualLogout,
  errorMessage,
  authorizationStatus,
  actions,
  person,
  returnToFFUrl,
  path,
  strict,
  exact,
  component,
}) {
  const location = useLocation()
  const history = useHistory()
  const redirectTo = getRedirectTo(location)

  useEffect(() => {
    const unlistenHistory = history.listen((route) => updateByWhitelist(route))
    return () => {
      unlistenHistory()
    }
  }, [history])

  useEffect(() => {
    if (isUnauthorized(authorizationStatus) && manualLogout) {
      logout(person, returnToFFUrl)
    } else if (isUnauthorized(authorizationStatus) && !manualLogout) {
      login(errorMessage)
    } else if (hasAuthorizationErrored(authorizationStatus)) {
      login(errorMessage)
    } else {
      handleAuthentication(actions, location, history)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUnauthorized(authorizationStatus) && manualLogout) {
      // Was there a manual logout initiated? (user clicked the logout button)
      logout(person, returnToFFUrl)
    } else if (isUnauthorized(authorizationStatus) && !manualLogout) {
      if (redirectTo) {
        redirectLogger.log(redirectTo, 'set')
        // 15 minutes
        const minutes = 15 * 60000
        const expiry = new Date(new Date().getTime() + minutes)

        localStorage.setItem(
          LOCAL_STORAGE.REDIRECT_TO,
          JSON.stringify({ expiry, redirectTo }),
        )
      }
      login(errorMessage)
    } else if (hasAuthorizationErrored(authorizationStatus)) {
      login(errorMessage)
    }
  }, [
    authorizationStatus,
    errorMessage,
    manualLogout,
    person,
    redirectTo,
    returnToFFUrl,
  ])

  const maybeRender = (routeProps) => {
    if (authorizationStatus === authorizationStatuses.AUTHORIZED) {
      return React.createElement(component, routeProps)
    }

    return null
  }

  return (
    <Route
      path={path}
      strict={strict}
      exact={exact}
      render={(routeProps) => maybeRender(routeProps)}
    />
  )
}

function mapStateToProps(state) {
  const person = selectPerson(state)

  return {
    person,
    authorizationStatus: state.login.authorizationStatus,
    errorMessage: state.login.error,
    manualLogout: state.login.manualLogout,
    returnToFFUrl: selectReturnToFFUrlForCurrentOrganization(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        concludeAuthentication,
        logout: duxLogout,
      },
      dispatch,
    ),
  }
}

AuthenticatedRoute.propTypes = {
  // For some reason, using PropTypes.node did not pass validation.
  // I checked the React Router source and in <Route /> prop types, they define
  // component to be a func.
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,
  manualLogout: PropTypes.bool,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  errorMessage: PropTypes.string,
  authorizationStatus: PropTypes.symbol.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  // used in componentDidUpdate
  // eslint-disable-next-line react/no-unused-prop-types
  redirectTo: PropTypes.string,
  person: PropTypes.object,
  returnToFFUrl: PropTypes.string,
}
AuthenticatedRoute.defaultProps = {
  exact: false,
  strict: false,
  manualLogout: false,
  errorMessage: '',
  redirectTo: '',
  person: null,
  returnToFFUrl: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute)
