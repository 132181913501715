import isFinite from 'lodash/isFinite'
import { percentage } from 'utils/native/numbers'
import { createSerializedQueryFromGroupId } from 'utils/emplify/analyze_helper'
import { getSerializedAttributeFilters } from 'utils/emplify/id_helper'
import { buildSortedFilters } from 'utils/emplify/filters'

export const isValidParticipation = (participation = {}) =>
  isFinite(participation.completedCount) && isFinite(participation.size)

export const isEnoughParticipation = (participation = {}, campaign) =>
  participation.completedCount >= campaign.confidentialityLevel

/**
 * Generate the unique ID for an optionally cross-segmented group participation
 * @param {String|Number} campaignId
 * @param {String|Number} groupId
 * @param {String|Number} crossSegmentGroupId
 * @returns {String} unique ID
 */
export function buildGroupParticipationId(
  campaignId,
  groupId,
  crossSegmentGroupId
) {
  const filterIdSuffix = crossSegmentGroupId
    ? createSerializedQueryFromGroupId(crossSegmentGroupId)
    : ''
  const fullSuffix = filterIdSuffix ? `:${filterIdSuffix}` : ''

  return `${campaignId}:${groupId}${fullSuffix}`
}

export function buildFilterableParticipationId(
  campaignId,
  groupId,
  attributeFilters
) {
  const attributesFilter = getSerializedAttributeFilters(attributeFilters)

  if (!groupId || groupId === 'organization') {
    return `${campaignId}${attributesFilter}`
  }
  return `${campaignId}:${groupId}${attributesFilter}`
}

export function buildSegmentFilteredParticipationId(
  campaignId,
  groupId,
  attributeFilters,
  segmentFilter
) {
  const id = `${campaignId}:${groupId}`
  const sortedFilters = buildSortedFilters(attributeFilters, segmentFilter)

  if (sortedFilters.length > 0) {
    return `${id}:${sortedFilters}`
  }

  return id
}

/**
 * Computes the response rate for a campaign from a participation object.
 * Response rate is the proportion of completed surveys to administered surveys, as a percentage.
 * @param {Object} participation A group *or* organization campaign participation object
 * @param {Number} [numberOfDecimals=2] How many decimals to round the response rate to
 * @returns {Number} The response rate
 */
export function computeResponseRate(participation, numberOfDecimals = 2) {
  if (!participation) {
    return null
  }
  const numerator = isFinite(participation.completedCount)
    ? participation.completedCount
    : participation.completedSurveyCount
  const denominator = isFinite(participation.size)
    ? participation.size
    : participation.audienceSize
  if (!denominator || numerator === null || numerator === undefined) {
    return null
  }
  return percentage(numerator, denominator, numberOfDecimals)
}
