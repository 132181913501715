import reducer from 'dux/helpers/reducer'
import get from 'lodash/get'
import isString from 'lodash/isString'
import { sidepostEmployeeAsync } from 'dux/api/actions/employees'
import { statuses } from 'dux/api/action_types'
import SavingStatuses from 'constants/saving_statuses'
import { showToast, ToastTypes } from 'utils/toast'

const initialState = {
  savingStatus: null,
  redirect: null
}

// Constants (i.e. action types)
const RESET_NEW_EMPLOYEE = 'employeeNew/RESET_NEW_EMPLOYEE'
export const SET_SAVING_STATUS = 'employeeNew/SET_SAVING_STATUS'

function reduceSavingStatus(state = initialState, action) {
  const { savingStatus, redirect } = action
  return {
    ...state,
    savingStatus,
    redirect
  }
}

function reduceResetNewEmployee() {
  return initialState
}

// Combined reducer functions
export default reducer(
  {
    [SET_SAVING_STATUS]: reduceSavingStatus,
    [RESET_NEW_EMPLOYEE]: reduceResetNewEmployee
  },
  initialState
)

// Action creators
export function setSavingStatus(savingStatus, messageDetail, redirect = null) {
  return {
    type: SET_SAVING_STATUS,
    savingStatus,
    messageDetail,
    redirect
  }
}

export function resetNewEmployee() {
  return {
    type: RESET_NEW_EMPLOYEE
  }
}

export function handleAddNewEmployee(organizationId, formValues) {
  return function createNewEmployee(dispatch) {
    const {
      startedOn,
      employersEmployeeId,
      organizationEmployeeId,
      emailAddress,
      mobilePhoneNumber,
      firstName,
      middleName,
      lastName,
      bornOn,
      localeId
    } = formValues
    const employeeData = {
      organizationId,
      startedOn,
      employersEmployeeId,
      organizationEmployeeId,
      emailAddress: emailAddress || null,
      mobilePhoneNumber: mobilePhoneNumber || null,
      firstName,
      middleName,
      lastName,
      bornOn,
      localeId
    }

    const membershipData = []
    const v3MembershipData = []
    const employeeAttributesData = []

    let redirectPath
    dispatch(setSavingStatus(SavingStatuses.REQUEST))
    return dispatch(
      sidepostEmployeeAsync(
        organizationId,
        employeeData,
        membershipData,
        v3MembershipData,
        employeeAttributesData,
        {
          suppressToast: true
        }
      )
    )
      .then(result => {
        if (result.status !== statuses.SUCCESS) {
          redirectPath = null
          throw result.response
        }
        const employeeId = get(result, 'data.result.employees[0]')

        redirectPath = `/employee-list/${employeeId}`

        showToast('Successfully created employee', ToastTypes.SUCCESS)
        dispatch(setSavingStatus(SavingStatuses.SUCCESS, null, redirectPath))
      })
      .catch(error => {
        // TODO: Handle detail error better with suppressing.
        let errorMessage = error.message || null
        let errorDetail = get(error, 'data.errors[0].detail', '')
        if (!isString(errorDetail)) {
          errorDetail = JSON.stringify(errorDetail)
        }
        if (!errorMessage) {
          errorMessage = 'Failed to create employee'
        }

        showToast(errorMessage, ToastTypes.SUCCESS)
        dispatch(
          setSavingStatus(SavingStatuses.ERROR, errorDetail, redirectPath)
        )
      })
  }
}
