import { createSelector } from 'reselect'
import find from 'lodash/find'
import filter from 'lodash/filter'
import {
  createEntityGetter,
  createEntitySelector,
  createPhaseSelector,
  createPropGetter,
} from 'utils/selectors'
import { isConditionOrDriver } from 'utils/emplify/categories'
import { entities, methods } from 'dux/api/action_types'
import orderBy from 'lodash/orderBy'

export const selectCategoriesPhase = createPhaseSelector(
  entities.CATEGORIES,
  methods.GET,
  (props) => ({ organizationId: props.organizationId }),
)

export const selectCampaignCategoriesPhase = createPhaseSelector({
  entity: entities.CAMPAIGN_CATEGORIES,
  method: methods.GET,
  mapProps: ({ organizationId, campaignId }) => ({
    organizationId,
    campaignId,
  }),
})

export const selectCategory = createEntitySelector(
  entities.CATEGORIES,
  'categoryId',
)

/**
 * Get organization's driver and condition categories.
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - collection of categories
 */
export const selectCategories = createSelector(
  createEntityGetter(entities.CATEGORIES),
  (categories) =>
    filter(categories, (category) => isConditionOrDriver(category)),
)

export const selectSelectedCampaignCategories = createSelector(
  createEntityGetter(entities.CAMPAIGN_CATEGORIES),
  createEntityGetter(entities.CATEGORIES),
  createPropGetter('campaignId'),
  (allCampaignCategories, categories, campaignId) => {
    const campaignCategories = filter(
      allCampaignCategories,
      (campaignCategory) =>
        `${campaignCategory.campaignId}` === `${campaignId}`,
    )

    return campaignCategories.map((campaignCategory) => {
      return find(
        categories,
        (category) => `${category.id}` === `${campaignCategory.categoryId}`,
      )
    })
  },
)

export const seletCampaignCategoriesByCampaignId = createSelector(
  createEntityGetter(entities.CAMPAIGN_CATEGORIES),
  createPropGetter('campaignId'),
  (allCampaignCategories, campaignId) => {
    return filter(
      allCampaignCategories,
      (campaignCategory) =>
        `${campaignCategory.campaignId}` === `${campaignId}`,
    )
  },
)

export const selectSelectedCampaignDriversAndConditions = createSelector(
  selectSelectedCampaignCategories,
  (categories) =>
    filter(categories, (category) => isConditionOrDriver(category)),
)

export const selectCategoryFromStatement = createSelector(
  createEntityGetter(entities.CATEGORIES),
  createEntityGetter(entities.STATEMENTS),
  createPropGetter('statementId'),
  (categories, statements, statementId) => {
    const statement = statements[statementId]
    return categories[statement.categoryId]
  },
)

export const selectDriversAndConditions = createSelector(
  createEntityGetter(entities.CATEGORIES),
  (categories) => Object.values(categories).filter(isConditionOrDriver),
)

export const selectDriversAndConditionsById = createSelector(
  selectDriversAndConditions,
  createPropGetter('categoryId'),
  (categories, categoryId) => {
    return find(categories, (category) => `${category.id}` === `${categoryId}`)
  },
)

export const selectCategoriesByTypes = createSelector(
  createEntityGetter(entities.CATEGORIES),
  createPropGetter('types'),
  (categories, types) => {
    if (!types) {
      return {}
    }
    const matching = filter(categories, (c) => types.includes(c.categoryType))
    return orderBy(matching, ['title'], ['asc'])
  },
)

export const selectCategoryByType = (type) =>
  createSelector([createEntityGetter(entities.CATEGORIES)], (categories) =>
    find(categories, (c) => c.categoryType === type),
  )
