import { useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

const environment = process.env.VPC_NAME || process.env.ENVIRONMENT
const datadogRUMApplicationId = process.env.DATADOG_RUM_APPLICATION_ID
const datadogRUMClientToken = process.env.DATADOG_RUM_CLIENT_TOKEN

function DatadogRUM() {
  useEffect(() => {
    if (datadogRUMApplicationId && datadogRUMClientToken) {
      datadogRum.init({
        applicationId: datadogRUMApplicationId,
        clientToken: datadogRUMClientToken,
        env: environment,
        site: 'datadoghq.com',
        service: `engagement-${environment}`,
        sampleRate: 10
      })
    }
  }, [])

  return null
}

export default DatadogRUM
