import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import { USER_TYPES } from 'constants/user'

/**
 * Return V3 Groups with the group.name transformed to include parent names
 * i.e. 'P&E > Engineering > Team Squad'
 *
 * @param {Array|Object} groups array containing V3 Groups objects
 * @param {Object} options Additional options
 * @param {String} options.numParentNames The number of parent ancestor names to include in the name string
 * @param {String} options.sortBy The sortBy direction string ('ASC' || 'DESC')
 * @param {Boolean} options.excludeRootGroup Boolean that determines to exclude the root group
 *
 * @return {Array} The transformed groups array
 */
export function getV3GroupsWithParentNames(groups, options = {}) {
  const { numParentNames = 1, excludeRootGroup } = options
  const keyedGroups = keyBy(groups, 'id')

  let sortedGroups = groups
  if (options && options.sortBy) {
    sortedGroups = sortBy(groups, options.sortBy)
  }

  if (excludeRootGroup) {
    sortedGroups = sortedGroups.filter(group => !group.isRoot)
  }

  return map(sortedGroups, group => {
    const newGroup = { ...group }

    let breadcrumbName = group.displayName || group.name
    let currentGroup = group

    // If parentNameCount is -1, the parent label will grow until no parents are left
    let parentNameCount = numParentNames
    while (parentNameCount > 0 || numParentNames === -1) {
      const currentName = breadcrumbName
      if (
        currentGroup.parentGroupId &&
        keyedGroups[currentGroup.parentGroupId]
      ) {
        const parentGroup = keyedGroups[currentGroup.parentGroupId]
        const parentGroupLabel = parentGroup.displayName || parentGroup.name
        breadcrumbName = `${parentGroupLabel} > ${currentName}`

        currentGroup = parentGroup

        parentNameCount -= 1
      } else {
        break
      }
    }

    newGroup.breadcrumbName = breadcrumbName
    return newGroup
  })
}

/**
 * Get V3 Group name with it's direct parent included.
 * Ex. Corporate > Marketing
 * @param {Object} v3Groups - collection of v3 groups
 * @param {String} groupId - selected group
 */
export function getV3GroupWithDirectParentName(v3Groups, groupId) {
  const group = v3Groups[groupId] || {}
  const parentGroup = v3Groups[group.parentGroupId] || null

  return parentGroup ? `${parentGroup.name} > ${group.name}` : group.name
}

/**
 *  Determines if a root group should be excluded based on a users permissions
 */
export function shouldExcludeRootGroup(userType) {
  return (
    userType !== USER_TYPES.ADMIN &&
    userType !== USER_TYPES.ORGANIZATION_LEADER &&
    userType !== USER_TYPES.ENGAGEMENT_ADMIN
  )
}
