import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import Button from './button'

function NavigationButton({ className, children, ...props }) {
  const cx = cn('tpz-btn-navigation', {
    [className]: !!className
  })

  return (
    <Button {...props} className={cx}>
      {[children, <FontAwesomeIcon icon={faChevronRight} />]}
    </Button>
  )
}

NavigationButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}
NavigationButton.defaultProps = {
  className: null,
  children: undefined
}

export default NavigationButton
