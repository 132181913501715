import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

function Switch({
  className,
  disabled,
  onSwitch,
  description,
  switched,
  label
}) {
  return (
    <div className={cn('switch-container', className)}>
      {description && <div className='switch-description'>{description}</div>}

      <label className='switch'>
        <input
          type='checkbox'
          disabled={disabled}
          checked={switched}
          onChange={e => {
            onSwitch(e.target.checked)
          }}
        />
        <span className='slider' />
        {label && <div className='switch-label'>{label}</div>}
      </label>
    </div>
  )
}

Switch.propTypes = {
  className: PropTypes.string,
  onSwitch: PropTypes.func,
  disabled: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  switched: PropTypes.bool,
  label: PropTypes.string
}

Switch.defaultProps = {
  className: null,
  onSwitch: () => {},
  description: '',
  disabled: false,
  switched: false,
  label: ''
}

export default Switch
