export const USER_TYPES = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
  MULTI_GROUP_LEADER: 'multi-group-leader',
  READ_ONLY: 'read-only',
  SINGLE_GROUP_LEADER: 'single-group-leader',
  ENGAGEMENT_ADMIN: 'engagement-admin',
  ORGANIZATION_ADMIN: 'organization-admin',
  COACH: 'coach',
  EXECUTIVE_ADVISOR: 'executive-advisor',
  FACILITATOR: 'facilitator'
}
