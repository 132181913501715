/**
 * Describe our resources for the benefit of the serializer and deserialize.
 * To be JSON API compliant, we need to explicitly state some things about our entities
 * and the relationships between them.
 * Entities not listed here will be dealt with in a default manner.
 */

const RESOURCES = {
  employees: {
    relationships: {
      person: 'people',
      memberships: 'memberships'
    }
  },
  actionPlans: {
    relationships: {
      'action-occurrences': 'actionOccurrences'
    }
  },
  'facilitation-teams': {
    relationships: {
      facilitationTeamMembers: 'facilitation-team-members'
    }
  },
  'coaching-subscriptions': {
    relationships: {
      coachingSubscriptionParticipants: 'coaching-subscription-participants'
    }
  }
}

export default RESOURCES
