import React from 'react'
import PropTypes from 'prop-types'
import { toUpper } from 'lodash'

const DropdownNavSection = ({ children, header }) => {
  return (
    <div>
      <h2 className='text-14 text-[#5D5775] px-6 mt-6 mb-2'>
        {toUpper(header)}
      </h2>
      {children}
      <div className='border-b-0 border-solid border-[#D2CFD8] mt-2' />
    </div>
  )
}

DropdownNavSection.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string
}

DropdownNavSection.defaultProps = {
  header: ''
}

export default DropdownNavSection
