import reducer from 'dux/helpers/reducer'

import { serializeObject } from 'utils/native/object'

const initialState = {}

// Constants
const SET_YES_NO_RESPONSE = 'helpful_yes_no/SET_YES_NO_RESPONSE'

function reduceSetYesNoResponse(state = initialState, action) {
  const { topic, keys, response } = action
  let newState = { ...state }
  if (!newState[topic]) {
    newState = {
      ...state,
      [topic]: {}
    }
  }

  newState[topic][serializeObject(keys)] = response

  return newState
}

// Combined reducer functions
export default reducer(
  {
    [SET_YES_NO_RESPONSE]: reduceSetYesNoResponse
  },
  initialState
)

// Action creators
function setYesNoResponse(topic, keys, response) {
  return {
    type: SET_YES_NO_RESPONSE,
    topic,
    keys,
    response
  }
}

// Export necessary action types and action creators
export { setYesNoResponse }
