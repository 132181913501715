import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Title from './card_header_title'
import Subtitle from './card_header_subtitle'

function CardHeader({
  icon,
  title,
  subtitle,
  style,
  className,
  divider,
  children
}) {
  const cx = cn(
    'furniture-card-header',
    { title: !!icon || !!title },
    { [className]: !!className },
    { divider: !!divider }
  )

  // if we are going to render both title and subtitle, then it's standard!
  // Possible TODO: Establish a Card and StandardCard export pattern where StandardCard sets up Card component layout for you.
  const standardHeader = title && subtitle

  return (
    <div style={style} className={cx}>
      {standardHeader ? (
        <div>
          <Title icon={icon} title={title} />
          <Subtitle subtitle={subtitle} />
        </div>
      ) : (
        <>
          <Title icon={icon} title={title} />
          <Subtitle subtitle={subtitle} />
        </>
      )}
      {children}
    </div>
  )
}

CardHeader.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  divider: PropTypes.bool
}
CardHeader.defaultProps = {
  icon: null,
  title: '',
  subtitle: '',
  children: undefined,
  style: {},
  className: null,
  divider: false
}

export default CardHeader
