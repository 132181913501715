import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class CardActions extends PureComponent {
  render() {
    const cx = cn('furniture-card-actions', {
      [this.props.className]: !!this.props.className
    })

    return (
      <div style={this.props.style} className={cx}>
        {this.props.children}
      </div>
    )
  }
}

CardActions.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
}
CardActions.defaultProps = {
  children: undefined,
  style: {},
  className: null
}

export default CardActions
