import get from 'lodash/get'
import find from 'lodash/find'
import includes from 'lodash/includes'
import { ROLES } from 'constants/roles'

// This file is for utilities generally related to permissions, roles, etc.

/**
 * Return a colon delimited accessible id for a permission
 * @param {Object} permission
 */
function getPermissionCompositeId(permission) {
  return `${permission.accessibleType}:${permission.accessibleId}:${
    permission.access
  }`
}

/**
 * Search to see if the requester has the requested permisson. First
 * check for the explicit grant. If that grant is missing, check
 * for its parent. For example, if the organization-group permission
 * is missing, we would then check to see if the higher-level
 * organization permission is available.
 *
 * @param {Object[]} permissions the requester's permissions
 * @param {Object} grant the grant the requester needs
 * @param {String} grant.type the permission accessible type being checked
 * @param {String} grant.access the level of access needed on the resource (r, w)
 * @param {String[]} grant.params the param list to check in the request. Only
 * the first index of params is checked on each execution. We pop items off of
 * the params list until we find an appropriate permission (or run out of params)
 * @param {Object} ids the ids to be used when checking against accessibleIds
 */
function findPermission(permissions, grant, ids) {
  if (!grant) {
    throw new Error('grant must be specified for findPermission')
  }
  const { type, access, params = [] } = grant
  const accessibleIdParam = get(params, 0, 'organizationId')
  const accessibleId = get(ids, accessibleIdParam, null)

  if (!accessibleId) {
    return false
  }

  // Look for the explicit resource permission
  const permission = find(permissions, {
    accessibleType: type,
    access,
    accessibleId: parseInt(accessibleId, 10)
  })

  if (permission) {
    return true
  }

  const nextParams = params.slice(1)
  if (nextParams.length < 1) {
    return false
  }

  // Break the permission apart by `-` and check the parent permision
  // This regex uses negative lookahead to find the last -section of the grant
  const parentType = type.replace(/(?!.+-)-.+/, '')
  const parentGrant = { type: parentType, access, params: nextParams }
  return findPermission(permissions, parentGrant, ids)
}

/**
 * Return true if the role passed in is 'Engage Super Admin'
 * @param {String} role
 * @returns {Boolean}
 */
function isAdminRole(role) {
  return role === ROLES.ADMIN
}

/**
 * Return true if the role passed in is 'Engage Super Admin'
 * @param {String} role
 * @returns {Boolean}
 */
function isBypassRole(role, bypassRoles) {
  return isAdminRole(role) || includes(bypassRoles, role)
}

export { getPermissionCompositeId, findPermission, isAdminRole, isBypassRole }
