import reducer from 'dux/helpers/reducer'

const initialState = {
  anchorId: null,
  data: {}
}

const HEATMAP_TOOLTIP_EVENT = 'heatmapTooltip/HEATMAP_TOOLTIP_EVENT'

function reduceHeatmapTooltipEvent(state = initialState, action) {
  const { anchorId, data } = action

  if (anchorId !== state.anchorId) {
    return {
      ...state,
      anchorId,
      data
    }
  }

  return state
}

export default reducer(
  {
    [HEATMAP_TOOLTIP_EVENT]: reduceHeatmapTooltipEvent
  },
  initialState
)

function heatmapTooltipEvent(anchorId, data) {
  return {
    type: HEATMAP_TOOLTIP_EVENT,
    anchorId,
    data
  }
}

export { heatmapTooltipEvent }
