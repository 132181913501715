import reducer from 'dux/helpers/reducer'
import {
  createApiCallActionType,
  statuses,
  entities,
  methods,
} from 'dux/api/action_types'
import { SWITCH_ORGANIZATION } from 'dux/organizations'

const PAGE_LIMIT = 50

const initialState = {
  searchText: '',
  sentimentFilter: null,
  surveyTypeFilter: null,
  themeFilter: null,
  v3GroupId: null,
  driverFilter: null,
  pageOptions: {
    rowsPerPage: PAGE_LIMIT,
    page: 0,
    sortBy: 'campaignId',
    sortDirection: 'ASC',
  },
  totalResponseCount: 0,
}

// Constants
const SET_RESPONSE_SEARCH = 'responses/SET_SEARCH'
const SET_SENTIMENT_FILTER = 'responses/SET_SENTIMENT'
const SET_SURVEY_TYPE_FILTER = 'responses/SET_SURVEY_TYPE'
const SET_V3_GROUP_FILTER = 'responses/SET_V3_GROUP'
const SET_DRIVER_FILTER = 'responses/SET_DRIVER'
const SET_THEME_FILTER = 'responses/SET_THEME'
const SET_PAGE_OPTIONS = 'responses/SET_PAGE_OPTIONS'
const FEEDBACK_RESPONSES_GET_SUCCESS = createApiCallActionType(
  entities.FEEDBACK_RESPONSES,
  methods.GET,
  statuses.SUCCESS,
)

// Reducers
function reduceSetSearch(state = initialState, action) {
  return {
    ...state,
    searchText: action.searchText,
  }
}

function reduceSetSentiment(state = initialState, action) {
  return {
    ...state,
    sentimentFilter: action.sentimentFilter,
  }
}

function reduceSetSurveyType(state = initialState, action) {
  return {
    ...state,
    surveyTypeFilter: action.surveyTypeFilter,
  }
}

function reduceSetTheme(state = initialState, action) {
  return {
    ...state,
    themeFilter: action.themeFilter,
  }
}

function reduceSetDriver(state = initialState, action) {
  return {
    ...state,
    driverFilter: action.driverFilter,
  }
}

function reduceSetV3Group(state = initialState, action) {
  return {
    ...state,
    v3GroupId: action.v3GroupId,
  }
}

function reduceSetPageOptions(state = initialState, action) {
  const { pageOptions } = action

  if (!pageOptions) {
    return state
  }

  return {
    ...state,
    pageOptions,
  }
}

function reduceSuccessfulResponseGet(state = initialState, action) {
  if (action.meta) {
    const { count } = action.meta
    return {
      ...state,
      totalResponseCount: count,
    }
  }
  return state
}

function reduceResetResponsesState() {
  return initialState
}

// Combined reducer functions
export default reducer(
  {
    [SET_RESPONSE_SEARCH]: reduceSetSearch,
    [SET_SENTIMENT_FILTER]: reduceSetSentiment,
    [SET_SURVEY_TYPE_FILTER]: reduceSetSurveyType,
    [SET_DRIVER_FILTER]: reduceSetDriver,
    [SET_THEME_FILTER]: reduceSetTheme,
    [SET_V3_GROUP_FILTER]: reduceSetV3Group,
    [SET_PAGE_OPTIONS]: reduceSetPageOptions,
    [FEEDBACK_RESPONSES_GET_SUCCESS]: reduceSuccessfulResponseGet,
    [SWITCH_ORGANIZATION]: reduceResetResponsesState,
  },
  initialState,
)

// Actions
function setResponseSearchText(searchText) {
  return {
    type: SET_RESPONSE_SEARCH,
    searchText,
  }
}

function setSentimentFilter(sentimentFilter) {
  return {
    type: SET_SENTIMENT_FILTER,
    sentimentFilter,
  }
}

function setSurveyTypeFilter(surveyTypeFilter) {
  return {
    type: SET_SURVEY_TYPE_FILTER,
    surveyTypeFilter,
  }
}

function setThemeFilter(themeFilter) {
  return {
    type: SET_THEME_FILTER,
    themeFilter,
  }
}

function setV3GroupFilter(v3GroupId) {
  return {
    type: SET_V3_GROUP_FILTER,
    v3GroupId,
  }
}

function setPageOptions(pageOptions) {
  return {
    type: SET_PAGE_OPTIONS,
    pageOptions,
  }
}

function setDriverFilter(driverFilter) {
  return {
    type: SET_DRIVER_FILTER,
    driverFilter,
  }
}

export {
  setResponseSearchText,
  setSentimentFilter,
  setSurveyTypeFilter,
  setThemeFilter,
  setV3GroupFilter,
  setPageOptions,
  setDriverFilter,
}
