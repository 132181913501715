import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

/**
 * Fetch a campaign-memberships object for a single campaign and v3 group
 * @param {String|Number} Organization ID
 * @param {String|Number} Campaign ID
 * @param {String|Number} V3 Group ID
 * @returns {Promise.<Object>} SUCCESS or FAILURE API action
 */
export function getV3GroupCampaignMembershipsAsync(
  organizationId,
  campaignId,
  groupId,
  options = {}
) {
  const params = { organizationId, campaignId, groupId }
  const opts = {
    ...options,
    entity: 'v3-campaign-memberships'
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/v3-groups/:groupId/campaign-memberships',
    params,
    opts
  )
}

export function getCampaignMembershipsAsync(organizationId, campaignId) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/campaign-memberships',
    params
  )
}
