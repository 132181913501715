import dayjs from 'dayjs'
import dropRight from 'lodash/dropRight'
import fill from 'lodash/fill'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import last from 'lodash/last'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import orderBy from 'lodash/orderBy'
import reverse from 'lodash/reverse'
import slice from 'lodash/slice'
import sortBy from 'lodash/sortBy'
import times from 'lodash/times'
import values from 'lodash/values'
import { createSelector } from 'reselect'
import { takeLast } from 'utils/native/array'
import {
  formatDateForCampaignUpperCase,
  formatLongCampaignDate,
  formatShortCampaignDate,
} from 'utils/formatter'
import { isFetchingCampaigns } from 'selectors/requests'
import CampaignTypes from 'constants/campaign_types'
import { CAMPAIGN_STATUSES } from 'constants/campaigns'
import {
  createEntityGetter,
  createPropGetter,
  createPhaseSelector,
} from 'utils/selectors'
import { entities, methods } from 'dux/api/action_types'
import {
  selectHasOrganizationResultsReadPermission,
  selectPermittedCampaignReportIds,
} from 'selectors/user'
import { selectSmartPulseOrganizationFiles } from 'selectors/smart_pulse'
import { selectLegacyCustomCampaignOrganizationFiles } from 'selectors/legacy_custom_campaigns'
import { buildCampaignResultId } from 'utils/emplify/campaign_results'
import { getEngageSurveyMajorVersion } from 'utils/surveys'

const MAGIC_CAMPAIGN_LIMIT = 4
const EMPTY_OBJECT = {}

// helpers
function generateEmptyCampaignQuarters(
  date = Date.now(),
  index = MAGIC_CAMPAIGN_LIMIT,
  extendFromDate = false,
  handleMissedDate = false,
) {
  let startDate = date
  if (handleMissedDate && Date.now() > new Date(date)) {
    startDate = Date.now()
  }

  // Generate `index` formatted labels from `date` in 3 month increments
  const quarters = times(index, (n) => {
    const number = extendFromDate ? n + 1 : n
    return dayjs(startDate)
      .add(number * 3, 'months')
      .valueOf()
  })

  // Fill the beginning of array with empty values
  times(MAGIC_CAMPAIGN_LIMIT - index, () => quarters.unshift(''))

  return quarters
}

/**
 * Gets an associative array of all campaigns
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of all campaigns.
 */
export function getAllCampaigns(state) {
  return state.entities.campaigns || EMPTY_OBJECT
}

export function selectTimeTravelCampaignId(state) {
  return state.results.campaignId || null
}

export const selectTimeTravelCampaign = createSelector(
  createEntityGetter(entities.CAMPAIGNS),
  selectTimeTravelCampaignId,
  (campaigns, anchorCampaignId) => get(campaigns, [anchorCampaignId]),
)

export function getSurveyToAdd(state) {
  return state.campaigns.surveyToAdd || EMPTY_OBJECT
}

export function selectShowCampaignValidationMessage(state) {
  return state.campaigns.showCampaignValidationMessage || false
}

export const getAllCampaignsArray = createSelector(getAllCampaigns, values)

function getCurrentOrganizationId(state, props = {}) {
  const orgId =
    props.organizationId || state.organizations.currentOrganizationId
  if (!orgId) {
    return null
  }
  return parseInt(orgId, 10)
}

function getSelectedCampaignIdFromProps(state, props = {}) {
  const { campaign, campaignId } = props
  return campaign ? campaign.id : campaignId
}

function getSelectedCampaignIdFromParams(state, props = {}) {
  return get(props, 'match.params.id') || null
}

function getParentCampaignId(state, props = {}) {
  return props.parentCampaignId || EMPTY_OBJECT
}

export const selectCampaignsPhase = createPhaseSelector(entities.CAMPAIGNS)

export const selectCampaignPhaseByCampaignType = createPhaseSelector({
  entity: entities.CAMPAIGNS,
  method: methods.GET,
  mapProps: ({ organizationId }) => {
    return {
      organizationId,
      'filter[campaignType]': true,
    }
  },
})

export const selectSelectedCampaignId = createSelector(
  getSelectedCampaignIdFromProps,
  getSelectedCampaignIdFromParams,
  (idFromComponent, idFromParams) => idFromComponent || idFromParams,
)

/**
 * Select the campaign object for currently selected campaign
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - the campaign object
 */
export const selectSelectedCampaign = createSelector(
  getAllCampaigns,
  selectSelectedCampaignId,
  (campaigns, selectedCampaignId) =>
    campaigns[selectedCampaignId] || EMPTY_OBJECT,
)

export const selectCampaignById = createSelector(
  getAllCampaigns,
  createPropGetter('campaignId'),
  (campaigns, campaignId) => campaigns[campaignId] || EMPTY_OBJECT,
)

/**
 * Gets an associative array of all campaigns with the provided survey type.
 * @param {ApiCampaignsDict} campaigns The campaigns to reduce.
 * @param {String} campaignType The survey type id.
 * @returns {ApiCampaignsDict} An associative array of all campaigns with the provided survey type
 */
const getCampaignsByType = (campaigns, campaignType) => {
  return keyBy(
    filter(campaigns, (campaign) => campaign.campaignType === campaignType),
    'id',
  )
}

/**
 * Gets an associative array of all quarterly campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of quarterly campaigns.
 */
export const getQuarterlyCampaigns = createSelector(
  getAllCampaigns,
  (campaigns) => getCampaignsByType(campaigns, CampaignTypes.QUARTERLY),
)

/**
 * Returns a collection of unfinished quarterly campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of quarterly campaigns.
 */
export const getInProgressQuarterlyCampaigns = createSelector(
  getQuarterlyCampaigns,
  (campaigns) =>
    filter(
      campaigns,
      (campaign) => campaign.status === CAMPAIGN_STATUSES.INPROGRESS,
    ),
)

/**
 * Get the current organization's in progress quarterly campaign, if one exists.
 * @param {Object} state Entire redux state tree
 * @returns {Object} The "latest" in progress quarterly campaign
 *  (We really expect there to ever be only one)
 */
export const selectInProgressQuarterlyCampaign = createSelector(
  getInProgressQuarterlyCampaigns,
  (campaigns = []) => {
    if (campaigns.length === 0) {
      return undefined
    }
    if (campaigns.length === 1) {
      return campaigns[0]
    }
    return orderBy(campaigns, ['startedAt'], ['desc'])[0]
  },
)

/**
 * Gets an array of all quarterly campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaign[]} An array of all quarterly campaigns.
 */
export const getQuarterlyCampaignsArray = createSelector(
  getQuarterlyCampaigns,
  values,
)

/**
 * Gets an associative array of all topic based campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of all smart pulse campaigns.
 */
export const selectTopicBasedCampaigns = createSelector(
  getAllCampaigns,
  (campaigns) => getCampaignsByType(campaigns, CampaignTypes.TOPIC_BASED),
)

/**
 * Gets an associative array of all smart pulse campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of all smart pulse campaigns.
 */
export const getSmartPulseCampaigns = createSelector(
  getAllCampaigns,
  (campaigns) => getCampaignsByType(campaigns, CampaignTypes.SMART_PULSE),
)

/**
 * Gets an associative array of all custom campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaignsDict} An associative array of all smart pulse campaigns.
 */
export const getCustomCampaigns = createSelector(getAllCampaigns, (campaigns) =>
  getCampaignsByType(campaigns, CampaignTypes.CUSTOM),
)

/**
 * Gets an array of all smartpulse campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaign[]} An array of all quarterly campaigns.
 */
export const getSmartPulseCampaignsArray = createSelector(
  getSmartPulseCampaigns,
  values,
)

/**
 * Gets an array of all finished smartpulse campaigns.
 * @param {Object} state The redux store state
 * @returns {ApiCampaign[]} An array of all finished smart pulse campaigns.
 */
export const selectPermittedFinishedSmartPulseCampaigns = createSelector(
  getSmartPulseCampaignsArray,
  selectHasOrganizationResultsReadPermission,
  selectPermittedCampaignReportIds,
  function filterPermittedFinishedSmartPulseCampaigns(
    campaigns,
    hasOrgReadPermission,
    permittedCampaignIds,
  ) {
    // Admins or Org Read users
    if (hasOrgReadPermission) {
      return filter(campaigns, { status: CAMPAIGN_STATUSES.FINISHED })
    }

    // Leaders with campaign permissions
    return filter(
      campaigns,
      (c) =>
        c.status === CAMPAIGN_STATUSES.FINISHED &&
        c.isPublished &&
        permittedCampaignIds.find((id) => `${id}` === `${c.id}`),
    )
  },
)

/**
 * Gets an associative array of campaigns.
 * @param {Object} state The redux store state
 * @param {Object} [props] The props.
 * @param {CampaignTypes} [props.campaignType=SurveyType.QUARTERLY] The campaign type.
 * @returns {ApiCampaignsDict} An associative array of campaigns.
 */
export function getCampaigns(state, props = {}) {
  const { campaignType = null } = props

  if (campaignType === CampaignTypes.ALL) {
    return getAllCampaigns(state)
  }

  if (campaignType === CampaignTypes.QUARTERLY_AND_TOPIC_BASED) {
    const topicBased = selectTopicBasedCampaigns(state)
    const quarterly = getQuarterlyCampaigns(state)
    return { ...quarterly, ...topicBased }
  }

  if (campaignType === CampaignTypes.SMART_PULSE) {
    return getSmartPulseCampaigns(state)
  }

  if (campaignType === CampaignTypes.CUSTOM) {
    return getCustomCampaigns(state)
  }

  return getQuarterlyCampaigns(state)
}

/**
 * Gets an array of campaigns.
 * @param {Object} state The redux store state
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {ApiCampaignsDict} An array of campaigns.
 */
export const getCampaignsArray = createSelector(getCampaigns, values)

/**
 * Return a collection of campaigns for the currently selected surveyType and
 * organization. Returns the campaigns in a sorted order by start date. If parentCampaignId
 * is provided, filter only for that parent.
 *
 * @param {Object} state - entire Redux state tree
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {Object[]} - a collection of quarterly campaigns
 */
export const selectCampaignHistory = createSelector(
  getCampaignsArray,
  getCurrentOrganizationId,
  getParentCampaignId,
  (campaignsArray, currentOrganizationId, parentCampaignId) => {
    let filteredCampaigns = campaignsArray.filter(
      (campaign) => `${campaign.organizationId}` === `${currentOrganizationId}`,
    )
    if (!isEmpty(parentCampaignId)) {
      filteredCampaigns = filteredCampaigns.filter(
        (campaign) => campaign.parentCampaignId === Number(parentCampaignId),
      )
    }
    return orderBy(filteredCampaigns, ['startedAt', 'id'], ['desc', 'desc'])
  },
)

/**
 * Select the latest campaign for the current organization
 * @param {Object} state - entire Redux state tree
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {String} - the id of the latest campaign for the org for the given survey type
 */
export const selectLatestCampaignForCurrentOrg = createSelector(
  selectCampaignHistory,
  (campaigns) => {
    return campaigns[0] || EMPTY_OBJECT
  },
)

/**
 * Select the latest, finished campaign for the current organization
 * @param {Object} state - entire Redux state tree
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {String} - the id of the latest campaign for the org for the given survey type
 */
export const selectLatestFinishedCampaignForCurrentOrg = createSelector(
  selectCampaignHistory,
  (campaigns) =>
    find(campaigns, ['status', CAMPAIGN_STATUSES.FINISHED]) || EMPTY_OBJECT,
)

/**
 * Selects an array of the latest finished and any newer campaigns
 * @param {Object} state - entire Redux state tree
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {Object[]} - array of campaigns
 */
export const selectLatestFinishedAndNewCampaigns = createSelector(
  selectCampaignHistory,
  (campaigns) => {
    const firstFinishedIndex = findIndex(campaigns, [
      'status',
      CAMPAIGN_STATUSES.FINISHED,
    ])
    const finishedAndNewer = slice(campaigns, 0, firstFinishedIndex + 1)
    return finishedAndNewer
  },
)

/**
 * Selects an array of ids for campaigns that can be snapshotted
 * @param {Object} state - entire Redux state tree
 * @param {Object} [props] The props.
 * @param {CampaignTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {String[]} - array of campaigns
 */
export const selectSnapshotEnabledCampaigns = createSelector(
  selectCampaignHistory,
  createPropGetter('campaignType'),
  (allCampaignsHistory, campaignType) => {
    const snapshotEnabledCampaigns = new Set()

    if (
      allCampaignsHistory.length === 0 ||
      campaignType !== CampaignTypes.QUARTERLY
    ) {
      return snapshotEnabledCampaigns
    }

    allCampaignsHistory.forEach((campaign) => {
      if (campaign.status === CAMPAIGN_STATUSES.FINISHED) {
        snapshotEnabledCampaigns.add(campaign.id)
      }
    })

    return snapshotEnabledCampaigns
  },
)

export const selectReopenEnabledCampaigns = createSelector(
  selectLatestFinishedAndNewCampaigns,
  createPropGetter('campaignType'),
  (latestFinishedAndNew, campaignType) => {
    const reopenEnabledCampaigns = new Set()

    if (
      latestFinishedAndNew.length === 0 ||
      campaignType !== CampaignTypes.QUARTERLY
    ) {
      return reopenEnabledCampaigns
    }

    const latestFinishedCampaign = last(latestFinishedAndNew)
    const newCampaigns = dropRight(latestFinishedAndNew)

    if (!find(newCampaigns, (c) => c.status === CAMPAIGN_STATUSES.INPROGRESS)) {
      reopenEnabledCampaigns.add(latestFinishedCampaign.id)
    }

    return reopenEnabledCampaigns
  },
)

/**
 * Hydrates campaign results into campaigns
 * @param {Object} state The redux store state
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {ApiCampaigns[]} An array of campaigns with organization results.
 */
const selectHydratedCampaigns = createSelector(
  getCampaigns,
  createEntityGetter(entities.CAMPAIGN_RESULTS),
  createPropGetter('attributeFilters'),
  createPropGetter('groupId'),
  (campaigns, campaignResults, attributeFilters, groupId) => {
    return mapValues(campaigns, function hydrate(campaign) {
      let filterAttributes = attributeFilters
      if (groupId && groupId !== 'organization') {
        filterAttributes = null
      }
      const campaignResult =
        campaignResults[buildCampaignResultId(campaign.id, filterAttributes)]
      return { ...campaign, campaignResult }
    })
  },
)

/**
 * Gets campaigns for the organization
 * @param {*} state The redux store state
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.surveyType=SurveyType.QUARTERLY] The survey type.
 * @returns {ApiCampaigns[]} An array of campaigns with organization results.
 */
export const selectCampaignsArray = createSelector(
  getCurrentOrganizationId,
  selectHydratedCampaigns,
  function getCampaignsArrayForOrg(orgId, campaigns) {
    if (!orgId) {
      return []
    }
    return values(campaigns).filter(
      (campaign) => campaign.organizationId === orgId,
    )
  },
)

/**
 * Gets the finished and scored campaigns
 * @param {*} state The redux store state
 * @param {Object} [props] The props.
 * @param {SurveyTypes} [props.campaignType=SurveyType.QUARTERLY] The survey type.
 * @returns {ApiCampaigns[]} An array of campaigns with organization results.
 */
const selectFinishedAndScoredCampaigns = createSelector(
  selectCampaignsArray,
  createPropGetter('includeAllFinishedCampaigns'),
  createPropGetter('includeTargetedCampaigns'),
  (
    campaigns,
    includeAllFinishedCampaigns = true, // should only be false for Action Planning
    includeTargetedCampaigns = true,
  ) =>
    filter(campaigns, (campaign) => {
      const isFinished = campaign.status === CAMPAIGN_STATUSES.FINISHED

      // This score check should only apply to Action Planning.
      const hasEmplifyScore =
        has(campaign, 'campaignResult.emplifyScore') ||
        includeAllFinishedCampaigns

      const isValidTargetedType =
        (includeTargetedCampaigns && campaign.isTargeted) ||
        !campaign.isTargeted

      return isFinished && hasEmplifyScore && isValidTargetedType
    }),
)

export const selectChildCampaigns = createSelector(
  getAllCampaignsArray,
  getParentCampaignId,
  (campaigns, parentCampaignId) =>
    campaigns.filter(
      (campaign) => campaign.parentCampaignId === Number(parentCampaignId),
    ),
)

export const selectCampaignsFilteredByTarget = createSelector(
  selectFinishedAndScoredCampaigns,
  selectTimeTravelCampaign,
  (allCampaigns, anchorCampaign) => {
    if (!anchorCampaign) {
      return allCampaigns
    }

    // Return campaigns whose campaign targets match that of the anchor campaign
    return filter(
      allCampaigns,
      (c) => c.targetContext === get(anchorCampaign, 'targetContext', null),
    )
  },
)

/**
 * Select campaigns ordered by startedAt.
 * @returns {Array} Campaigns ordered by startedAt
 */
export const selectOrderedCampaigns = createSelector(
  selectCampaignsFilteredByTarget,
  selectTimeTravelCampaignId,
  function orderCampaigns(campaignsArray, anchorCampaignId) {
    const orderedArray = sortBy(campaignsArray, 'startedAt')

    if (!anchorCampaignId) {
      return orderedArray
    }

    const indexOfAnchorCampaign = orderedArray.findIndex(
      (campaign) => `${campaign.id}` === `${anchorCampaignId}`,
    )
    if (indexOfAnchorCampaign > -1) {
      return slice(orderedArray, 0, indexOfAnchorCampaign + 1)
    }

    return []
  },
)

export const selectOrderedCampaignsForEngagementFeedback = createSelector(
  selectOrderedCampaigns,
  function filterCampaigns(campaignArray) {
    return reverse(
      filter(
        campaignArray,
        (campaign) => dayjs(campaign.startedAt) > dayjs('2019-09-19'),
      ),
    )
  },
)

export const selectOrderedRecentCampaigns = createSelector(
  selectOrderedCampaigns,
  (campaigns) => takeLast(campaigns, 4),
)

/**
 * Selects campaign Ids ordered by startedAt.
 * @returns {Array} Campaign Ids orderd by the campaign's started at date
 */
export const selectOrderedCampaignIds = createSelector(
  selectOrderedCampaigns,
  (orderedCampaigns) => orderedCampaigns.map((campaign) => campaign.id),
)

/**
 * Select up to 4 of the most recent campaigns.
 * They are in descending order of recency - most recent first.
 * @param {Object} state - The entire redux state tree
 * @returns {Array<String>} - Four most recent campaign ID, most recent first
 */
export const selectMostRecentCampaignIds = createSelector(
  selectOrderedCampaignIds,
  (ids) => {
    const copy = Array.from(ids) // Do not mutate ordered campaign IDs or there WILL be bugs
    return takeLast(copy, 4).reverse()
  },
)

export const selectLatestCampaignId = createSelector(
  selectOrderedCampaignIds,
  function getLatestCampaignId(campaignIds) {
    if (!campaignIds || campaignIds.length < 1) {
      return null
    }
    return campaignIds[campaignIds.length - 1]
  },
)

export const selectLatestCampaign = createSelector(
  selectFinishedAndScoredCampaigns,
  selectLatestCampaignId,
  (campaigns, latestCampaignId) => {
    return (
      find(
        campaigns,
        (campaign) => `${campaign.id}` === `${latestCampaignId}`,
      ) || {}
    )
  },
)

/**
 * If there is an in progress campaign, returns the ID of that campaign.
 * Otherwise, returns the ID of the most recent quarterly campaign.
 * @param {Object} state Entire redux state tree
 * @returns {String} Campaign ID
 */
export const selectCurrentCampaignId = createSelector(
  selectLatestCampaignId,
  selectInProgressQuarterlyCampaign,
  (finishedCampaignId, inProgressCampaign = {}) => {
    return inProgressCampaign.id || finishedCampaignId || null
  },
)

/**
 * Select the ID of the previous quarterly campaign
 * @param {Object} state Entire redux state tree
 * @returns {Number} ID of previous campaign
 */
export const selectPreviousCampaignId = createSelector(
  selectOrderedCampaignIds,
  function getPreviousCampaignId(campaignIds) {
    if (!campaignIds || campaignIds.length < 2) {
      return null
    }
    return campaignIds[campaignIds.length - 2]
  },
)

export const selectLatestFinishedAndScoredCampaign = createSelector(
  selectOrderedCampaigns,
  function getLatestCampaign(orderedCampaigns) {
    if (!orderedCampaigns || orderedCampaigns.length < 1) {
      return null
    }
    return orderedCampaigns[orderedCampaigns.length - 1]
  },
)

export const selectPreviousFinishedAndScoredCampaign = createSelector(
  selectOrderedCampaigns,
  function getPreviousCampaign(orderedCampaigns) {
    if (!orderedCampaigns || orderedCampaigns.length < 2) {
      return null
    }

    return orderedCampaigns[orderedCampaigns.length - 2]
  },
)

export function getCampaignName(state, { campaignId } = {}) {
  const campaign = state.entities.campaigns[campaignId]
  if (!campaign || !campaign.startedAt) {
    return ''
  }
  return formatDateForCampaignUpperCase(campaign.startedAt)
}

export const selectLongCampaignName = createSelector(
  getAllCampaigns,
  getSelectedCampaignIdFromProps,
  (campaigns, campaignId) => {
    const currentCampaign = find(
      campaigns,
      (campaign) => `${campaign.id}` === `${campaignId}`,
    )
    return currentCampaign
      ? formatLongCampaignDate(currentCampaign.startedAt)
      : undefined
  },
)

export const selectCampaignQuarters = createSelector(
  isFetchingCampaigns,
  selectOrderedCampaigns,
  function getQuarters(fetchingCampaigns, orderedCampaigns) {
    // Calculate number of empty quarters needed
    let emptyQuarters = MAGIC_CAMPAIGN_LIMIT - orderedCampaigns.length

    // If any empty quarters are needed, generate the labels
    if (
      emptyQuarters > 0 &&
      emptyQuarters < MAGIC_CAMPAIGN_LIMIT &&
      orderedCampaigns.length
    ) {
      emptyQuarters = generateEmptyCampaignQuarters(
        orderedCampaigns[orderedCampaigns.length - 1].startedAt,
        emptyQuarters + 1,
      )
    } else if (fetchingCampaigns) {
      emptyQuarters = fill(Array(MAGIC_CAMPAIGN_LIMIT), null)
    } else {
      emptyQuarters = generateEmptyCampaignQuarters()
    }

    return merge(
      [],
      emptyQuarters,
      orderedCampaigns.map((campaign) => Date.parse(campaign.startedAt)),
    )
  },
)

/**
 * Similar to selectCampaignQuarters, but only returns the 4 most recent campaigns
 */
export const selectRecentCampaignQuarters = createSelector(
  selectCampaignQuarters,
  (quarters) => takeLast(quarters, 4),
)

export const selectSelectedCampaignMessages = createSelector(
  createEntityGetter('messages'),
  selectSelectedCampaignId,
  (messages, campaignId) =>
    filter(messages, (message) => `${message.campaignId}` === `${campaignId}`),
)

export const selectSelectedCampaignMessagesWithTemplateId = createSelector(
  createEntityGetter('emails'),
  createEntityGetter('sms'),
  selectSelectedCampaignMessages,
  (emails, sms, messages) =>
    map(messages, (message) => {
      const messageableType = message.messageableType
      const messageableId = message.messageableId
      let templateId
      if (messageableType === 'emails') {
        templateId = emails[messageableId].templateId
      }
      if (messageableType === 'sms') {
        templateId = sms[messageableId].templateId
      }
      return { ...message, templateId }
    }),
)

export const selectCampaignParticipations = createSelector(
  createEntityGetter('campaign-participations'),
  (participations) => participations,
)

export const selectCampaignParticipationByCampaignId = createSelector(
  selectCampaignParticipations,
  createPropGetter('campaignId'),
  (participations, campaignId) => {
    return filter(participations, (participation) => {
      return `${participation.campaignId}` === `${campaignId}`
    })
  },
)

/**
 * Get the approiate campaign create link for a given surveyType
 * @param {Object} state The redux store state
 * @param {Object} [props] campaignType to generate link for
 * @returns {String} The link for the appropriate campaign create page
 */
export const selectNewCampaignLink = createSelector(
  createPropGetter('campaignType'),
  function getNewCampaignLink(campaignType) {
    if (campaignType === CampaignTypes.CUSTOM) {
      return '/new_custom_campaign'
    }

    return '/campaigns/new'
  },
)

/**
 * Select the statements for the specified campaign
 *
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps.campaign - the campaign we want to select statements for
 * @returns {Object} - the statements for the specified campaign
 */
export const selectSelectedCampaignStatements = createSelector(
  createEntityGetter(entities.CAMPAIGN_STATEMENTS),
  selectSelectedCampaignId,
  (statements, selectedCampaignId) =>
    filter(
      statements,
      (statement) => `${statement.campaignId}` === `${selectedCampaignId}`,
    ),
)

/**
 * Select the survey for the specified campaign
 *
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps.campaign - the campaign we want to select survey for
 * @returns {Object} - the survey for the specified campaign
 */
export const selectSelectedCampaignLegacySurvey = createSelector(
  selectSelectedCampaign,
  createEntityGetter(entities.SURVEYS),
  (campaign, surveys) => get(surveys, [campaign.legacySurveyId]),
)

/**
 * TODO: Rename
 * Detect if this is the organization's first 4.x survey if they have more than one survey.
 * Note: If the org has exactly one survey and it is 4.x, this will return FALSE.
 */
export const selectIsFirstFourOhSurvey = createSelector(
  [
    selectOrderedCampaigns,
    createEntityGetter(entities.SURVEYS),
    createEntityGetter(entities.CAMPAIGN_SURVEYS),
  ],
  (campaigns, surveys, campaignSurveys) => {
    if (!campaigns || campaigns.length <= 1) {
      return false
    }

    if (!surveys) {
      return false
    }

    const orderedSurveyVersions = campaigns
      .map((c) => getEngageSurveyMajorVersion(c, surveys, campaignSurveys))
      .filter((v) => v !== '')
    const count = orderedSurveyVersions.length

    const current = orderedSurveyVersions[count - 1]
    const previous = orderedSurveyVersions[count - 2]

    return `${current}` === '4' && `${previous}` !== '4'
  },
)

/**
 * Selects whether or not a campaign is publishable. It must be a SmartPulse or Custom
 * campaign and not target the entire org.
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps.campaign - the campaign we want to select survey for
 * @returns {Bool} - true if campaign isPublishable
 */
export const selectSelectedCampaignIsPublishable = createSelector(
  selectSelectedCampaign,
  (campaign) => {
    const validTypes = [CampaignTypes.SMART_PULSE, CampaignTypes.CUSTOM]
    return !!(
      (campaign.groupId || campaign.v3GroupId) &&
      campaign.campaignType &&
      validTypes.includes(campaign.campaignType)
    )
  },
)

export const selectMostRecentFinishedQuarterlyCampaigns = createSelector(
  getQuarterlyCampaigns,
  (allQuarterlyCampaigns) => {
    // First sort descending so that we can slice the top 4 most recent campaigns
    const finishedQuarterlyCampaigns = orderBy(
      filter(
        allQuarterlyCampaigns,
        (c) => c.status === CAMPAIGN_STATUSES.FINISHED,
      ),
      ['endedAt'],
      'desc',
    )
      // Select up to 4 recently finished campaigns
      .slice(0, 4)

    // Campaigns will be sorted with the oldest campaign at the 0-index and the newest at the last-index
    return orderBy(finishedQuarterlyCampaigns, ['endedAt'], ['asc'])
  },
)

export const selectSmartPulseCampaignsWithOrgFiles = createSelector(
  createEntityGetter(entities.CAMPAIGNS),
  selectSmartPulseOrganizationFiles,
  (campaigns, files) => {
    const parentCampaigns = filter(
      campaigns,
      (c) =>
        c.parentCampaignId === null &&
        c.status === CAMPAIGN_STATUSES.FINISHED &&
        c.campaignType === CampaignTypes.QUARTERLY,
    )

    const filteredCampaignsByType = filter(
      campaigns,
      (campaign) =>
        campaign.campaignType === CampaignTypes.SMART_PULSE &&
        campaign.status === CAMPAIGN_STATUSES.FINISHED,
    )

    const filteredCampaignsWithFiles = map(
      filteredCampaignsByType,
      (campaign) => {
        return {
          ...campaign,
          file: find(files, (f) => `${f.campaignId}` === `${campaign.id}`),
        }
      },
    )

    const parentCampaignsWithCampaignsAndFiles = map(
      parentCampaigns,
      (campaign) => {
        return {
          ...campaign,
          childCampaigns: filter(
            filteredCampaignsWithFiles,
            (c) => `${c.parentCampaignId}` === `${campaign.id}`,
          ),
        }
      },
    )

    return orderBy(parentCampaignsWithCampaignsAndFiles, ['endedAt'], ['desc'])
  },
)

export const selectLegacyCustomCampaignsWithOrgFiles = createSelector(
  createEntityGetter(entities.CAMPAIGNS),
  selectLegacyCustomCampaignOrganizationFiles,
  (campaigns, files) => {
    const customCampaigns = filter(
      campaigns,
      (c) =>
        c.campaignType === CampaignTypes.LEGACY_CUSTOM &&
        c.status === CAMPAIGN_STATUSES.FINISHED,
    )

    const customCampaignsWithFiles = map(customCampaigns, (cc) => {
      return {
        ...cc,
        file: find(files, (f) => `${f.campaignId}` === `${cc.id}`),
      }
    })

    return orderBy(customCampaignsWithFiles, ['endedAt'], ['desc'])
  },
)

export const selectCampaignConfidentiality = createSelector(
  selectSelectedCampaign,
  (campaign) => campaign.confidentialityLevel,
)
