import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

export function getV3GroupParticipationsAsync(
  organizationId,
  campaignId,
  options = {}
) {
  const params = { organizationId, campaignId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaigns/:campaignId/v3-group-participations',
    params,
    options
  )
}

/**
 * Fetch participation for a single group in a campaign
 * @param {String|Number} organizationId
 * @param {String|Number} campaignId
 * @param {String|Number} groupId
 * @returns {Promise.<Object>} Resolves to the result of the api call
 */
export function getV3GroupParticipationForCampaignAsync(
  organizationId,
  campaignId,
  groupId,
  options = {}
) {
  const url =
    '/organizations/:organizationId/campaigns/:campaignId/v3-groups/:groupId/v3-group-participations'
  const params = { organizationId, campaignId, groupId }
  return ApiCallThunkCreator.get(url, params, options)
}
