import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { Button, BUTTON_RANKS } from 'furniture/buttons'
import { Card } from 'furniture/card'
import { Heading } from 'furniture/typography'
import { Div } from 'components/elements'
import { RowExpandIcon } from 'components/icons'
import Resize from 'components/resize'

const styles = {
  iconStyleExpanded: { transform: 'rotate(180deg)' },
  iconStyle: {},
}

const RESIZE_REFRESH_RATE = 42 // milliseconds

export const TOGGLE_POSITIONS = {
  RIGHT: 'right',
  BOTTOM: 'bottom',
}

function Toggle({
  className,
  expandButton,
  iconStyle,
  onClick,
  toggleLabel,
  dataStaticId,
}) {
  const cx = cn('expansion-panel-toggle', { [className]: !!className })

  if (expandButton) {
    return <div className={cx}>{expandButton}</div>
  }
  return (
    <Button
      className={cx}
      onClick={onClick}
      label={toggleLabel || null}
      icon={<RowExpandIcon />}
      rank={BUTTON_RANKS.TERTIARY}
      style={iconStyle}
      dataStaticId={dataStaticId}
    />
  )
}

Toggle.propTypes = {
  className: PropTypes.string,
  expandButton: PropTypes.node,
  iconStyle: PropTypes.object,
  onClick: PropTypes.func,
  toggleLabel: PropTypes.string,
  dataStaticId: PropTypes.string,
}
Toggle.defaultProps = {
  className: null,
  expandButton: undefined,
  iconStyle: undefined,
  onClick: undefined,
  toggleLabel: null,
  dataStaticId: null,
}

/**
 * An expandable panel that can display a preview when collapsed.
 * This can manage it's own expanded / collapsed state.
 * However, if you provide an onToggle prop, then the expanded / collapsed state is controlled
 * via the isExpanded property.
 */
function ExpansionPanel({
  summary,
  children,
  headline,
  isExpanded,
  onToggle,
  managedToggle,
  style,
  className,
  expandButton,
  useCard,
  toggleClassName,
  toggleLabel,
  showError,
  disabled,
  dataStaticId,
  togglePosition,
  autoHideSummary,
}) {
  const [expanded, setExpanded] = useState(false)
  const [height, setHeight] = useState('auto')

  // Source of truth depends on if caller is managing or if this is self-managed
  const managedIsExpanded = managedToggle ? isExpanded : expanded

  const onResize = ({ height: newHeight }) => {
    setHeight(newHeight)
  }

  const toggle = () => {
    if (onToggle) {
      onToggle(!managedIsExpanded)
    }

    if (!managedToggle) {
      setExpanded(!managedIsExpanded)
    }
  }

  const cx = cn(
    'furniture-expansion-panel',
    { expanded: managedIsExpanded },
    { [className]: !!className },
  )

  let childrenToDisplay = null
  if (children && managedIsExpanded) {
    childrenToDisplay = children
  }

  const headlineClasses = cn(
    'expansion-panel-headline',
    {
      'with-content': (!managedIsExpanded && !!summary) || !!childrenToDisplay,
    },
    { 'without-children': !children },
    { disabled },
  )

  const iconStyle = managedIsExpanded
    ? styles.iconStyleExpanded
    : styles.iconStyle

  const Wrapper = useCard ? Card : Div

  const hideSummary = headline && managedIsExpanded && autoHideSummary

  return (
    <Wrapper style={style} className={cx}>
      <div style={{ height }}>
        <Resize refreshRate={RESIZE_REFRESH_RATE} onResize={onResize}>
          {headline && (
            <div
              className={headlineClasses}
              onClick={disabled ? null : toggle}
              role='button'
            >
              {headline && <Heading cardSection>{headline}</Heading>}
              <div className='flex flex-row justify-between items-center'>
                {showError && (
                  <FontAwesomeIcon icon={faExclamationCircle} error />
                )}
                {!disabled && togglePosition === TOGGLE_POSITIONS.RIGHT && (
                  <Toggle
                    className={toggleClassName}
                    expandButton={expandButton}
                    iconStyle={iconStyle}
                    onClick={toggle}
                    toggleLabel={toggleLabel}
                    dataStaticId={dataStaticId}
                  />
                )}
              </div>
            </div>
          )}
          <div className='expansion-panel-summary'>
            {!hideSummary && summary}
            {(!headline || togglePosition === TOGGLE_POSITIONS.BOTTOM) && (
              <Toggle
                className={toggleClassName}
                expandButton={expandButton}
                onClick={toggle}
                iconStyle={iconStyle}
                toggleLabel={toggleLabel}
                dataStaticId={dataStaticId}
              />
            )}
          </div>
          {childrenToDisplay}
        </Resize>
      </div>
    </Wrapper>
  )
}

ExpansionPanel.propTypes = {
  summary: PropTypes.node, // must be React element
  children: PropTypes.node,
  headline: PropTypes.string,
  isExpanded: PropTypes.bool,
  onToggle: PropTypes.func,
  managedToggle: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  expandButton: PropTypes.node,
  useCard: PropTypes.bool,
  toggleClassName: PropTypes.string,
  toggleLabel: PropTypes.string,
  showError: PropTypes.bool,
  disabled: PropTypes.bool,
  dataStaticId: PropTypes.string,
  togglePosition: PropTypes.oneOfType(Object.values(TOGGLE_POSITIONS)),
  autoHideSummary: PropTypes.bool,
}
ExpansionPanel.defaultProps = {
  summary: null,
  children: null,
  headline: null,
  isExpanded: undefined,
  onToggle: undefined,
  managedToggle: false,
  style: {},
  className: null,
  expandButton: null,
  useCard: true,
  toggleClassName: null,
  toggleLabel: null,
  showError: false,
  disabled: false,
  dataStaticId: null,
  togglePosition: TOGGLE_POSITIONS.RIGHT,
  autoHideSummary: true,
}

export default ExpansionPanel
