import { createSelector } from 'reselect'

import get from 'lodash/get'
import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import values from 'lodash/values'

import { createEntityGetter } from 'utils/selectors'
import { entities } from 'dux/api/action_types'
import { SURVEY_TYPES, FEEDBACK_TYPE_NAMES } from 'constants/survey_types'
import { selectSelectedCampaign } from './campaigns'

export const selectHydratedCampaignSurveys = createSelector(
  createEntityGetter(entities.CAMPAIGN_SURVEYS),
  createEntityGetter(entities.SURVEYS),
  createEntityGetter(entities.SURVEY_TYPES),
  createEntityGetter(entities.SURVEY_STATEMENTS),
  (campaignSurveys, surveys, surveyTypes, surveyStatements) => {
    return map(campaignSurveys, campaignSurvey => {
      const survey = get(surveys, [campaignSurvey.surveyId])
      const surveyType = get(surveyTypes, [campaignSurvey.surveyTypeId])

      return {
        ...campaignSurvey,
        surveyTypeName: get(surveyType, 'name'),
        surveyName: get(survey, 'name'),
        surveyVersion: get(survey, 'version'),
        surveyStatements: filter(
          surveyStatements,
          ss => `${ss.surveyId}` === `${campaignSurvey.surveyId}`
        ),
        hasBeenSent: get(survey, 'hasBeenSent'),
        organizationId: get(survey, 'organizationId')
      }
    })
  }
)

export const selectHydratedCampaignSurveysForCampaign = createSelector(
  selectSelectedCampaign,
  selectHydratedCampaignSurveys,
  createEntityGetter(entities.SURVEYS),
  createEntityGetter(entities.SURVEY_TYPES),
  (campaign, campaignSurveys, surveys, surveyTypes) => {
    const hydratedCampaignSurveys = filter(
      campaignSurveys,
      campaignSurvey => `${campaignSurvey.campaignId}` === `${campaign.id}`
    )

    const legacySurveyId = get(campaign, 'legacySurveyId')
    const legacySurvey = get(surveys, [legacySurveyId], {})
    const legacySurveyType = get(surveyTypes, [legacySurvey.surveyTypeId], {})

    if (
      legacySurveyId &&
      !find(
        hydratedCampaignSurveys,
        cs => `${cs.surveyId}` === `${legacySurveyId}`
      )
    ) {
      hydratedCampaignSurveys.unshift({
        id: `${campaign.id}:${legacySurveyId}:${legacySurveyType.id}`,
        surveyTypeName: get(legacySurveyType, 'name'),
        surveyName: get(legacySurvey, 'name'),
        surveyVersion: get(legacySurvey, 'version'),
        organizationId: get(legacySurvey, 'organizationId')
      })
    }

    return sortBy(hydratedCampaignSurveys, 'order')
  }
)

export const selectCampaignHasCustomSurveys = createSelector(
  selectHydratedCampaignSurveysForCampaign,
  campaignSurveys => {
    return !!find(campaignSurveys, campaignSurvey =>
      includes(
        // Custom Surveys includes Topic Based surveys for now
        [SURVEY_TYPES.CUSTOM, SURVEY_TYPES.TOPIC_BASED],
        campaignSurvey.surveyTypeName
      )
    )
  }
)

export const selectHasMultipleSurveyTypes = createSelector(
  selectHydratedCampaignSurveysForCampaign,
  campaignSurveys => {
    const surveyTypes = {}

    forEach(campaignSurveys, cs => {
      surveyTypes[cs.surveyTypeName] = cs.surveyTypeName
    })

    return values(surveyTypes).length > 1
  }
)

export const selectAvailableSurveyTypesForDropdown = createSelector(
  selectHydratedCampaignSurveysForCampaign,
  campaignSurveys => {
    const SURVEY_TYPES_FOR_DROPDOWN = {}

    forEach(campaignSurveys, campaignSurvey => {
      const id = campaignSurvey.surveyTypeName
      SURVEY_TYPES_FOR_DROPDOWN[id] = {
        id,
        name: FEEDBACK_TYPE_NAMES[campaignSurvey.surveyTypeName]
      }
    })

    const surveyTypesForDropdown = values(SURVEY_TYPES_FOR_DROPDOWN)
    surveyTypesForDropdown.unshift({ id: null, name: 'All' })

    return surveyTypesForDropdown
  }
)
