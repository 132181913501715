/**
 * This module contains factory methods that produce async action creators.
 * When using redux thunk, there is a lot of boilerplate for making API calls.
 * You want to dispatch an action that says you're starting the request. Then you dispatch an action
 * on the success or failure of that request. These factory methods let you produce async action
 * creators that do exactly that. There is no magic here. Just factoring repeated code into
 * a higher level of abstraction.
 */

import last from 'lodash/last'
import getFromObject from 'lodash/get' // The function that makes GET requests is taking the name "get"
import { isValidId } from 'utils/emplify/id_helper'
import { normalize } from 'utils/json_api/normalizer'
import { emplifyRequester, applyParamsToTemplate } from 'utils/http'
import jsonApiSerializer from 'utils/json_api/serializer'
import {
  createApiCallActionType,
  methods,
  statuses,
} from 'dux/api/action_types'
import {
  checkForNewTokenAsync,
  is401Response,
  overrideEntityIdsWithParams,
  validateParams,
} from 'dux/api/helper'
import { ApiError, ERROR_NAMES } from 'constants/errors'
import { selectToken } from 'selectors/login'
import { checkSessionAsync, logout } from 'dux/login'
import querystring from 'querystring'

const HTTP_STATUS_NO_CONTENT = 204
const MAX_RETRIES = 3

function isAPILoggerEnabled() {
  return localStorage.getItem('enableApiLogger') === 'true'
}

function apiLog(message, { path, css }) {
  if (isAPILoggerEnabled()) {
    // eslint-disable-next-line no-console
    console.log(`[API] %c${message}`, css, path)
  }
}

function shouldPreventGetRequest(cache, path) {
  const previousCallForPath = getFromObject(cache, [path])
  if (!previousCallForPath || !previousCallForPath.status) {
    return false
  }

  return (
    (previousCallForPath.status === statuses.FAILURE &&
      previousCallForPath.retries >= MAX_RETRIES) ||
    previousCallForPath.status === statuses.REQUEST ||
    previousCallForPath.status === statuses.SUCCESS
  )
}

/**
 * Infers the resource of the request from the path
 * e.g.,
 *  1. `"/people"` => `"people"`
 *  2. `"/organizations/1/employees/7?include=person"` => `"employees"`
 * @param {String} path - The path to make an HTTP request at. e.g., "/people"
 * @returns {String} - The resource that is being requested. Inferred from the `path`.
 */
function extractResourceFromPath(path) {
  if (path === '' || path === null || path === undefined) {
    throw new Error('path must be a non-empty string')
  }
  const pathWithoutQueryParams = path.split('?')[0]
  const parts = pathWithoutQueryParams.split('/')
  const lastPart = last(parts)

  // The last part of the path is an ID. So return the second to last part.
  if (isValidId(lastPart)) {
    return parts[parts.length - 2]
  }

  return lastPart
}

function extractIdFromPath(path) {
  const partsWithoutQueryParams = path.split('?')[0]
  return last(partsWithoutQueryParams.split('/'))
}

function extractIdFromResponse(response) {
  return response.data.data.id
}

function extractIncludedFromResponse(response) {
  const included = response.data.included || []
  return included.map((obj) => obj.data)
}

/**
 * Creates a function that can make API actions.
 * An API action has the form:
 * ```js
 * {
 *  type: 'API_CALL',
 *  entity: 'people',
 *  method: 'GET',
 *  status: 'REQUEST'
 * }
 * ```
 *
 * **Note** serializedQuery will override all response data ids with included
 * for more information look here /dux/api/helper#overrideEntityIdsWithParams
 *
 * @param {String} entity - e.g., `'people'`
 * @param {String} method
 * @param {String} path - the path the api call will be calling
 * @param {String} params - the template params used for the call
 * @param {String} query - the query params used for the call
 * @param {Boolean} serializeQuery - override all ids from entities responses with serializedQuery
 * @param {Boolean} shouldEntityCache - cache the entitiy ids with the filter for this action
 *
 */
export function createApiActionCreator(
  entity,
  method,
  path,
  params,
  query,
  serializeQuery,
  shouldEntityCache,
  serializeParams,
) {
  return function createApiAction(status) {
    return {
      type: createApiCallActionType(entity, method, status),
      entity,
      method,
      status,
      path,
      params,
      query,
      serializeQuery,
      serializeParams,
      shouldEntityCache,
    }
  }
}

/**
 * Produces an async action (thunk) that makes an HTTP request
 * @param {String} path - The path to make an HTTP request at. e.g., `"/people"`
 * @param {String} method
 * @param {Object} [data] - The data, if any, to include in the request body
 * @param {Object} options - A config object that allows the ability to override default workflow
 * @param {String} options.entity - Override the entity for a request
 * @param {String} options.entityId - Override the entity ID to patch/delete for a request
 * @param {Boolean} options.force - Override internal fetch preventCall
 * @param {String} options.toastMessage - Override the default success message for toast
 * @param {String} [options.errorToastMessage] - Override the default error message for toast
 */
function request(path, method, params, data, options = {}, isRetry) {
  if (!methods[method]) {
    throw new Error(
      `Cannot make HTTP request at path "${path}" with invalid method "${method}"`,
    )
  }

  return function requestThunk(dispatch, getState) {
    if (!validateParams(path, params)) {
      apiLog('{invalid}', { path, css: 'color:red' })
      return Promise.reject(
        new Error(`ignored api ${method} call - invalid params`),
      )
    }

    let requestPath = applyParamsToTemplate(path, params)
    const state = getState()

    if (options && options.query) {
      requestPath += `?${querystring.stringify(options.query)}`
    }

    // cache is being built for GET calls only
    // other methods will have empty caches until we need to implement them
    const callCache = getFromObject(state, ['requests', 'cache', method])

    if (options.force) {
      apiLog('{forced} ', { path: requestPath, css: 'color:purple' })
    } else if (shouldPreventGetRequest(callCache, requestPath)) {
      apiLog('{cached} ', { path: requestPath, css: 'color:orange' })

      // This escapes breaks request execution. If you need a call to be ran anyway
      // pass the options.force = true
      return Promise.resolve(`ignored api ${method} call`)
    } else {
      apiLog('{regular}', { path: requestPath, css: 'color:blue' })
    }

    let resource = extractResourceFromPath(requestPath)

    if (options && options.entity) {
      resource = options.entity
    }

    const { query, serializeQuery, shouldEntityCache, serializeParams } =
      options

    const createApiAction = createApiActionCreator(
      resource,
      method,
      requestPath,
      params,
      query,
      serializeQuery,
      shouldEntityCache,
      serializeParams,
    )

    const requestAction = createApiAction(statuses.REQUEST)
    if (method === methods.DELETE || method === methods.PATCH) {
      requestAction.id = options.entityId || extractIdFromPath(requestPath)
    }

    if (method === methods.GET && options && options.campaignId) {
      requestAction.campaignId = options.campaignId
    }

    dispatch(requestAction)

    let jsonApiData = {}
    if (
      (method === methods.POST || method === methods.PATCH) &&
      data !== undefined
    ) {
      jsonApiData = jsonApiSerializer.serialize(resource, data)
    }

    if (method === methods.POST && options && options.included) {
      jsonApiData.included = options.included
    }

    return (
      checkForNewTokenAsync(state, apiLog, requestPath)
        // eslint-disable-next-line consistent-return
        .then((newToken) => {
          let token = selectToken(state)
          if (newToken) {
            token = newToken
          }

          if (method === methods.GET) {
            return emplifyRequester.get(requestPath, token)
          }
          if (method === methods.POST) {
            return emplifyRequester.post(requestPath, jsonApiData, token)
          }
          if (method === methods.DELETE) {
            return emplifyRequester.del(requestPath, token)
          }
          if (method === methods.PATCH) {
            return emplifyRequester.patch(requestPath, jsonApiData, token)
          }
        })
        .then(function handleResponse(response) {
          if (!response.isOk) {
            throw new ApiError(response)
          }
          const successAction = createApiAction(statuses.SUCCESS)
          successAction.status_code = response.status

          if (options && options.toastMessage) {
            successAction.toastMessage = options.toastMessage
          }

          if (options && options.suppressToast) {
            successAction.suppressToast = options.suppressToast
          }

          if (options && options.suppressSuccessToast) {
            successAction.suppressToast = options.suppressSuccessToast
          }

          if (options && options.toastId) {
            successAction.toastId = options.toastId
          }

          // Why is this doing this here, going to use it?
          if (method === methods.GET && options && options.campaignId) {
            successAction.campaignId = options.campaignId
          }

          if (method === methods.DELETE) {
            successAction.id =
              options.entityId || extractIdFromPath(requestPath)
            if (options && options.clearRelatedForeignEntities) {
              successAction.clearRelatedForeignEntities =
                options.clearRelatedForeignEntities
            }
          }
          if (method === methods.PATCH) {
            if (options && options.clearRelatedForeignEntities) {
              successAction.clearRelatedForeignEntities =
                options.clearRelatedForeignEntities
            }
            successAction.id =
              options.entityId || extractIdFromPath(requestPath)
          }
          if (method === methods.POST) {
            if (response.status !== HTTP_STATUS_NO_CONTENT) {
              successAction.id = extractIdFromResponse(response)
            }

            if (options && options.included) {
              successAction.included = extractIncludedFromResponse(response)
            }
          }
          if (method !== methods.DELETE) {
            if (response.status !== HTTP_STATUS_NO_CONTENT) {
              const normalizedData = normalize(response.data)

              // This is a way to cache data in a unique way
              // forces us to have unique ids when api returns
              // overriding ids via query or params
              // DOES NOT WORK ON RELATIONSHIPS
              let responseData = normalizedData
              if (serializeParams) {
                responseData = overrideEntityIdsWithParams(
                  normalizedData,
                  params,
                )
              } else if (serializeQuery) {
                responseData = overrideEntityIdsWithParams(
                  normalizedData,
                  query,
                )
              }

              successAction.data = responseData
            }

            successAction.meta = getFromObject(response, 'data.meta') || {}
          }
          return dispatch(successAction)
        })
        .catch(function handleError(error) {
          if (
            error.name !== ERROR_NAMES.API_ERROR &&
            error.name !== ERROR_NAMES.REQUESTER_ERROR
          ) {
            // let error continue on if not from api
            throw error
          }

          // eslint-disable-next-line no-console
          console.error(
            `Encountered error while making a ${method.toUpperCase()} request for resource "${resource}"`,
          )
          // eslint-disable-next-line no-console
          console.error(error)
          const failureAction = createApiAction(statuses.FAILURE)
          failureAction.error = error
          failureAction.response = error && error.response

          if (options && options.suppressToast) {
            failureAction.suppressToast = options.suppressToast
          }

          if (options && options.toastId) {
            failureAction.toastId = options.toastId
          }

          if (options && options.errorToastMessage) {
            failureAction.toastMessage = options.errorToastMessage
          }

          if (options && options.showErrorModal) {
            failureAction.showErrorModal = options.showErrorModal
          }

          if (is401Response(failureAction.response) && isRetry) {
            // We already attempted to check our session and retry our calls
            // Our token is still bad. Let's go ahead and log the user out
            dispatch(logout('Your session has expired. Please login again.'))
          } else if (is401Response(failureAction.response) && !isRetry) {
            // If we receive a 401 we should try check our session once and queue up the call again
            dispatch(failureAction)
            dispatch(checkSessionAsync())
            return request(
              path,
              method,
              params,
              data,
              options,
              true,
            )(dispatch, getState)
          }

          // if we have an api error and it's not a 401 dispatch and return the failureAction
          // so we can chain it in our api actions
          return dispatch(failureAction)
        })
    )
  }
}

/**
 * Produces a thunk (async action) for PATCHing a resource
 *
 * This will dispatch 2 API_CALL actions.
 *
 * We use the path parameter `"/people"` as an example.
 *
 * First, an action with a status of REQUEST will *always* be dispatched,
 * right before the request is made.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'GET',
 *   status: 'REQUEST',
 *   entity: 'people'
 * }
 * ```
 *
 * If the request is successful, an action with a status of SUCCESS will be dispatched.
 * It will also have a `data` property to contain the HTTP response body from the server.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'GET',
 *   status: 'SUCCESS',
 *   entity: 'people',
 *   data: {..}
 * }
 * ```
 *
 * The request is considered to fail if either
 * 1. The request is not made due to a client-side error or network error
 * 2. The server responds with a status code outside of the 200s
 *
 * In these cases, an action with a status of FAILURE will be dispatched. The action will contain
 * an `error` property holding the actual JavaScript error object representing the request failure.
 * e.g.,
 * ```
 * {
 *  type: 'API_CALL',
 *  method: 'GET',
 *  status: 'FAILURE',
 *  entity: 'people',
 *  error: {..}
 * }
 * ```
 *
 * A request can only be considered to succeed *or* fail, but never both. If all 3 actions are
 * dispatched, then the thunk created by this method likely caught an error thrown by an unrelated
 * process in the event loop.
 */
function get(path, params, options) {
  return request(path, methods.GET, params, null, options)
}

/**
 * Produces a thunk (async action) for POSTing a resource
 *
 * This will dispatch 2 API_CALL actions.
 *
 * We use the path parameter `"/people"` as an example.
 *
 * First, an action with a status of REQUEST will *always* be dispatched,
 * right before the request is made.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'POST',
 *   status: 'REQUEST',
 *   entity: 'people'
 * }
 * ```
 *
 * If the request is successful, an action with a status of SUCCESS will be dispatched.
 * It will also have a `data` property to contain the HTTP response body from the server.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'PATCH',
 *   status: 'SUCCESS',
 *   entity: 'people',
 *   data: {..}
 * }
 * ```
 *
 * The request is considered to fail if either
 * 1. The request is not made due to a client-side error or network error
 * 2. The server responds with a status code outside of the 200s
 *
 * In these cases, an action with a status of FAILURE will be dispatched. The action will contain
 * an `error` property holding the actual JavaScript error object representing the request failure.
 * e.g.,
 * ```
 * {
 *  type: 'API_CALL',
 *  method: 'POST',
 *  status: 'FAILURE',
 *  entity: 'people',
 *  error: {..}
 * }
 * ```
 *
 * A request can only be considered to succeed *or* fail, but never both. If all 3 actions are
 * dispatched, then the thunk created by this method likely caught an error thrown by an unrelated
 * process in the event loop.
 */
function create(path, params, data, options) {
  return request(path, methods.POST, params, data, options)
}

/**
 * Produces a thunk (async action) for DELETing a resource
 *
 * This will dispatch 2 API_CALL actions.
 *
 * We use the path parameter `"/people"` as an example.
 *
 * First, an action with a status of REQUEST will *always* be dispatched,
 * right before the request is made.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'DELETE',
 *   status: 'REQUEST',
 *   entity: 'people'
 * }
 * ```
 *
 * If the request is successful, an action with a status of SUCCESS will be dispatched.
 * It will also have an `id` property containing the ID of the deleted resource.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'DELETE',
 *   status: 'SUCCESS',
 *   entity: 'people',
 *   id: 27
 * }
 * ```
 *
 * The request is considered to fail if either
 * 1. The request is not made due to a client-side error or network error
 * 2. The server responds with a status code outside of the 200s
 *
 * In these cases, an action with a status of FAILURE will be dispatched. The action will contain
 * an `error` property holding the actual JavaScript error object representing the request failure.
 * e.g.,
 * ```
 * {
 *  type: 'API_CALL',
 *  method: 'DELETE',
 *  status: 'FAILURE',
 *  entity: 'people',
 *  error: {..}
 * }
 * ```
 *
 * A request can only be considered to succeed *or* fail, but never both. If all 3 actions are
 * dispatched, then the thunk created by this method likely caught an error thrown by an unrelated
 * process in the event loop.
 */
function destroy(path, params, options) {
  return request(path, methods.DELETE, params, null, options)
}

/**
 * Produces a thunk (async action) for PATCHing a resource
 *
 * This will dispatch 2 API_CALL actions.
 *
 * We use the path parameter `"/people"` as an example.
 *
 * First, an action with a status of REQUEST will *always* be dispatched,
 * right before the request is made.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'PATCH',
 *   status: 'REQUEST',
 *   entity: 'people'
 * }
 * ```
 *
 * If the request is successful, an action with a status of SUCCESS will be dispatched.
 * It will also have a `data` property to contain the HTTP response body from the server.
 * e.g.,
 * ```
 * {
 *   type: 'API_CALL',
 *   method: 'PATCH',
 *   status: 'SUCCESS',
 *   entity: 'people',
 *   data: {..}
 * }
 * ```
 *
 * The request is considered to fail if either
 * 1. The request is not made due to a client-side error or network error
 * 2. The server responds with a status code outside of the 200s
 *
 * In these cases, an action with a status of FAILURE will be dispatched. The action will contain
 * an `error` property holding the actual JavaScript error object representing the request failure.
 * e.g.,
 * ```
 * {
 *  type: 'API_CALL',
 *  method: 'PATCH',
 *  status: 'FAILURE',
 *  entity: 'people',
 *  error: {..}
 * }
 * ```
 *
 * A request can only be considered to succeed *or* fail, but never both. If all 3 actions are
 * dispatched, then the thunk created by this method likely caught an error thrown by an unrelated
 * process in the event loop.
 */
function update(path, params, data, options) {
  return request(path, methods.PATCH, params, data, options)
}

export default {
  get,
  create,
  destroy,
  update,
}
