import reducer from 'dux/helpers/reducer'

const initialState = {
  chatterPanels: {
    'Engagement Flow': false,
    'Psychological Conditions': false,
    'Engagement Drivers': false
  }
}

// Constants (i.e. action types)
const TOGGLE_CHATTER = 'education/TOGGLE_CHATTER'

// Reducers
function reduceToggleChatter(state, action) {
  const { panel } = action

  return {
    ...state,
    chatterPanels: {
      ...state.chatterPanels,
      [panel]: !state.chatterPanels[panel]
    }
  }
}

// Combined reducer functions
export default reducer(
  {
    [TOGGLE_CHATTER]: reduceToggleChatter
  },
  initialState
)

// Action creators
function toggleChatter(panel) {
  return {
    type: TOGGLE_CHATTER,
    panel
  }
}

// Export necessary action types and action creators
export { TOGGLE_CHATTER, toggleChatter }
