import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

function DropdownNavListLink({ label, className, labelClassName, route }) {
  const cx = cn('dropdown-sub-menu-list-item', {
    [className]: !!className
  })
  const labelCx = cn('dropdown-sub-menu-list-item-label', {
    [labelClassName]: !!labelClassName
  })

  return (
    <div className={cx}>
      <a
        className='dropdown-sub-menu-list-item-link'
        target='_blank'
        rel='noopener noreferrer'
        href={route}
      >
        <div className={labelCx}>{label}</div>
      </a>
    </div>
  )
}

DropdownNavListLink.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string
}

DropdownNavListLink.defaultProps = {
  label: null,
  className: '',
  labelClassName: ''
}

export default DropdownNavListLink
