import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import cn from 'classnames'

import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import FontAwesomeIcon from 'furniture/font_awesome_icon'
import { Button, BUTTON_RANKS, BUTTON_CONTEXTS } from 'furniture/buttons'

import 'react-datepicker/dist/react-datepicker.css'

function DateTimePicker({
  icon,
  maxDate,
  minDate,
  minTime,
  maxTime,
  format,
  value,
  placeholder,
  onChange,
  className,
  dataStaticId,
  showTimeSelect,
  onSelect,
  onClear,
  disabled,
  showYearDropdown
}) {
  let defaultMinTime = minTime
  let defaultMaxTime = maxTime
  if (!minTime && maxTime) {
    defaultMinTime = dayjs(maxTime)
      .startOf('day')
      .toDate()
  }
  if (minTime && !maxTime) {
    defaultMaxTime = dayjs(minTime)
      .endOf('day')
      .toDate()
  }

  return (
    <div
      className={cn('furniture-date', className)}
      data-static-id={dataStaticId}
    >
      {icon && <div className='mr-2'> {icon} </div>}
      <DatePicker
        showYearDropdown={showYearDropdown}
        scrollableYearDropdown
        placeholderText={placeholder}
        wrapperClassName='furniture-date-time-picker-wrapper'
        className='furniture-date-time-picker'
        showTimeSelect={showTimeSelect}
        minDate={minDate}
        maxDate={maxDate}
        minTime={defaultMinTime}
        maxTime={defaultMaxTime}
        selected={value}
        onChange={onChange}
        onCalendarClose={onSelect}
        dateFormat={format}
        disabled={disabled}
        yearDropdownItemNumber={100}
      />
      {onClear && (
        <Button
          className='furniture-clear-date-picker'
          rank={BUTTON_RANKS.TERTIARY}
          icon={<FontAwesomeIcon icon={faTimes} />}
          onClick={onClear}
          context={BUTTON_CONTEXTS.DESTRUCTIVE}
        />
      )}
    </div>
  )
}

DateTimePicker.propTypes = {
  dataStaticId: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  icon: PropTypes.node,
  value: PropTypes.instanceOf(Date),
  showTimeSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  showYearDropdown: PropTypes.bool
}

DateTimePicker.defaultProps = {
  onClear: undefined,
  dataStaticId: undefined,
  minDate: undefined,
  maxDate: undefined,
  minTime: undefined,
  maxTime: undefined,
  format: 'MMMM d, yyyy h:mm aa',
  className: '',
  placeholder: undefined,
  icon: null,
  value: undefined,
  showTimeSelect: false,
  disabled: false,
  showYearDropdown: false
}

export default DateTimePicker
