/**
 * A listener to run a callback when the specified element is removed.
 * @param {HTMLElement} element - Element that we are watching
 * @param {Function} onDetachCallback - method to run when the element is removed
 */
export function onElementRemove(element, onDetachCallback) {
  const observer = new MutationObserver(() => {
    function isDetached(el) {
      if (el.parentNode === document) {
        return false
      }
      if (el.parentNode === null) {
        return true
      }
      return isDetached(el.parentNode)
    }

    if (isDetached(element)) {
      observer.disconnect()
      onDetachCallback()
    }
  })

  observer.observe(document, {
    childList: true,
    subtree: true
  })
}
