import reducer from 'dux/helpers/reducer'
import {
  createInviteForEmployeeAsync,
  getInProgressCampaignsForEmployeeAsync
} from 'dux/api/actions/campaigns'
import get from 'lodash/get'
import { selectEmplifyFormData } from 'selectors/emplify_form'

const initialState = {
  invitableCampaignIds: []
}

const SET_INVITABLE_CAMPAIGNS =
  'create_new_campaign_invite/SET_INVITABLE_CAMPAIGNS'

function reduceSetInvitableCampaigns(state = initialState, action) {
  return {
    ...state,
    invitableCampaignIds: action.invitableCampaignIds
  }
}

export default reducer(
  {
    [SET_INVITABLE_CAMPAIGNS]: reduceSetInvitableCampaigns
  },
  initialState
)

function setInvitableCampaigns(invitableCampaignIds) {
  return {
    type: SET_INVITABLE_CAMPAIGNS,
    invitableCampaignIds
  }
}

function fetchInvitableCampaignsForEmployee(organizationId, employeeId) {
  return dispatch => {
    return dispatch(
      getInProgressCampaignsForEmployeeAsync(organizationId, employeeId, {
        force: true
      })
    ).then(response => {
      const invitableCampaignIds = get(response, 'data.result.campaigns', [])
      return dispatch(setInvitableCampaigns(invitableCampaignIds))
    })
  }
}

function createInvites(employee, dispatch, getState) {
  const formData = selectEmplifyFormData(getState(), {
    formName: 'createNewInviteForm'
  })
  const emailChecked = get(formData, 'emailChecked', false)
  const smsChecked = get(formData, 'smsChecked', false)
  const campaigns = get(formData, 'campaigns', [])
    .filter(({ checked }) => checked)
    .map(({ campaign }) => campaign)

  if (emailChecked || smsChecked) {
    return Promise.all(
      campaigns.map(campaign =>
        dispatch(
          createInviteForEmployeeAsync(
            employee.organizationId,
            campaign.id,
            employee.id,
            {
              shouldCreateEmail: emailChecked,
              shouldCreateSms: smsChecked
            }
          )
        )
      )
    )
  }
  return Promise.resolve(null)
}

function createNewCampaignInvites(employee) {
  return function createNewCampaignInvitesThunk(dispatch, getState) {
    return createInvites(employee, dispatch, getState)
  }
}

export { createNewCampaignInvites, fetchInvitableCampaignsForEmployee }
