import { matchRouteByWhitelist } from './route_whitelist'
import {
  getIntercomUserAttributes,
  getIntercomCompanyAttributes,
} from './utils'

// Redux Helpers
// Get Redux reference from react-redux flow
let store
/**
 * Load in the redux store into the intercom
 *
 * @param {Object} appStore - The redux store for the application
 */
export function initializeIntercom(appStore) {
  store = appStore
}

function getState() {
  if (store && store.getState) {
    return store.getState()
  }

  return {}
}

// End Redux Helpers

export default function intercom(...args) {
  if (!window.Intercom) {
    console.error('utils/intercom not loaded')
    return false
  }

  if (!process.env.INTERCOM_APP_ID) {
    console.warn('utils/intercom disabled')
  }

  return window.Intercom(...args)
}

export function boot(options) {
  const state = getState()
  const { intercomUserHash } = state.login
  const userAttributes = getIntercomUserAttributes(state)
  const company = getIntercomCompanyAttributes(state)

  const meta = {
    ...userAttributes,
    company,
    ...options,
    user_hash: intercomUserHash,
  }


  return intercom('boot', meta)
}

/**
 * Update the intercom user with the specified fields
 * @param {Object} data
 */
export function update(data) {
  return intercom('update', data)
}

export function intercomTrackEvent(event, meta) {
  return intercom('trackEvent', event, meta)
}

export function show() {
  return intercom('show')
}

export function hide() {
  return intercom('hide')
}

/**
 * Shows the default intercom launcher in the lower
 * right corner for Insights v2
 */
export function showLauncher() {
  const meta = {
    hide_default_launcher: false,
    alignment: 'right',
    horizontal_padding: 0,
    vertical_padding: 0,
  }
  intercom('update', meta)
}

/**
 * Hides the default intercom launcher and places
 * the intercom chat box next to the "Feedback & Support" button
 * in Insights v1
 */
export function hideLauncher() {
  const meta = {
    hide_default_launcher: true,
    alignment: 'left',
    horizontal_padding: 180,
    vertical_padding: 49,
  }
  intercom('update', meta)
}

export function updateByWhitelist(route, options) {
  if (matchRouteByWhitelist(route)) {
    const state = getState()
    const company = getIntercomCompanyAttributes(state)
    const meta = {
      company,
      ...options,
    }

    intercom('update', meta)
  }
}
