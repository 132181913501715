import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'furniture/icons/svg_icon'

import Colors from 'constants/colors'

function NumberIcon({ color, fontSize, fontWeight, number, style }) {
  return (
    <SvgIcon style={style} color={color}>
      <circle cx='12' cy='12' r='10' />
      <text
        x='12'
        y='16'
        fontWeight={fontWeight}
        textAnchor='middle'
        fontSize={fontSize}
        fill={Colors.PRIMARY_WHITE}
      >
        {number}
      </text>
    </SvgIcon>
  )
}

NumberIcon.propTypes = {
  color: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  style: PropTypes.object
}
NumberIcon.defaultProps = {
  fontSize: 12,
  fontWeight: 500,
  style: {}
}

export default NumberIcon
