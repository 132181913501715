const EMPLOYEE_VIEWS = {
  ACTIVE: 'active',
  ACCESS: 'access',
  ALL: 'all'
}

const SETTINGS_VIEWS = {
  ORG_SETTINGS: 'assessment-settings',
  EMPLOYEES: 'employee-list',
  SURVEY_MANAGEMENT: 'surveys',
  CAMPAIGN_CHANGE_HISTORY: 'change-history',
  GROUP_MANAGEMENT: 'group-type-management'
}

const EMPLOYEE_LIST_TABLE_NAMES = {
  [EMPLOYEE_VIEWS.ACTIVE]: 'employeesPaginated',
  [EMPLOYEE_VIEWS.ACCESS]: 'employeesLeadersPaginated',
  [EMPLOYEE_VIEWS.ALL]: 'employeesPaginated'
}

export { EMPLOYEE_VIEWS, EMPLOYEE_LIST_TABLE_NAMES, SETTINGS_VIEWS }
