import React from 'react'
import PropTypes from 'prop-types'
import { Toaster as ReactHotToaster, resolveValue } from 'react-hot-toast'
import Toast from './toast'

export const TOAST_POSITIONS = {
  topLeft: 'top-left',
  topCenter: 'top-center',
  topRight: 'top-right',
  bottomLeft: 'bottom-left',
  bottomCenter: 'bottom-center',
  bottomRight: 'bottom-right'
}

export const TOAST_DURATIONS = {
  long: 6000,
  normal: 3000
}
function Toaster({ position, messageDetails, duration, setShowDialog }) {
  return (
    <ReactHotToaster toastOptions={{ position, duration, setShowDialog }}>
      {t => {
        return (
          <Toast
            setShowDialog={setShowDialog}
            messageType={t.type}
            message={resolveValue(t.message)}
            hasMessageDetails={!!messageDetails}
          />
        )
      }}
    </ReactHotToaster>
  )
}

Toaster.propTypes = {
  messageDetails: PropTypes.string,
  position: PropTypes.oneOf(Object.values(TOAST_POSITIONS)),
  duration: PropTypes.oneOf(Object.values(TOAST_DURATIONS)),
  setShowDialog: PropTypes.func
}
Toaster.defaultProps = {
  messageDetails: '',
  position: TOAST_POSITIONS.bottomCenter,
  duration: TOAST_DURATIONS.normal,
  setShowDialog: () => {}
}
export default Toaster
