import React from 'react'

import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

function RowCollapseIcon() {
  return <FontAwesomeIcon icon={faChevronUp} />
}

export default RowCollapseIcon
