import { toast } from 'react-hot-toast'

export const ToastTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info'
}
export function showToast(message, toastType, id) {
  switch (toastType) {
    case ToastTypes.SUCCESS:
      toast.success(message, { duration: 3000, id })
      break
    case ToastTypes.ERROR:
      toast.error(message, { duration: 6000, id })
      break
    default:
      toast.info(message, { duration: 3000, id })
  }
}
