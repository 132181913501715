import get from 'lodash/get'

import reducer from 'dux/helpers/reducer'
import { getInsightsUsersAsync } from 'dux/api/actions/employees'
import { getOrganizationPermissionsAsync } from 'dux/api/actions/permissions'

const initialState = {
  shouldForceRefetch: false,
}

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'
const SET_SHOULD_FORCE_REFETCH = 'groups/SET_SHOULD_FORCE_REFETCH'

function reduceSetShouldForceRefetch(state = initialState, action) {
  if (state.shouldForceRefetch === action.force) {
    return state
  }
  return {
    ...state,
    shouldForceRefetch: action.force,
  }
}

function reduceRouteChange(state = initialState, action) {
  const pathname = get(action, 'payload.location.pathname')
  if (pathname.startsWith('/group-type-management')) {
    return state
  }
  if (state.shouldForceRefetch) {
    return state
  }
  return {
    ...state,
    shouldForceRefetch: true,
  }
}

export default reducer(
  {
    [SET_SHOULD_FORCE_REFETCH]: reduceSetShouldForceRefetch,
    [LOCATION_CHANGE]: reduceRouteChange,
  },
  initialState,
)

function setShouldForceRefetch(force) {
  return { type: SET_SHOULD_FORCE_REFETCH, force }
}

// TODO: Remove with the v2-migration-tool
function fetchGroupLeaderDataAsync(organizationId) {
  return function fetchGroupLeaderDataThunk(dispatch, getState) {
    const state = getState()
    const force = state.groups.shouldForceRefetch
    if (force) {
      // We are going to force fetch, so flip it back to false
      dispatch(setShouldForceRefetch(false))
    }

    dispatch(
      getOrganizationPermissionsAsync(organizationId, {
        force,
        query: {
          'filter[accessibleType]': 'organizations-groups',
          'filter[access]': 'r',
        },
      }),
    )
    return dispatch(getInsightsUsersAsync(organizationId), force)
  }
}

function fetchGroupLeaderDataV3Async(organizationId) {
  return function fetchGroupLeaderDataThunk(dispatch, getState) {
    const state = getState()
    const force = state.groups.shouldForceRefetch
    if (force) {
      // We are going to force fetch, so flip it back to false
      dispatch(setShouldForceRefetch(false))
    }

    dispatch(
      getOrganizationPermissionsAsync(organizationId, {
        force,
        query: {
          'filter[accessibleType]': 'organizations-results-v3groups',
        },
      }),
    )

    return dispatch(getInsightsUsersAsync(organizationId, force))
  }
}

export { fetchGroupLeaderDataAsync, fetchGroupLeaderDataV3Async }
