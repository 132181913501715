import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import values from 'lodash/values'
import find from 'lodash/find'

import { selectCurrentOrganizationId } from 'selectors/organizations'
import { selectUserRole, selectUserPermissions } from 'selectors/user'
import { findPermission, isBypassRole } from 'utils/emplify/authorization'
import { ROLES } from 'constants/roles'

class AuthComponentByPermission extends PureComponent {
  render() {
    const {
      children,
      fallback,
      ids,
      permissions,
      requiredGrant,
      role,
      bypassRoles
    } = this.props
    let hasRequiredGrant
    if (requiredGrant.length) {
      hasRequiredGrant = find(requiredGrant, rg =>
        findPermission(values(permissions), rg, ids)
      )
    } else {
      hasRequiredGrant = findPermission(values(permissions), requiredGrant, ids)
    }

    if (isBypassRole(role, bypassRoles) || hasRequiredGrant) {
      return children
    }
    if (fallback) {
      return fallback
    }

    return null
  }
}

function mapStateToProps(state, props) {
  const organizationId = selectCurrentOrganizationId(state)
  const { ids = { organizationId } } = props
  return {
    ids,
    role: selectUserRole(state),
    permissions: selectUserPermissions(state)
  }
}

AuthComponentByPermission.propTypes = {
  requiredGrant: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
  role: PropTypes.oneOf(values(ROLES)),
  bypassRoles: PropTypes.arrayOf(PropTypes.string),
  permissions: PropTypes.object,
  ids: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  fallback: PropTypes.node
}

AuthComponentByPermission.defaultProps = {
  role: ROLES.EMPLOYEE,
  bypassRoles: [],
  permissions: {},
  fallback: null
}

export default connect(mapStateToProps)(AuthComponentByPermission)
