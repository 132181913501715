/**
 * Basic HTML elements.
 * Any props passed will be directly attached to the element.
 * Children will be rendered.
 */

import React from 'react'
import PropTypes from 'prop-types'

const propTypes = { children: PropTypes.node }
const defaultProps = { children: null }

function Div({ children, ...props }) {
  return <div {...props}>{children}</div>
}
Div.propTypes = propTypes
Div.defaultProps = defaultProps

export { Div }
