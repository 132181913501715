export const ERROR_NAMES = {
  API_ERROR: 'ApiError',
  REQUESTER_ERROR: 'RequesterError'
}

export class ApiError extends Error {
  constructor(
    response,
    message = 'Received an error response from the server'
  ) {
    super(message)
    this.name = ERROR_NAMES.API_ERROR
    this.response = response
    if (response && response.data && response.data.errors) {
      this.errors = response.data.errors
    }
    // IE does not support captureStackTrace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}

export class RequesterError extends Error {
  constructor(error) {
    super(error)
    this.name = ERROR_NAMES.REQUESTER_ERROR
    this.fullError = error
    console.error(`Requester handled an error: ${JSON.stringify(error)}`)
    // IE does not support captureStackTrace
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}
