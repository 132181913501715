import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import Popover, { POPOVER_POSITIONS } from 'furniture/popover'
import truncate from 'lodash/truncate'
import { NAV_ITEM_KEYS } from 'constants/v2_nav'
import { selectCurrentOrganization } from 'selectors/organizations'
import DrawerTooltip from './drawer_tooltip'
import SubMenuPopoverContent from './sub_menu_popover_content'

function MainNavItem({
  onClick,
  isCollapsed,
  label,
  tooltip,
  icon,
  selected,
  navItemId
}) {
  const [showSubMenu, setShowSubMenu] = useState(false)
  const organization = useSelector(state => selectCurrentOrganization(state))

  const handleClick = e => {
    if (!isCollapsed) {
      onClick?.() // eslint-disable-line no-unused-expressions
      return
    }
    if (isCollapsed) {
      onClick?.() // eslint-disable-line no-unused-expressions
      return
    }

    e.preventDefault()
    if (!showSubMenu) {
      setShowSubMenu(true)
    }
  }

  return (
    <div
      className={cn('custom-list-item', {
        selected,
        'org-switch-item': navItemId === NAV_ITEM_KEYS.ORG_SWITCH
      })}
      role='button'
      onClick={handleClick}
    >
      <DrawerTooltip
        anchorEl={<div className='flex custom-list-item-icon'>{icon}</div>}
        hasTooltip={isCollapsed}
      >
        <div>{tooltip}</div>
      </DrawerTooltip>
      {isCollapsed && (
        <Popover
          open={showSubMenu}
          className='drawer-popover'
          anchorEl={<div className='empty-anchor-element' />}
          position={POPOVER_POSITIONS.RIGHT}
          offset={[0, 16]}
          onClickOutside={() => {
            setShowSubMenu(false)
          }}
        >
          <SubMenuPopoverContent
            onClick={() => {
              setShowSubMenu(false)
            }}
            navItemId={navItemId}
          />
        </Popover>
      )}
      <div
        className={cn('custom-list-item-label', {
          'org-switch-label': navItemId === NAV_ITEM_KEYS.ORG_SWITCH
        })}
      >
        <p className='org-switch-title'>{label}</p>
        {navItemId === NAV_ITEM_KEYS.ORG_SWITCH && (
          <p className='org-label'>
            {truncate(organization.name, { length: 35, omission: '...' })}
          </p>
        )}
      </div>
    </div>
  )
}

MainNavItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  icon: PropTypes.node,
  selected: PropTypes.bool,
  navItemId: PropTypes.string
}

MainNavItem.defaultProps = {
  isCollapsed: false,
  label: '',
  tooltip: '',
  icon: null,
  selected: false,
  navItemId: ''
}

export default MainNavItem
