/**
 * Enum for organization file types.
 * @readonly
 * @enum {string}
 */
export const OrganizationFileTypes = {
  DYNAMIC_FEEDBACK_REPORT_TYPE: 'dynamic_feedback_report',
  SP_REPORT_TYPE_LEGACY: 'smart_pulse_report_legacy',
  SP_REPORT_TYPE: 'smart_pulse_report',
  LEGACY_CUSTOM_CAMPAIGN_REPORT: 'legacy_custom_campaign_report',
  SECURE_CUSTOMER_CSV: 'secure_customer_csv',
  ERRORED_IMPORT_CSV: 'errored_import_csv',
  EMPLOYEE_MANAGEMENT_IMPORT: 'employee_management_import'
}
