import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

/**
 * Fetches person details for executive advisors and customer success managers
 * @returns {Thunk} The created thunk.
 */
export function getOrganizationCustomerSuccessPeopleAsync(
  organizationId,
  options = {}
) {
  const params = { organizationId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/customer-success',
    params,
    options
  )
}
