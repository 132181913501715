import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { AuthComponentByRole } from 'containers/authorization'
import NavListItem from 'containers/dropdown_nav/dropdown-nav-list-item'
import { selectCurrentOrganization } from 'selectors/organizations'
import { ROLES } from 'constants/roles'

function AdminSubMenuContainer() {
  const currentOrganization = useSelector((state) =>
    selectCurrentOrganization(state),
  )
  return (
    <div>
      <AuthComponentByRole
        allowedRoles={[
          ROLES.ADMIN,
          ROLES.CUSTOMER_SUCCESS_MANAGER,
          ROLES.EXECUTIVE_ADVISOR,
          ROLES.COACH,
          ROLES.FACILITATOR,
        ]}
      >
        <NavListItem
          value='Organizations'
          containerElement={<Link to='/organizations' />}
          isSubMenuItem
        />
        <NavListItem
          value='Current Organization'
          containerElement={
            <Link to={`/organizations/${currentOrganization.id}`} />
          }
          isSubMenuItem
        />
      </AuthComponentByRole>
      <AuthComponentByRole allowedRoles={[ROLES.ADMIN]}>
        <NavListItem
          value='Campaigns'
          containerElement={<Link to='/admin/campaigns' />}
          isSubMenuItem
        />
        <NavListItem
          value='Global Survey Management'
          containerElement={<Link to='/admin/surveys' />}
          isSubMenuItem
        />
        <NavListItem
          value='Internal Role Management'
          containerElement={<Link to='/internal-role-management' />}
          isSubMenuItem
        />
        <NavListItem
          value='Jobs'
          containerElement={<Link to='/jobs' />}
          isSubMenuItem
        />
        <NavListItem
          value='Assessment Library'
          containerElement={<Link to='/assessment-library' />}
          isSubMenuItem
        />
        <NavListItem
          value='Transform'
          containerElement={<Link to='/transform-resources' />}
          isSubMenuItem
        />
      </AuthComponentByRole>
    </div>
  )
}

AdminSubMenuContainer.propTypes = {}

export default AdminSubMenuContainer
