import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'
import { statuses } from 'dux/api/action_types'
import { getOptimizedOrganizationsAsync } from 'dux/api/actions/optimized_organizations'
import { getOrganizationCustomerSuccessPeopleAsync } from 'dux/api/actions/customer_success'
import { switchOrganizationAsync } from 'dux/organizations'
import { addInternalPersonRoleAsync } from 'dux/api/actions/roles'
import { addToastMessage, ToastTypes } from 'dux/toast'
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import { uploadFileToPresignedUrl } from 'lib/aws/s3'
import { INTERNAL_ROLE_NAMES } from 'constants/roles'
import { getOrganizationAsync } from 'dux/api/actions/organizations'

/**
 * Fetch a person with included employees, organizations, permissions,
 * person-roles, and roles
 * @param {String} personId
 */
function getUserPerson(personId) {
  const url = '/people/:personId'
  const params = { personId }
  const options = {
    query: {
      include: 'person-roles.organizations,person-roles.roles,permissions',
    },
  }
  return ApiCallThunkCreator.get(url, params, options)
}

/**
 * Fetches all person and associated data relevant for the current logged-in user
 */
export function getUser(personId, history) {
  return function thunk(dispatch, getState) {
    let userOrganizationIds
    let getOrgsResult
    let getUserResult

    // Fetch only organizations that are not deleted.
    // Paranoid = true means were afraid of fetching deleted so we check to ensure they aren't,
    return dispatch(
      getOptimizedOrganizationsAsync({
        query: {
          paranoid: true,
        },
      }),
    )
      .then((result) => {
        getOrgsResult = result
        userOrganizationIds = get(
          getOrgsResult,
          'data.result.optimized-organizations',
          [],
        )
        return dispatch(getUserPerson(personId))
      })
      .then(function setOrgFromResponse(result) {
        getUserResult = result
        const employeeOrganizationIds = get(
          getUserResult,
          'data.result.organizations',
          [],
        )
        const currentOrganizationId = get(
          getState(),
          'organizations.currentOrganizationId',
        )
        // If an organization is not set, choose the first organization the personRole has access to
        // If no personRole exists with an organizationId, set the organization to the first organization permitted by the API
        const employeeOrgId =
          employeeOrganizationIds[0] || userOrganizationIds[0]
        const apiSuccess =
          get(getOrgsResult, 'status') === statuses.SUCCESS &&
          get(getUserResult, 'status') === statuses.SUCCESS
        // Switch organization if the 2 api calls above succeeded, the user has permission to 1 organization AND it's not the current org,
        // OR the current organization is not one of the user's organizations
        if (
          apiSuccess &&
          ((userOrganizationIds.length <= 1 &&
            currentOrganizationId !== employeeOrgId) ||
            userOrganizationIds.indexOf(currentOrganizationId) === -1)
        ) {
          return dispatch(
            switchOrganizationAsync(employeeOrgId, history, {
              preventQueryClear: true,
            }),
          )
        }
        return dispatch(getOrganizationAsync(currentOrganizationId)).then(() =>
          dispatch(
            getOrganizationCustomerSuccessPeopleAsync(currentOrganizationId),
          ),
        )
      })
      .then(() => getUserResult)
  }
}

export function updatePersonAsync(personId, data, options = {}) {
  const params = { personId }
  return ApiCallThunkCreator.update('/people/:personId', params, data, options)
}

export function createPersonAsync(data, options = {}) {
  return ApiCallThunkCreator.create('/people', null, data, options)
}

/**
 * Sends a engage invite to an existing person
 * @param {String} personId - the id of the person to fetch
 * @returns {Thunk} The created thunk.
 */
export function createPortalInviteForPersonAsync(personId) {
  return ApiCallThunkCreator.create(
    '/people/:personId/invite',
    { personId },
    null,
  )
}

/**
 * Update a person.
 * This endpoint goes through the organization and employee so that we can scope this endpoint
 * to leaders who should only be able to edit people who are employees in their own org.
 *
 * @param {String|Number} organizationId ID of the org the person is associated with
 *  via Employee
 * @param {String|Number} employeeId ID of employee the person is associated with
 * @param {String|Number} personId ID of the person to update
 * @param {Object} data Updates to apply to the person
 * @returns {Promise.<Object>} Resolves to the last action dispatched by the thunk creator
 */
export function updateEmployeesPersonAsync(
  organizationId,
  employeeId,
  personId,
  data,
) {
  const url =
    '/organizations/:organizationId/employees/:employeeId/people/:personId'
  const params = { organizationId, employeeId, personId }
  return ApiCallThunkCreator.update(url, params, data)
}

/**
 * Fetches a person by id, with included employees and employments.
 *
 * @param {String} personId - the id of the person to fetch
 * @returns {Thunk} The created thunk.
 */
export function getPersonAsync(personId, options) {
  const params = { personId }
  const opts = {
    query: {
      include: 'employees.employments',
    },
    ...options,
  }
  return ApiCallThunkCreator.get('/people/:personId', params, opts)
}

/**
 * Fetches person details for an employee inside of an org
 * @param {String} personId - the id of the person to fetch
 * @returns {Thunk} The created thunk.
 */
export function getOrganizationPersonAsync(organizationId, personId, options) {
  const params = { organizationId, personId }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/people/:personId',
    params,
    options,
  )
}

/**
 * Fetches person details for engagement executive advisors and customer success managers
 * @returns {Thunk} The created thunk.
 */
export function getCustomerSuccessPeopleAsync(options) {
  const opts = {
    query: {
      include: 'person-roles.roles',
    },
    ...options,
  }
  return ApiCallThunkCreator.get('/people/customer-success', null, opts)
}

/**
 * Fetches all people with a given filter query.
 *
 * @param {Object} filterQuery - the filter query to be applied
 * @returns {Thunk} The created thunk.
 */
export function getFilteredPeopleAsync(filterQuery, options = {}) {
  return ApiCallThunkCreator.get('/people', null, {
    query: filterQuery,
    shouldEntityCache: true,
    ...options,
  })
}

export function createInternalPersonWithPersonRole(formData) {
  const personData = get(formData, 'data', {})
  const { emailAddress, organization, role = {} } = personData
  const organizationId = get(organization, 'id', null)
  const roleId = get(role, 'id')

  const options = {
    suppressToast: true,
  }

  return (dispatch) =>
    dispatch(
      getFilteredPeopleAsync(
        { include: 'person-roles.roles', 'filter[emailAddress]': emailAddress },
        { force: true },
      ),
    )
      .then((response) => {
        // If person already has an internal role, the new role must be created through standard flow
        const existingRoles = get(response, 'data.entities.roles', {})
        const existingInternalRole = find(existingRoles, (r) =>
          includes(INTERNAL_ROLE_NAMES, r.name),
        )

        if (existingInternalRole) {
          return dispatch(
            addToastMessage(ToastTypes.ERROR, 'Internal user already exists.'),
          )
        }

        // If person already exists, but is not an internal user, add the internal role to make them an internal user
        const total = get(response, 'meta.total', 0)
        if (total > 0) {
          const personId = get(response, 'data.result.people[0]')
          return dispatch(
            addInternalPersonRoleAsync(personId, roleId, {}, options),
          ).then(() => dispatch(createPortalInviteForPersonAsync(personId)))
        }

        return dispatch(
          createPersonAsync(personData, {
            ...options,
            included: [
              {
                data: {
                  type: 'personRoles',
                  attributes: { roleId, organizationId },
                },
              },
            ],
          }),
        ).then((person) => {
          const personId = get(person, 'id')
          return dispatch(createPortalInviteForPersonAsync(personId))
        })
      })
      .then((response) => {
        if (response.status !== 'SUCCESS') {
          return Promise.resolve(null)
        }

        return dispatch(
          addToastMessage(
            ToastTypes.SUCCESS,
            'Successfully created person with role',
          ),
        )
      })
}

export function attachProfilePhoto(personId, file) {
  // Get a pre-signed URL,
  // Use that to upload to the bucket
  // Then update the person with the URI of that resource
  return (dispatch) => {
    dispatch(
      ApiCallThunkCreator.create(
        '/people/:personId/upload-url',
        { personId },
        { mimetype: file.type },
      ),
    )
      .then((response) => {
        if (response.status !== 'SUCCESS') {
          return null
        }

        const s3Info = get(response, `data.entities.s3Key[${personId}]`)
        return uploadFileToPresignedUrl(s3Info.signedUrl, file)
      })
      .then((response) => {
        const [url] = (response ? response.url : '').split('?')
        if (url) {
          return dispatch(
            updatePersonAsync(personId, { id: personId, photo: url }),
          )
        }
        return dispatch(
          addToastMessage(ToastTypes.ERROR, 'Failed to update profile photo'),
        )
      })
  }
}

export function getPersonEmployeesAsync(personId, options = {}) {
  const params = { personId }
  return ApiCallThunkCreator.get('/people/:personId/employees', params, options)
}
