import reducer from 'dux/helpers/reducer'
import lodashFilter from 'lodash/filter'

const initialState = {
  filters: {},
  attributeFilters: { employeeAttributes: [] }
}

// Constants
const RESET_ATTRIBUTE_FILTERS = 'attributeFilters/RESET_ATTRIBUTE_FILTERS'
const ADD_ATTRIBUTE_FILTER = 'attributeFilters/ADD_ATTRIBUTE_FILTER'
const REMOVE_ATTRIBUTE_FILTER = 'attributeFilters/REMOVE_ATTRIBUTE_FILTER'

function reduceAddAttributeFilter(state, action) {
  const { filter } = action
  const { employeeAttributes } = state.attributeFilters
  return {
    ...state,
    filters: {
      ...state.filters,
      [filter.id]: filter
    },
    attributeFilters: {
      employeeAttributes: employeeAttributes.concat(
        `${filter.organizationEmployeeAttributeId}:${filter.attrValue}`
      )
    }
  }
}

function reduceRemoveAttributeFilter(state, action) {
  const { filter } = action
  const newState = { ...state }
  const employeeAttributes = lodashFilter(
    newState.attributeFilters.employeeAttributes,
    value =>
      value !== `${filter.organizationEmployeeAttributeId}:${filter.attrValue}`
  )
  delete newState.filters[filter.id] // THIS MUTATES THE OBJECT. Make sure to pass in completely new references based on this object.
  return {
    ...newState,
    filters: {
      ...newState.filters
    },
    attributeFilters: {
      employeeAttributes
    }
  }
}

function reduceResetAttributeFilters() {
  return initialState
}

// Combined reducer functions
export default reducer(
  {
    [ADD_ATTRIBUTE_FILTER]: reduceAddAttributeFilter,
    [REMOVE_ATTRIBUTE_FILTER]: reduceRemoveAttributeFilter,
    [RESET_ATTRIBUTE_FILTERS]: reduceResetAttributeFilters
  },
  initialState
)

// Action creators
function addAttributeFilter(filter = {}) {
  return {
    type: ADD_ATTRIBUTE_FILTER,
    filter
  }
}

function removeAttributeFilter(filter = {}) {
  return {
    type: REMOVE_ATTRIBUTE_FILTER,
    filter
  }
}

function resetAttributeFilters() {
  return {
    type: RESET_ATTRIBUTE_FILTERS
  }
}

// Export necessary action types and action creators
export { addAttributeFilter, removeAttributeFilter, resetAttributeFilters }
