import { createSelector } from 'reselect'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import get from 'lodash/get'
import filter from 'lodash/filter'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import includes from 'lodash/includes'
import concat from 'lodash/concat'
import {
  selectSelectedOrganization,
  selectCurrentOrganizationId
} from 'selectors/organizations'
import { selectIsAdmin, selectIsEngagementAdmin } from 'selectors/user'
import { selectPeopleWithHydratedRolesAndPermissions } from 'selectors/people'
import { entities } from 'dux/api/action_types'
import { createEntityGetter, createPropGetter } from 'utils/selectors'
import { ROLES, INTERNAL_ROLE_NAMES } from 'constants/roles'
import { ACCESS_LEVEL_DEFINITIONS } from '../constants/access_levels'

export const selectRoles = createSelector(
  createEntityGetter(entities.ROLES),
  roles => roles
)

export const selectPersonRoles = createSelector(
  createEntityGetter(entities.PERSON_ROLES),
  pr => pr
)

export const selectInternalRoles = createSelector(
  createEntityGetter(entities.ROLES),
  roles => {
    // We want `Engage Super Admin` to be included, but we are excluding it from this initial sorted array
    // Because we want `Engage Super Admin` to be at the bottom of the pre-sorted list
    const internalRoles = sortBy(
      filter(
        roles,
        r => includes(INTERNAL_ROLE_NAMES, r.name) && r.name !== ROLES.ADMIN
      ),
      'name'
    )

    const adminRole = find(roles, r => r.name === ROLES.ADMIN)
    internalRoles.push(adminRole)

    return internalRoles
  }
)

const EMPTY_OBJECT = {}

/**
 * Select the employee role
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - the employee role
 */
export const selectEmployeeRole = createSelector(
  selectRoles,
  roles => find(roles, r => r.name === ROLES.EMPLOYEE) || EMPTY_OBJECT
)

export const selectExecutiveAdvisorRole = createSelector(
  selectRoles,
  roles => find(roles, r => r.name === ROLES.EXECUTIVE_ADVISOR) || EMPTY_OBJECT
)

export const selectCustomerSuccessManagerRole = createSelector(
  selectRoles,
  roles =>
    find(roles, r => r.name === ROLES.CUSTOMER_SUCCESS_MANAGER) || EMPTY_OBJECT
)

export const selectEngagementAdminRole = createSelector(
  selectRoles,
  roles => find(roles, r => r.name === ROLES.ENGAGEMENT_ADMIN) || EMPTY_OBJECT
)

export const selectCoachRole = createSelector(
  selectRoles,
  roles => find(roles, r => r.name === ROLES.COACH) || EMPTY_OBJECT
)

export const selectPersonRoleForPersonIdByOrganization = createSelector(
  createEntityGetter(entities.PERSON_ROLES),
  createPropGetter('personId'),
  createPropGetter('organizationId'),
  (personRoles, personId, organizationId) =>
    find(
      personRoles,
      pr =>
        `${pr.personId}` === `${personId}` &&
        `${pr.organizationId}` === `${organizationId}`
    )
)

export const selectPersonRoleForPersonIdWithName = createSelector(
  selectPersonRoleForPersonIdByOrganization,
  selectRoles,
  (personRole, roles) => {
    const prRoleId = get(personRole, 'roleId')
    const roleObject = find(roles, role => `${prRoleId}` === `${role.id}`) || {
      name: ROLES.EMPLOYEE
    }
    return { ...personRole, name: roleObject.name }
  }
)

/**
 * Select roles grouped together by their personId
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - arrays of permissions keyed by their personId
 */
export const selectRolesGroupedByPersonId = createSelector(
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  (personRoles, roles) => {
    const expandedRoles = map(personRoles, pr => {
      const { personId, organizationId } = pr
      const role = get(roles, get(pr, 'roleId'))
      return { ...role, personRoleId: pr.id, personId, organizationId }
    })
    return groupBy(expandedRoles, 'personId')
  }
)

/**
 * Select if a person has the facilitator role.
 * @param {Object} state - the entire redux state
 * @param {Object} props.personId - Person Id to check\
 * @returns {boolean} - Boolean that determines if a person has the facilitator role
 */
export const selectHasFacilitatorRole = createSelector(
  selectRolesGroupedByPersonId,
  createPropGetter('personId'),
  (roles, personId) => {
    return !!find(
      roles[personId],
      personRole =>
        personRole.name === ROLES.EXECUTIVE_ADVISOR ||
        personRole.name === ROLES.COACH ||
        personRole.name === ROLES.FACILITATOR
    )
  }
)

/**
 * Select if a person has the customer success manager role.
 * @param {Object} state - the entire redux state
 * @param {Object} props.personId - Person Id to check
 * @returns {boolean} - Boolean that determines if a person has the customer success manager role
 */
export const selectHasCustomerSuccessManagerRole = createSelector(
  selectRolesGroupedByPersonId,
  createPropGetter('personId'),
  (roles, personId) => {
    return !!find(
      roles[personId],
      personRole => personRole.name === ROLES.CUSTOMER_SUCCESS_MANAGER
    )
  }
)

export const selectPersonRolesFilteredByRole = createSelector(
  selectCurrentOrganizationId,
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  createPropGetter('role'),
  (organizationId, personRoles, roles, roleName) => {
    const role = find(roles, r => r.name === roleName)
    if (!role) {
      return []
    }
    return filter(
      personRoles,
      pr =>
        pr &&
        `${pr.organizationId}` === `${organizationId}` &&
        `${pr.roleId}` === `${role.id}`
    )
  }
)

export const selectOrganizationPeopleByRole = createSelector(
  selectPersonRolesFilteredByRole,
  selectPeopleWithHydratedRolesAndPermissions,
  (personRoles, people) => {
    return map(personRoles, role =>
      find(people, person => `${role.personId}` === `${person.id}`)
    )
  }
)

export const selectExecutiveAdvisorForOrganization = createSelector(
  selectSelectedOrganization,
  selectPeopleWithHydratedRolesAndPermissions,
  createEntityGetter(entities.PERSON_ROLES),
  (organization, people, personRoles) => {
    const primaryPersonRole =
      find(
        personRoles,
        pr => `${pr.id}` === `${organization.primaryExecutiveAdvisorId}`
      ) || {}

    return (
      find(people, p => `${p.id}` === `${primaryPersonRole.personId}`) || {}
    )
  }
)

export const selectCustomerSuccessManagerForOrganization = createSelector(
  selectSelectedOrganization,
  selectPeopleWithHydratedRolesAndPermissions,
  createEntityGetter(entities.PERSON_ROLES),
  (organization, people, personRoles) => {
    const primaryPersonRole =
      find(
        personRoles,
        pr => `${pr.id}` === `${organization.primaryCustomerSuccessManagerId}`
      ) || {}

    return (
      find(people, p => `${p.id}` === `${primaryPersonRole.personId}`) || {}
    )
  }
)

export const selectAvailableExecutiveAdvisors = createSelector(
  selectPeopleWithHydratedRolesAndPermissions,
  people =>
    filter(people, p =>
      find(
        p.personRoles,
        pr => get(pr, 'role.name') === ROLES.EXECUTIVE_ADVISOR
      )
    )
)

export const selectAvailableCustomerSuccessManagers = createSelector(
  selectPeopleWithHydratedRolesAndPermissions,
  people =>
    filter(people, p =>
      find(
        p.personRoles,
        pr => get(pr, 'role.name') === ROLES.CUSTOMER_SUCCESS_MANAGER
      )
    )
)

const selectAllCustomerSuccessPeople = createSelector(
  selectAvailableExecutiveAdvisors,
  selectAvailableCustomerSuccessManagers,
  (executiveAdvisors, customerSuccessManagers) =>
    concat(executiveAdvisors, customerSuccessManagers)
)

export const selectAllCustomerSuccessPeopleAlphabetically = createSelector(
  selectAllCustomerSuccessPeople,
  csPeople => sortBy(csPeople, person => person.firstName)
)

/**
 * Determines what roles are available to select for access.
 * NOTE: If engagement admin is already selected, then we will disable that role for selection.
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - array of roles
 */
export const selectAvailableRolesForAccess = createSelector(
  selectRoles,
  selectIsAdmin,
  selectIsEngagementAdmin,
  (roles, isAdmin, isEngagementAdmin) => {
    const externalRoles = filter(
      roles,
      role =>
        role.name !== ROLES.CUSTOMER_SUCCESS_MANAGER &&
        role.name !== ROLES.EXECUTIVE_ADVISOR &&
        role.name !== ROLES.ADMIN &&
        role.name !== ROLES.ENGAGEMENT_ADMIN &&
        role.name !== ROLES.COACH &&
        role.name !== ROLES.FACILITATOR
    )

    if (!externalRoles.length) {
      return []
    }

    if (isAdmin || isEngagementAdmin) {
      const engagementAdminRole = find(
        roles,
        role => role.name === ROLES.ENGAGEMENT_ADMIN
      )
      if (engagementAdminRole) {
        externalRoles.push(engagementAdminRole)
      }
    }
    const externalRolesWithDescription = map(externalRoles, role => {
      return { ...role, description: ACCESS_LEVEL_DEFINITIONS[role.name] }
    })
    return externalRolesWithDescription
  }
)
