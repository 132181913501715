import reducer from 'dux/helpers/reducer'

const initialState = {
  selectedNavItem: null,
  selectedNavSubMenuItem: null,
  subMenuOpen: false,
  isCollapsed: localStorage.getItem('drawer-collapse') === 'true' || false
}

const SET_SELECTED_NAV_ITEM = 'v2Nav/SET_SELECTED_NAV_ITEM'
const SET_SELECTED_NAV_SUB_MENU_ITEM = 'v2Nav/SET_SELECTED_NAV_SUB_MENU_ITEM'
const SET_SUB_MENU_OPEN = 'v2Nav/SET_SUB_MENU_OPEN'
const SET_IS_COLLAPSED = 'v2Nav/SET_IS_COLLAPSED'

// Reducers
function reduceSelectedNavItem(state, action) {
  if (state.selectedNavItem === action.selectedNavItem) {
    return state
  }

  return {
    ...state,
    selectedNavItem: action.selectedNavItem
  }
}

function reduceSelectedNavSubMenuItem(state, action) {
  if (state.selectedNavSubMenuItem === action.selectedNavSubMenuItem) {
    return state
  }

  return {
    ...state,
    selectedNavSubMenuItem: action.selectedNavSubMenuItem
  }
}

function reduceSetSubMenuOpen(state, action) {
  if (state.subMenuOpen === action.subMenuOpen) {
    return state
  }

  return {
    ...state,
    subMenuOpen: action.subMenuOpen
  }
}

function reduceSetIsCollapsed(state, action) {
  if (state.isCollapsed === action.isCollapsed) {
    return state
  }

  return {
    ...state,
    isCollapsed: action.isCollapsed
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_SELECTED_NAV_ITEM]: reduceSelectedNavItem,
    [SET_SELECTED_NAV_SUB_MENU_ITEM]: reduceSelectedNavSubMenuItem,
    [SET_SUB_MENU_OPEN]: reduceSetSubMenuOpen,
    [SET_IS_COLLAPSED]: reduceSetIsCollapsed
  },
  initialState
)

// Action creators
function setSelectedNavItem(selectedNavItem) {
  return {
    type: SET_SELECTED_NAV_ITEM,
    selectedNavItem
  }
}

function setSelectedNavSubMenuItem(selectedNavSubMenuItem) {
  return {
    type: SET_SELECTED_NAV_SUB_MENU_ITEM,
    selectedNavSubMenuItem
  }
}

function setSubMenuOpen(subMenuOpen) {
  return {
    type: SET_SUB_MENU_OPEN,
    subMenuOpen
  }
}

function setIsCollapsed(isCollapsed) {
  return {
    type: SET_IS_COLLAPSED,
    isCollapsed
  }
}

// Export necessary action types and action creators
export {
  setSelectedNavItem,
  setSelectedNavSubMenuItem,
  setSubMenuOpen,
  setIsCollapsed
}
