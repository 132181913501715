/**
 * Enum for saving employee access type statuses.
 * @readonly
 * @enum {string}
 */
const SavingStatuses = {
  REQUEST: 'request',
  SUCCESS: 'success',
  ERROR: 'error'
}

export default SavingStatuses
