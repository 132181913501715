import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { useSelectedValueDispatch } from 'furniture/selectable_list'

import { NAV_ITEMS, NAV_ITEM_KEYS } from 'constants/v2_nav'
import OrgSwitcherDialog from './org_switcher_dialog'
import MainNavItem from './main_nav_item'

/**
 * To be able to dispatch a new value to selectable list provider, we need to access
 * the dispatch hook in the children of the selectable list.
 */
function OrgSwitcherNavigationItem({ showTooltip }) {
  const [orgSwitcherOpen, setOrgSwitcherStatus] = useState(false)

  const handleOrgSwitcherHotKey = useCallback(
    event => {
      const { keyCode, shiftKey, metaKey } = event
      // Opens the OrgSwitcher using hotkeys shift+cmd+o
      if (keyCode === 79 && shiftKey && metaKey) {
        setOrgSwitcherStatus(!orgSwitcherOpen)
      }
    },
    [orgSwitcherOpen]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleOrgSwitcherHotKey)
    return () => {
      document.removeEventListener('keydown', handleOrgSwitcherHotKey)
    }
  }, [handleOrgSwitcherHotKey])

  const setSelectedContext = useSelectedValueDispatch()

  return (
    <>
      <MainNavItem
        onClick={() => {
          setOrgSwitcherStatus(true)
        }}
        navItemId={NAV_ITEMS[NAV_ITEM_KEYS.ORG_SWITCH].id}
        icon={<FontAwesomeIcon icon={faBuilding} />}
        label={NAV_ITEMS[NAV_ITEM_KEYS.ORG_SWITCH].label}
        tooltip={NAV_ITEMS[NAV_ITEM_KEYS.ORG_SWITCH].label}
        isCollapsed={showTooltip}
      />
      <OrgSwitcherDialog
        open={orgSwitcherOpen}
        handleClose={() => {
          setSelectedContext({ value: null })
          setOrgSwitcherStatus(false)
        }}
      />
    </>
  )
}

OrgSwitcherNavigationItem.propTypes = {
  showTooltip: PropTypes.bool
}

OrgSwitcherNavigationItem.defaultProps = {
  showTooltip: false
}

export default OrgSwitcherNavigationItem
