import { createSelector } from 'reselect'

import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'

import { selectActiveOptimizedOrganizations } from 'selectors/optimized_organizations'
import naiveFuzzySearch from 'utils/search/naive_fuzzy_search'

function getSearchText(state) {
  return state.orgSwitcherV2.searchText
}

function getIncludeTrialOrgs(state) {
  return state.orgSwitcherV2.includeTrialOrgs
}

export const selectSearchText = createSelector(
  getSearchText,
  searchText => searchText
)

export const selectIncludeTrialOrgs = createSelector(
  getIncludeTrialOrgs,
  includeTrialOrgs => includeTrialOrgs
)

const selectOrganizationsForSwitcher = createSelector(
  selectActiveOptimizedOrganizations,
  getIncludeTrialOrgs,
  (organizations, includeTrialOrgs) => {
    return filter(
      organizations,
      org => !org.isTrial || (org.isTrial && includeTrialOrgs)
    ).map(org => ({
      ...org,
      nameAndId: `${org.name} (${org.id}) -- 15Five ID: ${org.ffCompanyId ||
        'N/A'}`
    }))
  }
)

/**
 * Get the recentOrganizationIds from the state tree
 * @param {Object} state - The complete redux state tree
 * @returns {Array} - Collection of recent organization ids
 */
function getRecentOrganizationIds(state) {
  return uniq(map(state.organizations.recentOrganizationIds, id => `${id}`))
}

/**
 * Select the recent organizations that have been navigated to in the org switcher
 * @param {Object} state - The complete redux state tree
 * @returns {Array} - Collection of recent organizations
 */
export const selectRecentOrganizations = createSelector(
  selectOrganizationsForSwitcher,
  getRecentOrganizationIds,
  (organizations, recentOrganizationIds) => {
    const recentOrganizations = map(recentOrganizationIds, orgId =>
      find(organizations, organization => `${organization.id}` === `${orgId}`)
    )

    return filter(recentOrganizations, org => !!org)
  }
)

export const selectFilteredOrganizations = createSelector(
  selectOrganizationsForSwitcher,
  getSearchText,
  (organizations, searchText) => {
    if (!searchText) {
      return sortBy(organizations, 'name')
    }

    return sortBy(
      naiveFuzzySearch(
        organizations,
        ['name', 'displayName', 'id'],
        searchText
      ),
      'name'
    )
  }
)
