import reducer from 'dux/helpers/reducer'

const initialState = {
  searchText: '',
  includeTrialOrgs: false
}

// Constants
const UPDATE_SEARCH_TEXT = 'org_switcher_v2/UPDATE_SEARCH_TEXT'
const INCLUDE_TRIAL_ORGS = 'org_switcher_v2/INCLUDE_TRIAL_ORGS'

// Reducers
function reduceSearchText(state = initialState, action) {
  return {
    ...state,
    searchText: action.searchText
  }
}

function reduceIncludeTrialOrgs(state = initialState, action) {
  return {
    ...state,
    includeTrialOrgs: action.includeTrialOrgs
  }
}

// Combined reducer functions
export default reducer(
  {
    [UPDATE_SEARCH_TEXT]: reduceSearchText,
    [INCLUDE_TRIAL_ORGS]: reduceIncludeTrialOrgs
  },
  initialState
)

// Action creators
function updateSearchText(searchText) {
  return {
    type: UPDATE_SEARCH_TEXT,
    searchText
  }
}

function setIncludeTrialOrgs(includeTrialOrgs) {
  return {
    type: INCLUDE_TRIAL_ORGS,
    includeTrialOrgs
  }
}

// Export necessary action types and action creators
export { updateSearchText, setIncludeTrialOrgs }
