import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class ExpansionPanelActions extends PureComponent {
  render() {
    const classes = cn(this.props.className, {
      divider: !!this.props.divider,
      [this.props.extraClassNames]: !!this.props.extraClassNames
    })

    return <div className={classes}>{this.props.children}</div>
  }
}

ExpansionPanelActions.propTypes = {
  className: PropTypes.string,
  extraClassNames: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.node
}
ExpansionPanelActions.defaultProps = {
  className: 'expansion-panel-actions',
  extraClassNames: null,
  divider: true,
  children: undefined
}

export default ExpansionPanelActions
