import React from 'react'
import PropTypes from 'prop-types'

import { POPOVER_POSITIONS } from 'furniture/popover'
import { Tooltip } from 'furniture/tooltip'

function DrawerTooltip({ hasTooltip, anchorEl, children }) {
  if (hasTooltip) {
    return (
      <Tooltip
        offset={[0, 16]}
        className='drawer-tooltip'
        anchorEl={anchorEl}
        position={POPOVER_POSITIONS.RIGHT}
      >
        {children}
      </Tooltip>
    )
  }
  return <>{anchorEl}</>
}

DrawerTooltip.propTypes = {
  hasTooltip: PropTypes.bool,
  anchorEl: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
}

DrawerTooltip.defaultProps = {
  hasTooltip: false
}

export default DrawerTooltip
