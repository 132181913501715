import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Button, BUTTON_RANKS } from 'furniture/buttons'
import { ToastTypes } from 'utils/toast'

function Toast({ messageType, message, hasMessageDetails, setShowDialog }) {
  const openDetailDialog = () => {
    setShowDialog(true)
  }

  if (!message) {
    return null
  }

  if (messageType === ToastTypes.ERROR && hasMessageDetails) {
    // render an error toast with a detail button
    // contains a "DETAIL" which opens a dialog that shows more information about the error
    // also stays open for 6 seconds, instead of 3

    return (
      <div className={cn('furniture-toast', messageType)}>
        <div className='furniture-toast-details'>
          <span>{message}</span>
          <Button
            rank={BUTTON_RANKS.TERTIARY}
            label='DETAILS'
            onClick={openDetailDialog}
            className='custom-toast-button'
          />
        </div>
      </div>
    )
  }

  // render a normal 3 second toast
  return <div className={cn('furniture-toast', messageType)}>{message}</div>
}

Toast.propTypes = {
  message: PropTypes.string,
  hasMessageDetails: PropTypes.bool,
  messageType: PropTypes.string,
  setShowDialog: PropTypes.func
}
Toast.defaultProps = {
  message: '',
  hasMessageDetails: false,
  messageType: ToastTypes.INFO,
  setShowDialog: () => {}
}

export default Toast
