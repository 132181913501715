import reducer from 'dux/helpers/reducer'

const initialState = {
  selectedGroupId: null
}

// Constants
const SET_SELECTED_GROUP_ID = 'organizationHierarchy/SET_SELECTED_GROUP_ID'

// Reducers
function reduceSetSelectedGroupId(state = initialState, action) {
  return {
    ...state,
    selectedGroupId: action.selectedGroupId
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_SELECTED_GROUP_ID]: reduceSetSelectedGroupId
  },
  initialState
)

// Actions
function setSelectedGroupId(selectedGroupId) {
  return {
    type: SET_SELECTED_GROUP_ID,
    selectedGroupId
  }
}

export { setSelectedGroupId }
