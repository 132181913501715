import words from 'lodash/words'

/**
 * Determines if the text "fuzzily" contains the pattern.
 * Here "fuzzy" simply means that pattern letters are contained in the
 * text in the correct order, possibly with symbols in between.
 *
 * @param {String} pattern The pattern to look for in text
 * @param {String} text Text to match against
 * @returns {Boolean} True if text fuzzily matches the text.
 *
 * @example
 * fuzzyFilter('cob', 'Cobalt Corp') // true
 * fuzzyFilter('cc', 'Cobalt Corp') // true
 * fuzzyFilter('cbtp') // true
 * fuzzyFilter('xyz', 'Cobalt Corp') // false
 */
export function fuzzyFilter(pattern, text) {
  const lowercaseText = text.toLowerCase()
  const lowercasePattern = pattern.toLowerCase()

  let patternIndex = 0
  for (let index = 0; index < text.length; index++) {
    if (lowercaseText[index] === lowercasePattern[patternIndex]) {
      patternIndex += 1
    }
  }

  return patternIndex === pattern.length
}

export function initialismMatch(pattern, text) {
  if (!text || !pattern) {
    return false
  }

  const lowercaseText = text.toLowerCase()
  const lowercasePattern = pattern.toLowerCase()

  const wx = words(lowercaseText) || []
  if (wx.length < 1) {
    return false
  }

  const initials = wx.map(word => word && word.length > 0 && word[0]).join('')
  return lowercasePattern === initials
}
