/**
 * @typedef {('post'|'put'|'patch'|'get'|'delete')} HttpMethod
 */

import { RequesterError } from 'constants/errors'

/**
 * Creates the options to pass to the fetch() method
 * @param {HttpMethod} method - The HTTP method to use for the request
 * @param {Object} data - The data, if any, to send with the request. Must be JSON serializable.
 */
function fetchOptions(method, data, token) {
  const headers = {}

  const opts = {
    method,
    headers,
    mode: 'cors'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (data) {
    headers['Content-Type'] = 'application/json'
    opts.body = JSON.stringify(data)
  }

  return opts
}

/**
 * Creates the options to pass to the fetch() method for multipart/form-data to post files.
 * Do not need to specify a content type header for posting multipart/form-data. The browser
 * will substitute its own.
 * @param {HttpMethod} method - The HTTP method to use for the request
 * @param {Object} data - The data, if any, to send with the request. Must be JSON serializable.
 */
function fetchFileOptions(method, data, token) {
  const headers = {}

  const opts = {
    method,
    headers,
    mode: 'cors'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (data) {
    opts.body = data
  }

  return opts
}

function createResponse(fetchPromise, options = {}) {
  let status
  let isOk
  let data

  return fetchPromise
    .then(function handleResponse(response) {
      status = response.status
      isOk = response.ok

      const contentType = options.contentType
        ? options.contentType
        : response.headers.get('content-type')

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json() // Returns an object literal
      }

      if (contentType && contentType.indexOf('text/csv') !== -1) {
        return response.text()
      }

      /**
       * Using .blob() as our fallback, so that we can handle files that can be PDF, CSV, or TXT.
       */
      return response.blob()
    })
    .then(function handleData(responseData) {
      data = responseData
      return {
        status,
        isOk,
        data
      }
    })
    .catch(function handleApiError(error) {
      throw new RequesterError(error)
    })
}

/**
 * @param {String} uri - The URI of the resource to GET
 * @param {String} [token] - The token, if the endpoint is authenticated
 * @param {Object} options - Use to override the default fetch options
 */
function get(uri, token, options = {}) {
  return createResponse(
    fetch(uri, fetchOptions('get', undefined, token)),
    options
  )
}

/**
 * @param {String} uri - The URI of the resource to POST
 * @param {Object} data - The data to post. Must be JSON serializable
 * @param {String} [token] - The token, if the endpoint is authenticated
 */
function post(uri, data, token) {
  return createResponse(fetch(uri, fetchOptions('post', data, token)))
}

/**
 * Post for data that includes files.
 * @param {String} uri - The URI of the resource to POST
 * @param {Object} data - The data to post. Must be JSON serializable
 * @param {String} [token] - The token, if the endpoint is authenticated
 */
function postFile(uri, data, token) {
  return createResponse(fetch(uri, fetchFileOptions('post', data, token)))
}

/**
 * @param {String} uri - The URI of the resource to PATCH
 * @param {Object} data - The data to post. Must be JSON serializable
 * @param {String} [token] - The token, if the endpoint is authenticated
 */

// PATCH HAS TO BE ALL CAPS https://github.com/github/fetch/issues/254
function patch(uri, data, token) {
  return createResponse(fetch(uri, fetchOptions('PATCH', data, token)))
}

/**
 * @param {String} uri - The URI of the resource to DELETE
 * @param {Object} data - The data to post. Must be JSON serializable
 * @param {String} [token] - The token, if the endpoint is authenticated
 */
function del(uri, token) {
  return createResponse(fetch(uri, fetchOptions('delete', undefined, token)))
}

export default {
  post,
  postFile,
  get,
  del,
  patch
}
