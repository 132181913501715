import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { SelectedValueProvider } from './selected_context'

function SelectableList({ className, value, children, style }) {
  const cx = cn('custom-selectable-list', {
    [className]: !!className
  })

  return (
    <div className={cx} style={style}>
      <SelectedValueProvider value={value}>{children}</SelectedValueProvider>
    </div>
  )
}

SelectableList.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}
SelectableList.defaultProps = {
  value: '',
  children: [],
  className: undefined,
  style: null
}

export default SelectableList
