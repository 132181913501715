import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { ListItem } from 'furniture/selectable_list'

function DropdownNavListItem({
  value,
  label,
  className,
  labelClassName,
  isSubMenuItem,
  containerElement,
  onClick
}) {
  const cx = cn({
    [className]: !!className,
    'dropdown-sub-menu-list-item': isSubMenuItem
  })
  const labelCx = cn({
    [labelClassName]: !!labelClassName,
    'dropdown-sub-menu-list-item-label': isSubMenuItem
  })

  return (
    <ListItem
      applyDefaultStyles={false}
      containerElement={containerElement}
      label={label || value}
      className={cx}
      labelClassName={labelCx}
      onClick={onClick}
    />
  )
}

DropdownNavListItem.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  isSubMenuItem: PropTypes.bool,
  containerElement: PropTypes.node,
  onClick: PropTypes.func
}

DropdownNavListItem.defaultProps = {
  label: null,
  className: '',
  labelClassName: '',
  isSubMenuItem: false,
  containerElement: null,
  onClick: () => {}
}

export default DropdownNavListItem
