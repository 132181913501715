import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class ExpansionPanelWrapper extends PureComponent {
  render() {
    return (
      <div className='furniture-expansion-panel-wrapper'>
        {this.props.children}
      </div>
    )
  }
}

ExpansionPanelWrapper.propTypes = {
  children: PropTypes.node
}
ExpansionPanelWrapper.defaultProps = {
  children: undefined
}

export default ExpansionPanelWrapper
