import values from 'lodash/values'
import reducer from 'dux/helpers/reducer'
import { SWITCH_ORGANIZATION } from 'dux/organizations'
import { ACTION_DASHBOARD_VIEWS } from 'constants/action_dashboard'

const initialState = {
  actionDashboardView: ACTION_DASHBOARD_VIEWS.OPEN
}

// Constants (i.e. action types)
const SET_ACTION_DASHBOARD_VIEW = 'actionDashboard/SET_ACTION_DASHBOARD_VIEW'
const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

function reduceSetActionDashboardView(state = initialState, action) {
  const { actionDashboardView } = action

  // Do not allow the action dashboard view to be set to an unacceptable value
  if (!values(ACTION_DASHBOARD_VIEWS).includes(actionDashboardView)) {
    return state
  }

  return {
    ...state,
    actionDashboardView
  }
}

function reduceLocationChange(state = initialState, action) {
  const { actionDashboardView } = state
  if (!action.payload || !action.payload.pathname) {
    return state
  }
  const { pathname } = action.payload

  if (
    actionDashboardView !== ACTION_DASHBOARD_VIEWS.OPEN &&
    (pathname === '/action_dashboard/my_actions' ||
      pathname === '/action_dashboard/other_actions')
  ) {
    return { ...state, actionDashboardView: ACTION_DASHBOARD_VIEWS.OPEN }
  }

  return state
}

function reduceSwitchOrganization() {
  return initialState
}

export default reducer(
  {
    [SET_ACTION_DASHBOARD_VIEW]: reduceSetActionDashboardView,
    [LOCATION_CHANGE]: reduceLocationChange,
    [SWITCH_ORGANIZATION]: reduceSwitchOrganization
  },
  initialState
)

function setActionDashboardView(actionDashboardView) {
  return {
    type: SET_ACTION_DASHBOARD_VIEW,
    actionDashboardView
  }
}

export { setActionDashboardView }
