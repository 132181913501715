import React from 'react'
import PropTypes from 'prop-types'

import { SelectableList } from 'furniture/selectable_list'
import { NAV_ITEM_KEYS } from 'constants/v2_nav'
import { ROLES } from 'constants/roles'

import AssessmentsSubMenuContainer from './containers/assessments'

export const SUB_MENU_ALLOWED_ROLES = [
  ROLES.ADMIN,
  ROLES.CUSTOMER_SUCCESS_MANAGER,
  ROLES.EXECUTIVE_ADVISOR,
  ROLES.LEADER,
  ROLES.ENGAGEMENT_ADMIN,
  ROLES.ORGANIZATION_ADMIN,
  ROLES.COACH,
  ROLES.FACILITATOR
]

function SubMenuContent({ navItemId, className, displayTitle }) {
  if (
    navItemId === NAV_ITEM_KEYS.ASSESSMENTS_RESULTS_ROOT ||
    navItemId === NAV_ITEM_KEYS.ASSESSMENTS_RESULTS
  ) {
    return (
      <SelectableList className={className} displayTitle={displayTitle}>
        <AssessmentsSubMenuContainer />
      </SelectableList>
    )
  }
  return null
}

SubMenuContent.propTypes = {
  navItemId: PropTypes.string,
  className: PropTypes.string,
  displayTitle: PropTypes.bool
}

SubMenuContent.defaultProps = {
  navItemId: null,
  className: '',
  displayTitle: true
}

export default SubMenuContent
