import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import values from 'lodash/values'

import { FontAwesomeIcon as ReactFontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Colors from 'constants/colors'
import TopazColors from 'constants/topaz_colors'

import { mutuallyExclusiveBooleanProps } from 'utils/validation/proptypes'

export const FONT_AWESOME_SIZES = {
  extraSmall: 'xs',
  small: 'sm',
  large: 'lg',
  extraLarge: '2x',
  threeX: '3x'
}

const BOOLEAN_COLOR_PROP_TYPES = [
  'color',
  'error',
  'warning',
  'success',
  'destructive',
  'disabled'
]

function getColor({ color, error, warning, success, destructive, disabled }) {
  if (error) {
    return TopazColors.RUBY_600
  }

  if (warning) {
    return TopazColors.AMBER_400
  }

  if (success) {
    return TopazColors.EMERALD_800
  }

  if (destructive) {
    return TopazColors.DESTRUCTIVE_RED
  }

  if (disabled) {
    return TopazColors.DISABLED_GRAY
  }

  if (color) {
    return color
  }

  return Colors.PRIMARY_BLACK
}

/**
 * 💎 Topaz FontAwesomeIcon 💎
 * Font Awesome Documentation: https://fontawesome.com/v5/docs/web/use-with/react#get-started
 * Font Awesome Icon Library: https://fortawesome.com/sets/font-awesome-5-solid
 *
 * @param {Node} props.icon - the required icon SVG
 * Most of our icons will be "solid" icons and will come from the `@fortawesome/free-solid-svg-icons` library,
 * but when we need "light" icons, we need to import them from the `@fortawesome/pro-light-svg-icons` library.
 * @param {String} props.size - the optional icon size (default: 'lg')
 * @param {String} props.className - any additional classnames you want to apply to button (only use if need additional styling)
 */
function FontAwesomeIcon(props) {
  const { icon, size, onClick, transform, className } = props

  const cx = cn({
    [className]: !!className
  })

  return (
    <ReactFontAwesomeIcon
      icon={icon}
      size={size}
      color={getColor(props)}
      onClick={onClick}
      transform={transform}
      className={cx}
    />
  )
}

FontAwesomeIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  size: PropTypes.oneOf(values(FONT_AWESOME_SIZES)),
  onClick: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  transform: PropTypes.shape({
    rotate: PropTypes.number
  }),
  className: PropTypes.string,
  ...mutuallyExclusiveBooleanProps(BOOLEAN_COLOR_PROP_TYPES, {
    required: false
  })
}
FontAwesomeIcon.defaultProps = {
  size: FONT_AWESOME_SIZES.large,
  transform: undefined,
  onClick: undefined,
  className: null
}

export default FontAwesomeIcon
