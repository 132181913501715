import { createSelector } from 'reselect'
import get from 'lodash/get'
import { selectPerson } from 'selectors/user'
import { selectCurrentOrganization } from 'selectors/organizations'
import { selectLatestFinishedCampaignForCurrentOrg } from 'selectors/campaigns'
import { createPropGetter } from 'utils/selectors'

/**
 * @param {Object} state Entire redux state tree
 * @returns {{ field: String, order: String}} Current Launch Darkly Flags
 */
function getFlags(state) {
  return state.launchDarkly.flags
}

export function getIsUserIdentified(state) {
  return state.launchDarkly.isUserIdentified
}

/**
 * Create a representation of the user to send to Launch Darkly
 * @param {Object} state Entire redux state tree
 * @returns {Object} a representation of the current user to be consumed by Launch Darkly
 */
export const selectLaunchDarklyUser = createSelector(
  [
    selectPerson,
    selectCurrentOrganization,
    selectLatestFinishedCampaignForCurrentOrg
  ],
  function getLaunchDarklyUser(person, organization, campaign) {
    if (!person || !organization) {
      return null
    }
    return {
      kind: 'user',
      key: `${person.id}`,
      latestCampaignEnd: get(campaign, 'endedAt'),
      organizationId: organization.id,
      organizationEmployeeLimit: organization.employeeLimit
    }
  }
)

/**
 * Boolean representing where we have identified our user to launch darkly.
 * @param {Object} state Entire redux state tree
 * @returns {Boolean} True if user has been identified by launch darkly.
 */
export const selectIsUserIdentified = createSelector(
  getIsUserIdentified,
  isUserIdentified => isUserIdentified
)

/**
 * Gets the current Launch Darkly Flags
 * @param {Object} state Entire redux state tree
 * @returns {Object} All Launch Darkly Flags
 */
export const selectFlags = createSelector(
  getFlags,
  flags => flags
)

export const selectBoolFlag = createSelector(
  getFlags,
  createPropGetter('flagKey'),
  (flags, flagKey) => !!flags[flagKey]
)

export const selectBenchmarkStrategy = createSelector(
  [selectFlags],
  flags => {
    const isLatest = flags['benchmark-selection']
    if (isLatest) {
      return 'latest'
    }
    return 'nearest'
  }
)
