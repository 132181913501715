export function uploadFileToPresignedUrl(url, file) {
  const headers = new Headers()
  headers.append('Content-Type', file.type)
  headers.append('Access-Control-Allow-Origin', '*')
  headers.append('Access-Control-Request-Method', 'PUT')

  const request = new Request(url, {
    method: 'PUT',
    headers,
    body: file
  })

  return fetch(request)
}
