import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'

export function applyParamsToTemplate(templatePath, params) {
  if (!params || isEmpty(params)) {
    return templatePath
  }

  let requestPath = ''
  Object.keys(params).forEach(paramKey => {
    let templateString = requestPath
    if (!requestPath) {
      templateString = templatePath
    }

    const templateParamKey = `:${paramKey}`
    const paramValue = params[paramKey]
    requestPath = templateString.replace(templateParamKey, paramValue)
  })

  return requestPath
}

export function getQueryString(options = {}) {
  const queryOptions = get(options, 'query', {})
  return `?${queryString.stringify(queryOptions)}`
}

export function getFilenameQueryString(options) {
  const query = get(options, 'query', {})
  const filenameFilters = map(query, (value, key) => `${key}_${value}`)
  return filenameFilters.length ? `-${filenameFilters.join('-')}` : ''
}
