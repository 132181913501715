// The phone number formats that we are going accept parsing for:
// +11234567890
// (123) 456-7890
// 1234567890
// 123-456-7890
// 1-123-456-7890
// +1-123-456-7890
// 123.456.7890
export const phoneValidationRegex = /^(\+?1)?[ .-]?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/

export function validatePhone(phone) {
  return phoneValidationRegex.test(phone)
}
