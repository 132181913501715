import React from 'react'
import { useDispatch } from 'react-redux'

import { logout } from 'dux/login'

import NavListItem from 'containers/dropdown_nav/dropdown-nav-list-item'

function SignoutMenuItem() {
  const dispatch = useDispatch()
  return (
    <NavListItem
      className='m-4 cursor-pointer'
      value='Sign Out'
      isSubMenuItem
      onClick={() => dispatch(logout(null, true))}
    />
  )
}

SignoutMenuItem.propTypes = {}

export default SignoutMenuItem
