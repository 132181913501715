import reducer from 'dux/helpers/reducer'

const initialState = {
  sort: {
    // Sort by title secondarily to cover missing data states
    // e.g., If we do not show data because of rule of 5, sort by driver name ascending
    fields: ['score', 'title'],
    order: ['asc', 'asc']
  }
}

// Constants (i.e. action types)
const SET_SORT = 'v3GroupCategoryScores/SET_SORT'

// Reducers
function reduceSetSort(state, action) {
  const { sort } = action

  return {
    ...state,
    sort
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_SORT]: reduceSetSort
  },
  initialState
)

// Action creators
function setSort(sort) {
  return {
    type: SET_SORT,
    sort
  }
}

// Export necessary action types and action creators
export {
  // exported for unit tests
  SET_SORT,
  // exported for actual use
  setSort
}
