import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { mutuallyExclusiveBooleanProps } from 'utils/validation/proptypes'

const headingPropTypes = [
  'card',
  'section',
  'table',
  'page',
  'cardSection',
  'dialog'
]

function Heading({
  page,
  section,
  dialog,
  table,
  cardSection,
  hugTop,
  children,
  style,
  className,
  card
}) {
  const classes = cn({
    'furniture-heading': page || section || dialog || table || cardSection,
    page,
    section,
    dialog,
    table,
    'furniture-heading-hug-top': hugTop,
    [className]: !!className
  })

  if (page) {
    return (
      <h2 className={classes} style={style}>
        {children}
      </h2>
    )
  }

  if (section) {
    return (
      <h2 className={classes} style={style}>
        {children}
      </h2>
    )
  }

  if (dialog) {
    return (
      <h2 className={classes} style={style}>
        {children}
      </h2>
    )
  }

  if (card) {
    return (
      <h3 className={classes} style={style}>
        {children}
      </h3>
    )
  }

  if (cardSection) {
    return (
      <h4 className={classes} style={style}>
        {children}
      </h4>
    )
  }

  if (table) {
    return (
      <h5 className={classes} style={style}>
        {children}
      </h5>
    )
  }

  return (
    <h6 className={classes} style={style}>
      {children}
    </h6>
  )
}

Heading.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  ...mutuallyExclusiveBooleanProps(headingPropTypes),
  hugTop: PropTypes.bool,
  page: PropTypes.bool,
  section: PropTypes.bool,
  dialog: PropTypes.bool,
  table: PropTypes.bool,
  cardSection: PropTypes.bool,
  card: PropTypes.bool
}
Heading.defaultProps = {
  style: {},
  className: '',
  children: '',
  hugTop: undefined,
  page: false,
  section: false,
  dialog: false,
  table: false,
  cardSection: false,
  card: false
}

export default Heading
