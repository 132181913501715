import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import Colors from 'constants/colors'

import V2TextField from './text_field'

function V2SearchField({
  id,
  className,
  value,
  hintText,
  onKeyDown,
  autoFocus,
  onChange
}) {
  function handleChange(searchValue) {
    onChange(searchValue)
  }

  const cx = cn('furniture-v2-search-field', {
    [className]: !!className
  })

  return (
    <V2TextField
      id={id}
      containerClassName={cx}
      value={value}
      hintText={hintText}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      leftIcon={
        <FontAwesomeIcon icon={faSearch} color={Colors.TPZ_ICON_GREY} />
      }
      autoFocus={autoFocus}
      autoComplete={false}
      selectContentOnFocus
    />
  )
}

V2SearchField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  hintText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool
}

V2SearchField.defaultProps = {
  id: undefined,
  className: null,
  value: null,
  hintText: '',
  onChange: () => {},
  onKeyDown: () => {},
  autoFocus: false
}

export default V2SearchField
