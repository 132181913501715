import reducer from 'dux/helpers/reducer'
import { REHYDRATE } from 'redux-persist'
import concat from 'lodash/concat'
import get from 'lodash/get'
import pull from 'lodash/pull'
import { clearQueryParams } from 'utils/browser/query'

import {
  createOrganizationAsync,
  updateOrganizationAsync,
  getOrganizationAsync,
} from 'dux/api/actions/organizations'
import { addRoleAsync } from 'dux/api/actions/roles'
import { statuses } from 'dux/api/action_types'
import { addToastMessage, ToastTypes } from 'dux/toast'

const initialState = {
  currentOrganizationId: null,
  recentOrganizationIds: [],
}

function createNewOrganizationAsync(
  orgData,
  {
    selectedExecutiveAdvisor,
    executiveAdvisorRole,
    selectedCustomerSuccessManager,
    customerSuccessManagerRole,
  },
) {
  return (dispatch) => {
    return dispatch(
      createOrganizationAsync(orgData, { suppressToast: true }),
    ).then((response) => {
      if (response.status === statuses.SUCCESS) {
        let addExecutiveAdvisorPromise = Promise.resolve({})
        if (selectedExecutiveAdvisor) {
          addExecutiveAdvisorPromise = dispatch(
            addRoleAsync(
              response.id,
              selectedExecutiveAdvisor.id,
              executiveAdvisorRole.id,
              {},
              { suppressToast: true },
            ),
          )
        }

        let addCustomerSuccessManagerPromise = Promise.resolve({})
        if (selectedCustomerSuccessManager) {
          addCustomerSuccessManagerPromise = dispatch(
            addRoleAsync(
              response.id,
              selectedCustomerSuccessManager.id,
              customerSuccessManagerRole.id,
              {},
              { suppressToast: true },
            ),
          )
        }

        return Promise.all([
          addExecutiveAdvisorPromise,
          addCustomerSuccessManagerPromise,
        ])
          .then(
            ([
              executiveAdvisorPersonRoleResponse,
              customerSuccessManagerPersonRoleResponse,
            ]) =>
              dispatch(
                updateOrganizationAsync(response.id, {
                  id: response.id,
                  primaryExecutiveAdvisorId:
                    executiveAdvisorPersonRoleResponse.id,
                  primaryCustomerSuccessManagerId:
                    customerSuccessManagerPersonRoleResponse.id,
                }),
              ),
          )
          .then(() =>
            dispatch(
              addToastMessage(
                ToastTypes.SUCCESS,
                'Successfully created the organization',
              ),
            ),
          )
          .then(() => response)
          .catch(() => {
            dispatch(
              addToastMessage(
                ToastTypes.ERROR,
                'Failed to create additional organization resources',
                'Failed to create the roles',
              ),
            ).then(() => response)
          })
      }

      const messageDetail = get(response, 'error.errors[0].detail')
      return dispatch(
        addToastMessage(
          ToastTypes.ERROR,
          'Failed to create organization',
          messageDetail,
        ),
      ).then(() => response)
    })
  }
}

// Utility methods

function isIdValid(id) {
  return Number.isFinite(parseInt(id, 10))
}

function addToFront(array, item) {
  return concat(item, pull(array, item))
}

// Constants (i.e. action types)

const SWITCH_ORGANIZATION = 'organizations/SWITCH_ORGANIZATION'

// Reducers

function reduceRehydrate(state = initialState, action) {
  const storedState = action.payload && action.payload.organizations

  if (!storedState) {
    return state
  }

  // Warning: If this reducers state tree changes, then we need to handle the "migration" here
  // We may pull out old data from local storage and need to shape it to fit the new state tree

  return {
    ...storedState,
  }
}

function reduceSwitchOrganization(state = initialState, action) {
  const { recentOrganizationIds } = state
  const { organizationId } = action

  if (!isIdValid(organizationId)) {
    return state
  }

  return {
    ...state,
    currentOrganizationId: organizationId,
    recentOrganizationIds: addToFront(recentOrganizationIds, organizationId),
  }
}

// Combined reducer functions

export default reducer(
  {
    [REHYDRATE]: reduceRehydrate,
    [SWITCH_ORGANIZATION]: reduceSwitchOrganization,
  },
  initialState,
)

// Action creators

function switchOrganization(organizationId, loggedInPersonId) {
  return {
    type: SWITCH_ORGANIZATION,
    organizationId,
    loggedInPersonId,
  }
}

// Async actions

function switchOrganizationAsync(
  organizationId,
  history,
  { preventQueryClear } = {},
) {
  return (dispatch, getState) => {
    // Prevent clearing of query params if opting out
    if (!preventQueryClear) {
      clearQueryParams(history) // clear query params on org switch
    }
    const loggedInPersonId = getState().login.personId
    dispatch(getOrganizationAsync(organizationId)).then(() =>
      dispatch(switchOrganization(organizationId, loggedInPersonId)),
    )
  }
}

// Export necessary action types and action creators

export {
  SWITCH_ORGANIZATION,
  switchOrganizationAsync,
  createNewOrganizationAsync,
}
