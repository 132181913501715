import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import get from 'lodash/get'
import { Heading, Text } from 'furniture/typography'

function TopazTextField({
  id,
  type,
  className,
  containerClassName,
  inputStyle,
  value,
  hintText,
  onBlur,
  onKeyUp,
  onKeyDown,
  min,
  max,
  leftIcon,
  rightIcon,
  autoFocus,
  autoComplete,
  readOnly,
  selectContentOnFocus,
  defaultValue,
  onChange,
  label,
  errorText,
  format,
  parse,
  disabled,
  subTitle,
  dataStaticId,
  onClick
}) {
  const [internalValue, setInternalValue] = useState(value || defaultValue)

  const internalOnChange = e => {
    const eventValue = parse(e.target.value)
    onChange(eventValue)
    setInternalValue(eventValue)
  }

  const onFocus = e => {
    if (selectContentOnFocus) {
      e.target.select()
    }
  }

  const cx = cn('furniture-v2-text-field', {
    [className]: !!className,
    'v2-text-field-has-left-icon': !!leftIcon,
    'v2-text-field-has-right-icon': !!rightIcon
  })

  const containerCx = cn({ [containerClassName]: !!containerClassName })

  // We are doing this to guarentee the text field is driven by local state to avoid weird cursor jumping
  // issues when the value is driven through redux
  useEffect(() => {
    setInternalValue(value || defaultValue)
  }, [value, defaultValue])

  return (
    <div className={containerCx}>
      {label && <Heading cardSection>{label}</Heading>}
      {subTitle && (
        <Text className='mb-[12px]' info>
          {subTitle}
        </Text>
      )}
      <span className={cx}>
        {leftIcon}
        <input
          onClick={onClick}
          data-static-id={dataStaticId}
          id={id}
          name={id}
          type={type}
          onChange={internalOnChange}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={format(internalValue)}
          placeholder={hintText}
          min={min}
          max={max}
          style={inputStyle}
          autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
          autoComplete={autoComplete ? 'on' : 'off'}
          readOnly={readOnly}
          disabled={disabled}
        />
        {rightIcon}
      </span>
      {errorText && (
        <Text warning className='p-4 font-bold'>
          {errorText}
        </Text>
      )}
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  const { value, valueField } = ownProps

  return {
    value: valueField ? get(value, [valueField]) : value
  }
}

TopazTextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  /** The classes to apply to the entire container of the field (label and text input) */
  containerClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  valueField: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hintText: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  selectContentOnFocus: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.bool,
  readOnly: PropTypes.bool,
  /** The display label of the text field */
  label: PropTypes.node,
  /** Text to display when there is an error with the given input */
  errorText: PropTypes.string,
  format: PropTypes.func,
  parse: PropTypes.func,
  disabled: PropTypes.bool,
  subTitle: PropTypes.string,
  dataStaticId: PropTypes.string,
  onClick: PropTypes.func
}

TopazTextField.defaultProps = {
  id: undefined,
  type: 'text',
  className: null,
  containerClassName: null,
  inputStyle: {},
  value: null,
  valueField: null,
  defaultValue: '',
  hintText: '',
  onChange: () => {},
  onBlur: () => {},
  onKeyUp: () => {},
  onKeyDown: () => {},
  selectContentOnFocus: false,
  min: null,
  max: null,
  leftIcon: null,
  rightIcon: null,
  autoFocus: false,
  autoComplete: true,
  readOnly: false,
  label: null,
  errorText: null,
  format: val => val,
  parse: val => val,
  disabled: false,
  subTitle: null,
  dataStaticId: null,
  onClick: null
}

export default connect(mapStateToProps)(TopazTextField)
