import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

function getPermissionAsync(organizationId, employeeId, permissionId) {
  const params = {
    organizationId,
    employeeId,
    permissionId
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/employees/:employeeId/permissions/:permissionId',
    params
  )
}

/**
 * Creates an async action that fetches a person's permissions
 * @param {String} personId - the person to fetch permissions for
 * @param {Object} options - options for api call
 * @returns {Function} Thunk for GETing the permissions]
 */
function getPersonPermissionsAsync(personId, options = {}) {
  const params = { personId }
  return ApiCallThunkCreator.get(
    '/people/:personId/permissions',
    params,
    options
  )
}

/**
 * Fetches an employee's specified permission based on their person-tied "employee" ExplicitPermission
 * @param {String} organizationId
 * @param {String} employeeId
 * @param {Object} options - options for api call
 * @returns {Function} Thunk for GETing the permissions]
 */
function getEmployeePermissionsAsync(organizationId, employeeId, options = {}) {
  const params = {
    organizationId,
    employeeId
  }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/employees/:employeeId/permissions',
    params,
    options
  )
}

function getOrganizationPermissionsAsync(organizationId, options) {
  const params = { organizationId }

  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/permissions',
    params,
    options
  )
}

/**
 * Creates an async action that creates one or more permissions
 * @param {String} organizationId
 * @param {String} employeeId
 * @param {Array|Object} data One or more permissions to create
 * @returns {Function} Thunk for POSTing the permissions]
 */
function createEmployeePermissionsAsync(organizationId, employeeId, data) {
  const params = {
    organizationId,
    employeeId
  }

  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/employees/:employeeId/permissions',
    params,
    data
  )
}

/**
 * Creates an async action that creates one or more permissions in an org
 * @param {String} organizationId
 * @param {Array|Object} data One or more permissions to create
 * @returns {Function} Thunk for POSTing the permissions]
 */
function createOrganizationPermissionsAsync(organizationId, data) {
  const params = { organizationId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/permissions',
    params,
    data
  )
}

/**
 * Creates an async action that creates one or more permissions for a person
 * @param {String} organizationId
 * @param {Array|Object} data One or more permissions to create
 * @returns {Function} Thunk for POSTing the permissions]
 */
function createPersonPermissionsAsync(personId, data) {
  const params = { personId }
  return ApiCallThunkCreator.create(
    '/people/:personId/permissions',
    params,
    data
  )
}

function deletePersonPermissionAsync(personId, permissionId) {
  const params = {
    personId,
    permissionId
  }

  return ApiCallThunkCreator.destroy(
    '/people/:personId/permissions/:permissionId',
    params
  )
}

function deleteOrganizationPersonPermissionAsync(
  organizationId,
  personId,
  permissionId
) {
  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/people/:personId/permissions/:permissionId',
    {
      organizationId,
      personId,
      permissionId
    }
  )
}

/**
 * Deletes an employee's specified permission based on their person-tied "employee" ExplicitPermission
 * @param {String} organizationId
 * @param {String} employeeId
 * @param {Object} options - options for api call
 * @returns {Function} Thunk for GETing the permissions]
 */
function deleteEmployeePermissionAsync(
  organizationId,
  employeeId,
  permissionId
) {
  const params = {
    organizationId,
    employeeId,
    permissionId
  }

  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/employees/:employeeId/permissions/:permissionId',
    params
  )
}

export {
  getPermissionAsync,
  getPersonPermissionsAsync,
  getEmployeePermissionsAsync,
  getOrganizationPermissionsAsync,
  createEmployeePermissionsAsync,
  createOrganizationPermissionsAsync,
  createPersonPermissionsAsync,
  deleteEmployeePermissionAsync,
  deletePersonPermissionAsync,
  deleteOrganizationPersonPermissionAsync
}
