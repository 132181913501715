import { showLauncher } from 'utils/intercom'

/**
 * Controls the behavior of the intercom launcher and placement
 * depending on insights v1 or v2
 */
function IntercomController() {
  showLauncher()

  return null
}

export default IntercomController
