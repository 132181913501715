import React from 'react'
import PropTypes from 'prop-types'
import LearnMore, { LearnMoreLinks } from 'components/learn_more'

export default function LearnMoreVocab({ children, learnMoreType }) {
  return (
    <span>
      {children}
      {learnMoreType && (
        <div className='learn-more-vocab'>
          <LearnMore type={learnMoreType} />
        </div>
      )}
    </span>
  )
}

LearnMoreVocab.propTypes = {
  children: PropTypes.node,
  learnMoreType: PropTypes.oneOf(Object.values(LearnMoreLinks))
}

LearnMoreVocab.defaultProps = {
  children: null,
  learnMoreType: null
}
