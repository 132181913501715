import React, { Suspense, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import queryString from 'query-string'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { CircularProgress } from 'furniture/progress'
import { selectIsUserIdentified } from 'selectors/launch_darkly'
import { setLaunchDarklyFlags } from 'dux/launch_darkly'

import Drawer from 'containers/drawer'
import Toaster from 'containers/toast'

import Header from 'containers/structure_header'
import IncompatibleBrowserBanner from 'components/incompatible_browser_banner'

let routes = []

import('router').then((module) => {
  routes = module.default
})
function Structure({ actions, isUserIdentified }) {
  const history = useHistory()
  const location = useLocation()

  const queryParams = queryString.parse(location.search)
  const flags = useFlags()
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    actions.setLaunchDarklyFlags(flags)
  }, [actions, flags])

  useEffect(() => {
    // Catch an open analytic event (capture email open) and then remove analytic
    // query param from url search
    if (queryParams && queryParams.a) {
      // Assumption this event is coming from email
      // If not, the a query param needs extended or better arch to support
      // more complex events

      const newQueryParams = {
        ...queryParams,
        // Strip old event
        a: undefined,
      }
      const newLocation = {
        ...history.location,
        search: queryString.stringify(newQueryParams),
      }
      history.replace(newLocation)
    }
  }, [actions, history, queryParams])

  if (!isUserIdentified) {
    return <div className='app' />
  }
  return (
    <div className='rebrand-app'>
      <IncompatibleBrowserBanner />
      <Drawer
        isOpen={showDrawer}
        location={location}
        setShowDrawer={setShowDrawer}
      />
      <Header
        location={location}
        toggleShowDrawer={() => {
          setShowDrawer(!showDrawer)
        }}
      />
      <Suspense fallback={<CircularProgress />}>
        <Switch>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={
                  typeof route.content === 'function'
                    ? route.content
                    : undefined
                }
                render={
                  typeof route.content !== 'function'
                    ? (props) => <route.content {...props} />
                    : undefined
                }
              />
            )
          })}
        </Switch>
      </Suspense>
      <Toaster />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isUserIdentified: selectIsUserIdentified(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setLaunchDarklyFlags,
      },
      dispatch,
    ),
  }
}

Structure.propTypes = {
  // eslint cannot figure out that updateDrawer() is using this prop
  // eslint-disable-next-line react/no-unused-prop-types
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isUserIdentified: PropTypes.bool.isRequired,
}

Structure.defaultProps = {}

export { Structure as PresentationalStructure }
export default compose(connect(mapStateToProps, mapDispatchToProps))(Structure)
