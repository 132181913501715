import dayjs from 'dayjs'
import { validateDate } from 'utils/validation/dates'

export function formatMonthAP(date) {
  // Do not abbreviate March, April, May, June, or July
  const spelledOutMonths = new Set([2, 3, 4, 5, 6])
  const m = dayjs(date)
  let formatted = ''

  if (m.month() === 8) {
    // Dayjs (formerly Moment) abbreviates September as Sep, but we want Sept
    formatted = 'Sept'
  } else if (spelledOutMonths.has(m.month())) {
    formatted = m.format('MMMM')
  } else {
    formatted = m.format('MMM')
  }

  return formatted
}

export function formatDate(date, dayFormat = 'D', yearFormat = 'YYYY') {
  if (!date) {
    return null
  }

  const month = formatMonthAP(date)
  const day = dayFormat ? ` ${dayjs(date).format(dayFormat)},` : ''
  const year = yearFormat ? ` ${dayjs(date).format(yearFormat)}` : ''

  return `${month}${day}${year}`
}

/**
 * @param timestamp A JS datetimestamp string
 * @returns a formatted date string (ex: AUG 2019)
 */
export function formatDateForCampaignUpperCase(timestamp) {
  return timestamp ? formatDate(timestamp, null, 'YYYY').toUpperCase() : ''
}

/**
 * @param timestamp A JS datetimestamp string
 * @param options.showDay A boolean that indicates whether to show the day
 * @returns a formatted date string (ex: August 28, 2019 or August 2019
 */
export function formatLongCampaignDate(timestamp, options = {}) {
  const { showDay = false, abbreviateMonth = false } = options
  const month = abbreviateMonth ? 'MMM' : 'MMMM'

  const formatStr = showDay ? `${month} D, YYYY` : `${month} YYYY`
  return timestamp ? dayjs(timestamp).format(formatStr) : ''
}

/**
 * @param timestamp A JS datetimestamp string
 * @returns a formatted date string (ex: Aug 19)
 */
export function formatShortCampaignDate(timestamp) {
  return timestamp ? dayjs(timestamp).format('MMM YY') : ''
}

// Per dayjs, formats a date like "Oct 25, 2017 1:53 PM"
export function formatDateForLogTimestamp(timestamp) {
  return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss.SSS')
}

// Per dayjs, formats a date like "Oct 25, 2017 1:53 PM"
export function formatDateForTable(timestamp) {
  return dayjs(timestamp).format('MMM D, YYYY h:mm A')
}

/**
 * Converts a timestamp string to a JS Date object
 *
 * @param {string} timestamp - The timestamp string
 * @returns {Date} - A Javascript Date object
 */
export function getDateYearMonthDay(timestamp) {
  return new Date(dayjs(timestamp))
}

/**
 * Formats a Date object into a string like:
 * Fri Apr 20, 2018 01:49 PM
 *
 * @param {Date} timestamp - A JS Date object
 * @returns {string} - A formatted date string
 */
export function formatDateForCampaignWizard(timestamp) {
  if (typeof timestamp === 'string') {
    return timestamp
  }

  return dayjs(timestamp).format('ddd MMM DD, YYYY hh:mm A')
}

/**
 * Formats a Date object into a string like:
 * 04/20/2018
 *
 * @param {string} value - the string to format
 * @returns {string} - A formatted date string
 */
export function formatDateField(value) {
  if (validateDate(value)) {
    return dayjs(value).format('MM/DD/YYYY')
  }
  if (typeof value === 'undefined' || value === null || value === '') {
    return ''
  }
  return value
}
