import { useEffect, useState } from 'react'

/**
 * Accessed data from local storage.
 * @returns The key for the data to be accessed
 */
const useLocalStorage = key => {
  const [storedValue, setValue] = useState()

  useEffect(() => {
    if (storedValue === undefined) {
      let value = localStorage.getItem(key)

      try {
        value = JSON.parse(localStorage.getItem(key))
      } catch (e) {
        console.error('Error parsing JSON:', e) // eslint-disable-line no-console
      }

      setStoredValue(value)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setStoredValue = value => {
    localStorage.setItem(key, value)
    setValue(value)
  }

  return { storedValue, setStoredValue }
}

export default useLocalStorage
