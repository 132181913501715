import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { LoadingIndicator } from '@15five/design-system'

function CircularProgress({ className }) {
  return (
    <div className={cn('furniture-circular-icon-container', className)}>
      <LoadingIndicator />
    </div>
  )
}

CircularProgress.propTypes = {
  className: PropTypes.string,
}

CircularProgress.defaultProps = {
  className: '',
}
export default CircularProgress
