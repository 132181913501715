import React from 'react'

import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

function RowNavigateIcon() {
  return <FontAwesomeIcon icon={faChevronRight} />
}

export default RowNavigateIcon
