import queryString from 'query-string'
import get from 'lodash/get'

function loadQueryParams(history) {
  return queryString.parse(history?.location?.search)
}

// Prevents the back button from working
function updateQueryParams(query, history) {
  const urlQuery = loadQueryParams(history)
  const newQuery = Object.assign({}, urlQuery, query)
  const location = {
    ...history?.location,
    search: queryString.stringify(newQuery),
  }
  history?.replace(location)
}

// Allows for the back button to go back
function pushQueryParams(query, history) {
  const oldQuery = loadQueryParams(history)
  const newQuery = Object.assign({}, oldQuery, query)
  const newQueryString = `?${queryString.stringify(newQuery)}`
  history.push({
    pathname: history?.location?.pathname,
    search: newQueryString,
  })
  return newQueryString
}

/**
 * @param {String|Array} paramOrParams
 * @param {Object} options
 * @returns {String} updated query string
 */
function removeQueryParams(paramOrParams, history, options) {
  const params = [].concat(paramOrParams)
  const query = loadQueryParams(history)
  params.forEach((param) => {
    delete query[param]
  })
  const newQueryString = `?${queryString.stringify(query)}`
  if (options?.useReplace) {
    history.replace({ ...history?.location, search: newQueryString })
  } else {
    history.push({
      pathname: history?.location?.pathname,
      search: newQueryString,
    })
  }
  return newQueryString
}

function clearQueryParams(history) {
  // Don't reroute if search is already fine
  if (history?.location?.search) {
    const location = { ...history?.location, search: '' }
    history.replace(location)
  }
}

function getGroupResultsGroupIdToFilterBy(pathname) {
  const groupIdRegex = new RegExp(/\/group_results\/(\d+)/)
  return get(groupIdRegex.exec(pathname), '1', null)
}

/**
 * getRedirectBlacklistPathsMap - Returns a key object of paths
 *  we want to disable redirects to
 *
 * @return {object} An object disabling the direct functionality
 */
export function getRedirectBlacklistPathsMap() {
  return {
    '/login': true,
    '/logout': true,
  }
}

function getRedirectTo(location) {
  const blacklistPathsMap = getRedirectBlacklistPathsMap()
  const { pathname, search, hash } = location
  if (blacklistPathsMap[pathname]) {
    return null
  }

  return `${pathname}${search}${hash}`
}

function getAssessmentCampaignIdFromPath(pathname) {
  const groupIdRegex = new RegExp(/\/assessments\/(\d+)/)
  return get(groupIdRegex.exec(pathname), '1', null)
}

/**
 * Gets the groupId from the assessments/groups path.
 * NOTE: The title and secondary navigation are not in the router context
 * so we extract the groupId from the path.
 */
function getAssessmentGroupIdFromPath(pathname) {
  const groupIdRegex = new RegExp(/\/groups\/(\d+)/)
  return get(groupIdRegex.exec(pathname), '1', null)
}

export {
  loadQueryParams,
  updateQueryParams,
  pushQueryParams,
  removeQueryParams,
  clearQueryParams,
  getGroupResultsGroupIdToFilterBy,
  getRedirectTo,
  getAssessmentCampaignIdFromPath,
  getAssessmentGroupIdFromPath,
}
