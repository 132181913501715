import { createSelector } from 'reselect'
import values from 'lodash/values'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import some from 'lodash/some'
import {
  createPhaseSelector,
  createEntityGetter,
  createPagesGetter,
  createPropGetter
} from 'utils/selectors'
import { entities, methods } from 'dux/api/action_types'
import orderBy from 'lodash/orderBy'
import { selectCurrentOrganizationId } from 'selectors/organizations'
import { EMPLOYMENT_STATUSES } from 'constants/employment'
import { getDisplayName } from 'utils/emplify/employee'
import { getPageKey } from 'utils/pagination'
import { ROLES } from 'constants/roles'
import { ACCESSIBLE_TYPES } from 'constants/permissions'

// TODO: use role entity to actual represent role column for all types of roles, ya feel me?
function getRole(employee, person, personRoles, roles) {
  let roleName = 'Employee'
  if (!person) {
    return roleName
  }

  const { organizationId } = employee

  const adminRole = find(roles, r => r.name === ROLES.ADMIN) || {}
  const adminPersonRole = find(
    personRoles,
    pr =>
      `${pr.roleId}` === `${adminRole.id}` &&
      `${pr.personId}` === `${person.id}`
  )

  const personRole =
    adminPersonRole ||
    find(
      personRoles,
      pr =>
        `${pr.organizationId}` === `${organizationId}` &&
        `${pr.personId}` === `${person.id}`
    )

  if (!personRole) {
    return roleName
  }

  const personRoleId = get(personRole, 'roleId')
  const role = find(roles, r => `${r.id}` === `${personRoleId}`)
  roleName = get(role, 'name')

  return roleName
}

const EMPTY_OBJECT = {}

export function getPageOptions(state) {
  return state.employees.pageOptions || EMPTY_OBJECT
}

export function getTotalEmployees(state) {
  return state.employees.totalEmployeeCount || 0
}
function getCurrentOrganizationId(state) {
  return state.organizations.currentOrganizationId || null
}
export const selectEmployeesPhase = createPhaseSelector(entities.EMPLOYEES)
export const selectDeleteEmployeePhase = createPhaseSelector(
  entities.EMPLOYEES,
  methods.DELETE
)

export function getEmployeeById(state, props) {
  const { employees } = state.entities
  const employeeId =
    get(props, 'match.params.id') ||
    get(props, 'employee.id') ||
    get(props, 'employeeId')

  // handle creating a new employee
  if (employeeId === 'new') {
    return props.employee
  }

  return employees[employeeId] || null
}

/**
 * @param {Object} state - Complete redux state tree
 * @returns {Object} - map of Employee ID to Employee, containing only employees of the current org
 */
export const selectEmployeesForCurrentOrg = createSelector(
  createEntityGetter(entities.EMPLOYEES),
  getCurrentOrganizationId,
  (employees, currentOrganizationId) =>
    pickBy(
      employees,
      employee => `${employee.organizationId}` === `${currentOrganizationId}`
    )
)

/**
 * Selects the employee (if there is one) of the currently selected organization for a provided personId
 * ASSUMPTION: This selector assumes there is only one employee permission (r+w) per person per org
 */
export const selectPersonEmployeeForCurrentOrg = createSelector(
  selectEmployeesForCurrentOrg,
  createPropGetter('personId'),
  (employees, personId) =>
    find(employees, employee => `${employee.personId}` === `${personId}`) ||
    EMPTY_OBJECT
)

/**
 * @param {Object} state - Complete redux state tree
 * @returns {Object} - map of Employee ID to Employee, containing only employees of the current org
 */
const selectEmployeesForCurrentPage = createSelector(
  selectCurrentOrganizationId,
  createEntityGetter(entities.EMPLOYEES),
  createPagesGetter(entities.EMPLOYEES),
  getPageOptions,
  function getEmployeesForOrg(
    organizationId,
    employees,
    employeePages,
    pageOptions
  ) {
    const pageKey = getPageKey({ organizationId }, pageOptions)
    const pageIds = employeePages[pageKey] || []

    return pageIds.map(employeeId => employees[employeeId])
  }
)

/**
 * Hydrate an employee with their person and role
 * @param {*} employee - Employee to hydrate
 * @param {Object} people - All people from state, keyed by ID
 * @return Hydrated employee
 */
function hydrateEmployee(employee, people, personRoles, roles) {
  const personId = get(employee, 'personId')
  const person = people[personId] || {}
  person.fullName = getDisplayName(person)

  const role = getRole(employee, person, personRoles, roles)

  const hydratedEmployee = {
    ...employee,
    fullName: getDisplayName(employee),
    person,
    role
  }

  return hydratedEmployee
}

export const selectHydratedEmployeesForCurrentOrg = createSelector(
  selectEmployeesForCurrentOrg,
  createEntityGetter(entities.PEOPLE),
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  function hydrate(employees, people, personRoles, roles) {
    return mapValues(employees, employee =>
      hydrateEmployee(employee, people, personRoles, roles)
    )
  }
)

export const selectHydratedEmployeesForCurrentPage = createSelector(
  selectEmployeesForCurrentPage,
  createEntityGetter(entities.PEOPLE),
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  function hydrate(employees, people, personRoles, roles) {
    return mapValues(employees, employee =>
      hydrateEmployee(employee, people, personRoles, roles)
    )
  }
)

export const selectHydratedActiveEmployeesForCurrentOrg = createSelector(
  selectHydratedEmployeesForCurrentOrg,
  employees => {
    const filtered = filter(employees, {
      employmentStatus: EMPLOYMENT_STATUSES.ACTIVE
    })
    return keyBy(filtered, 'id')
  }
)

/**
 * Select all valid choices for the employee search in an employee AutoComplete
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - array of objects containing the display name for the employee
 */
export const selectEmployeeChoicesForAutoComplete = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  employees =>
    values(employees).map(employee => {
      const {
        id: employeeId,
        personId,
        emailAddress: email,
        mobilePhoneNumber: phoneNumber
      } = employee
      return {
        text: getDisplayName(employee),
        email,
        phoneNumber,
        employeeId,
        personId
      }
    })
)

/**
 * Select an employee hydrated with person
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps - props from React component
 * @param {String} ownProps.match.params.id - Employee ID
 * @returns {Object|null} employee hydrated with person
 */
export const selectEmployeeById = createSelector(
  getEmployeeById,
  createEntityGetter(entities.PEOPLE),
  createEntityGetter(entities.PERSON_ROLES),
  createEntityGetter(entities.ROLES),
  selectCurrentOrganizationId,
  (employee, people, personRoles, roles, currentOrgId) => {
    if (!employee || !currentOrgId) {
      return null
    }
    const orgId = parseInt(currentOrgId, 10)
    if (!orgId || employee.organizationId !== orgId) {
      return null
    }
    return hydrateEmployee(employee, people, personRoles, roles)
  }
)

/**
 * Select the employments for the current selected employee
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps - props from react component
 * @param {String} ownProps.employee - Employee
 * @returns {Array} - list of employments for employee
 */
export const selectEmploymentsByEmployeeId = createSelector(
  getEmployeeById,
  createEntityGetter(entities.EMPLOYMENTS),
  (employee, employments) => {
    if (!employee) {
      return null
    }
    const employeeEmployments = employee.employments || []
    return employeeEmployments
      .map(employment => employments[Number(employment.id)])
      .filter(employment => !!employment)
  }
)

/**
 * Select the employment history for a user.
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - list of ordered, unique employments for employee
 */
export const selectOrderedEmploymentHistory = createSelector(
  selectEmploymentsByEmployeeId,
  employments =>
    orderBy(employments, ['effectiveDate', 'createdAt'], ['desc', 'desc'])
)

/**
 * Select an array of employees with results access based on role & permissions
 * @param {Object} state - entire Redux state tree
 * @returns {Oject[]} - an array of leader objects
 */
export const selectResultsAccessEmployees = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  createEntityGetter(entities.PERMISSIONS),
  (employees, permissions) => {
    const resultsAccessEmployees = filter(employees, employee => {
      const { person } = employee
      if (employee.role !== ROLES.EMPLOYEE) {
        const currentEmployeePermissions = filter(
          permissions,
          perm => `${perm.personId}` === `${person.id}`
        )

        const hasResultsAccess = some(
          currentEmployeePermissions,
          perm =>
            perm.accessibleType === ACCESSIBLE_TYPES.ORGANIZATIONS ||
            perm.accessibleType === ACCESSIBLE_TYPES.ORGANIZATIONS_RESULTS ||
            perm.accessibleType === ACCESSIBLE_TYPES.V3_GROUPS
        )
        return hasResultsAccess
      }
      return false
    })

    return resultsAccessEmployees
  }
)

/**
 * Select an array of engage users for an organization's employees.
 * NOTE: This will not include multi-org non-native users (people who have access without employee record for org)
 * @param {Object} state - entire Redux state tree
 * @returns {Object[]} - an array of leader objects
 */
export const selectInsightsUserEmployees = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  employees => {
    const users = reduce(
      employees,
      (result, e) => {
        if (e.role !== ROLES.EMPLOYEE) {
          const name = getDisplayName(e.person)
          result.push({ ...e, name })
        }
        return result
      },
      []
    )

    return users
  }
)
