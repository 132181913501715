import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Card } from 'furniture'

import InfoIcon from './info_icon'

class InfoBar extends PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps === prevState) {
      return null
    }
    if (!nextProps.isVisible && prevState.isVisible) {
      // Fallback to previous type if no type is passed so when closing the type
      // is persisted and doesn't change styling while closing
      return {
        ...prevState,
        children: nextProps.children,
        isVisible: nextProps.isVisible
      }
    }
    return nextProps
  }

  constructor(props) {
    super(props)
    // Intentional to support nice animation
    // persist previous type while the component animates out of view
    this.state = { ...props }
  }

  render() {
    const { dataStaticId, children, isVisible } = this.state

    const cx = cn('info-bar', {
      'info-info-bar': this.state.info,
      'success-info-bar': this.state.success,
      'warning-info-bar': this.state.warning,
      'error-info-bar': this.state.error,
      'ff-info-bar': this.state.ff,
      hide: !isVisible
    })

    return (
      <Card data-static-id={dataStaticId} className={cx}>
        <div className='bar-icon'>
          <InfoIcon {...this.state} />
        </div>
        {children}
      </Card>
    )
  }
}

InfoBar.propTypes = {
  dataStaticId: PropTypes.string,
  /* eslint-disable react/no-unused-prop-types */
  isVisible: PropTypes.bool,
  overrideIcon: PropTypes.node,
  children: PropTypes.node,
  // Temporary fix until mutuallyExclusiveBooleanProps handles our use cases
  warning: PropTypes.bool,
  error: PropTypes.bool,
  info: PropTypes.bool,
  success: PropTypes.bool
  /* eslint-enable react/no-unused-prop-types */
}
InfoBar.defaultProps = {
  dataStaticId: undefined,
  isVisible: true,
  overrideIcon: null,
  children: null,
  warning: false,
  error: false,
  info: false,
  success: false
}

export default InfoBar
