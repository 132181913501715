import React from 'react'
import PropTypes from 'prop-types'

function AppBar({ menuButton, children }) {
  return (
    <div className='furniture-app-bar'>
      <div className='furniture-app-bar-menu'>{menuButton}</div>
      <div className='furniture-app-bar-content'>{children}</div>
    </div>
  )
}

AppBar.propTypes = {
  menuButton: PropTypes.node,
  children: PropTypes.node
}

AppBar.defaultProps = {
  menuButton: null,
  children: null
}

export default AppBar
