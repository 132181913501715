import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { CATEGORY_LABELS } from 'constants/categories'
import { getFriendlyCategoryName } from 'utils/emplify/categories'

function getCategoryLabelText(categoryLabel) {
  return `the ${getFriendlyCategoryName(categoryLabel)} driver.`
}

const RULE_OF_5 = 'Rule of 5'
const RUNNING_A_SURVEY = 'Running a survey'
const CONDITIONS = 'Conditions'
const DRIVERS = 'Drivers'
const INFLUENCE = 'Influence'
const STRUGGLES = 'Struggles'
const ONE_THING_WORKSHEET = '1 Thing Worksheet'
const ACTION_PLAYBOOK = 'Action Playbook'
const SPHERE = 'Sphere'

export const LearnMoreLinks = {
  RULE_OF_5,
  RUNNING_A_SURVEY,
  CONDITIONS,
  DRIVERS,
  INFLUENCE,
  STRUGGLES,
  ONE_THING_WORKSHEET,
  ACTION_PLAYBOOK,
  SPHERE,
  ...CATEGORY_LABELS
}

const mapping = {
  [RULE_OF_5]: {
    text: 'confidentiality.',
    url:
      'https://success.15five.com/hc/en-us/articles/8359259684507-The-Rule-of-5'
  },
  [RUNNING_A_SURVEY]: {
    text: 'running a successful survey.',
    url:
      'https://success.15five.com/hc/en-us/sections/9577786611739-Before-the-survey'
  },
  [CONDITIONS]: {
    text: 'how Psychological Conditions relate to engagement.',
    url:
      'https://intercom.help/emplify-insights/emplify-engagement-model/conditions'
  },
  [DRIVERS]: {
    text: 'how Drivers relate to engagement.',
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers'
  },
  [INFLUENCE]: {
    text: 'how Influence is calculated.',
    url:
      'https://success.15five.com/hc/en-us/articles/8359222995867-Influence-bubbles'
  },
  [SPHERE]: {
    text: 'Spheres of Experience.',
    url:
      'https://success.15five.com/hc/en-us/articles/8359402550683-Spheres-of-experience'
  },
  [STRUGGLES]: {
    text: 'how these areas are targeted.',
    url:
      'https://success.15five.com/hc/en-us/articles/8359504442779-Focus-areas-in-engagement-surveys'
  },
  [CATEGORY_LABELS.AUTONOMY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.AUTONOMY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JVQ62Z701ZSEJYX8QN07PB'
  },
  [CATEGORY_LABELS.CAPACITY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.CAPACITY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JVQAQWFHNSPHJRPSD8RS6A'
  },
  [CATEGORY_LABELS.COWORKER_RELATIONSHIPS]: {
    text: getCategoryLabelText(CATEGORY_LABELS.COWORKER_RELATIONSHIPS),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JVR3W61CHMNY7VB7MRRV11'
  },
  [CATEGORY_LABELS.FAIRNESS]: {
    text: getCategoryLabelText(CATEGORY_LABELS.FAIRNESS),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JVYQV05N66B9C57SWVCK09'
  },
  [CATEGORY_LABELS.FEEDBACK]: {
    text: getCategoryLabelText(CATEGORY_LABELS.FEEDBACK),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JW6SYP938409PS8C2PBZHN'
  },
  [CATEGORY_LABELS.GOAL_SUPPORT]: {
    text: getCategoryLabelText(CATEGORY_LABELS.GOAL_SUPPORT),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JW73WTCS0BCY490TWM2EWV'
  },
  [CATEGORY_LABELS.LEADER_AVAILABILITY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.LEADER_AVAILABILITY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXN8FX1EPV3GZ2H2VYMZW8'
  },
  [CATEGORY_LABELS.LEADER_INTEGRITY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.LEADER_INTEGRITY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXNHNHABCT8ZKMJENJVEMB'
  },
  [CATEGORY_LABELS.MANAGER]: {
    text: getCategoryLabelText(CATEGORY_LABELS.MANAGER),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXNSX4KHZRGS59GBZG5RES'
  },
  [CATEGORY_LABELS.MEANING]: {
    text: getCategoryLabelText(CATEGORY_LABELS.MEANING),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXP2T2QAYQ6EBMMWE83DDM'
  },
  [CATEGORY_LABELS.PROF_DEV]: {
    text: getCategoryLabelText(CATEGORY_LABELS.PROF_DEV),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXPGHC340W3FDTCQKJ5QS1'
  },
  [CATEGORY_LABELS.SAFETY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.SAFETY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXPSPMADR487WPS75C2EHB'
  },
  [CATEGORY_LABELS.PURPOSE]: {
    text: getCategoryLabelText(CATEGORY_LABELS.PURPOSE),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXPZ39AQ0D4ZXBG7HDJCZV'
  },
  [CATEGORY_LABELS.REST]: {
    text: getCategoryLabelText(CATEGORY_LABELS.REST),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXQABPM4BN4J8GKNT913RP'
  },
  [CATEGORY_LABELS.ROLE_CLARITY]: {
    text: getCategoryLabelText(CATEGORY_LABELS.ROLE_CLARITY),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXQJZK3Z6C8MYDAJCQT414'
  },
  [CATEGORY_LABELS.SHARED_VALUES]: {
    text: getCategoryLabelText(CATEGORY_LABELS.SHARED_VALUES),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXQSKQVT05W0R15CXKHQHK'
  },
  [CATEGORY_LABELS.UTILIZATION]: {
    text: getCategoryLabelText(CATEGORY_LABELS.UTILIZATION),
    url:
      'https://success.15five.com/hc/en-us/articles/7823207086363-Engagement-drivers#h_01G9JXQYKPQHM1VWPY482EHGKD'
  }
}

const Space = () => <span>&nbsp;</span>

function LearnMore({ type, className, thick, includeSpaceBeforeLink }) {
  const { text, url } = mapping[type] || {}
  const spanClassName = cn(className, {
    thick
  })

  if (!text || !url) {
    return null
  }

  return (
    <span className={spanClassName}>
      {includeSpaceBeforeLink ? <Space /> : ''}
      <a
        className='learn-more-anchor'
        href={url}
        rel='noopener noreferrer'
        target='_blank'
      >
        <span>Learn more about&nbsp;{text}</span>
      </a>
    </span>
  )
}

LearnMore.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  includeSpaceBeforeLink: PropTypes.bool,
  thick: PropTypes.bool
}
LearnMore.defaultProps = {
  className: 'learn-more',
  includeSpaceBeforeLink: false,
  thick: false
}

export default LearnMore
