import React from 'react'

/**
 * Enum for survey types.
 * @readonly
 * @enum {string}
 */
export const SURVEY_TYPES = {
  ALL: 'all',
  QUARTERLY: 'quarterly',
  TOPIC_BASED: 'topic_based',
  SMART_PULSE: 'smart_pulse',
  CUSTOM: 'custom',
  ENPS: 'enps',
  GLOBAL: 'global',
  LIFECYCLE: 'lifecycle',
}

export const SURVEY_CATEGORY_TYPES = {
  RECOMMENDED: 'recommended',
  DRIVERS: 'drivers',
  CUSTOM_GLOBAL: 'custom_global',
  CUSTOM: 'custom',
}

export const RECOMMENDED_SURVEY_NAMES = {
  ENGAGEMENT_WITH_DRIVERS: 'EngageSurvey',
  ENGAGEMENT_SCORE_ONLY: 'Engagement-only Survey',
  MANAGER_EFFECTIVENESS: 'Manager Effectiveness Survey',
  ENPS: 'eNPS',
}

export const DRIVERS_SURVEY_NAMES = {
  AUTONOMY: 'Autonomy',
  CAPACITY: 'Capacity',
  COWORKER_RELATIONSHIPS: 'Coworker Relationships',
  FAIRNESS: 'Fairness',
  FEEDBACK: 'Feedback',
  GOAL_SUPPORT: 'Goal Support',
  LEADER_AVAILABILITY: 'Leader Availability',
  LEADER_INTEGRITY: 'Leader Integrity',
  MANAGER: 'Manager',
  MEANING: 'Meaning',
  PROFESSIONAL_DEVELOPMENT: 'Prof. Dev.',
  PSYCHOLOGICAL_SAFETY: 'Safety',
  PURPOSE: 'Purpose',
  REST: 'Rest',
  ROLE_CLARITY: 'Role Clarity',
  SHARED_VALUES: 'Shared Values',
  UTILIZATION: 'Utilization',
}

export const SURVEY_TYPE_NAMES = {
  [SURVEY_TYPES.ALL]: 'All',
  [SURVEY_TYPES.QUARTERLY]: 'Engagement',
  [SURVEY_TYPES.TOPIC_BASED]: 'Topic Based',
  [SURVEY_TYPES.SMART_PULSE]: 'Smart Pulse',
  [SURVEY_TYPES.CUSTOM]: 'Custom',
  [SURVEY_TYPES.ENPS]: 'eNPS',
  [SURVEY_TYPES.GLOBAL]: 'Global Survey',
  [SURVEY_TYPES.LIFECYCLE]: 'Lifecycle Survey',
}

export const FEEDBACK_TYPE_NAMES = {
  [SURVEY_TYPES.ALL]: 'All',
  [SURVEY_TYPES.QUARTERLY]: 'Dynamic',
  [SURVEY_TYPES.TOPIC_BASED]: 'Topic Based',
  [SURVEY_TYPES.SMART_PULSE]: 'Smart Pulse',
  [SURVEY_TYPES.CUSTOM]: 'Custom',
  [SURVEY_TYPES.ENPS]: 'eNPS',
}

export const AVAILABLE_CAMPAIGN_SURVEY_TYPES = [
  SURVEY_TYPES.QUARTERLY,
  SURVEY_TYPES.ENPS,
  SURVEY_TYPES.CUSTOM,
]

export const RECOMMENDED_SURVEY_TYPES = {
  ENGAGEMENT_WITH_DRIVERS: 'Engagement with Drivers',
  ENGAGEMENT_SCORE_ONLY: 'Engagement Score Only',
  MANAGER_EFFECTIVENESS: 'Manager Effectiveness',
  ENPS: 'eNPS',
}

/**
 * Enum for survey description
 * @enum {string}
 */
export const SURVEY_DESCRIPTION = {
  [SURVEY_TYPES.QUARTERLY]:
    'You will see the robust results experience that includes trending and filtering for the Engagement Score and/or Engagement Drivers.',
  [SURVEY_TYPES.ENPS]: (
    <span>
      You will see your organization&rsquo;s eNPS score, group filters, and a
      distribution of Detractor, Neutral, and Promotor responses, but no
      trending.
    </span>
  ),
  [SURVEY_TYPES.CUSTOM]:
    'You will see aggregate responses for each question and be able to export these responses by group, but no trending.',
}

export const EXPRESS_SURVEY_OPTIONS = {
  'Engagement-only Survey': {
    name: 'Engagement-only Survey',
    displayName: 'Engagement-only',
    shortDescription:
      'Get a quick, authentic measure on your company’s engagement.',
    longDescription:
      'A 7 question survey will be sent to all employees. Employees will have 1 week to complete the survey, then an Engagement Score will be generated and show on the Outcomes Dashboard. ',
  },
  EngageSurvey: {
    name: 'EngageSurvey',
    displayName: 'Engagement w/ Drivers',
    shortDescription:
      'Identify engagement strengths and weaknesses within your organization and teams.',
    longDescription: (
      <>
        This 6-minute survey consists of 58 questions and one optional, open
        response question. Questions focus on two things - the{' '}
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://success.15five.com/hc/en-us/articles/8359125336859'
        >
          Engagement Score
        </a>{' '}
        and{' '}
        <a
          rel='noopener noreferrer'
          target='_blank'
          href='https://success.15five.com/hc/en-us/articles/7823207086363'
        >
          Engagement Drivers
        </a>
        . Employees will have 1 week to complete the survey, then an Engagement
        Score will be generated and show on the Outcomes Dashboard.
      </>
    ),
  },
  'Manager Effectiveness Survey': {
    name: 'Manager Effectiveness Survey',
    displayName: 'Manager Effectiveness',
    shortDescription:
      'Formally evaluate Manager Effectiveness within your organization.',
    longDescription:
      'This survey gathers anonymous perspectives from employees about their direct manager, formally evaluating Manager Effectiveness. The Manager Effectiveness Survey results are part of the Manager Effectiveness Indicator (MEI) on the Outcomes Dashboard. Results also show in aggregate responses by question in Engage. Employees will have 1 week to complete the survey.',
  },
}

export default SURVEY_TYPES
