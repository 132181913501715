import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import keycode from 'keycode'

import { Button, BUTTON_RANKS } from 'furniture/buttons'
import Popover, { POPOVER_POSITIONS } from 'furniture/popover'
import { Card, CardBody } from 'furniture/card'

import V2TextField from 'furniture/text_field/v2/text_field'

function PopoverEditTextField({
  anchorEl,
  isOpen,
  floatingLabelText,
  value,
  closePopover,
  isValid,
  onEdit
}) {
  const [updatedValue, setUpdatedValue] = useState(value)

  useEffect(() => {
    if (value) {
      setUpdatedValue(value)
    }
  }, [value, setUpdatedValue])

  const onKeyUp = e => {
    switch (keycode(e)) {
      case 'enter':
        onUpdate()
        break

      case 'esc':
        closePopover()
        break

      default:
        break
    }
  }

  const onRequestClose = () => {
    setUpdatedValue(value)
    closePopover()
  }

  const onChange = v => {
    setUpdatedValue(v)
  }

  const onUpdate = () => {
    if (isValid(value, updatedValue)) {
      onEdit(updatedValue)
    }
    closePopover()
  }

  return (
    <div className='mb-[15px]'>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        position={POPOVER_POSITIONS.TOP}
        onRequestClose={onRequestClose}
      >
        <Card>
          <CardBody>
            <div className='mb-[15px]'>
              <V2TextField
                autoFocus
                onKeyUp={onKeyUp}
                value={updatedValue}
                floatingLabelText={floatingLabelText}
                onChange={onChange}
              />
            </div>
            <div className='flex justify-end'>
              <Button
                label='Cancel'
                rank={BUTTON_RANKS.SECONDARY}
                onClick={onRequestClose}
                className='mr-2'
              />
              <Button
                label='Update'
                rank={BUTTON_RANKS.PRIMARY}
                onClick={onUpdate}
                disabled={!isValid(value, updatedValue)}
              />
            </div>
            <div />
          </CardBody>
        </Card>
      </Popover>
    </div>
  )
}

PopoverEditTextField.propTypes = {
  isOpen: PropTypes.bool,
  isValid: PropTypes.func,
  closePopover: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  floatingLabelText: PropTypes.string,
  value: PropTypes.string
}

PopoverEditTextField.defaultProps = {
  isOpen: false,
  isValid: (value, updatedValue) => {
    if (value === updatedValue || !updatedValue || updatedValue === '') {
      return false
    }

    return true
  },
  anchorEl: null,
  floatingLabelText: undefined,
  value: ''
}

export default PopoverEditTextField
