import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { setSelectedNavItem } from 'dux/v2_nav'

import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { NAV_ITEMS, NAV_ITEM_KEYS } from 'constants/v2_nav'
import MainNavItem from './main_nav_item'

function ResultsNavItem({ showTooltip, selectedNavItem, groupIdToFilterBy }) {
  const resultsRoute = groupIdToFilterBy
    ? `${NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].route}/${groupIdToFilterBy}`
    : NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].route

  const history = useHistory()
  const dispatch = useDispatch()
  /* Disallowed role guards are temporary as we will open these up
    when the sections have been hardened appropriately for the role */
  return (
    <MainNavItem
      selected={selectedNavItem === NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].id}
      navItemId={NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].id}
      isCollapsed={showTooltip}
      icon={<FontAwesomeIcon icon={faChartBar} />}
      label={NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].label}
      tooltip={NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].label}
      onClick={() => {
        history.push(resultsRoute)
        dispatch(setSelectedNavItem(NAV_ITEMS[NAV_ITEM_KEYS.RESULTS].id))
      }}
    />
  )
}

ResultsNavItem.propTypes = {
  selectedNavItem: PropTypes.string,
  groupIdToFilterBy: PropTypes.string,
  showTooltip: PropTypes.bool,
}

ResultsNavItem.defaultProps = {
  selectedNavItem: null,
  groupIdToFilterBy: null,
  showTooltip: false,
}

export default ResultsNavItem
