import reducer from 'dux/helpers/reducer'

const initialState = {
  filteredAttributes: {}
}

// Constants
const SET_FILTERED_ATTRIBUTES = 'v3Groups/SET_FILTERED_ATTRIBUTES'

function reduceSetFilteredAttributes(state, action) {
  return {
    ...state,
    filteredAttributes: action.filteredAttributes
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_FILTERED_ATTRIBUTES]: reduceSetFilteredAttributes
  },
  initialState
)

function setFilteredAttributes(filteredAttributes) {
  return {
    type: SET_FILTERED_ATTRIBUTES,
    filteredAttributes
  }
}

// Export necessary action types and action creators
export { setFilteredAttributes }
