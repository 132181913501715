/* eslint-disable no-param-reassign */
import get from 'lodash/get'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'

function isPaginated(action) {
  const query = get(action, 'query', {})
  return Object.keys(query).some(qKey => includes(qKey, 'page'))
}

function parseOptions(action, key, keyParent = false) {
  const query = get(action, 'query', {})
  return Object.keys(query).reduce((opts, qKey) => {
    if (includes(qKey, key)) {
      const matches = qKey.match(/\[(.*?)\]/)
      if (matches && matches[1]) {
        if (keyParent) {
          opts[qKey] = query[qKey]
        } else {
          opts[matches[1]] = query[qKey]
        }
      }
    }

    return opts
  }, {})
}

function formatSearchOptions(searchOptions) {
  const { searchText } = searchOptions

  const filter = {}
  if (searchText) {
    filter[`filter[fuzzy]`] = searchText
  }

  return filter
}

function getKeyValues(params) {
  if (isEmpty(params)) {
    return []
  }

  const paramsKeys = Object.keys(params)

  // Sort keys alphabetically so that generated composite keys
  // are identical despite object order
  paramsKeys.sort()

  return paramsKeys.map(key => `${key}=${params[key]}`)
}

function getPageKey(params, pageOptions, searchOptions) {
  const page = {
    ...pageOptions,
    limit: pageOptions?.rowsPerPage,
    offset: pageOptions?.page * pageOptions?.rowsPerPage
  }

  let search = searchOptions || {}
  if (page.searchOptions) {
    search = formatSearchOptions(page.searchOptions)
    delete page.searchOptions
  }

  let query = {}
  if (page.query) {
    query = page.query
    delete page.query
  }

  const combinedFilterOptions = Object.assign(search, query, {})

  const pageKeyArr = [
    ...getKeyValues(params),
    ...getKeyValues(page),
    ...getKeyValues(combinedFilterOptions)
  ]

  return pageKeyArr.join(',')
}

function getPageQueryOptions(passedOptions) {
  const options = {
    ...passedOptions
  }

  if (options.page) {
    options.page = {
      ...options.page,

      limit: options.page?.rowsPerPage,
      offset: options.page?.page * options.page?.rowsPerPage
    }

    if (!options.query) {
      options.query = {}
    }

    Object.keys(options.page).forEach(pageKey => {
      options.query[`page[${pageKey}]`] = options.page[pageKey]
    })
  }

  return options
}

export { isPaginated, parseOptions, getPageKey, getPageQueryOptions }
