import reducer from 'dux/helpers/reducer'
import UAParser from 'ua-parser-js'

const initialState = {
  browser: {},
  platform: 'Web',
  device_type: null,
  device_model: null,
  carrier: null,
  app_version: null,
  os_name: null,
  os_version: null,
  language: null,
  start_version: null,
  width: null,
  height: null
}

// Constants
const UPDATE_DEVICE_INFO = 'device/UPDATE_DEVICE_INFO'
const UPDATE_WINDOW_SIZE = 'device/UPDATE_WINDOW_SIZE'

// Reducers
function reduceGetDeviceInfo(state = initialState, action) {
  const { browser, device, os } = UAParser(action.userAgent) || {}
  const language = action.language

  return {
    ...state,
    device_type: device.type,
    device_model: os.name,
    carrier: device.vendor,
    os_name: browser.name,
    os_version: browser.major,
    browser,
    language
  }
}

function reduceGetWindowSize(state = initialState, action) {
  return {
    ...state,
    width: action.width,
    height: action.height
  }
}

// Combined reducer functions
export default reducer(
  {
    [UPDATE_DEVICE_INFO]: reduceGetDeviceInfo,
    [UPDATE_WINDOW_SIZE]: reduceGetWindowSize
  },
  initialState
)

// Action creators
function updateDeviceInfo(userAgent, language) {
  return {
    type: UPDATE_DEVICE_INFO,
    userAgent,
    language
  }
}

function updateWindowSize(width, height) {
  return {
    type: UPDATE_WINDOW_SIZE,
    width,
    height
  }
}

// Export necessary action types and action creators
export { updateDeviceInfo, updateWindowSize }
