import React from 'react'
import PropTypes from 'prop-types'

import { AuthComponentByRole } from 'containers/authorization'
import SubMenuContent, {
  SUB_MENU_ALLOWED_ROLES
} from '../sub_menu/sub_menu_content'

function SubMenuPopoverContent({ navItemId, onClick }) {
  return (
    <div role='button' onClick={onClick}>
      <AuthComponentByRole allowedRoles={SUB_MENU_ALLOWED_ROLES}>
        <SubMenuContent
          displayTitle={false}
          className='collapsed-sub-menu-items'
          navItemId={navItemId}
        />
      </AuthComponentByRole>
    </div>
  )
}

SubMenuPopoverContent.propTypes = {
  navItemId: PropTypes.string,
  onClick: PropTypes.func
}

SubMenuPopoverContent.defaultProps = {
  navItemId: null,
  onClick: () => {}
}

export default SubMenuPopoverContent
