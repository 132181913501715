/**
 * Enum for user interface states or phases as we are calling them.
 * @readonly
 * @enum {string}
 */

const Phases = {
  BLANK: 'blank', // initial view
  LOADING: 'loading', // view once calls have started
  ERROR: 'error', // any error states, including lack of permissions
  EMPTY: 'empty', // finished loading but there is no data
  OBSCURED: 'obscured', // finished loading but data is hidden for anonymity
  IDEAL: 'ideal', // finished loading and there is data
  SAVING: 'saving' // finished loading but disabled user interaction for saving data
}

export default Phases
