import min from 'lodash/min'
import forEach from 'lodash/forEach'
import computeSortScore, {
  PERFECT,
  STARTS_WITH,
  INITIALISM,
  INCLUDES,
  FUZZY,
  NOT_A_MATCH
} from './compute_sort_score'

/**
 * Search through the items to find exact and "fuzzy" matches.
 * Non-matches are eliminated. The returned matches are in order with
 * better matches before worse matches.
 *
 * I call this a naive fuzzy search, because matches are not scored by a common fuzzy search
 * algorithm. In fuzzy search algorithms, a text is scored relative to a pattern by seeing how
 * many changes it would require to match the pattern exactly. That is NOT how this
 * algorithm works.
 *
 * @param {Object[]} items Items to search
 * @param {String} propertiesToSearch The properties on each item to search
 * @param {String} pattern The search query
 * @returns {Object[]} Matching items ordered by quality of the match
 */
export default function naiveFuzzySearch(items, propertiesToSearch, pattern) {
  const matches = {
    [PERFECT]: [],
    [STARTS_WITH]: [],
    [INITIALISM]: [],
    [INCLUDES]: [],
    [FUZZY]: []
  }

  forEach(items, item => {
    const score = min(
      propertiesToSearch.map(propertyToSearch =>
        computeSortScore(item, propertyToSearch, pattern)
      )
    )
    if (score !== NOT_A_MATCH) {
      matches[score].push(item)
    }
  })

  return matches[PERFECT].concat(
    matches[STARTS_WITH],
    matches[INITIALISM],
    matches[INCLUDES],
    matches[FUZZY]
  )
}
