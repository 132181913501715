import reducer from 'dux/helpers/reducer'

const initialState = {
  open: false
}

// Constants
const SET_INTERCOM_OPEN_STATE = 'intercom/SET_INTERCOM_OPEN_STATE'

function reduceSetIntercomOpenState(state, action) {
  return {
    ...state,
    open: action.open
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_INTERCOM_OPEN_STATE]: reduceSetIntercomOpenState
  },
  initialState
)

// Action creators
function setIntercomOpenState(open) {
  return {
    type: SET_INTERCOM_OPEN_STATE,
    open
  }
}

// Export necessary action types and action creators
export { setIntercomOpenState }
