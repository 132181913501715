import { createSelector } from 'reselect'
import union from 'lodash/union'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import set from 'lodash/set'
import mapValues from 'lodash/mapValues'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import {
  selectEmployeeChoicesForAutoComplete,
  selectHydratedActiveEmployeesForCurrentOrg
} from 'selectors/employees'
import { entities, methods } from 'dux/api/action_types'
import { selectSelectedCampaign } from 'selectors/campaigns'
import {
  selectCurrentOrganizationDisplayName,
  selectCurrentOrganizationId
} from 'selectors/organizations'
import { selectCategory } from 'selectors/categories'
import { selectCurrentOrgSubstitutionsMappedByType } from 'selectors/substitutions'
import {
  createPropGetter,
  createEntityGetter,
  createPhaseSelector
} from 'utils/selectors'
import { selectV3GroupAncestry } from 'selectors/v3_groups'
import { selectSmartPulseSurveys } from 'selectors/surveys'
import { EMPLOYMENT_STATUSES } from 'constants/employment'
import {
  MIN_NOTIFICATION_COUNT,
  MESSAGE_TEMPLATE_TREE,
  INVITATION_TYPES
} from 'constants/campaigns'
import CAMPAIGN_MESSAGE_TYPES from 'constants/campaign_message_types'
import OPEN_RESPONSE_TYPES from 'constants/open_response_types'
import CampaignTypes from 'constants/campaign_types'
import dayjs from 'dayjs'
import { skipWeekends } from 'utils/native/date'
import includes from 'lodash/includes'

export const CAMPAIGN_FORM_NAME = 'campaignForm'
export const SURVEY_FORM_NAME = 'surveyForm'

function getEmplifyFormCampaignData(state) {
  return get(state, 'emplifyForm.campaignForm.data') || EMPTY_OBJECT
}

function getEmplifyFormSurveyData(state) {
  return get(state, 'emplifyForm.surveyForm.data') || EMPTY_OBJECT
}

function getSurveyStatementsForSurvey(state, ownProps) {
  return filter(
    state.entities['survey-statements'],
    statement => `${statement.surveyId}` === `${get(ownProps, 'survey.id')}`
  )
}

/**
 * Select the compiled, ordered statements for the campaign. Uses campaign statements
 * if available, or falls back to organization statements.
 * @param {Object} state - entire Redux state tree
 * @returns {Object[]} - An ordered collection of statements
 */
const selectCompiledSurveyStatements = createSelector(
  createEntityGetter(entities.CAMPAIGN_STATEMENTS),
  createEntityGetter(entities.ORGANIZATION_STATEMENTS),
  createPropGetter('campaignId'),
  createPropGetter('survey.id'),
  (campaignStatements, orgStatements, campaignId, surveyId) => {
    let surveyStatements = filter(
      campaignStatements,
      statement => `${statement.campaignId}` === `${campaignId}`
    )
    if (isEmpty(surveyStatements)) {
      surveyStatements = filter(
        orgStatements,
        statement => `${statement.surveyId}` === `${surveyId}`
      )
    }

    return orderBy(surveyStatements, 'order')
  }
)

/**
 * Select all variant surveys that belong to a category
 * that has been selected from the category dropdown.
 * @param {Object} state - entire Redux state tree
 * @returns {Object[]} - An ordered collection of surveys
 */
export const selectSelectedCategorySurveys = createSelector(
  selectSmartPulseSurveys,
  createPropGetter('categoryId'),
  (surveys, categoryId) => {
    const categorySurveys = filter(surveys, survey => {
      const surveyCategoryId = get(survey, 'statements[0].category.id')
      return `${surveyCategoryId}` === `${categoryId}` && survey.variant
    })

    return orderBy(categorySurveys, 'variant')
  }
)

/**
 * @param {Object} state - Entire redux state tree
 * @returns {Object} The request phase for the campaigns entity.
 */
export const selectCampaignPhase = createSelector(
  createPhaseSelector({
    entity: entities.CAMPAIGNS,
    method: methods.POST,
    mapProps: ({ organizationId }) => ({ organizationId })
  }),
  phase => phase
)

const EMPTY_OBJECT = {}

/**
 * @param {Object} state - Entire redux state tree
 * @param {Object} props
 * @param {String} props.campaignId - Parent campaign of the Smart Pulse we are creating
 * @returns {Object} All V3 groups available to be picked for SPs
 */
export const selectV3GroupsWithParticipation = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('campaignId'),
  (groups, participations, campaignId) => {
    const groupsWithParticipation = mapValues(groups, group => ({
      ...group,
      participation: participations[`${campaignId}:${group.id}`] || EMPTY_OBJECT
    }))

    return groupsWithParticipation
  }
)

export const selectV3GroupsForPicker = createSelector(
  selectV3GroupsWithParticipation,
  groups => groups
)

/**
 * Select all of the survey statements for the selected survey
 *
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps.survey - the survey we are retrieviing statements for
 * @returns {Object} - An array of survey statements
 */
export const selectStatementsForSurvey = createSelector(
  getSurveyStatementsForSurvey,
  createEntityGetter(entities.STATEMENTS),
  (surveyStatements, statements) =>
    surveyStatements.map(s => statements[s.statementId])
)

/**
 * Select all of the survey statement bodies for the survey creator
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps.survey - the survey we are retrieviing statements for
 * @returns {Object} - An array of survey statements
 */
export const selectStatementsForSurveyCreator = createSelector(
  selectStatementsForSurvey,
  statements => (statements.length > 0 ? statements.map(s => s.body) : [''])
)

export const selectValidParticipants = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  getEmplifyFormCampaignData,
  createPropGetter('groupId'),
  createPropGetter('parentCampaignId'),
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createEntityGetter(entities.V3_MEMBERSHIPS),
  selectV3GroupAncestry,
  (
    employees,
    formData,
    passedV3GroupId,
    parentCampaignId,
    v3GroupParticipations,
    v3Memberships,
    groupAncestry
  ) => {
    if (isEmpty(employees)) {
      return []
    }

    const v3GroupId = formData.v3GroupId || passedV3GroupId
    const v3GroupIds = map(groupAncestry, g => g.id)

    let participantIds
    if (parentCampaignId) {
      const participationKey = `${parentCampaignId}:${v3GroupId}`
      participantIds = get(
        v3GroupParticipations,
        [participationKey, 'employeeIds'],
        []
      )
    } else {
      participantIds = filter(v3Memberships, m =>
        includes(v3GroupIds, `${m.groupId}`)
      ).map(m => m.memberId)
    }

    // Filter participants by selected Employee Attributes
    const targetedEmployeeAttributes = get(
      formData,
      'targetedEmployeeAttributes',
      {}
    )

    // We create a `union` of all employeeIds within an Attribute Type
    // but an `intersection` of employeeIds across Attribute Types
    const employeeIdsByAttributeType = {}
    forEach(targetedEmployeeAttributes, tea => {
      if (tea.employeeIds) {
        set(
          employeeIdsByAttributeType,
          tea.attributeType,
          union(employeeIdsByAttributeType[tea.attributeType], tea.employeeIds)
        )
      }
    })

    const filteredEmployeeIds = intersection(...map(employeeIdsByAttributeType))
    if (isEmpty(targetedEmployeeAttributes)) {
      return filter(participantIds, pid => !!employees[pid]).map(
        pid => employees[pid]
      )
    }

    const filteredParticipantIds = intersection(
      filteredEmployeeIds,
      participantIds
    )

    const validParticipants = []
    forEach(filteredParticipantIds, pid => {
      const employee = employees[pid]
      if (
        !!employee &&
        employee.receivingSurveys &&
        employee.employmentStatus === EMPLOYMENT_STATUSES.ACTIVE
      ) {
        validParticipants.push(employee)
      }
    })

    return validParticipants
  }
)

/**
 * TODO: Could refactor this to use selectTargetGroupMembers
 *
 * Select the valid participants for the current campaign. If a group is selected in the form,
 * it will filter the results based on memberships.
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - An array of valid participants for the campaign
 */
export const selectPartcipantsForCurrentCampaignV3Group = createSelector(
  selectHydratedActiveEmployeesForCurrentOrg,
  createPropGetter('parentCampaignId'),
  getEmplifyFormCampaignData,
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  (employees, parentCampaignId, formData, v3GroupParticipations) => {
    if (isEmpty(employees)) {
      return []
    }

    if (!formData.v3Group) {
      return Object.values(employees)
    }

    const v3GroupId = get(formData, ['v3Group', 'id'])
    const participationKey = `${parentCampaignId}:${v3GroupId}`

    const participantIds = get(
      v3GroupParticipations,
      [participationKey, 'employeeIds'],
      []
    ).filter(pid => !!employees[pid])

    const targetedEmployeeAttributes = get(
      formData,
      'targetedEmployeeAttributes',
      {}
    )

    // We create a `union` of all employeeIds within an Attribute Type
    // but an `intersection` of employeeIds across Attribute Types
    const employeeIdsByAttributeType = {}
    forEach(targetedEmployeeAttributes, tea => {
      if (tea.employeeIds) {
        set(
          employeeIdsByAttributeType,
          tea.attributeType,
          union(employeeIdsByAttributeType[tea.attributeType], tea.employeeIds)
        )
      }
    })

    const intersectedEmployeeIds = intersection(
      ...map(employeeIdsByAttributeType)
    )
    if (isEmpty(targetedEmployeeAttributes)) {
      return map(participantIds, pid => employees[pid])
    }

    const intersectedParticipantIds = intersection(
      intersectedEmployeeIds,
      participantIds
    )

    return map(intersectedParticipantIds, pid => employees[pid])
  }
)

export const selectTestMessageRecepientChoices = createSelector(
  createPropGetter('messageType'),
  selectEmployeeChoicesForAutoComplete,
  (messageType, employeeChoices) =>
    employeeChoices.reduce((result, employeeChoice) => {
      if (
        messageType === CAMPAIGN_MESSAGE_TYPES.EMAILS &&
        employeeChoice.email
      ) {
        result.push(employeeChoice)
      }
      if (
        messageType === CAMPAIGN_MESSAGE_TYPES.SMS &&
        employeeChoice.phoneNumber
      ) {
        result.push(employeeChoice)
      }
      if (
        messageType === CAMPAIGN_MESSAGE_TYPES.EMAILS_SMS &&
        (employeeChoice.email || employeeChoice.phoneNumber)
      ) {
        result.push(employeeChoice)
      }
      return result
    }, [])
)

/**
 * Select the campaign statement to render for a SmartPulse. By default this
 * will be pulled from the survey-statement record unless we have provided overrides
 * @param {Object} state - entire Redux state tree
 * @returns {String} a statement to render
 */
export const selectCampaignStatement = createSelector(
  createPropGetter('openResponseType'),
  createPropGetter('disabled'),
  getEmplifyFormCampaignData,
  selectCompiledSurveyStatements,
  (openResponseType, disabled, campaignFormData, statements) => {
    const defaultStatement =
      'Select a question from the Question Bank to preview the question'
    const statementCampaignId = get(statements, '[0].campaignId', null)
    // statements with campaign id mean we are in an already created SmartPulse state.
    const body = disabled && statementCampaignId ? 'compiledBody' : 'body'
    let statement = get(statements, `[0].${body}`, defaultStatement)
    if (openResponseType === OPEN_RESPONSE_TYPES.CUSTOM) {
      const defaultCustomStatement = 'Enter a custom question'
      statement = get(
        campaignFormData,
        'templateData.substitutions.statement',
        defaultCustomStatement
      )
    }
    return statement
  }
)

export const selectCurrentAttributeGroupId = createSelector(
  getEmplifyFormCampaignData,
  data => {
    const currentAttributeGroupId = get(data, 'v3Group.parentGroupId', null)
    if (!currentAttributeGroupId) {
      return get(data, 'v3Group.id', null)
    }
    return currentAttributeGroupId
  }
)

/**
 * Select the campaign background to render for a SmartPulse.
 * @param {Object} state - entire Redux state tree
 * @returns {String} a statement to render
 */
export const selectCampaignBackground = createSelector(
  getEmplifyFormCampaignData,
  selectCategory,
  selectSelectedCampaign,
  (campaignFormData, category, campaign) => {
    const campaignObject = isEmpty(campaignFormData)
      ? campaign
      : campaignFormData
    const statementOverride = get(
      campaignObject,
      'templateData.substitutions.background'
    )
    const background = get(category, 'compiledBackground')

    const defaultStatement =
      'Select a Driver or Condition to preview the background'
    return statementOverride || background || defaultStatement
  }
)

/**
 * Select the organization phrase to render in the inline editable field.
 * This will use what is set in the form if available, otherwise will build
 * the name through a somewhat fragile inferrence of substitution data.
 * @param {Object} state - entire Redux state tree
 * @returns {String} a phrase to render
 */
export const selectOrganizationPhrase = createSelector(
  getEmplifyFormCampaignData,
  selectCurrentOrganizationDisplayName,
  (campaignFormData, orgValue) => {
    return get(
      campaignFormData,
      'templateData.substitutions.organization',
      orgValue
    )
  }
)

/**
 * TODO: Clean up this selector #164410600
 * Select the group phrase to render in the inline editable field.
 * This will use what is set in the form if available, otherwise will build
 * the name through a somewhat fragile inferrence of substitution data.
 * @param {Object} state - entire Redux state tree
 * @returns {String} a phrase to render
 */
export const selectGroupPhrase = createSelector(
  getEmplifyFormCampaignData,
  selectCurrentOrgSubstitutionsMappedByType,
  (campaignFormData, substitutions) => {
    const groupSubstitution = substitutions.group || {}
    let groupValue =
      groupSubstitution.substitution || campaignFormData.displayName
    // Using the default, which is 'the {groupName} {groupTypeName}'
    if (!groupValue || groupSubstitution.isDefault) {
      let groupName
      const v3Group = campaignFormData.v3Group || null
      if (v3Group) {
        groupName = v3Group.displayValue || v3Group.name
        groupValue = `the ${groupName} group`
      } else {
        groupValue = 'the Organization'
      }
    }
    return get(campaignFormData, 'templateData.substitutions.group', groupValue)
  }
)

/**
 * Select the data necessary to create / update a campaign. Most of this information
 * will come from the form, but there are a few other pieces we need to assemble.
 * @param {Object} state - entire Redux state tree
 * @returns {Object} campaign payload
 */
export const selectCampaignPayload = createSelector(
  getEmplifyFormCampaignData,
  getEmplifyFormSurveyData,
  selectCurrentOrganizationId,
  selectValidParticipants,
  (efFormData, efSurveyData, organizationId, participants) => {
    return {
      ...efFormData,
      survey: efSurveyData,
      participants,
      organizationId
    }
  }
)

/**
 * Determine the appropriate redirect URL for the campaign wizard.
 * @param {Object} state - entire Redux state tree
 * @param {String} props.parentCampaignId - If provided, uses this for smart
 * pulse redirect. Necessary when creating a new campaign.
 * @param {String} props.campaignType - If provided, uses this for smart
 * pulse redirect. Necessary when creating a new campaign.
 * @returns {String} a URL to navigate to
 */
export const selectCampaignRedirectUrl = createSelector(
  selectSelectedCampaign,
  createPropGetter('parentCampaignId'),
  createPropGetter('campaignType'),
  function getRedirectUrl(campaign, parentCampaignId, campaignType) {
    let url = ''
    const parentId = parentCampaignId || get(campaign, 'parentCampaignId')
    const campaignTypeName = campaignType || get(campaign, 'campaignType')

    switch (campaignTypeName) {
      case CampaignTypes.SMART_PULSE:
        url = `/admin/campaigns/${parentId}/smart_pulse`
        break
      case CampaignTypes.CUSTOM:
        url = '/custom_campaigns_admin'
        break
      default:
        url = '/admin/campaigns'
    }
    return url
  }
)

function getPartial(partials, campaignType, invitationType, emailPiece) {
  const partial = get(MESSAGE_TEMPLATE_TREE, [
    campaignType,
    invitationType,
    emailPiece
  ])
  const compiled = get(partials, `${partial}.compiled`, '')
  return compiled.trim()
}

export const selectInitialCampaignNotifications = createSelector(
  createEntityGetter(entities.COMPILED_MESSAGE_PARTIALS),
  createPropGetter('campaignType'),
  (partials, campaignType) => {
    const notifications = []
    const reminderCount = MIN_NOTIFICATION_COUNT[campaignType]
    let nextMessageDate = dayjs()
    for (let i = 0; i < reminderCount; i += 1) {
      const notification = { templateData: {} }
      notification.scheduledAt = nextMessageDate

      let invitationType = INVITATION_TYPES.REMINDER
      let templateId = `${campaignType}/reminder`
      if (i === 0) {
        invitationType = INVITATION_TYPES.INVITE
        templateId = `${campaignType}/invite`
      }
      if (i === reminderCount - 1) {
        invitationType = INVITATION_TYPES.FINAL
        templateId = `${campaignType}/final`
      }
      notification.templateId = templateId

      if (campaignType === CampaignTypes.CUSTOM) {
        notification.templateData.emailSubject = getPartial(
          partials,
          campaignType,
          invitationType,
          'subject'
        )
        notification.templateData.emailBody = getPartial(
          partials,
          campaignType,
          invitationType,
          'body'
        )
      }
      nextMessageDate = skipWeekends(dayjs(nextMessageDate).add(1, 'd'))
      notifications.push(notification)
    }
    return notifications
  }
)
