import React from 'react'
import PropTypes from 'prop-types'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import { Button, BUTTON_RANKS } from 'furniture/buttons'

const Navigate15Five = ({ returnToFFUrl }) => {
  const navigate15Five = () => {
    window.location.href = returnToFFUrl || '/'
  }
  return (
    <div
      className='flex justify-between items-center cursor-pointer'
      data-static-id='navigate-to-15Five-button-container'
      role='button'
      onClick={navigate15Five}
    >
      <Button
        className='back-to-home-button'
        rank={BUTTON_RANKS.TERTIARY}
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
      />
      <p className='pl-4 font-semibold leading-24'>Back to home</p>
    </div>
  )
}

Navigate15Five.propTypes = { returnToFFUrl: PropTypes.string }

Navigate15Five.defaultProps = { returnToFFUrl: '/' }

export default Navigate15Five
