import get from 'lodash/get'
import { createSelector } from 'reselect'
import { serializeRequestParams } from 'dux/api/helper'
import { statuses, methods } from 'dux/api/action_types'
import Phases from 'constants/phases'

/**
 * createRequestsFromParams - Converts param array from createPhaseSelector to a single param format
 *
 * @param {Object[]} [params=[]] Array of params.
 *  They can be objects, strings, additional arrays, or empty.
 *
 * @return {Object[]} Returns array of Request objects.
 */
function createRequestsFromParams(params = []) {
  if (typeof params[0] === 'string') {
    return [
      {
        entity: params[0],
        method: params[1],
        mapProps: params[2]
      }
    ]
  }

  // Treat it as an empty request was passed
  // console.error will occur below
  if (params.length === 0) {
    return [{}]
  }

  if (Array.isArray(params[0])) {
    return params[0]
  }

  return params
}

/**
 * This creates an unmemoized selector that simply extracts a single property out of props
 *
 * Why does this exist?
 * To cut down on selector bloat inside selector files.
 * Any selector that uses props must depend on a "prop extractor" selector.
 * In many files we have simple, private selectors that simply get a value out of the props.
 * These selectors are often repeated in many files
 *
 * @param {String} propertyPath - The path of the property to extract out of props
 * @returns {Object} - Whatever value is stored on the given property, provided it exists
 */
export function createPropGetter(propertyPath) {
  return (state, props = {}) => get(props, propertyPath)
}

/**
 * This creates an unmemoized selector that extracts a value from the entities store.
 * @param {String} entityType The type of the entity you want to retreive from the store
 */
export function createEntityGetter(entityType) {
  return state => state.entities[entityType]
}

/**
 * This creates a selector that extracts pages for a specified entity from the store.
 * @param {String} entityType The entity type for the pages you want to retreive from the store
 */
export function createPagesGetter(entityType) {
  return state => state.pages[entityType]
}

/**
 * Parses out the statement id from the composite id of a campaign_statement object
 * @param {Object} statement The campaign_statement object
 * @returns {String} The parsed statement id of the campaign_statement
 */
export function getCampaignStatementId(statement) {
  return statement.id ? get(statement.id.split(':'), '[0]', null) : null
}

/**
 * This creates a memoized selector that returns a single entity of the requested
 * type given its ID as a property with the passed name.
 * @param {String} entityType The type of entity you want to retreive from the store
 * @param {string} idPropertyName The name of the property to extract from props to
 * be used as the primary key for entity lookup
 */
export function createEntitySelector(entityType, idPropertyName) {
  return createSelector(
    createPropGetter(idPropertyName),
    createEntityGetter(entityType),
    (id, entities) => get(entities, id)
  )
}

/**
 * Creates a selector that will look up a request status by the URL parameters of the request.
 * Note, the created selector assumes that ALL of the props are URL parameters,
 * so pass those in carefully!
 *
 * This util introduces an alternative to the `getRequestStateByParams` pattern.
 * I believe this approach is superior, because it allows selectors to depend on one very
 * particular piece of the requests subtree, rather than the entire requests subtree.
 *
 * @param {string} entity The entity type the request is about
 * @param {string} method The HTTP method of the request.
 *  See methods constant from modules/api/action_types
 * @returns {function} Selector that gets a request status by params
 */
export function createRequestStatusByParamsSelector(entity, method, mapProps) {
  return (state, props) => {
    const params = mapProps ? mapProps(props) : props
    const paramKey = serializeRequestParams(params)
    return state.requests[entity][method][paramKey] || statuses.PREREQUEST
  }
}

/**
 * createPhaseSelector - Generate request phase for a single entity or multiple entities.
 *  Varity of options of calling the createPhaseSelector
 *  ```
 *  function(param1, param2, param3)
 *  function([{ param1, param2, param3 }, { param1, param2, param3 }])
 *  function({ param1, param2, param3 }, { param1, param2, param3 })
 *  ```
 *
 * @param {Object|Object[]} [requests] Array of request objects, must contain valid entity
 * @param {enum} requests.entity An entity from our backend
 * @param {enum} [requests.method=get] The api request's method `GET`, `PATCH`, or `DELETE`.
 *  Defaults to `get` if not passed
 * @param {func} [requests.mapProps] mapProps function allow for flexible params.
 *  Enables droping, adding, or renaming passed props
 * @param {enum} entity An entity from our backend
 * @param {enum} [method=get] The api request's method `GET`, `PATCH`, or `DELETE`.
 *  Defaults to `get` if not passed
 * @param {func} [mapProps] mapProps function allow for flexible params.
 *  Enables droping, adding, or renaming passed props
 *
 * @return {enum} Phase returned from the selector. `IDEAL`, `ERROR`, `BLANK`, `LOADING`
 */
export function createPhaseSelector(...selectorParams) {
  return createSelector(
    state => state.requests,
    (state, props) => props,
    (stateRequests, props) => {
      const requests = createRequestsFromParams(selectorParams)
      const requestStatuses = []
      const requestMethods = requests.map(r => get(r, 'method', methods.GET))

      requests.forEach((request = {}) => {
        const { entity } = request
        if (!stateRequests[entity]) {
          // Requests Reducer, Entities constants, or Phase Selector were not created correctly
          console.error('createPhaseSelector received a nonexistant entity', {
            entity,
            selectorParams
          })
          requestStatuses.push(statuses.FAILURE)
        } else {
          const method = request.method ? request.method : methods.GET
          const params = !request.mapProps ? props : request.mapProps(props)
          const paramKey = serializeRequestParams(params)
          const status =
            stateRequests[entity][method][paramKey] || statuses.PREREQUEST
          requestStatuses.push(status)
        }
      })

      if (requestStatuses.every(status => status === statuses.SUCCESS)) {
        return Phases.IDEAL
      }

      if (requestStatuses.some(status => status === statuses.FAILURE)) {
        return Phases.ERROR
      }

      if (requestStatuses.every(status => status === statuses.PREREQUEST)) {
        return Phases.BLANK
      }

      // This code for determining loading versus saving will need to be
      // revisited in a few scenarios.
      // 1. If we split out DELETING as a separate phase from SAVING
      // 2. If we have a case where we have mixed read (GET) and write (PATCH, PUT DELETE) calls,
      //    but we want LOADING to be the phase when in-flight instead of SAVING
      // 3. Any other case where a caller wants control to override the in-flight or fallback phase.
      if (
        requestMethods.some(function isWrite(method) {
          return method !== methods.GET
        })
      ) {
        return Phases.SAVING
      }
      return Phases.LOADING
    }
  )
}
