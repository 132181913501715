import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

class CardBody extends PureComponent {
  render() {
    const cx = cn(
      'furniture-card-body',
      { [this.props.className]: !!this.props.className },
      { 'no-content': this.props.noContent }
    )

    return (
      <div style={this.props.style} className={cx}>
        {this.props.children}
      </div>
    )
  }
}

CardBody.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  noContent: PropTypes.bool
}
CardBody.defaultProps = {
  children: undefined,
  style: {},
  className: null,
  noContent: false
}

export default CardBody
