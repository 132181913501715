import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import timeoutPromise from 'utils/promise/timeout'

export function is401Response(response = {}) {
  return response.status === 401
}

/**
 * Serialize the params for an API request.
 * If the params are null, undefined, or an empty object, use null as a key.
 * @param {Object} params - the parameters to serialize
 */
export function serializeRequestParams(params) {
  if (params == null || isEmpty(params)) {
    return null
  }
  return sortBy(Object.keys(params))
    .map(paramKey => `${paramKey}=${params[paramKey]}`)
    .join(',')
}

export function checkForNewTokenAsync(
  state,
  apiLog,
  requestPath,
  timeout = 4000
) {
  // If we are currently checking our session give some time before letting
  // a bad token api call go
  let holdPromise = Promise.resolve()
  if (state.login.isCheckingSession && state.login.checkingSessionPromise) {
    apiLog('{delayed}', { path: requestPath, css: 'color:blue' })
    holdPromise = state.login.checkingSessionPromise
  }

  return (
    timeoutPromise(
      holdPromise,
      timeout,
      'Timed out while waiting on checkSession to finish'
    )
      .then((results = {}) => results.accessToken)
      // we don't care if this fails, the api calls will cover this error
      .catch(e => {
        console.error(e)
      })
  )
}

// TODO:NOTE This function does not support relationships.
// In order to complete this we need to do it a lower level
// we need to override where we normalize from jsonapi format
/**
 * createNewId - create a new override with serialized query params and an id
 *
 * @param {string} id resource id
 * @param {string} serializedQueryId serializedQueryParams
 * @param {string} [separator=:] seperator that is inserted between the two ids
 *
 * @return {string} new id that is a combination of an id and serializeQueryParams
 */
function createNewId(id, serializedQueryId, separator = ':') {
  return `${id}${separator}${serializedQueryId}`
}

/**
 * overrideEntityIdsWithParams - Iterates through normalizedJsonApiData
 * and returns entities with new overrided ids
 * generated from the serializedQueryParams
 *
 * @param {object} normalizedData normalizedData from a jsonapi-normalizer
 * @param {object} params params object used on the api call
 *
 * @return {object} New normalizedData with all ids overrided from createNewId function
 */
export function overrideEntityIdsWithParams(normalizedData, params) {
  if (!params) {
    return normalizedData
  }

  const serializedQueryId = serializeRequestParams(params)
  const entityKeys = Object.keys(normalizedData.entities)
  const resultKeys = Object.keys(normalizedData.result)
  const newEntities = {}
  const newResults = {}

  entityKeys.forEach(entityKey => {
    const entities = {}
    const receivedIds = Object.keys(normalizedData.entities[entityKey])
    receivedIds.forEach(receivedId => {
      const id = createNewId(receivedId, serializedQueryId)
      entities[id] = {
        ...normalizedData.entities[entityKey][receivedId],
        id
      }
    })

    newEntities[entityKey] = entities
  })

  resultKeys.forEach(entityKey => {
    const resultIds = []
    normalizedData.result[entityKey].forEach(receivedId => {
      const id = createNewId(receivedId, serializedQueryId)
      resultIds.push(id)
    })

    newResults[entityKey] = resultIds
  })

  const newData = {
    ...normalizedData,
    entities: newEntities,
    result: newResults
  }

  return newData
}

/**
 * Finds keys within request path to match and validate with param object keys
 * @param {String} templatePath The path to make an HTTP request at. e.g., `"/organizations/:orgId"`
 * @returns {Array} Array of found keys to compare to param object keys
 */
export function getPathKeys(templatePath) {
  // The following regex matches template values that exist between
  // the delimiters (":" and "/") OR (":" and "?") OR
  // (":" and "#") OR (":" and the end of the string)
  const re = /:(.*?)(\/|\?|#|$)/g
  const pathKeys = []

  let matches
  // eslint-disable-next-line no-cond-assign
  while ((matches = re.exec(templatePath))) {
    pathKeys.push(matches[1])
  }
  return pathKeys
}

/**
 * Validate that required templatePath values are present within params object
 * @param {String} templatePath The path to make an HTTP request at. e.g., `"/organizations/:orgId"`
 * @param {Object} params The params containing values to be replaced into the API templatePath
 * @returns {Boolean} Are all required templatePath values present within params object?
 */
export function validateParams(templatePath, params) {
  let isValid = true
  const pathKeys = getPathKeys(templatePath)

  pathKeys.forEach(pathKey => {
    if (!params[pathKey]) {
      isValid = false
    }
  })

  return isValid
}
