import get from 'lodash/get'

import {
  selectUserRole,
  selectResultAccessLevel,
  selectEmployee,
  selectPerson,
  selectOrganization,
  selectOrganizationCampaigns,
  selectOrganizationFinishedQuaterlyCampaigns,
  selectLatestQuarterlyOrganizationCampaign,
} from 'selectors/user'
import { selectGroupPermissionsCount } from 'selectors/group_filter'
import {
  selectExecutiveAdvisorForOrganization,
  selectCustomerSuccessManagerForOrganization,
} from 'selectors/roles'
import { selectOrganizationPackageProgress } from 'selectors/packages'

export function joinName({ firstName, lastName }) {
  if (!firstName && !lastName) {
    return undefined
  }

  if (!firstName) {
    return lastName
  }

  if (!lastName) {
    return firstName
  }

  return `${firstName} ${lastName}`
}

export function getIntercomUserAttributes(state) {
  const employee = selectEmployee(state) || {}
  const person = selectPerson(state) || {}
  const permissionsCount = selectGroupPermissionsCount(state)
  const resultsAccess = selectResultAccessLevel(state)
  const role = selectUserRole(state)
  const name = joinName(person)

  return {
    name,
    role,
    user_id: person.id,
    email: person.emailAddress,
    birth_date: employee.bornOn,
    phone: employee.mobilePhoneNumber,
    created_at: new Date(person.createdAt),
    start_date: employee.startedOn,
    results_access: resultsAccess,
    group_access_count:
      permissionsCount !== null ? permissionsCount : undefined,
    is_insights_user: true,
  }
}

export function getIntercomCompanyAttributes(state) {
  const organization = selectOrganization(state) || {}
  const campaigns = selectOrganizationCampaigns(state) || []
  const quarterlyCampaigns =
    selectOrganizationFinishedQuaterlyCampaigns(state) || []
  const lastQuarterlyCampaign =
    selectLatestQuarterlyOrganizationCampaign(state) || {}
  const companyExecutiveAdvisor = selectExecutiveAdvisorForOrganization(state)
  const companyCustomerSuccessManager =
    selectCustomerSuccessManagerForOrganization(state)
  const organizationPackageProgress =
    selectOrganizationPackageProgress(state) || {}

  // Rely on lodash.get to provide undefineds when package progress is not loaded in yet.
  // We don't clear any previous values out in intercom and null will clear previous values we have set.
  const employeeCount = get(organizationPackageProgress, 'employeeCount')
  // This field is based on the number of users who have results access.
  const packageProgressLeaderCount = get(
    organizationPackageProgress,
    'leaderCount',
  )

  const totalCampaignsCount =
    campaigns.length === 0 ? undefined : campaigns.length

  const quarterlyCampaignsCount =
    quarterlyCampaigns.length === 0 ? undefined : quarterlyCampaigns.length

  const lastQuarterlyCampaignDate =
    lastQuarterlyCampaign && lastQuarterlyCampaign.startedAt
      ? new Date(lastQuarterlyCampaign.startedAt)
      : undefined

  return {
    id: organization.id,
    name: organization.name,
    company_id: organization.ffCompanyId,
    ff_company_id: organization.ffCompanyId,
    is_ff_managed: organization.isFFManaged,
    created_at: new Date(organization.createdAt),
    total_campaign_count: totalCampaignsCount,
    size: employeeCount,
    package_progress_leader_count: packageProgressLeaderCount,
    quarterly_campaign_count: quarterlyCampaignsCount,
    last_quarterly_campaign_date: lastQuarterlyCampaignDate,
    executive_advisor: get(companyExecutiveAdvisor, 'emailAddress'),
    customer_success_manager: get(
      companyCustomerSuccessManager,
      'emailAddress',
    ),
  }
}
