import { statuses, methods, isApiCall } from 'dux/api/action_types'
import { serializeRequestParams } from 'dux/api/helper'

import get from 'lodash/get'

const initState = {
  employees: {},
  people: {},
  employments: {}
}

/**
 * Custom filtered entities merge.
 */
function customMerge(state, action) {
  const receivedResults = action.data.result
  if (!receivedResults) {
    return state
  }

  const entityKeys = Object.keys(receivedResults)
  if (!entityKeys || entityKeys.length < 1) {
    return state
  }

  let newState = {}
  const serializedParams = serializeRequestParams(action.params)
  const serializedQuery = serializeRequestParams(action.query)

  entityKeys.forEach(entityKey => {
    const receivedIds = receivedResults[entityKey]

    const oldParamState = get(state, [entityKey, serializedParams], {})

    const newParamState = {
      [serializedQuery]: receivedIds
    }

    newState = {
      ...newState,
      [entityKey]: {
        [serializedParams]: {
          ...oldParamState,
          ...newParamState
        }
      }
    }
  })

  return {
    ...state,
    ...newState
  }
}

function reduceApiCall(state, action) {
  const { status, method, shouldEntityCache } = action

  if (status !== statuses.SUCCESS || method !== methods.GET) {
    return state
  }

  if (shouldEntityCache) {
    const newState = customMerge(state, action)

    return newState
  }

  return state
}

// Filtered entities reducer
export default function reducer(state = initState, action) {
  if (!action || !action.type) {
    return state
  }

  if (isApiCall(action.type)) {
    return reduceApiCall(state, action)
  }

  return state
}
