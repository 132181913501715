import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import { isReduxLoggerEnabled } from 'utils/logger/redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'

// Reducers
import actionDashboard from 'dux/action_dashboard'
import assessmentUploadPreview from 'dux/assessment_preview'
import calendly from 'dux/calendly'
import campaignParticipation from 'dux/campaign_participation'
import campaigns from 'dux/campaigns'
import campaignWizard from 'dux/campaign_wizard'
import createNewCampaignInvite from 'dux/create_new_campaign_invite'
import device from 'dux/device'
import education from 'dux/education'
import emplifyForm from 'dux/emplify_form'
import employeeEdit from 'dux/employee_edit'
import employeeImport from 'dux/employee_import'
import employeeManagement from 'dux/employee_management'
import employeeNew from 'dux/employee_new'
import employees from 'dux/employees'
import engagementByGroup from 'dux/engagement_by_group'
import entities from 'dux/api/reducers/entities'
import exportable from 'dux/exportable'
import filteredEntities from 'dux/api/reducers/filtered_entities'
import attributeFilters from 'dux/attribute_filters'
import groupCampaignMembers from 'dux/group_campaign_members'
import groups from 'dux/groups'
import heatmapTooltip from 'dux/heatmap_tooltip'
import helpfulYesNo from 'dux/helpful_yes_no'
import intercom from 'dux/intercom'
import jobs from 'dux/jobs'
import launchDarkly from 'dux/launch_darkly'
import login from 'dux/login'
import actionPlanWorksheet from 'dux/action_plan_worksheet'
import organizationHierarchy from 'dux/group_hierarchy_management'
import organizationNewForm from 'dux/organization_new_form'
import organizations from 'dux/organizations'
import orgSwitcherV2 from 'dux/org_switcher_v2'
import pages from 'dux/api/reducers/pages'
import permissionsEditor from 'dux/permissions_editor'
import reduxTable from 'dux/redux_table'
import requests from 'dux/api/reducers/requests'
import responses from 'dux/responses'
import results from 'dux/results'
import toast from 'dux/toast'
import summarizeScoreCard from 'dux/summarize_score_card'
import v2Nav from 'dux/v2_nav'
import v3Groups from 'dux/v3_groups'
import visited from 'dux/visited'
import v3GroupCategoryScores from 'dux/v3_group_category_scores'
import v3Segmentation from 'dux/v3_segmentation'

import { rootConfig as persistConfig } from 'dux/helpers/persist'

let store
const middleware = [thunk]

if (isReduxLoggerEnabled()) {
  const logger = createLogger()
  middleware.push(logger)
}

function isStoreInitialized() {
  if (!store) {
    // eslint-disable-next-line no-console
    console.error('Store has not been initialized! Did you run configureStore?')
    return false
  }

  return true
}

export function configureAppStore() {
  const rootReducer = combineReducers({
    actionDashboard,
    assessmentUploadPreview,
    calendly,
    campaignParticipation,
    campaigns,
    campaignWizard,
    createNewCampaignInvite,
    device,
    education,
    emplifyForm,
    employeeEdit,
    employeeImport,
    employeeManagement,
    employeeNew,
    employees,
    engagementByGroup,
    entities,
    exportable,
    filteredEntities,
    attributeFilters,
    launchDarkly,
    groupCampaignMembers,
    groups,
    heatmapTooltip,
    helpfulYesNo,
    intercom,
    jobs,
    login,
    actionPlanWorksheet,
    organizationHierarchy,
    organizationNewForm,
    organizations,
    orgSwitcherV2,
    pages,
    permissionsEditor,
    reduxTable,
    requests,
    responses,
    results,
    toast,
    summarizeScoreCard,
    v2Nav,
    v3Groups,
    v3Segmentation,
    visited,
    v3GroupCategoryScores,
  })

  const persistedRootReducer = persistReducer(persistConfig, rootReducer)
  store = configureStore({
    reducer: persistedRootReducer,
    middleware,
    devTools: true,
  })

  const persistor = persistStore(store)
  return { store, persistor }
}

export function dispatch(action) {
  if (!isStoreInitialized()) {
    return null
  }

  return store.dispatch(action)
}

export function getState() {
  if (!isStoreInitialized()) {
    return null
  }

  return store.getState()
}
