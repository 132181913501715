import React from 'react'
import { Link } from 'react-router-dom'

import NavListItem from 'containers/dropdown_nav/dropdown-nav-list-item'

function SettingsSubMenuContainer() {
  return (
    <NavListItem
      value='Settings'
      containerElement={<Link to='/employee-list' />}
      isSubMenuItem
    />
  )
}

SettingsSubMenuContainer.propTypes = {}

export default SettingsSubMenuContainer
