import reducer from 'dux/helpers/reducer'
import {
  entities,
  methods,
  statuses,
  createApiCallActionType
} from 'dux/api/action_types'

const { EMPLOYEE_IMPORT } = entities
const { POST } = methods

const initialState = {
  currentS3Info: {},
  currentJobId: null
}

// Constants (i.e. action types)

const RECEIVED_SIGNED_URL = 'employee_import/RECEIVED_SIGNED_URL'
const ERROR = 'employee_import/ERROR'
const IMPORT_POST_REQUEST = createApiCallActionType(
  EMPLOYEE_IMPORT,
  POST,
  statuses.REQUEST
)
const IMPORT_POST_SUCCESS = createApiCallActionType(
  EMPLOYEE_IMPORT,
  POST,
  statuses.SUCCESS
)
const IMPORT_POST_FAILURE = createApiCallActionType(
  EMPLOYEE_IMPORT,
  POST,
  statuses.FAILURE
)

// Reducers

function reduceEmployeeImportApiCall(state = initialState, action) {
  const callStatus = action.status

  if (callStatus === statuses.SUCCESS) {
    return {
      ...state,
      currentJobId: action.data.result.jobs[0]
    }
  }

  return {
    ...state,
    currentJobId: ''
  }
}

function reduceEmployeeImportSignedUrl(state = initialState, action) {
  return {
    ...state,
    currentS3Info: action.s3Info
  }
}

// Combined reducer functions

export default reducer(
  {
    [IMPORT_POST_REQUEST]: reduceEmployeeImportApiCall,
    [IMPORT_POST_SUCCESS]: reduceEmployeeImportApiCall,
    [IMPORT_POST_FAILURE]: reduceEmployeeImportApiCall,
    [RECEIVED_SIGNED_URL]: reduceEmployeeImportSignedUrl
  },
  initialState
)

// Action creators

function employeeImportError(message) {
  return {
    type: ERROR,
    message
  }
}

function employeeImportReceivedSignedUrl(s3Info) {
  return {
    type: RECEIVED_SIGNED_URL,
    s3Info
  }
}

// Async actions

function employeeImportErrorAsync(message) {
  return dispatch => {
    dispatch(employeeImportError(message))
  }
}

// Export necessary action types and action creators

export {
  // exporting for testing
  IMPORT_POST_SUCCESS,
  IMPORT_POST_FAILURE,
  // exporting for use
  ERROR,
  employeeImportError,
  employeeImportReceivedSignedUrl,
  employeeImportErrorAsync
}
