import { Order } from 'constants/sort'
import reducer from 'dux/helpers/reducer'

const { ASC, DESC } = Order

const initialState = {
  hasManuallySorted: false,
  sort: { field: 'score', order: DESC }
}

// Action types
const SET_SORT = 'engagementByGroup/SET_SORT'
const RESET_SORT = 'engagementByGroup/RESET_SORT'

// Actions
export function setSort(field) {
  return { type: SET_SORT, field }
}

export function resetSort() {
  return { type: RESET_SORT }
}

// Reducers
function reduceSetSort(state = initialState, action) {
  const field = action.field
  if (!field) {
    return state
  }

  if (field === state.sort.field) {
    return {
      ...state,
      hasManuallySorted: true,
      sort: {
        ...state.sort,
        // Toggle the order if we sort by the current field
        order: state.sort.order === ASC ? DESC : ASC
      }
    }
  }

  // Sorting by a new field. Sort ascending by default.
  return {
    ...state,
    hasManuallySorted: true,
    sort: { field, order: ASC }
  }
}

function reduceResetSort(state = initialState) {
  if (state.hasManuallySorted) {
    return initialState
  }
  return state
}

export default reducer(
  {
    [SET_SORT]: reduceSetSort,
    [RESET_SORT]: reduceResetSort
  },
  initialState
)
