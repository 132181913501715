import React, { useState } from 'react'
import PropTypes from 'prop-types'

import FontAwesomeIcon, {
  FONT_AWESOME_SIZES,
} from 'furniture/font_awesome_icon'
import { FloatingDismissButton } from 'furniture/dialog'
import cn from 'classnames'

function ProtipPopup({
  title,
  children,
  icon,
  canDismiss,
  containerClassName,
}) {
  const [showProtip, setShowProtip] = useState(true)
  const handleClose = () => {
    setShowProtip(false)
  }
  return (
    <div
      className={cn(
        'protip-content',
        !showProtip && 'hidden',
        containerClassName,
      )}
    >
      <div className='flex'>
        <div className='px-6 pt-2'>
          <FontAwesomeIcon size={FONT_AWESOME_SIZES.extraLarge} icon={icon} />
        </div>
        <div className='pt-[12px]'>
          <p className='protip-title'>{title}</p>
          {children}
        </div>
      </div>
      {canDismiss && <FloatingDismissButton onRequestClose={handleClose} />}
    </div>
  )
}

ProtipPopup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.object.isRequired,
  canDismiss: PropTypes.bool,
  containerClassName: PropTypes.string,
}

ProtipPopup.defaultProps = {
  canDismiss: true,
  containerClassName: null,
}

export default ProtipPopup
