import reducer from 'dux/helpers/reducer'
import { SWITCH_ORGANIZATION } from 'dux/organizations'

export const SEGMENT_BY = { GROUPS: 'groups', ATTRIBUTE: 'attribute' }

const initialState = {
  by: null,
  orgEmployeeAttributeId: null
}

const RESET_SEGMENTATION = 'v3GropsSegmentPicker/RESET_SEGMENTATION'
const SEGMENT_BY_GROUPS = 'v3GroupsSegmentPicker/SEGMENT_BY_GROUPS'
const SEGMENT_BY_ATTRIBUTE = 'v3GroupsSegmentPicker/SEGMENT_BY_ATTRIBUTE'

function reduceSegmentByGroups(state) {
  return {
    ...state,
    by: SEGMENT_BY.GROUPS
  }
}

function reduceSegmentByAttribute(state, action) {
  if (
    state.by === SEGMENT_BY.ATTRIBUTE &&
    state.orgEmployeeAttributeId === action.orgEmployeeAttributeId
  ) {
    return state
  }

  return {
    ...state,
    by: SEGMENT_BY.ATTRIBUTE,
    orgEmployeeAttributeId: action.orgEmployeeAttributeId
  }
}

function reduceResetSegmentationState() {
  return initialState
}

export default reducer(
  {
    [SEGMENT_BY_GROUPS]: reduceSegmentByGroups,
    [SEGMENT_BY_ATTRIBUTE]: reduceSegmentByAttribute,
    [RESET_SEGMENTATION]: reduceResetSegmentationState,
    [SWITCH_ORGANIZATION]: reduceResetSegmentationState
  },
  initialState
)

export function segmentByGroups() {
  return { type: SEGMENT_BY_GROUPS }
}

export function segmentByAttribute(orgEmployeeAttributeId) {
  return { type: SEGMENT_BY_ATTRIBUTE, orgEmployeeAttributeId }
}

export function resetSegmentationState() {
  return { type: RESET_SEGMENTATION }
}
