import { createSelector } from 'reselect'
import { entities } from 'dux/api/action_types'
import { createEntityGetter, createPropGetter } from 'utils/selectors'

import filter from 'lodash/filter'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import reduce from 'lodash/reduce'

/**
 * Select campaign statements for a specific category
 * @param {Object} state - entire Redux state tree
 * @param {Object} ownProps - props from React component
 * @param {String} ownProps.category - the category we want to select statements for
 * @param {String|Number} ownProps.campaignId - the campaign id
 * @returns {String[]} - An array of compiled statements for the category
 */
export const selectStatementsForCategory = createSelector(
  createEntityGetter(entities.CAMPAIGN_STATEMENTS),
  createEntityGetter(entities.CATEGORIES),
  createPropGetter('category'),
  createPropGetter('campaignId'),
  (statements, categories, category, campaignId) => {
    let categoryName = category
    if (categoryName === 'Professional Development') {
      categoryName = 'Prof. Dev.'
    }
    const selectedCategory = find(categories, { title: categoryName })

    let categoryStatements = []
    if (selectedCategory) {
      categoryStatements = reduce(
        statements,
        (result, s) => {
          const categoryComparison =
            s.categoryId === parseInt(selectedCategory.id, 10)
          const campaignComparison =
            parseInt(s.campaignId, 10) === parseInt(campaignId, 10)
          // For drivers and conditions, missing the statement index means that it is
          // an "experimental" statement and we are still gathering data on it
          if (categoryComparison && campaignComparison && !!s.statementIndex) {
            result.push(s.compiledBody)
          }
          return result
        },
        []
      )
    }

    return categoryStatements
  }
)

/**
 * Selects campaign statements for a prop provided campaignId
 * @param {Object} state Redux state tree
 * @param {Object} ownProps Provided props
 * @param {String|Number} ownProps.campaignId CampaignId
 * @returns {Object} Object of compiled statements for the campaign
 */
export const selectStatementsForCampaign = createSelector(
  createPropGetter('campaignId'),
  createEntityGetter(entities.CAMPAIGN_STATEMENTS),
  (campaignId, campaignStatements) =>
    keyBy(
      filter(
        campaignStatements,
        campaignStatement =>
          `${campaignStatement.campaignId}` === `${campaignId}`
      ),
      'id'
    )
)

export const selectFilteredStatements = createSelector(
  createEntityGetter(entities.CAMPAIGN_STATEMENTS),
  createPropGetter('filters'),
  (statements, filters) => filter(statements, filters)
)
