import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import get from 'lodash/get'
import find from 'lodash/find'
import values from 'lodash/values'
import map from 'lodash/map'
import some from 'lodash/some'
import pickBy from 'lodash/pickBy'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import first from 'lodash/first'
import sortBy from 'lodash/sortBy'

import { createSelector } from 'reselect'
import {
  createEntityGetter,
  createPropGetter,
  createPhaseSelector,
} from 'utils/selectors'
import { computeResponseRate } from 'utils/emplify/participation'
import {
  getV3GroupsWithParentNames,
  getV3GroupWithDirectParentName,
} from 'utils/v3_groups'
import { entities, methods } from 'dux/api/action_types'
import {
  selectCurrentOrganizationId,
  selectCurrentOrganizationName,
} from 'selectors/organizations'
import { selectPermissionsByGroup } from 'selectors/groups'
import { selectHydratedActiveEmployeesForCurrentOrg } from 'selectors/employees'
import {
  selectCurrentCampaignId,
  selectCampaignConfidentiality,
  selectMostRecentFinishedQuarterlyCampaigns,
} from 'selectors/campaigns'
import { selectPermittedV3Groups } from 'selectors/group_filter'
import { selectHasOrganizationResultsReadPermission } from 'selectors/user'
import { selectGroupPermissionsByEmployee } from 'selectors/permissions'
import { Context } from 'constants/emplify_score_guide'
import Phases from 'constants/phases'
import { ACCESS } from 'constants/permissions'
import { getGroupResultsGroupIdToFilterBy } from 'selectors/results'

function getV3Groups(state) {
  const v3Groups = state.entities['v3-groups'] || {}
  return keyBy(
    map(v3Groups, (v3Group) => {
      return {
        ...v3Group,
        context: v3Group.isRoot ? Context.ORGANIZATION : Context.GROUP,
      }
    }),
    'id',
  )
}

function getFilteredAttributes(state) {
  return get(state, ['v3Groups', 'filteredAttributes'], {})
}

/**
 * Baseline V3Groups phase selector for determining the v3Groups entity phase
 */
export const selectV3GroupsPhase = createPhaseSelector({
  entity: entities.V3_GROUPS,
  method: methods.GET,
  mapProps: ({ organizationId }) => ({ organizationId }),
})

/**
 * Returns a flat array of all groups that are descendents of the parent group
 * @param {Object|Array} v3Groups v3Groups state object
 * @param {String} parentGroupId ID of the parent group
 * @param {Array} relatedChildGroups Recursively growing list of related groups
 * @returns {Array} An array of the groups that are descendents of the parent group
 */
function getFlatGroupChildrenRelationships(
  v3Groups,
  parentGroupId,
  relatedChildGroups = [],
) {
  let copyRelatedChildGroups = [...relatedChildGroups]

  forEach(v3Groups, (g) => {
    if (`${g.parentGroupId}` === `${parentGroupId}`) {
      copyRelatedChildGroups.push(g)
      copyRelatedChildGroups = getFlatGroupChildrenRelationships(
        v3Groups,
        g.id,
        copyRelatedChildGroups,
      )
    }
  })

  return copyRelatedChildGroups
}

function getHistoricalGroupCampaignAggregatesWithResults(
  groupId,
  mostRecentFinishedQuarterlyCampaigns,
  historicalGroupCampaignAggregates,
) {
  const historicalGroupCampaignAggregatesByCampaignId = keyBy(
    historicalGroupCampaignAggregates,
    'campaignId',
  )

  return map(mostRecentFinishedQuarterlyCampaigns, (c) => {
    const gca = historicalGroupCampaignAggregatesByCampaignId[c.id]
    if (!gca) {
      return {
        groupId,
        campaignId: c.id,
        completedCount: 0,
        campaignEndedAt: c.endedAt,
      }
    }

    return gca
  })
}

const EMPTY_OBJECT = {}

export function getSelectedV3Group(state, ownProps) {
  const groupId = get(ownProps, 'groupId')
  return getV3Groups(state)[Number(groupId)] || EMPTY_OBJECT
}

export function getSelectedV3GroupId(state, ownProps) {
  return get(ownProps, 'groupId') || get(ownProps, 'match.params.id')
}

export const selectSelectedV3Group = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  getSelectedV3GroupId,
  (groups, groupId) => get(groups, [groupId]),
)

export const selectSelectedV3GroupWithBreadcrumbName = createSelector(
  getV3Groups,
  getSelectedV3GroupId,
  (v3Groups, groupId) => {
    const group = v3Groups[groupId] || {}
    const breadcrumbName = getV3GroupWithDirectParentName(v3Groups, groupId)
    return { ...group, breadcrumbName }
  },
)

export const selectFilteredAttributes = createSelector(
  getFilteredAttributes,
  (filteredAttributes) => filteredAttributes,
)

export const selectV3GroupsByOrganizationId = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  createPropGetter('organizationId'),
  (v3Groups, organizationId) => {
    const groups = filter(
      v3Groups,
      (g) => `${g.organizationId}` === `${organizationId}`,
    )

    return groups
  },
)

export const selectV3GroupsForCurrentOrganization = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  selectCurrentOrganizationId,
  (v3Groups, currentOrganizationId) => {
    return pickBy(
      v3Groups,
      (g) => `${g.organizationId}` === `${currentOrganizationId}`,
    )
  },
)

export const selectV3GroupsWithParentByOrganizationId = createSelector(
  selectV3GroupsByOrganizationId,
  createPropGetter('excludeRootGroup'),
  (v3Groups, excludeRootGroup) =>
    getV3GroupsWithParentNames(v3Groups, { excludeRootGroup }),
)

export const selectRootGroup = createSelector(
  selectV3GroupsForCurrentOrganization,
  (v3Groups) => find(v3Groups, (g) => g.isOrganizationGroup) || EMPTY_OBJECT,
)

export const selectRootV3Groups = createSelector(
  selectV3GroupsForCurrentOrganization,
  (v3Groups) => filter(v3Groups, (g) => g.parentGroupId === null),
)

export const selectChildGroups = createSelector(
  selectHasOrganizationResultsReadPermission,
  selectV3GroupsForCurrentOrganization,
  selectRootGroup,
  createPropGetter('parentGroupId'),
  selectPermittedV3Groups,
  (
    hasOrganizationReadPermission,
    v3Groups,
    rootGroup,
    parentGroupId,
    permittedV3Groups,
  ) => {
    // For limited access leaders they may not have access to a group on the root level
    // This handles that situation - Root level will be the users an individual has explicit access granted to
    if (!hasOrganizationReadPermission && parentGroupId === null) {
      return orderBy(Object.values(permittedV3Groups), ['name'])
    }

    if (!hasOrganizationReadPermission && parentGroupId === rootGroup.id) {
      return orderBy(Object.values(permittedV3Groups), ['name'])
    }

    return orderBy(
      filter(
        v3Groups,
        (group) => `${group.parentGroupId}` === `${parentGroupId}`,
      ),
      ['name'],
    )
  },
)

export const selectGroupHasChildren = createSelector(
  selectV3GroupsForCurrentOrganization,
  createPropGetter('groupId'),
  (v3Groups, groupId) => {
    return some(v3Groups, (g) => `${g.parentGroupId}` === `${groupId}`)
  },
)

/**
 * Selects the V3 groups as an array
 * @param {Object} state Entire redux state tree
 * @returns {Array} An array of v3 groups
 */
export const selectGroupsArray = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  (groups) => Object.values(groups),
)

/**
 * Selects direct children of the selected group
 * @param {Object} state Entire redux state tree
 * @param {String|Number} [props.groupId] V3 Group ID manually passed-in
 * @returns {Array} An array of the selected groups children
 */
export const selectSelectedGroupChildren = createSelector(
  createPropGetter('groupId'),
  selectGroupsArray,
  (groupId, groups) =>
    filter(groups, (group) => `${group.parentGroupId}` === `${groupId}`),
)

/**
 * Select all parent groups including the rootGroup
 * This is used for filtering on the campaign snapshot dropdown
 */
export const selectParentV3GroupsForGroupPicker = createSelector(
  selectV3GroupsForCurrentOrganization,
  (v3Groups) => {
    const parentGroups = filter(v3Groups, (g) => !g.parentGroupId)

    return parentGroups || [{ id: null }]
  },
)

export const selectParentV3GroupId = createSelector(
  selectV3GroupsForCurrentOrganization,
  createPropGetter('groupId'),
  (v3Groups, groupId) => {
    const v3Group = v3Groups[groupId]
    const parentGroupId = get(v3Group, 'parentGroupId')

    return parentGroupId ? `${parentGroupId}` : null
  },
)

export const selectParentV3Group = createSelector(
  selectV3GroupsForCurrentOrganization,
  selectParentV3GroupId,
  (groups, parentGroupId) => {
    const v3Group = groups[parentGroupId]
    if (!v3Group) {
      return null
    }

    v3Group.childGroups = {}
    forEach(groups, (g) => {
      if (`${g.parentGroupId}` === `${v3Group.id}`) {
        v3Group.childGroups[g.id] = g
      }
    })

    return v3Group
  },
)

export const selectV3GroupName = createSelector(getSelectedV3Group, (group) => {
  return get(group, 'name')
})

/**
 * Returns an ordered array with the current group and all ancestor groups of the current group.
 */
export const selectV3GroupAncestry = createSelector(
  getSelectedV3Group,
  selectV3GroupsForCurrentOrganization,
  (selectedGroup, v3Groups) => {
    const parentGroupId = selectedGroup.id
    const v3GroupAncestry = filter(
      v3Groups,
      (v3g) => `${v3g.parentGroupId}` === `${parentGroupId}`,
    )
    v3GroupAncestry.unshift(selectedGroup)
    return v3GroupAncestry
  },
)

/**
 * Selects the V3 groups phase
 * @param {Object} state Entire redux state tree
 * @returns {String} Phase for the v3_groups
 */
export const selectGroupsV3Phase = createPhaseSelector({
  entity: entities.V3_GROUPS,
})

/**
 * @param {Object} state Entire redux state tree
 * @returns {String} Phase for the v3_group members table
 */
export const selectV3GroupMembersTablePhase = createPhaseSelector(
  // v3_memberships are included with v3_groups call
  { entity: entities.V3_GROUPS },
)

/**
 * Select all v3_group owners mapped by a group Id. A group owner is
 * a hydrated employee who has a permission record for the given group.
 * The owner record will also contain the permission ID
 *
 * ownerExample: {
 *   id: employeeId,
 *   ...employeeData,
 *   ...personData,
 *   permissionId: groupPermissionId
 * }
 *
 * @param {Object} state - entire Redux state tree
 * @returns {Object} - v3_group owners mapped by group ID
 */
export const selectAllGroupV3OwnersByGroup = createSelector(
  selectPermissionsByGroup,
  createEntityGetter(entities.V3_GROUPS),
  selectHydratedActiveEmployeesForCurrentOrg,
  (permissions, groups, employees) => {
    const ownerMap = {}
    values(groups).forEach((group) => {
      const groupId = Number(group.id)
      const groupPermissions = permissions[groupId] || []
      const groupReadPermissions = filter(groupPermissions, {
        access: ACCESS.READ,
      })
      ownerMap[groupId] = []
      groupReadPermissions.forEach((permission) => {
        const { personId, accessibleId } = permission
        const owner = find(
          employees,
          (employee) => `${employee.personId}` === `${personId}`,
        )
        if (owner) {
          owner.accessibleId = accessibleId
          ownerMap[groupId].push(owner)
        }
      })
    })
    return ownerMap
  },
)

/**
 * Select all memberships for the selected v3_group. Used to populate the group employee list
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - array of v3_memberships for the selected group
 */
export const selectSelectedV3GroupMemberships = createSelector(
  getSelectedV3Group,
  createEntityGetter(entities.V3_MEMBERSHIPS),
  (group, memberships) =>
    values(memberships).filter(
      (membership) => membership.groupId === Number(group.id),
    ),
)

export const selectSelectedV3GroupMembershipsWithChildren = createSelector(
  getSelectedV3Group,
  createEntityGetter(entities.V3_GROUPS),
  createEntityGetter(entities.V3_MEMBERSHIPS),
  (group, v3Groups, memberships) => {
    const descendentGroups = getFlatGroupChildrenRelationships(
      v3Groups,
      group.id,
      [group],
    )

    const groupIds = map(descendentGroups, (r) => `${r.id}`)
    return pickBy(memberships, (m) => includes(groupIds, `${m.groupId}`))
  },
)

/**
 * Select all of the v3_group owners for the selected group.
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - array of owners for the selected group
 */
export const selectSelectedV3GroupOwners = createSelector(
  getSelectedV3Group,
  selectAllGroupV3OwnersByGroup,
  (group, owners) => owners[Number(group.id)],
)

/**
 * Select all of the employees for the selected v3_group. Filter out any deleted/empty records.
 * @param {Object} state - entire Redux state tree
 * @returns {Array} - array of hydrated employees for the selected v3_group
 */
export const selectSelectedV3GroupEmployees = createSelector(
  selectSelectedV3GroupMemberships,
  selectHydratedActiveEmployeesForCurrentOrg,
  (memberships, employees) =>
    map(memberships, (membership) => employees[membership.memberId]).filter(
      (employee) => !!employee,
    ),
)

export const selectSelectedV3GroupEmployeesWithChildren = createSelector(
  selectSelectedV3GroupMembershipsWithChildren,
  selectHydratedActiveEmployeesForCurrentOrg,
  (memberships, employees) =>
    map(memberships, (membership) => employees[membership.memberId]).filter(
      (employee) => !!employee,
    ),
)

/**
 * Select the data to render in the V3 Groups page table
 * @param {Object} state The Redux store
 * @example Group row:
 *  {
 *    id,
 *    name,
 *    parentGroupName,
 *    activeMembershipCount,
 *    reportOwners,
 *    responseRate,
 *    alerts
 *  }
 */
export const selectGroupV3DataForTable = createSelector(
  createEntityGetter(entities.V3_GROUPS),
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  createPropGetter('groupId'),
  selectCurrentCampaignId,
  selectMostRecentFinishedQuarterlyCampaigns,
  selectCampaignConfidentiality,
  (
    groups,
    groupParticipations,
    groupId,
    campaignId,
    mostRecentFinishedQuarterlyCampaigns,
    confidentialityLevel,
  ) => {
    const childGroups = filter(groups, (group) => {
      return (
        (groupId === 'all' || `${group.parentGroupId}` === `${groupId}`) &&
        !group.deletedAt
      )
    })

    return map(childGroups, (group) => {
      const {
        id,
        name,
        displayName,
        parentGroupId,
        activeMembershipCount,
        explicitPermissionCount,
        historicalGroupCampaignAggregates,
        referenceId,
      } = group
      const parentGroupName = get(groups, `[${parentGroupId}].name`, '')

      let responseRate = '-'
      if (campaignId) {
        const participationId = `${campaignId}:${id}`
        const participation = groupParticipations[participationId]
        const percentage = computeResponseRate(participation, 0)
        if (percentage !== null) {
          responseRate = `${percentage}%`
          if (activeMembershipCount < (confidentialityLevel || 5)) {
            responseRate = 'N/A'
          }
        }
      }

      return {
        id,
        name,
        displayName,
        parentGroupId,
        parentGroupName,
        activeMembershipCount,
        responseRate,
        explicitPermissionCount,
        historicalGroupCampaignAggregates,
        historicalGroupCampaignsWithResults:
          getHistoricalGroupCampaignAggregatesWithResults(
            group.id,
            mostRecentFinishedQuarterlyCampaigns,
            historicalGroupCampaignAggregates,
          ),
        referenceId,
      }
    })
  },
)

/**
 * Selects whether a v3 group is not found
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String|Number} [props.groupId] V3 Group ID manually passed-in
 * * @param {String|Number} [props.organizationId] Organization ID manually passed-in
 */
export const selectIsGroupFoundOrNotLoaded = createSelector(
  createPropGetter('groupId'),
  createEntityGetter(entities.V3_GROUPS),
  createPhaseSelector({
    entity: entities.V3_GROUP_RESULTS,
    method: methods.GET,
    mapProps: ({ organizationId, groupId }) => ({ organizationId, groupId }),
  }),
  function getIsGroupFound(groupId, groups, groupsResultsPhase) {
    const group = groups[groupId]
    if (group && group.id) {
      return true
    }
    // If we are currently fetching groups or we haven't fetched the data yet for the page
    // assume that we will find the group when they come back
    // This is because we want to show loading skeletons by default,
    // and only transition to NotFound state if necessary
    if (
      groupsResultsPhase !== Phases.IDEAL &&
      groupsResultsPhase !== Phases.ERROR
    ) {
      return true
    }

    return false
  },
)

// TODO: Use isOrganization Group and isAttributeGroup.
export const selectAttributeGroups = createSelector(
  selectV3GroupsForCurrentOrganization,
  createPropGetter('showDeleted'),
  (v3Groups, showDeleted = false) => {
    if (showDeleted) {
      return pickBy(
        v3Groups,
        (v3g) => !v3g.isRoot && v3g.parentGroupId === null,
      )
    }
    return pickBy(
      v3Groups,
      (v3g) => !v3g.isRoot && v3g.parentGroupId === null && !v3g.deletedAt,
    )
  },
)

/**
 * Selects the "Manager" attribute group with the child group count
 */
export const selectManagerGroup = createSelector(
  selectV3GroupsForCurrentOrganization,
  (groups) => {
    return find(groups, (group) => group.name === 'Manager') || {}
  },
)

/**
 * Selects the "Reports To" attribute group with the child group count
 */
export const selectReportsToGroup = createSelector(
  selectV3GroupsForCurrentOrganization,
  (groups) => {
    return (
      find(
        groups,
        (group) => group.name === 'Reports To' && !group.parentGroupId,
      ) || {}
    )
  },
)

/**
 * Selects attribute groups and appends the All option to the list of attribute groups as well.
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String|Number} [props.organizationId] Organization ID manually passed-in
 */
export const selectAttributeGroupsForDropdown = createSelector(
  selectAttributeGroups,
  (groups) => {
    const groupsArray = sortBy(groups, 'name')
    return filter(groupsArray, (group) => group.includeInResults)
  },
)

/**
 * Selects attribute groups that are available for filtering in dynamic feedback.
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String|Number} [props.organizationId] Organization ID manually passed-in
 */
export const selectAttributeGroupsForDynamicFeedback = createSelector(
  selectAttributeGroups,
  (groups) => {
    const groupsArray = sortBy(groups, 'name')
    return filter(
      groupsArray,
      (group) =>
        !group.isHidden && group.includeInResults && group.name !== 'Hierarchy',
    )
  },
)

/**
 * Selects attribute groups and appends the All option to the list of attribute groups as well.
 * This selector includes a 'None' group option with a null ID
 * This will match on a null making this the default option wherever group IDs are nullable
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {String|Number} [props.organizationId] Organization ID manually passed-in
 */
export const selectAttributeGroupsForKioskDropdown = createSelector(
  selectAttributeGroupsForDropdown,
  (groups) => {
    // Even if the user has access to a hidden group type,
    // We don't want to allow them to choose it for their Kiosk Group
    const groupsArray = filter(groups, (group) => !group.isHidden)

    groupsArray.unshift({
      id: null,
      name: 'None',
      displayValue: 'None',
    })

    return groupsArray
  },
)

export const selectGroupChildren = createSelector(
  selectV3GroupsForCurrentOrganization,
  createPropGetter('parentGroupId'),
  (v3Groups, parentGroupId) =>
    pickBy(v3Groups, (v3g) => `${v3g.parentGroupId}` === `${parentGroupId}`),
)

export const selectGroupChildrenForDropdown = createSelector(
  selectGroupChildren,
  selectPermittedV3Groups,
  selectCurrentOrganizationName,
  createPropGetter('campaign'),
  createEntityGetter(entities.V3_GROUP_PARTICIPATIONS),
  selectRootGroup,
  (
    groupChildren,
    permittedGroups,
    orgName,
    campaign,
    groupParticipations,
    organizationGroup,
  ) => {
    const validGroupsArray = filter(groupChildren, (group) => {
      const participationId = `${campaign.id}:${group.id}`
      const participation = get(groupParticipations, [participationId])
      const completedCount = get(participation, ['completedCount'])
      const permittedGroup = find(
        permittedGroups,
        (pg) => `${pg.id}` === `${group.id}`,
      )
      if (completedCount >= campaign.confidentialityLevel && permittedGroup) {
        return true
      }
      return false
    })

    const sortedGroupsArray = sortBy(validGroupsArray, 'name')

    sortedGroupsArray.unshift({
      name: `All of ${orgName}`,
      id: get(organizationGroup, 'id', null),
    })

    return sortedGroupsArray
  },
)

export const selectFeedbackGroup = createSelector(
  createPropGetter('campaign'),
  selectAttributeGroups,
  (campaign, attributeGroups) => {
    const feedbackGroupId = get(campaign, 'feedbackGroupId')
    return attributeGroups[feedbackGroupId]
  },
)

export const selectFirstAttributeGroupByOrder = createSelector(
  selectAttributeGroups,
  (attributeGroups) => {
    return first(orderBy(attributeGroups, ['order', 'name']))
  },
)

/**
 * Derives attributeGroups from V3Groups using parentGroupName and parentGroupId
 * and attaches childGroups to each derived attributeGroup
 *
 * Reason for Derived attributeGroups:
 * Limited Access Leaders are restricted from fetching the parents of the groups
 * to which they have been given permission. However, we can (and must) derive information
 * about the parentGroups in order to populate group dropdowns.
 *
 * @param {Object} state Entire redux state tree
 * @param {Object} V3Groups for current organization
 * @returns {Object} attributeGroups with attached childGroups object
 * @example:
 * {
 *   4: {
 *     id: 4,
 *     name: 'group4',
 *     parentGroupId: null,
 *     childGroups: {
 *       5: {
 *         id: 5,
 *         name: 'group5',
 *         breadcrumbName: 'group5',
 *         parentGroupId: 4,
 *         parentGroupName: 'group4'
 *       }
 *     }
 *   }
 * }
 */
export const selectDerivedAttributeGroupsWithChildren = createSelector(
  selectV3GroupsForCurrentOrganization,
  selectPermittedV3Groups,
  selectRootGroup,
  createPropGetter('includeRootGroup'),
  createPropGetter('usePermittedGroups'),
  (
    allV3Groups,
    permittedV3Groups,
    rootGroup,
    includeRootGroup,
    usePermittedGroups,
  ) => {
    let v3Groups = allV3Groups
    if (usePermittedGroups) {
      v3Groups = permittedV3Groups
    }
    const derivedAttributeGroups = {}

    // add root group to the list of groups so that group selection can be reset to org for custom campaigns and smart pulse
    if (includeRootGroup) {
      derivedAttributeGroups[0] = {
        name: 'Entire Organization',
        order: 1,
        includeInResults: true,
        isHidden: false,
        isRoot: true,
        childGroups: {
          [rootGroup.id]: rootGroup,
        },
      }
    }

    forEach(v3Groups, (v3g) => {
      if (v3g.parentGroupId !== null) {
        if (!derivedAttributeGroups[v3g.parentGroupId]) {
          const parentGroup = find(
            v3Groups,
            (group) => `${group.id}` === `${v3g.parentGroupId}`,
          )
          derivedAttributeGroups[v3g.parentGroupId] = {
            id: v3g.parentGroupId,
            name: v3g.parentGroupName,
            parentGroupId: null,
            includeInResults: parentGroup ? parentGroup.includeInResults : true,
            isHidden: parentGroup ? parentGroup.isHidden : false,
            childGroups: {},
          }
        }

        derivedAttributeGroups[v3g.parentGroupId].childGroups[v3g.id] = v3g
      }
    })

    return derivedAttributeGroups
  },
)

/**
 * Selects attribute groups and their children then sections the child groups by parent group.
 * This selector return in react-select {label: '', value: ''} format.
 * @param {Object} state Entire redux state tree
 * @param {Object} props
 * @param {Boolean} [includeRootGroup]
 * @param {Function} [setValue]
 */
export const selectCampaignTargetingOptions = createSelector(
  selectDerivedAttributeGroupsWithChildren,
  (groups) => {
    const targetingGroups = Object.values(groups)
      .filter((group) => !group.isRoot) // remove entire organization group
      .map((group) => {
        const childGroups = Object.values(group.childGroups)

        if (childGroups.length > 0) {
          const childGroupOptions = childGroups.map((childGroup) => ({
            label: childGroup.displayValue,
            value: childGroup.id,
          }))
          const childGroupOptionsSorted = sortBy(childGroupOptions, 'label')
          return {
            label: group.name,
            options: childGroupOptionsSorted,
          }
        }
        return {
          label: group.name,
          value: group.id,
        }
      })
    const sortedTargetingGroups = sortBy(targetingGroups, 'label')
    return sortedTargetingGroups
  },
)

export const selectResultsOnlyAccess = createSelector(
  selectPermittedV3Groups,
  selectHasOrganizationResultsReadPermission,
  (groups, hasOrganizationResultsPermission) => {
    if (hasOrganizationResultsPermission) {
      return false
    }

    // if they have only one permission, and that permission is the org group, return true
    const groupsValues = values(groups)
    if (
      groupsValues.length === 1 &&
      get(groupsValues, ['0', 'isOrganizationGroup'])
    ) {
      return true
    }
    return false
  },
)

export const selectCurrentGroupResultGroupId = createSelector(
  selectRootGroup,
  getGroupResultsGroupIdToFilterBy,
  selectHasOrganizationResultsReadPermission,
  selectPermittedV3Groups,
  selectResultsOnlyAccess,
  (
    organizationGroup,
    pathGroupId,
    hasOrganizationLevelAccess,
    groups,
    resultsOnlyAccess,
  ) => {
    if (!pathGroupId || hasOrganizationLevelAccess) {
      // org level or zero results access (stripped down version) go directly to org group
      if (hasOrganizationLevelAccess || resultsOnlyAccess) {
        return get(organizationGroup, 'id', null)
      }

      const sortedPermissionGroups = orderBy(
        filter(groups, (group) => !group.isOrganizationGroup),
        'desc',
      )

      return get(sortedPermissionGroups, '[0].id', null)
    }

    return pathGroupId
  },
)

/**
 * Selects the readable groups for the employee passed in
 * @param {Object} state - Redux state tree
 * @returns {ApiGroup[]} The readable groups for
 */
export const selectEmployeesPermittedGroups = createSelector(
  selectV3GroupsForCurrentOrganization,
  selectGroupPermissionsByEmployee,
  (groups = {}, groupPermissions = []) => {
    if (!groupPermissions || groupPermissions.length < 1) {
      return []
    }
    return filter(groups, (group) =>
      some(
        groupPermissions,
        (permission) =>
          `${permission.access}` === `${ACCESS.READ}` &&
          `${permission.accessibleId}` === `${group.id}`,
      ),
    )
  },
)
