import includes from 'lodash/includes'
import { getFullStoryProperties } from 'analytics/utils'

const RESTRICTED_ORG_IDS = process.env.RESTRICTED_ORG_IDS || []

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

// eslint-disable-next-line dot-notation
const getWindowFullStory = () => window[window['_fs_namespace']]

/**
 * Calls setUserVars within the Full Story context.
 * Drew some inspiration from this open source project: https://github.com/cereallarceny/react-fullstory
 * @param {Object} state - entire Redux state tree
 */
export function setUserVars(state) {
  const properties = getFullStoryProperties(state)
  // If 15Five employee, then do not track in FullStory.
  if (
    canUseDOM &&
    getWindowFullStory() &&
    includes(RESTRICTED_ORG_IDS, `${properties?.organizationId}`)
  ) {
    getWindowFullStory().shutdown()
  } else if (canUseDOM && getWindowFullStory()) {
    getWindowFullStory().setUserVars(properties)
  } else {
    console.warn('FullStory not initialized yet')
  }
}
