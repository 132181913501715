import React from 'react'
import { CATEGORIES_DEFINITIONS } from 'constants/categories'
import { LearnMoreLinks } from 'components/learn_more'
import { getFriendlyCategoryName } from 'utils/emplify/categories'

import LearnMoreVocab from './learn_more_vocab'

export const EMPLIFY_INDEX = {
  word: 'Engagement Index',
  definition: () =>
    'The percentile shows how your Engagement Score compares to other organizations in the Engage database.'
}

export const ACCESS_TYPE_FULL = {
  word: 'Full Access',
  definition: () => 'Organization leaders have visibility across all groups.'
}

export const ACCESS_TYPE_LIMITED = {
  word: 'Limited Access',
  definition: () =>
    "Group leaders have access to results for the specific group(s) they've been given permission."
}

export const ACCESS_TYPE_PEOPLE_LEADER = {
  word: 'People Leader',
  definition: () =>
    'Users with this role will receive an invite to the Engage system prior to the first survey so that they can learn about the Engage process, engagement, and proper expectations for their employees who are about to receive the survey.'
}

export const ACCESS_TYPE_ENGAGEMENT_ADMIN = {
  word: 'Engagement Admin',
  definition: () =>
    'The engagement admin role can only be assigned to one person who is responsible for the Engage account at your organization. They have complete access to all results and settings.'
}

// Group reports display group, organization, and index average Engagement Scores
// Group leaders do not see the percentile of the organization
// So we need to phrase the education on index average a bit differently
export const GROUP_EMPLIFY_INDEX = {
  word: 'Engagement Index',
  definition: () =>
    'The index average is the average Engagement Score of every organization in the Engagement database.'
}

export const HIDDEN_GROUP = {
  word: 'Hidden Group',
  definition: () =>
    'Results for groups with fewer responses than the confidentiality rule of your organization are hidden to protect employee identities.'
}

export const INFLUENCE_CORRELATIONS = {
  word: 'Influence',
  definition: () => (
    <LearnMoreVocab learnMoreType={LearnMoreLinks.INFLUENCE}>
      Influence measures how correlated a driver or condition is to engagement
      at your organization. Increasing the score of a high influence driver or
      condition should increase your overall engagement score.
    </LearnMoreVocab>
  )
}

export const SPHERE = {
  word: 'Spheres of Experience',
  definition: () => (
    <LearnMoreVocab learnMoreType={LearnMoreLinks.SPHERE}>
      Spheres of Experience are environments at work in which engagement drivers
      influence employees. Drivers are categorized into four Spheres in the
      Engagement Survey - Work Experience, Leadership, Manager, and Coworkers.
    </LearnMoreVocab>
  )
}
// Icons, misc

export const ALERT_ICON_UNGROUPED = {
  word: 'Ungrouped Employee',
  definition: () =>
    'An employee must be assigned to groups to be included in group reports.'
}

export const ALERT_ICON_EXCLUDED = {
  word: 'Excluded Employee',
  definition: () => 'This employee will not receive surveys.'
}

export const ALERT_ICON_PERMISSION_ERROR = {
  word: 'Access to Engage Portal Error',
  definition: () => 'Please select or update the access level.'
}

// Categories Vocab with Learn More and statements list
export const CATEGORIES = Object.keys(CATEGORIES_DEFINITIONS).reduce(
  (categories = {}, categoryLabel) => ({
    ...categories,
    [categoryLabel]: {
      word: getFriendlyCategoryName(categoryLabel),
      // eslint-disable-next-line react/prop-types
      definition: ({ statements }) => {
        return (
          <LearnMoreVocab learnMoreType={categoryLabel}>
            {CATEGORIES_DEFINITIONS[categoryLabel]}
            {statements}
          </LearnMoreVocab>
        )
      }
    }
  }),
  {}
)

export const META_DRIVER_PEOPLE = {
  word: 'Interpersonal Aspect',
  definition: () =>
    'The interpersonal aspect of the work contributes to engagement through the Trust, Manager, Shared Values, Psych. Safety, and Feedback drivers.'
}

export const META_DRIVER_PURPOSE = {
  word: 'Belief in the purpose and mission',
  definition: () =>
    'The belief in the purpose and mission of the organization contributes to engagement through the Professional Development, Purpose, Fairness, Authenticity, Meaning and Utilization drivers'
}

export const META_DRIVER_POSITION = {
  word: 'Work itself',
  definition: () =>
    'The work itself and the individual job responsibilities contribute to engagement through the Role Clarity, Capacity, Autonomy, Competency and Rest drivers.'
}

export const GROW_ARCHETYPE_WORKING = {
  word: 'Working',
  definition: () =>
    'Working is a component of employee engagement. It measures the way your work responsibilities and the work you do impact your ability to be engaged at work.'
}
export const GROW_ARCHETYPE_BELIEVING = {
  word: 'Believing',
  definition: () =>
    'Believing is a component of employee engagement. It measures how your connection to the mission and purpose of the organization impacts your ability to be engaged at work.'
}
export const GROW_ARCHETYPE_RELATING = {
  word: 'Relating',
  definition: () =>
    'Relating is a component of employee engagement. It measures how the interpersonal relationships at work impact your ability to be engaged.'
}

// Bracket explanations

export const BRACKETS = {
  '10': {
    word: 'Celebrate',
    definition: () =>
      'Employees in this group are able to bring their best selves to work and fully contribute. This is something to celebrate! Let these employees know what they are doing well and keep up your efforts to maintain an engaging work environment.'
  },
  '9': {
    word: 'Recognize',
    definition: () =>
      'Employees are feeling engaged and experiencing a positive work environment. Make sure to recognize the employees in this group and let them know they are doing well.'
  },
  '8': {
    word: 'Encourage',
    definition: () =>
      'This is a typical engagement level for employees. While this group may not require the most urgent action, you can still encourage employees on what is going well and improve areas that need focus.'
  },
  '7': {
    word: 'Develop',
    definition: () =>
      'We recommend taking action to develop this group. Employees have a lower engagement level which may be impacting their ability to fully contribute at work.'
  },
  '6': {
    word: 'Invest',
    definition: () =>
      'These employees require the most urgent investment to improve their engagement level. Find at least one action area for this group and commit to following through on that action.'
  },
  'not-available': {
    word: 'Not Available',
    definition: () =>
      `No data was available or fewer employees responded to the survey than the confidentiality rule for your organization. Results are not displayed.`
  }
}

export const HIDDEN_RESPONSE_RATE = {
  word: 'Response Rate Hidden',
  definition: () =>
    'This group has fewer members than the confidentiality rule for your organization. Response rate data is not available.'
}
