import get from 'lodash/get'
import intercom, { boot } from 'utils/intercom'
import { setUserVars } from 'utils/full_story'
import reducer from 'dux/helpers/reducer'
import { statuses } from 'dux/api/action_types'
import { getUser, updatePersonAsync } from 'dux/api/actions/people'
import { getOrganizationAsync } from 'dux/api/actions/organizations'
import EmplifyAuth from 'utils/emplify/authentication'
import LOCAL_STORAGE from 'constants/local_storage'
import { TOKEN_KEY } from 'constants/login'
import LocalStorageLogger, { redirectLoggerParams } from 'utils/logger'

const redirectLogger = new LocalStorageLogger(redirectLoggerParams)

/**
 * @enum {Symbol} authorizationStatuses
 * Each status represents where we are in the asynchronous process of authorizing the user.
 * If pending, we have not yet finished looking at the token and identifying the user.
 * Otherwise, we have determined that they are authorized or unauthorized.
 * Unauthorized could mean a number of things, including an expired token, or no token
 * (e.g., no one has ever signed in from the current browser before).
 */
const authorizationStatuses = {
  PENDING: Symbol('login/authorizationStatus/PENDING'),
  AUTHORIZED: Symbol('login/authorizationStatus/AUTHORIZED'),
  NOT_AUTHORIZED: Symbol('login/authorizationStatus/UNAUTHORIZED'),
  ERRORED: Symbol('login/authorization/ERROR'),
}

const initialState = {
  personId: null, // null or Number
  error: '',
  token: null,
  expiration: null,
  authorizationStatus: authorizationStatuses.PENDING,
  isCheckingSession: false,
  previousLoginAt: undefined, // NULL if no login before this one
  // This is defined from checkSessionAsync action
  // checkingSessionPromise: undefined
}

const PARSE_TOKEN = 'login/PARSE_TOKEN'
const LOG_OUT = 'login/LOG_OUT'
const ERROR = 'login/ERROR'
const SET_IS_AUTHENTICATED = 'login/SET_IS_AUTHENTICATED'
const SET_IS_CHECKING_SESSION = 'login/SET_IS_CHECKING_SESSION'
const SET_PREVIOUS_LOGIN_AT = 'login/SET_PREVIOUS_LOGIN_AT'

const emplifyAuth = new EmplifyAuth()

function reduceParseToken(state = initialState, action) {
  const payload = action.payload

  // Note that the authorizationStatus is intentionally NOT updated here
  // We will leave it PENDING until we identify the person this token belongs to
  return {
    ...state,
    token: action.token,
    expiration: payload.exp,
    personId: EmplifyAuth.getPayloadScopedAttribute(payload, 'person_id'),
    intercomUserHash: EmplifyAuth.getPayloadScopedAttribute(
      payload,
      'intercom_user_hash',
    ),
    ffUserId: EmplifyAuth.getPayloadScopedAttribute(payload, 'ff_user_id'),
  }
}

function reduceLogout(state, action) {
  const { errorMessage, manualLogout } = action
  const status = errorMessage
    ? authorizationStatuses.ERRORED
    : authorizationStatuses.NOT_AUTHORIZED

  return {
    ...initialState,
    error: errorMessage,
    authorizationStatus: status,
    manualLogout,
  }
}

function reduceError(state = initialState, action) {
  return {
    ...state,
    error: action.message,
    authorizationStatus: authorizationStatuses.ERRORED,
  }
}

function reduceIsAuthenticated(state = initialState) {
  return {
    ...state,
    authorizationStatus: authorizationStatuses.AUTHORIZED,
  }
}

/**
 * We typically do not allow non serializable things in state, but
 * we didn't want to build a large promise holding structure
 * to globally allow access to a temporary promise
 *
 * We should only do this in exception cases
 * @param {Object} state The redux store state
 * @param {Object} action The redux action
 * @param {Boolean} action.isCheckingSession Currently checking session state
 * @param {Promise} action.checkingSessionPromise Promise returned from renewAccessTokenAsync
 *
 * @returns {Object} Login state key
 */
function reduceSetIsCheckingSession(state, action) {
  return {
    ...state,
    isCheckingSession: action.isCheckingSession,
    checkingSessionPromise: action.checkingSessionPromise,
  }
}

function reduceSetPreviousLoginAt(state, action) {
  const { timestamp } = action
  if (timestamp === state.previousLoginAt) {
    return state
  }
  return {
    ...state,
    previousLoginAt: timestamp,
  }
}

// Combined reducer functions
export default reducer(
  {
    [PARSE_TOKEN]: reduceParseToken,
    [LOG_OUT]: reduceLogout,
    [ERROR]: reduceError,
    [SET_IS_AUTHENTICATED]: reduceIsAuthenticated,
    [SET_IS_CHECKING_SESSION]: reduceSetIsCheckingSession,
    [SET_PREVIOUS_LOGIN_AT]: reduceSetPreviousLoginAt,
  },
  initialState,
)

// Action creators
function logoutWithMessage(errorMessage = '', manualLogout) {
  return {
    type: LOG_OUT,
    errorMessage,
    manualLogout,
  }
}

function logout(errorMessage, manualLogout) {
  return (dispatch) => {
    intercom('shutdown')
    localStorage.removeItem(TOKEN_KEY)
    return dispatch(logoutWithMessage(errorMessage, manualLogout))
  }
}

function parseToken(token, payload) {
  return {
    type: PARSE_TOKEN,
    token,
    payload,
  }
}

function setIsAuthenticated() {
  return {
    type: SET_IS_AUTHENTICATED,
  }
}

function setIsCheckingSession(
  isCheckingSession = false,
  checkingSessionPromise,
) {
  return {
    type: SET_IS_CHECKING_SESSION,
    isCheckingSession,
    checkingSessionPromise,
  }
}

function setPreviousLoginAt(timestamp) {
  return { type: SET_PREVIOUS_LOGIN_AT, timestamp }
}

function identifyUserAsync() {
  return function identifyUserThunk(dispatch, getState) {
    const state = getState()
    const { personId } = state.login
    // TODO: We should add organization id here as a parameter to pass so
    // we can default the user of multi orgs to the org they log in as with SSO
    return dispatch(getUser(personId, history))
      .then(function handleGetPersonResponse(response) {
        dispatch(setIsAuthenticated())
        // INTERCOM BOOT
        boot()
        // FULL STORY SET USER VARIABLES
        // https://help.fullstory.com/develop-js/setuservars
        setUserVars(getState())

        const person = get(response, `data.entities.people[${personId}]`)

        // User succesfully logged in, so update lastWebPortalLogin
        if (response.status === statuses.SUCCESS) {
          const lastWebPortalLogin = get(person, 'lastWebPortalLogin') || null
          dispatch(setPreviousLoginAt(lastWebPortalLogin))

          const userId = get(state, 'login.ffUserId', null)

          const updates = {
            id: personId,
            lastWebPortalLogin: new Date(Date.now()),
          }
          if (userId) {
            updates.ffUserId = userId
          }
          const options = { suppressToast: true }
          return dispatch(updatePersonAsync(personId, updates, options))
        }
        return Promise.resolve()
      })
      .then(function fetchUsersOrganization() {
        const currentOrganizationId =
          getState().organizations.currentOrganizationId
        return dispatch(
          getOrganizationAsync(currentOrganizationId, {
            query: { include: 'v3-groups' },
          }),
        )
      })
  }
}

function renewAccessTokenAsync() {
  return new Promise((resolve, reject) => {
    // We used to use renewAuth() here
    // However, according to Auth0, the checkSession API is more appropriate for SPAs
    // checkSession is also nice compared to renewAuth because we no longer need to maintain
    // a silent.html like we did with renewAuth
    emplifyAuth.checkSession({}, (err, result) => {
      if (err !== null) {
        return reject(err.error_description)
      }
      return resolve(result)
    })
  })
}

// PERSISTED Route Linking on login
function handleLinkPersistence(redirectToHash, history) {
  const stringifiedRedirectObject = localStorage.getItem(
    LOCAL_STORAGE.REDIRECT_TO,
  )

  // NOTE: If a redirectTo is passed through login hash, we'll override the link persist.
  if (redirectToHash) {
    if (stringifiedRedirectObject) {
      localStorage.removeItem(LOCAL_STORAGE.REDIRECT_TO)
    }
    history.replace(redirectToHash)
    return
  }

  if (stringifiedRedirectObject) {
    let redirectObject

    // This could be abstracted into redux persist or our own localStorage abstraction
    try {
      redirectObject = JSON.parse(stringifiedRedirectObject)
    } catch (error) {
      redirectLogger.log(stringifiedRedirectObject, 'error', {
        css: 'color:red',
      })
      console.error(error)
    }

    // Safety fallback for unexpected returns from localStorage
    const { expiry, redirectTo } = redirectObject || {}
    if (redirectTo && new Date(expiry) > new Date()) {
      redirectLogger.log(redirectTo, 'replace')
      history.replace(redirectTo)
    } else if (redirectTo) {
      redirectLogger.log(redirectTo, 'expired', { css: 'color:orange' })
    }

    localStorage.removeItem(LOCAL_STORAGE.REDIRECT_TO)
  }
}

function checkSessionAsync(renewPromise) {
  return function checkSessionThunk(dispatch, getState) {
    if (getState().login.isCheckingSession) {
      return Promise.resolve({})
    }

    const promise = renewPromise || renewAccessTokenAsync()
    dispatch(setIsCheckingSession(true, promise))
    return promise
      .then((result) =>
        // eslint-disable-next-line no-use-before-define
        concludeAuthentication(result.accessToken)(dispatch, getState),
      )
      .then(() => dispatch(setIsCheckingSession(false)))
      .catch((errorDescription) => {
        console.error('Error while checking session', errorDescription)
        // Auth0 error messages are not very helpful to end users
        // So we control the messaging here
        dispatch(logout("Sorry, we couldn't sign you in right now."))
        dispatch(setIsCheckingSession(false))
      })
  }
}

function scheduleRenewAccessToken(delay) {
  return function renewAccessTokenThunk(dispatch) {
    setTimeout(() => {
      dispatch(checkSessionAsync())
    }, delay)
  }
}

function concludeAuthentication(token, redirectTo, history) {
  return (dispatch, getState) => {
    localStorage.setItem(TOKEN_KEY, token)

    const tokenPayload = EmplifyAuth.getTokenPayload(token)
    if (!EmplifyAuth.doesTokenHaveCorrectIssuer(tokenPayload)) {
      return dispatch(logout('Incorrect auth issuer'))
    }

    dispatch(parseToken(token, tokenPayload))
    // - 10000 at the end to renew ten seconds before expiration
    // So that we never actually make a request to Citadel with an expired token in storage
    const delay =
      getState().login.expiration * 1000 - new Date().getTime() - 10000
    if (delay < 1) {
      return dispatch(checkSessionAsync())
    }

    scheduleRenewAccessToken(delay)(dispatch, getState)
    handleLinkPersistence(redirectTo, history)
    return identifyUserAsync()(dispatch, getState)
  }
}

// Export necessary action types and action creators
export {
  authorizationStatuses,
  logoutWithMessage,
  logout,
  parseToken,
  renewAccessTokenAsync,
  setIsCheckingSession,
  setIsAuthenticated,
  concludeAuthentication,
  checkSessionAsync,
}
