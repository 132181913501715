import React from 'react'
import includes from 'lodash/includes'
import { detect } from 'detect-browser'

import { INCOMPATIBLE_BROWSERS } from 'constants/browser'

/**
 * Displays a banner at the top of the screen if the user's browser is incompatible
 * with our application
 */
const IncompatibleBrowserBanner = () => {
  /**
   * Is the current browser we're using an incompatible browser?
   */
  const isCompatibleBrowser = () => {
    const browser = detect()
    return !includes(Object.values(INCOMPATIBLE_BROWSERS), browser.name)
  }

  if (isCompatibleBrowser()) {
    return null
  }

  return (
    <div className='incompatible-browser-banner'>
      This application is incompatible with your current browser. For the best
      experience, please use{' '}
      <a
        href='https://www.google.com/chrome'
        rel='noopener noreferrer'
        target='_blank'
      >
        Google Chrome
      </a>
      .
    </div>
  )
}

export default IncompatibleBrowserBanner
