import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLDClient,
  withLDProvider,
  useFlags,
} from 'launchdarkly-react-client-sdk'
import { selectLaunchDarklyUser } from 'selectors/launch_darkly'
import { setLaunchDarklyUserIdentified } from 'dux/launch_darkly'

const { LAUNCH_DARKLY_CLIENT_SIDE_ID } = process.env

// We initialize the context with an unknown user
// The app renders for the first time before we know who the current user is
// Or possibly with no authenticated user
// We will later identify the user.
const GUEST_CONTEXT = {
  kind: 'user',
  key: '-1',
  firstName: 'Unknown',
  lastName: 'Guest',
}

/**
 * HOC that turns a component into a LaunchDarkly context provider.
 * @param {Object} Component Any React component
 * @returns {Object} LaunchDarkly context provider
 */
function withLaunchDarklyProvider(Component) {
  if (!LAUNCH_DARKLY_CLIENT_SIDE_ID) {
    console.error(
      'Missing required environment variable LAUNCH_DARKLY_CLIENT_SIDE_ID',
    )
  }
  return withLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: GUEST_CONTEXT,
    options: {}, // All the defaults are good for us for now
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(Component)
}

/**
 * Component responsible for providing the Launch Darkly context to children.
 * The Launch Darkly context includes a LD-specific representation of the current user,
 * the user's available feature flags, as well as a handle to the LD api for this specific environment / user.
 */
function LaunchDarklyUserIdentifier({ children }) {
  const dispatch = useDispatch()
  const ldClient = useLDClient()
  const user = useSelector((state) => selectLaunchDarklyUser(state))

  // Until we have a user with an organizationId, we don't want to identify the user
  useEffect(() => {
    if (ldClient && user && user.organizationId) {
      ldClient
        .identify(user)
        .then(() => dispatch(setLaunchDarklyUserIdentified()))
    }
  }, [dispatch, ldClient, user])

  return children
}

LaunchDarklyUserIdentifier.propTypes = {
  children: PropTypes.node.isRequired,
}

const LaunchDarklyContextProvider = withLaunchDarklyProvider(
  LaunchDarklyUserIdentifier,
)

LaunchDarklyContextProvider.displayName = 'LaunchDarklyContextProvider'

export function useBoolFlag(flagKey) {
  const flags = useFlags()
  if (!flags) {
    return false
  }
  return !!flags[flagKey]
}

export default LaunchDarklyContextProvider
