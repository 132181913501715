export const STATEMENT_TYPES = {
  LIKERT5: 'likert5',
  OPEN_RESPONSE: 'open_response',
  ENPS: 'enps'
}

export const STATEMENT_TYPES_FOR_CUSTOM_SURVEYS = {
  LIKERT5: STATEMENT_TYPES.LIKERT5,
  OPEN_RESPONSE: STATEMENT_TYPES.OPEN_RESPONSE
}

export const FRIENDLY_STATEMENT_TYPES = {
  [STATEMENT_TYPES.LIKERT5]: 'Opinion Scale',
  [STATEMENT_TYPES.OPEN_RESPONSE]: 'Opinion Response',
  [STATEMENT_TYPES.ENPS]: 'Opinion Scale (eNPS)'
}
