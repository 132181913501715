import React from 'react'
import PropTypes from 'prop-types'
import {
  AUTHORITY_LEVEL_DEFINITIONS,
  DEPRECATED_DRIVER_DEFINITIONS
} from 'constants/categories'

function VocabDefinition({ dict, dictProps, prompt, isSlideout }) {
  const authLevelDefinition = AUTHORITY_LEVEL_DEFINITIONS[prompt]
  const { word, definition } = dict

  const previousDefinition = DEPRECATED_DRIVER_DEFINITIONS[word]

  if (!prompt) {
    return (
      <>
        <p className={isSlideout && 'vocab-prompt-title'}>{word}</p>
        {previousDefinition && (
          <span className={isSlideout && 'vocab-prompt-definition'}>
            {previousDefinition}
            <br />
            <br />
          </span>
        )}
        <span className={isSlideout && 'vocab-prompt-definition'}>
          {definition(dictProps)}
        </span>
      </>
    )
  }

  return (
    <>
      <p className={isSlideout && 'vocab-prompt-title'}>{prompt}</p>
      <span className={isSlideout && 'vocab-prompt-definition'}>
        {authLevelDefinition}
      </span>
    </>
  )
}

VocabDefinition.propTypes = {
  // our dictionary definition, with a function for context definitions
  dict: PropTypes.shape({
    word: PropTypes.string,
    definition: PropTypes.func
  }),
  dictProps: PropTypes.object,
  prompt: PropTypes.string,
  isSlideout: PropTypes.bool
}
VocabDefinition.defaultProps = {
  dict: {
    word: '',
    definition: () => ''
  },
  dictProps: {},
  prompt: null,
  isSlideout: false
}

export default VocabDefinition
