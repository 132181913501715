import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'

function getAllCampaignResultsAsync(organizationId, options) {
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/campaign-results',
    {
      organizationId
    },
    options
  )
}

export { getAllCampaignResultsAsync }
