export const ACTION_DASHBOARD_VIEWS = {
  OPEN: 'open',
  COMPLETED: 'completed'
}

export const ACTION_PAGE_VIEWS = {
  OVERVIEW: 'overview',
  MY_ACTIONS: 'my_actions',
  OTHER_ACTIONS: 'other_actions'
}
