import trim from 'lodash/trim'

/**
 * Takes a string and returns a new string with an incremented number appended to the end
 * @param {String} str String to increment
 * @returns {String} String with incremented value
 */
export function incrementString(str) {
  // Find the trailing number with its index,
  // Or match the length of the string that has no trailing number
  const match = str.match(/\d*$/)

  // Extract the trimmed substring up to the place where the trailing number was found
  const text = trim(`${str.substring(0, match.index)}`)

  // Increment the matched count by 1
  const increment = trim(`${++match[0]}`)

  return `${text} ${increment}`
}
