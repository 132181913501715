import { CAMPAIGN_TARGETABLE_TYPES } from 'constants/campaign_targets'
import { filter } from 'lodash'

/**
 * Function that sanitizes the campaignForm's targetedEmployeeAttributes and
 * prepares them as an array for campaign creation
 * @param {String} campaignId - the id for the campaign to filter
 * @param {Object} targetedEmployeeAttributes - the unsanitized targetedEmployeeAttributes object
 * @returns {Array} sanitized targetedEmployeeAttributes
 */
export function targetedEmployeeAttributesToCampaignFilters(
  targetedEmployeeAttributes
) {
  return Object.values(targetedEmployeeAttributes)
    .filter(
      attribute => !!attribute.attributeType && !!attribute.attributeValue
    )
    .map(attribute => ({
      attribute: attribute.attributeType,
      employeeAttributeValue: attribute.attributeValue
    }))
}

export function checkEmployeeHasAccessToTargetedResults(
  targetContext,
  permittedGroups
) {
  const v3Targets = getV3TargetIdsFromContext(targetContext)

  let employeeHasAccess = false

  if (v3Targets.length > 0) {
    const permittedGroupIds = filter(permittedGroups, g => !g.isRoot).map(
      g => g.id
    )

    employeeHasAccess = v3Targets.some(id => permittedGroupIds.includes(id))
  }

  return employeeHasAccess
}

function getV3TargetIdsFromContext(targetContext) {
  const idsFromContext = targetContext.split(';').map(context => {
    const target = context.split(':')
    if (target[0] === CAMPAIGN_TARGETABLE_TYPES.V3_GROUPS) {
      return target[1]
    }
    return ''
  })

  return idsFromContext.filter(id => id !== '')
}
