import reducer from 'dux/helpers/reducer'

const initialState = {
  // [scorecard]: { elementId: 'scorecard', section: 'scorecard', label: 'Score Card' }
}

// Constants (i.e. action types)

const ADD = 'exportable/ADD'
const REMOVE = 'exportable/REMOVE'

// Reducers

function reduceAdd(state, action) {
  return {
    ...state,
    [action.elementId]: {
      elementId: action.elementId,
      section: action.section,
      label: action.label
    }
  }
}

function reduceRemove(state, action) {
  const newState = { ...state }
  delete newState[action.elementId] // Scary object mutation. If things aren't updating as expected check here.

  return newState
}

// Combined reducer functions

export default reducer(
  {
    [ADD]: reduceAdd,
    [REMOVE]: reduceRemove
  },
  initialState
)

// Action creators

function addElement(elementId, section, label) {
  return {
    type: ADD,
    elementId,
    section,
    label
  }
}

function removeElement(elementId) {
  return {
    type: REMOVE,
    elementId
  }
}

// Export necessary action types and action creators

export { addElement, removeElement }
