import React from 'react'
import PropTypes from 'prop-types'

import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

import TopazColors from 'constants/topaz_colors'

import AssessmentResultsSecondaryNav from 'containers/assessment_results_secondary_nav'

export default function AssessmentsSubMenuContainer({ displayTitle }) {
  return (
    <>
      {displayTitle && (
        <div className='my-views-title'>
          <FontAwesomeIcon
            icon={faUserFriends}
            color={TopazColors.ONYX_TINT_3}
          />
          <span className='ml-[6px]'>MY VIEWS</span>
        </div>
      )}
      <AssessmentResultsSecondaryNav />
    </>
  )
}

AssessmentsSubMenuContainer.propTypes = {
  displayTitle: PropTypes.bool
}

AssessmentsSubMenuContainer.defaultProps = {
  displayTitle: true
}
