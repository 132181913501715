import FileSaver from 'file-saver'

import get from 'lodash/get'

import ApiCallThunkCreator from 'dux/api/api_call_thunk_creator'
import EmplifyRequester from 'utils/http/emplify_requester'
import { selectToken } from 'selectors/login'
import kebabCase from 'lodash/kebabCase'
import {
  createApiCallActionType,
  entities,
  methods,
  statuses
} from '../action_types'

function getAllSurveysAsync(options = {}) {
  const query = get(options, 'query', {})
  const include = get(
    options,
    'query.include',
    'survey-types,survey-statements.statements,survey-prompts.prompts'
  )

  const opts = {
    ...options,
    query: {
      ...query,
      include
    }
  }
  return ApiCallThunkCreator.get('/surveys', null, opts)
}

function getAllSmartPulseSurveysByCategoryAsync(categoryId) {
  return ApiCallThunkCreator.get('/categories/:categoryId/surveys', {
    categoryId
  })
}

function getSurveyAsync(surveyId, options = {}) {
  const query = get(options, 'query', {})
  const include = get(
    options,
    'query.include',
    'survey-types,survey-statements.statements,survey-prompts.prompts,statements.categories'
  )

  const opts = {
    ...options,
    query: {
      ...query,
      include
    }
  }
  return ApiCallThunkCreator.get('/surveys/:surveyId', { surveyId }, opts)
}

function getAllOrganizationSurveysAsync(organizationId, options = {}) {
  const query = get(options, 'query', {})
  const include = get(
    options,
    'query.include',
    'survey-types,survey-statements.statements,survey-prompts.prompts'
  )

  const opts = {
    ...options,
    query: {
      ...query,
      include
    }
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/surveys',
    { organizationId },
    opts
  )
}

function getOrganizationSurveyAsync(organizationId, surveyId, options = {}) {
  const query = get(options, 'query', {})
  const include = get(
    options,
    'query.include',
    'survey-types,survey-statements.statements,survey-prompts.prompts'
  )

  const opts = {
    ...options,
    query: {
      ...query,
      include
    }
  }
  return ApiCallThunkCreator.get(
    '/organizations/:organizationId/surveys/:surveyId',
    { organizationId, surveyId },
    opts
  )
}

function createOrganizationSurveyAsync(organizationId, survey) {
  const params = { organizationId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/surveys',
    params,
    survey
  )
}

function cloneOrganizationSurveyAsync(organizationId, surveyId) {
  const params = { organizationId, surveyId }
  return ApiCallThunkCreator.create(
    '/organizations/:organizationId/surveys/:surveyId/clone',
    params
  )
}

function updateOrganizationSurveyAsync(organizationId, surveyId, survey) {
  return ApiCallThunkCreator.update(
    '/organizations/:organizationId/surveys/:surveyId',
    { organizationId, surveyId },
    survey
  )
}

function createSurveyAsync(survey) {
  return ApiCallThunkCreator.create('/surveys', null, survey)
}

function cloneSurveyAsync(surveyId) {
  const params = { surveyId }
  return ApiCallThunkCreator.create('/surveys/:surveyId/clone', params)
}

function updateSurveyAsync(survey) {
  return ApiCallThunkCreator.update(
    '/surveys/:surveyId',
    { surveyId: survey.id },
    survey
  )
}

function deleteSurveyAsync(surveyId) {
  return ApiCallThunkCreator.destroy('/surveys/:surveyId', { surveyId })
}

function deleteOrganizationSurveyAsync(organizationId, surveyId) {
  return ApiCallThunkCreator.destroy(
    '/organizations/:organizationId/surveys/:surveyId',
    { organizationId, surveyId }
  )
}

function parseXml(xmlFile) {
  const data = { xmlText: xmlFile }
  return ApiCallThunkCreator.create('/surveys/parse-xml', null, data)
}

function importSurvey(assessment, isAvailableToSend) {
  const data = {
    ...assessment,
    isAvailableToSend
  }
  return ApiCallThunkCreator.create('/surveys/import', null, data)
}

function exportSurveyRequest(entity) {
  return {
    type: createApiCallActionType(entity, methods.GET, statuses.REQUEST),
    method: methods.GET,
    status: statuses.REQUEST,
    entity,
    showToast: false
  }
}

function exportSurveySuccess(entity) {
  return {
    type: createApiCallActionType(entity, methods.GET, statuses.SUCCESS),
    method: methods.GET,
    status: statuses.SUCCESS,
    entity,
    showToast: true,
    data: null
  }
}

function exportSurveysFailure(entity, error) {
  return {
    type: createApiCallActionType(entity, methods.GET, statuses.FAILURE),
    method: methods.GET,
    status: statuses.FAILURE,
    entity,
    showToast: true,
    error
  }
}

export function exportSurveyXMLAsync(survey) {
  return function exportSurveysThunk(dispatch, getState) {
    dispatch(exportSurveyRequest(entities.SURVEY_XML))
    const token = selectToken(getState())
    const options = { contentType: 'text/xml' }
    return EmplifyRequester.get(
      `/surveys/${survey.id}/export-xml`,
      token,
      options
    )
      .then(response => {
        dispatch(exportSurveySuccess(entities.SURVEY_XML))
        const csvData = response.data
        const surveyNameSlug = kebabCase(survey.name)
        const filename = `${surveyNameSlug}-${survey.version}`
        const blob = new Blob([csvData], {
          type: `${options.contentType};charset=utf-8`
        })

        // FileSaver.saveAs(_, _, true) excludes UTF8 BOM char
        FileSaver.saveAs(blob, `${filename}.xml`, true)
      })
      .catch(function onError(error) {
        dispatch(exportSurveysFailure(entities.SURVEY_XML, error))
      })
  }
}

export function exportSurveyCSVAsync(survey) {
  return function exportSurveysThunk(dispatch, getState) {
    dispatch(exportSurveyRequest(entities.SURVEY_CSV))
    const token = selectToken(getState())
    const options = { contentType: 'text/csv' }
    return EmplifyRequester.get(
      `/surveys/${survey.id}/export-csv`,
      token,
      options
    )
      .then(response => {
        dispatch(exportSurveySuccess(entities.SURVEY_CSV))
        const csvData = response.data
        const surveyNameSlug = kebabCase(survey.name)
        const filename = `${surveyNameSlug}-${survey.version}`
        const blob = new Blob([csvData], {
          type: `${options.contentType};charset=utf-8`
        })

        // FileSaver.saveAs(_, _, true) excludes UTF8 BOM char
        FileSaver.saveAs(blob, `${filename}.csv`, true)
      })
      .catch(function onError(error) {
        dispatch(exportSurveysFailure(entities.SURVEY_CSV, error))
      })
  }
}

export {
  deleteSurveyAsync,
  deleteOrganizationSurveyAsync,
  getAllSurveysAsync,
  getAllSmartPulseSurveysByCategoryAsync,
  getSurveyAsync,
  getAllOrganizationSurveysAsync,
  getOrganizationSurveyAsync,
  createOrganizationSurveyAsync,
  cloneOrganizationSurveyAsync,
  updateOrganizationSurveyAsync,
  createSurveyAsync,
  cloneSurveyAsync,
  updateSurveyAsync,
  parseXml,
  importSurvey
}
