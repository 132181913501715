import { matchPath } from 'react-router-dom'
import forEach from 'lodash/forEach'

// This list should be kept pretty small
// Every route change will be calling this
const UPDATE_WHITELIST = [
  {
    exact: true,
    path: '/'
  },
  {
    exact: true,
    path: '/group-type-management'
  },
  {
    exact: true,
    path: '/feedback/pulse/:id'
  },
  {
    exact: false,
    path: '/feedback/pulse'
  },
  {
    exact: false,
    path: '/org-overview'
  },
  {
    exact: false,
    path: '/results/:groupTypeId/:groupId'
  },
  {
    exact: false,
    path: '/results/:groupTypeId'
  },
  {
    exact: false,
    path: '/results'
  }
]

/**
 * @class match
 * @type {Object}
 * @property {object} params Key/value pairs parsed from the URL corresponding dynamic path
 * @property {boolean} isExact true if the entire URL was matched (no trailing characters)
 * @property {string} path The path pattern used to match. Useful for building nested <Route>s
 * @property {string} url The matched portion of the URL. Useful for building nested <Link>s
 *
 */

/**
 * Match a route that that runs through a route whitelist
 * Use react router matchPath function to match a route agaisnt an array of routes
 * https://reacttraining.com/react-router/web/api/matchPath
 *
 * @param {Object} route - The the current route object from react-router
 * @param {string} route.pathname - The path name of the current route `/something/123`
 * @param {array} whitelist - Array of routes that can be matched on
 *
 * @returns {match} matched - Match object is returned if a direct match is found
 */
export function matchRouteByWhitelist(
  route = {},
  whitelist = UPDATE_WHITELIST
) {
  let matched = null
  // eslint-disable-next-line consistent-return
  forEach(whitelist, whitelistRoute => {
    const match = matchPath(route.pathname, whitelistRoute)
    if (match) {
      matched = match
      // break each iteration
      return false
    }
  })

  return matched
}
