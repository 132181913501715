import React from 'react'
import PropTypes from 'prop-types'

import Colors from 'constants/colors'

const defaultStyles = {
  display: 'inline-block',
  color: 'rgba(0, 0, 0, 0.87)',
  height: '28px',
  width: '28px',
  userSelect: 'none',
  transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; margin-right: 10px'
}

/**
 * 💎 SvgIcon 💎
 * This icon component was ripped from the Material UI (v0.20.0) SvgIcon component
 * The original documentation can be found here for reference: https://v0.mui.com/#/components/svg-icon
 * The `defaultStyles` above were pulled directly from the material-ui implementation
 *
 * @param {Node} props.children - the required icon children (see MUI documentation above for examples)
 * @param {Object} props.style - optional styles
 * @param {String} props.color - optional color (fill) override
 */
function SvgIcon({ children, style, color }) {
  return (
    <svg
      viewBox='0 0 24 24'
      style={{
        ...defaultStyles,
        ...style,
        fill: color
      }}
    >
      {children}
    </svg>
  )
}

SvgIcon.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  color: PropTypes.string
}

SvgIcon.defaultProps = {
  style: {},
  color: Colors.PRIMARY_BLACK
}

export default SvgIcon
