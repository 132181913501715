import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from './button'

function FileButton({
  className,
  accept,
  onChange,
  handleUpload,
  disabled,
  ...props
}) {
  const cx = cn('tpz-btn-file', {
    [className]: !!className
  })

  return (
    <Button {...props} className={cx} disabled={disabled}>
      <input
        type='file'
        accept={accept}
        disabled={disabled}
        className='tpz-btn-file-input'
        onChange={e => {
          onChange(e)
          return handleUpload(e)
        }}
        style={{
          cursor: 'pointer'
        }}
      />
    </Button>
  )
}

FileButton.propTypes = {
  className: PropTypes.string,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  handleUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
FileButton.defaultProps = {
  onChange: () => {},
  className: null,
  disabled: false
}

export default FileButton
