/**
 * Enum for campaign message types.
 * @readonly
 * @enum {string}
 */
const CAMPAIGN_MESSAGE_TYPES = {
  EMAILS: 'emails',
  SMS: 'sms',
  EMAILS_SMS: 'emails_sms',
  NONE: 'none'
}

export const CAMPAIGN_MESSAGE_TYPE_TEXT = {
  [CAMPAIGN_MESSAGE_TYPES.EMAILS]: 'Email',
  [CAMPAIGN_MESSAGE_TYPES.SMS]: 'SMS',
  [CAMPAIGN_MESSAGE_TYPES.EMAILS_SMS]: 'Email and SMS',
  [CAMPAIGN_MESSAGE_TYPES.NONE]: 'None'
}

export const DROPDOWN_CAMPAIGN_MESSAGE_TYPES = {
  EMAILS: { value: CAMPAIGN_MESSAGE_TYPES.EMAILS, name: 'Email' },
  SMS: { value: CAMPAIGN_MESSAGE_TYPES.SMS, name: 'SMS' },
  EMAIL_SMS: {
    value: CAMPAIGN_MESSAGE_TYPES.EMAILS_SMS,
    name: 'Email and SMS'
  },
  NONE: { value: CAMPAIGN_MESSAGE_TYPES.NONE, name: 'None' }
}

export default CAMPAIGN_MESSAGE_TYPES
