import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Vocab from 'components/vocab'

class Truncated extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      shouldTruncate: false
    }

    this.handleResize = this.handleResize.bind(this)
    this.resizeTimeout = this.resizeTimeout.bind(this)

    this.truncRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeTimeout)

    this.resizeTimeout()
  }

  componentDidUpdate(prevProps) {
    // Recalculate truncation if text changes
    if (prevProps.text !== this.props.text) {
      this.resizeTimeout()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeTimeout)
  }

  resizeTimeout() {
    // Timeout so that everything can render
    setTimeout(() => this.handleResize(), 1000)
  }

  handleResize() {
    let shouldTruncate = false

    const node = this.truncRef.current

    if (node) {
      const span = node.getElementsByTagName('span')[0]

      if (span) {
        // If the span is being ellipsated, then mark as truncated
        if (span.scrollWidth > span.clientWidth) {
          shouldTruncate = true
        }
        // If already truncated
        if (span.className.indexOf('education-vocab') !== -1) {
          // And it still should be truncated (this is very important!)
          if (node.scrollWidth > node.clientWidth) {
            shouldTruncate = true
          }
        }
      }
    }

    if (shouldTruncate !== this.state.shouldTruncate) {
      this.setState({ shouldTruncate })
    }
  }

  render() {
    return (
      <div className='truncated' style={this.props.style} ref={this.truncRef}>
        {this.state.shouldTruncate ? (
          <Vocab
            dict={{
              word: null,
              definition: () => this.props.text
            }}
            analyticEvent={{}}
            spanClassName={this.props.spanClassName}
          >
            {this.props.text}
          </Vocab>
        ) : (
          this.props.children
        )}
      </div>
    )
  }
}

Truncated.propTypes = {
  children: PropTypes.node,
  text: PropTypes.node,
  style: PropTypes.object,
  spanClassName: PropTypes.string // className to override styling if desired
}
Truncated.defaultProps = {
  text: '',
  children: '',
  style: {},
  spanClassName: ''
}

export default Truncated
