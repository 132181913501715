import filter from 'lodash/filter'
import reducer from 'dux/helpers/reducer'
import { entities } from 'dux/api/action_types'
import { onElementRemove } from 'utils/dom/element_removed'
import { 
  COACHING_SESSION_TYPE_NAMES,
  COACHING_SESSION_ONE_ON_ONE_TYPES,
} from 'constants/coaching_session_types'

const initialState = {
  calendlyOpen: false,
  coachingSession: null,
  dirtyCoachingSessions: [],
}

// Constants (i.e. action types)
const SET_COACHING_SESSION = 'calendly/SET_COACHING_SESSION'
const RESET_COACHING_SESSION = 'calendly/RESET_COACHING_SESSION'
const MARK_COACHING_SESSION_AS_DIRTY = 'calendly/MARK_SESSION_AS_DIRTY'
const SET_COACHING_SESSION_AS_REFRESHED =
  'calendly/SET_COACHING_SESSION_AS_REFRESHED'
const SET_CALENDLY_OPEN = 'calendly/SET_CALENDLY_OPEN'

// Reducers
function reduceSetCalendlyCoachingSession(state = initialState, action) {
  return {
    ...state,
    coachingSession: action.coachingSession,
  }
}

function reduceResetCalendlyCoachingSession(state = initialState) {
  return {
    ...state,
    coachingSession: null,
  }
}

function reduceMarkCoachingSessionAsDirty(state = initialState, action) {
  const { dirtyCoachingSessions } = state
  return {
    ...state,
    dirtyCoachingSessions: [...dirtyCoachingSessions, action.coachingSessionId],
  }
}

function reduceSetCoachingSessionAsRefreshed(state = initialState, action) {
  const dirtyCoachingSessions = filter(
    state.dirtyCoachingSessions,
    (id) => id !== action.coachingSessionId,
  )

  return {
    ...state,
    dirtyCoachingSessions,
  }
}

function reduceSetCalendlyOpen(state = initialState, action) {
  return {
    ...state,
    calendlyOpen: action.isOpen,
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_COACHING_SESSION]: reduceSetCalendlyCoachingSession,
    [RESET_COACHING_SESSION]: reduceResetCalendlyCoachingSession,
    [MARK_COACHING_SESSION_AS_DIRTY]: reduceMarkCoachingSessionAsDirty,
    [SET_COACHING_SESSION_AS_REFRESHED]: reduceSetCoachingSessionAsRefreshed,
    [SET_CALENDLY_OPEN]: reduceSetCalendlyOpen,
  },
  initialState,
)

// Action creators
export function setCalendlyCoachingSession(coachingSession) {
  return {
    type: SET_COACHING_SESSION,
    coachingSession,
  }
}

export function markCoachingSessionAsDirty(coachingSessionId) {
  return {
    type: MARK_COACHING_SESSION_AS_DIRTY,
    coachingSessionId,
  }
}

export function setCoachingSessionAsRefreshed(coachingSessionId) {
  return {
    type: SET_COACHING_SESSION_AS_REFRESHED,
    coachingSessionId,
  }
}

export function setCalendlyOpen(isOpen) {
  return {
    type: SET_CALENDLY_OPEN,
    isOpen,
  }
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {boolean} - Is one on one coaching session
 */
function isOneOnOneCoachingSession(coachingSession) {
  const coachingSessionTypeSlug = getCoachingSessionTypeSlug(coachingSession)
  return COACHING_SESSION_ONE_ON_ONE_TYPES.includes(coachingSessionTypeSlug)
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {string} - Session type slug
 */
function getCoachingSessionTypeSlug(coachingSession) {
  const { coachingSubscription } = coachingSession
  const { coachingSubscriptionType } = coachingSubscription
  const { coachingSessionType } = coachingSubscriptionType
  const { name: coachingSessionTypeSlug } = coachingSessionType
  return coachingSessionTypeSlug
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {string} - Session type
 */
function getSessionType(coachingSession) {
  const coachingSessionTypeSlug = getCoachingSessionTypeSlug(coachingSession)
  return COACHING_SESSION_TYPE_NAMES[coachingSessionTypeSlug] || 'Unknown'
}

/**
 * Determine session title to prefill calendly
 * Prefilling is best-effort, if sufficient data is
 * not available the title will be empty.
 *
 * @param {Object} coachingSession - Coaching session object
 * @returns {string} - Session title
 */
function getSessionTitle(coachingSession) {
  // Prefill name for 1:1 session if available
  if (isOneOnOneCoachingSession(coachingSession)) {
    const { firstName, lastName } = coachingSession.participants[0].person
    return firstName && lastName ? `${firstName} ${lastName}` : ''
  }

  // Use cohort name for group sessions if available
  const { coachingSubscriptionCohortName } = coachingSession
  return coachingSubscriptionCohortName || ''
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {string} - Session name
 */
function getSessionName(coachingSession) {
  const sessionType = getSessionType(coachingSession)
  const sessionTitle = getSessionTitle(coachingSession)
  return sessionTitle && sessionType
    ? `15five - ${sessionTitle} [${sessionType}]`
    : ''
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {string} - Prefill email address
 */
function getMainPrefillEmailAddress(coachingSession) {
  let prefillEmailAddress = ''

  const { coachingSubscription } = coachingSession
  const { designatedScheduler } = coachingSubscription
  if (designatedScheduler) {
    prefillEmailAddress = designatedScheduler.emailAddress
  }

  return prefillEmailAddress
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @returns {Object} - Prefill data
 */
function getCalendlyPreFill(coachingSession) {
  const sessionName = getSessionName(coachingSession)
  const prefillEmailAddress = getMainPrefillEmailAddress(coachingSession)

  return {
    name: sessionName,
    email: prefillEmailAddress,
  }
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @param {string} url - Calendly url
 * @returns {string} - Participant emails
 */
function getParticipantEmails(coachingSession) {
  const { participants, coachingSubscription } = coachingSession
  const { designatedScheduler } = coachingSubscription
  return participants?.length > 0
    ? participants
        .filter(
          ({ person }) =>
            person.emailAddress !== designatedScheduler?.emailAddress,
        )
        .map(({ person }) => person.emailAddress)
        .join(',')
    : ''
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @param {string} url - Calendly url
 * @returns {string} - Calendly url
 */
function getCalendlyUrl(coachingSession, url) {
  const participantEmails = getParticipantEmails(coachingSession)
  return participantEmails
    ? `${url}?guests=${encodeURIComponent(participantEmails)}`
    : url
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @param {string} url - Calendly url
 * @returns {Object} - Calendly options
 */
function getCalendlyOptions(coachingSession, url) {
  return {
    url: getCalendlyUrl(coachingSession, url),
    utm: {
      utmSource: `${coachingSession.id}`,
      utmMedium: entities.COACHING_SESSIONS,
    },
    prefill: getCalendlyPreFill(coachingSession),
  }
}

/**
 * @param {Object} coachingSession - Coaching session object
 * @param {string} url - Calendly url
 * @returns {Function} - Dispatch function
 */
export function openCalendlyLink(coachingSession, url) {
  return (dispatch) => {
    const options = getCalendlyOptions(coachingSession, url)

    const onPopupClose = () => {
      dispatch(setCalendlyOpen(false))
    }

    window.Calendly.initPopupWidget(options)

    const popupWidget = document.getElementsByClassName('calendly-popup')
    if (popupWidget && popupWidget.length) {
      onElementRemove(popupWidget[0], onPopupClose)
    }

    dispatch(setCalendlyCoachingSession(coachingSession))
    return dispatch(setCalendlyOpen(true))
  }
}
