import React from 'react'

import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import FontAwesomeIcon from 'furniture/font_awesome_icon'

function RowExpandIcon() {
  return <FontAwesomeIcon icon={faChevronDown} />
}

export default RowExpandIcon
