import get from 'lodash/get'
import includes from 'lodash/includes'
import { statuses } from 'dux/api/action_types'
import { isPaginated, getPageKey, parseOptions } from 'utils/pagination'

const initState = {
  employees: {},
  people: {}
}

function setPageData(state, action) {
  const entity = get(action, 'entity', null)
  const data = get(action, `data.entities[${entity}]`, null)

  const newState = state
  if (data) {
    const params = get(action, 'params', null)
    const pageOptions = parseOptions(action, 'page')
    const searchOptions = parseOptions(action, 'filter', true)

    const pageKey = getPageKey(params, pageOptions, searchOptions)
    newState[entity][pageKey] = Object.keys(data)
  }

  return newState
}

export default function reducer(state = initState, action) {
  if (!action || !action.type) {
    return state
  }

  if (action.status !== statuses.SUCCESS) {
    return state
  }

  if (includes(Object.keys(initState), action.entity) && isPaginated(action)) {
    return setPageData(state, action)
  }

  return state
}
