import reducer from 'dux/helpers/reducer'
import { SWITCH_ORGANIZATION } from 'dux/organizations'
import { getAllCampaignResultsAsync } from 'dux/api/actions/campaign_results'
import { getAllCampaignsByOrgIdAsync } from 'dux/api/actions/campaigns'

const initialState = {
  'group-results': {
    hasFetched: {
      // id: bool
    }
  }
}

// Constants (i.e. action types)
const SET_HAS_FETCHED_GROUP_RESULTS =
  'summarize_score_card/SET_HAS_FETCHED_GROUP_RESULTS'

// Reducers
function reduceHasFetchedGroupResults(state, action) {
  const { groupId } = action

  return {
    ...state,
    'group-results': {
      ...state['group-results'],
      hasFetched: {
        ...state['group-results'].hasFetched,
        [groupId]: true
      }
    }
  }
}

function reduceSwitchOrganization(state) {
  return {
    ...state,
    ...initialState
  }
}

// Combined reducer functions
export default reducer(
  {
    [SET_HAS_FETCHED_GROUP_RESULTS]: reduceHasFetchedGroupResults,
    [SWITCH_ORGANIZATION]: reduceSwitchOrganization
  },
  initialState
)

// Action creators
function setHasFetchedGroupResults(groupId) {
  return {
    type: SET_HAS_FETCHED_GROUP_RESULTS,
    groupId
  }
}

function fetchOrganizationLevelDataAsync(organizationId) {
  return function fetchOrganizationLevelDataThunk(dispatch) {
    dispatch(getAllCampaignsByOrgIdAsync(organizationId))
    return dispatch(getAllCampaignResultsAsync(organizationId))
  }
}

// Export necessary action types and action creators
export {
  // exported for unit tests
  SET_HAS_FETCHED_GROUP_RESULTS,
  // exported for actual use
  setHasFetchedGroupResults,
  fetchOrganizationLevelDataAsync
}
