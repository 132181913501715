import { createSelector } from 'reselect'

function getLogin(state) {
  return state.login || {}
}

function getPeople(state) {
  return state.entities.people || {}
}

const getPreviousLoginAt = state => state.login.previousLoginAt

export const selectUser = createSelector(
  [getLogin, getPeople],
  (login, people) => people[login.personId] || {}
)

export const selectToken = createSelector(
  getLogin,
  function getToken(login) {
    return login.token ? login.token : null
  }
)

export const selectIsFirstLogin = createSelector(
  getPreviousLoginAt,
  previousLoginAt => {
    if (previousLoginAt === null) {
      return true
    }
    return false
  }
)
