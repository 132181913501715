import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Dialog from 'furniture/dialog'
import { Button, BUTTON_RANKS } from 'furniture/buttons'
import { clearToastMessage, clearToastDialogMessageDetail } from 'dux/toast'
import {
  Toaster as FurnitureToaster,
  TOAST_DURATIONS,
  TOAST_POSITIONS
} from 'furniture/toast'

function Toaster({ position, actions, messageDetails, duration }) {
  const [showDialog, setShowDialog] = useState(false)
  const handleDetailDialogClose = e => {
    e.stopPropagation()
    setShowDialog(false)
    actions.clearToastDialogMessageDetail()
  }

  return (
    <>
      <FurnitureToaster
        setShowDialog={setShowDialog}
        toastOptions={{ position, duration }}
        messageDetails={messageDetails}
      />
      <Dialog
        title='Error Detail'
        actions={[
          <Button
            key={1}
            rank={BUTTON_RANKS.TERTIARY}
            label='Close'
            onClick={handleDetailDialogClose}
          />
        ]}
        open={showDialog}
        onRequestClose={handleDetailDialogClose}
      >
        {messageDetails}
      </Dialog>
    </>
  )
}

function mapStateToProps(state) {
  return {
    message: state.toast.message,
    messageDetails: state.toast.messageDetail,
    messageType: state.toast.messageType,
    isOpen: state.toast.isOpen,
    showModal: state.toast.showModal
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearToastMessage,
        clearToastDialogMessageDetail
      },
      dispatch
    )
  }
}
Toaster.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  messageDetails: PropTypes.string,
  position: PropTypes.oneOf(Object.values(TOAST_POSITIONS)),
  duration: PropTypes.oneOf(Object.values(TOAST_DURATIONS))
}
Toaster.defaultProps = {
  messageDetails: '',
  position: TOAST_POSITIONS.bottomCenter,
  duration: TOAST_DURATIONS.normal
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toaster)
