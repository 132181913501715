const DEEP_CERULEAN = '#2d536c' // Darker blue that is used in text and links
const LIGHT_CERULEAN = '#d6eaf6'
const METHODOLOGY_AMBER = '#F1BF30'
const SUCCESS_GREEN = '#89C445'
const DESTRUCTIVE_RED = '#C23934'
const DISABLED_GRAY = '#dcdcdc'
const EMPLIFY_BLUE = '#5CA9DC'

// Tints
const EMERALD_TINT_7 = '#dbedc7'
const EMERALD_800 = '#527629'
const TURQOISE_TINT_7 = '#b3e4e4'
const RUBY_TINT_7 = '#ecc3c2'
const RUBY_600 = '#AF332F'
const PEARL = '#f6f2eb'
const ONYX_TINT_10 = '#f8f8f8'
const ONYX_TINT_9 = '#eaeaea'
const ONYX_TINT_3 = '#6f6f6f'
const AMBER_400 = '#f5d26e'

// Grays
const GRAY_100 = '#e6e6e6'
const DARK_GRAY = '#323232'

export default {
  DEEP_CERULEAN,
  LIGHT_CERULEAN,
  METHODOLOGY_AMBER,
  SUCCESS_GREEN,
  DESTRUCTIVE_RED,
  DISABLED_GRAY,
  EMERALD_TINT_7,
  EMERALD_800,
  TURQOISE_TINT_7,
  RUBY_TINT_7,
  PEARL,
  ONYX_TINT_10,
  ONYX_TINT_9,
  ONYX_TINT_3,
  EMPLIFY_BLUE,
  GRAY_100,
  AMBER_400,
  RUBY_600,
  DARK_GRAY
}
